import React, { useEffect, useState } from 'react'
import { Input, message } from 'antd';
import InputField from '../../../components/inputcomponents/InputField';
import { Validators } from '../../../components/Validators/Validators'
import DropDown from '../../../components/inputcomponents/DropDown';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { authHeader } from '../../../helpers/usertoken/UserToken';
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';

import { name_reg_string, name_reg_validation, email_reg_validation, mobile_reg_validation } from '../../../helpers/constants/ConstantReg';


function CreateContact() {

    const { contact_id } = useParams()
    const { pathname } = useLocation()
    const { state } = useLocation()
    const navigate = useNavigate()

    const name = useSelector(state => state.Auth.name)
    const id = useSelector(state => state.Auth.id)

    const department = useSelector(state => state.Auth.department)


    const [source_arr, setsource_arr] = useState([])
    const [industry_arr, setindustry_arr] = useState([])
    const [status_arr, setStatus_arr] = useState([])
    const [edit, setedit] = useState(false)


    let [errors, seterrors] = useState(0)


    const restButton = {
        contact_owner: name,
        company_name: "",
        contact_name: "",
        phone: "",
        email: "",
        designation: "",
        contact_source: "",
        website: "",
        industry: "",
        company_phone: "",
        company_email: "",
        secondary_contact_person: "",
        secondary_contact_phone: "",
        secondary_contact_designation: "",
        status: "",
        street: "",
        city: "",
        state: "",
        country: "",
        pin_code: "",
        description: ""
    }
    const [data, setdata] = useState({
        contact_owner: name,
        company_name: "",
        contact_name: "",
        phone: "",
        email: "",
        designation: "",
        contact_source: "",
        website: "",
        industry: "",
        company_phone: "",
        company_email: "",
        secondary_contact_person: "",
        secondary_contact_phone: "",
        secondary_contact_designation: "",
        status: "",
        street: "",
        city: "",
        state: "",
        country: "",
        pin_code: "",
        description: ""
    })
    const [dataerr, setdataerr] = useState({
        contact_name_err: {},
        phone_err: {},
        email_err: {},
        designation_err: {},
        secondary_con_per_err: {},
        secondary_con_pho_err: {},
        secondary_con_des_err: {},
        company_err: {},
        company_phone_err: {},
        company_email_err: {},
        websiteerr: {},
        contact_source_err: {},
        contact_status_err: {},
        contact_owner_err: {},
        industry_err: {},
        street_err: {},
        city_err: {},
        state_err: {},
        pincode_err: {},
        country_err: {},
        description_err: {}
    })

    useEffect(() => {
        if (contact_id && pathname.split('/')[1] === 'edit_contact') {
            setedit(true)
            axios.get(`contact/get/${contact_id}`, authHeader).then(response => {
                const singleData = response.data.datas[0];
                setdata({
                    contact_owner: singleData?.contact_owner.name,
                    company_name: singleData?.company_name,
                    contact_name: singleData?.contact_name,
                    phone: singleData?.phone,
                    email: singleData?.email,
                    designation: singleData?.designation,
                    contact_source: singleData?.contact_source?.id,
                    website: singleData?.website,
                    industry: singleData?.industry?.id,
                    company_phone: singleData?.company_phone,
                    company_email: singleData?.company_email,
                    secondary_contact_person: singleData?.secondary_contact_person,
                    secondary_contact_phone: singleData?.secondary_contact_phone,
                    secondary_contact_designation: singleData?.secondary_contact_designation,
                    status: singleData?.status,
                    street: singleData?.street,
                    city: singleData?.city,
                    state: singleData?.state,
                    country: singleData?.country,
                    pin_code: singleData?.pin_code,
                    description: singleData?.description
                });
            })

        } else {
            setedit(false)
        }


        setStatus_arr([
            {
                id: "Active",
                value: "Active"
            },
            {
                id: "In Active",
                value: "In Active"
            },
            {
                id: "Connected",
                value: "Connected"
            },
            {
                id: "Not Connected",
                value: "Not Connected"
            },
            {
                id: "Old Client",
                value: "Old Client"
            }
        ])

        axios.get('source/get', authHeader)
            .then((res) => {
                const source_arr_created = []
                res.data.datas.forEach(d => {
                    let data = {}
                    data['label'] = d.source_name
                    data['value'] = d.source_name
                    data['id'] = d.id
                    source_arr_created.push(data)
                })
                setsource_arr(source_arr_created)

            })
        axios.get('industry/get', authHeader)
            .then((res) => {

                const industry_arr_created = []
                res.data.datas.forEach(d => {
                    let data = {}
                    data['label'] = d.industry_name
                    data['value'] = d.industry_name
                    data['id'] = d.id
                    industry_arr_created.push(data)
                })
                setindustry_arr(industry_arr_created)
            })


    }, [])

    function resetForm() {
        setdata(restButton)
    }

    function handleChange({ key, value }) {
        if (key === 'contact_name') {
            setdataerr({ ...dataerr, contact_name_err: {} })
        } else if (key === 'title') {
            setdataerr({ ...dataerr, title_err: {} })
        } else if (key === 'phone') {
            setdataerr({ ...dataerr, phone_err: {} })
        } else if (key === 'email') {
            setdataerr({ ...dataerr, email_err: {} })
        } else if (key === 'designation') {
            setdataerr({ ...dataerr, designation_err: {} })
        } else if (key === 'company_name') {
            setdataerr({ ...dataerr, company_err: {} })
        } else if (key === 'contact_owner') {
            setdataerr({ ...dataerr, contact_owner_err: {} })
        } else if (key === 'status') {
            setdataerr({ ...dataerr, contact_status_err: {} })
        } else if (key === 'country') {
            setdataerr({ ...dataerr, country_err: {} })
        } else if (key === 'state') {
            setdataerr({ ...dataerr, state_err: {} })
        } else if (key === 'city') {
            setdataerr({ ...dataerr, city_err: {} })
        }
        setdata({ ...data, [key]: value })

    }

    function backendErrorMsg(err) {
        if (err?.response?.data?.errors?.company_name) {
            setdataerr({
                ...dataerr,
                contact_name_err: { error: true, message: err?.response?.data?.errors?.company_name }
            });
        }
        if (err?.response?.data?.errors?.phone) {
            setdataerr({ ...dataerr, phone_err: { error: true, message: err?.response?.data?.errors?.phone } });
        }
        if (err?.response?.data?.errors?.email) {
            setdataerr({ ...dataerr, email_err: { error: true, message: err?.response?.data?.errors?.email } });
        }
        if (err?.response?.data?.errors?.designation) {
            setdataerr({
                ...dataerr,
                designation_err: { error: true, message: err?.response?.data?.errors?.designation }
            });
        }
        if (err?.response?.data?.errors?.contact_source) {
            setdataerr({
                ...dataerr,
                contact_source_err: { error: true, message: err?.response?.data?.errors?.contact_source }
            });
        }
        if (err?.response?.data?.errors?.website) {
            setdataerr({ ...dataerr, websiteerr: { error: true, message: err?.response?.data?.errors?.website } });
        }
        if (err?.response?.data?.errors?.industry) {
            setdataerr({ ...dataerr, industry_err: { error: true, message: err?.response?.data?.errors?.industry } });
        }
        if (err?.response?.data?.errors?.company_phone) {
            setdataerr({
                ...dataerr,
                company_phone_err: { error: true, message: err?.response?.data?.errors?.company_phone }
            });
        }
        if (err?.response?.data?.errors?.company_email) {
            setdataerr({
                ...dataerr,
                company_email_err: { error: true, message: err?.response?.data?.errors?.company_email }
            });
        }
        if (err?.response?.data?.errors?.secondary_contact_person) {
            setdataerr({
                ...dataerr,
                secondary_con_per_err: { error: true, message: err?.response?.data?.errors?.secondary_contact_person }
            });
        }
        if (err?.response?.data?.errors?.secondary_contact_phone) {
            setdataerr({
                ...dataerr,
                secondary_con_pho_err: { error: true, message: err?.response?.data?.errors?.secondary_contact_phone }
            });
        }
        if (err?.response?.data?.errors?.secondary_contact_designation) {
            setdataerr({
                ...dataerr,
                secondary_con_des_err: {
                    error: true,
                    message: err?.response?.data?.errors?.secondary_contact_designation
                }
            });
        }
        if (err?.response?.data?.errors?.status) {
            setdataerr({
                ...dataerr,
                contact_status_err: { error: true, message: err?.response?.data?.errors?.status }
            });
        }
        if (err?.response?.data?.errors?.street) {
            setdataerr({ ...dataerr, street_err: { error: true, message: err?.response?.data?.errors?.street } });
        }
        if (err?.response?.data?.errors?.city) {
            setdataerr({ ...dataerr, city_err: { error: true, message: err?.response?.data?.errors?.city } });
        }
        if (err?.response?.data?.errors?.state) {
            setdataerr({ ...dataerr, state_err: { error: true, message: err?.response?.data?.errors?.state } });
        }
        if (err?.response?.data?.errors?.country) {
            setdataerr({ ...dataerr, country_err: { error: true, message: err?.response?.data?.errors?.country } });
        }
        if (err?.response?.data?.errors?.pin_code) {
            setdataerr({ ...dataerr, pincode_err: { error: true, message: err?.response?.data?.errors?.pin_code } });
        }
        if (err?.response?.data?.errors?.description) {
            setdataerr({
                ...dataerr,
                description_err: { error: true, message: err?.response?.data?.errors?.description }
            });
        }
        if (err?.response.data.errors?.status === 422) {
            message.error(err?.response.data.errors?.data);
        }

    }

    function addcontact() {
        if (!data.contact_name && data.contact_name.length < 3) {
            setdataerr({ ...dataerr, contact_name_err: { error: true, message: 'Contact name should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.contact_name)) {
            setdataerr({ ...dataerr, contact_name_err: { error: true, message: `This contact name should contains only characters ${name_reg_string}` } })
        } else if (!data.phone || data.phone.length !== 10) {
            setdataerr({ ...dataerr, phone_err: { error: true, message: !mobile_reg_validation(data.phone) && data.phone.length > 1 ? 'Enter a valid 10 digit mobile number' : 'This field is required' } })
        } else if (!data.email) {
            setdataerr({ ...dataerr, email_err: { error: true, message: 'This field is required' } })
        } else if (data.email && !email_reg_validation(data.email)) {
            setdataerr({ ...dataerr, email_err: { error: true, message: 'Enter a valid email address' } })
        } else if (!data.designation && data.designation.length < 3) {
            setdataerr({ ...dataerr, designation_err: { error: true, message: 'Designation should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.designation)) {
            setdataerr({ ...dataerr, designation_err: { error: true, message: `This designation should contains only characters ${name_reg_string}` } })
        } else if (!data.company_name && data.company_name.length < 3) {
            setdataerr({ ...dataerr, company_err: { error: true, message: 'Company name should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.company_name)) {
            setdataerr({ ...dataerr, company_err: { error: true, message: `This company should contains only characters ${name_reg_string}` } })
        } else if (!data.contact_source) {
            setdataerr({ ...dataerr, contact_source_err: { error: true, message: 'This field is required' } })
        } else if (!data.industry) {
            setdataerr({ ...dataerr, industry_err: { error: true, message: 'This field is required' } })
        } else if (!data.status) {
            setdataerr({ ...dataerr, contact_status_err: { error: true, message: 'This field is required' } })
        } else if (!data.contact_owner) {
            setdataerr({ ...dataerr, contact_owner_err: { error: true, message: 'This field is required' } })
        } else if (!data.city) {
            setdataerr({ ...dataerr, city_err: { error: true, message: 'City should be atleast 3 characters' } })
        } else if (data.city.length < 3) {
            setdataerr({ ...dataerr, city_err: { error: true, message: 'City should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.city)) {
            setdataerr({ ...dataerr, city_err: { error: true, message: `This city should contains only characters ${name_reg_string}` } })
        } else if (!data.state) {
            setdataerr({ ...dataerr, state_err: { error: true, message: 'State should be atleast 3 characters' } })
        } else if (data.state.length < 3) {
            setdataerr({ ...dataerr, city_err: { error: true, message: 'City should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.state)) {
            setdataerr({ ...dataerr, state_err: { error: true, message: `This state should contains only characters ${name_reg_string}` } })
        } else if (!data.country) {
            setdataerr({ ...dataerr, country_err: { error: true, message: 'Country  should be atleast 3 characters' } })
        } else if (data.country.length < 3) {
            setdataerr({ ...dataerr, city_err: { error: true, message: 'City should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.country)) {
            setdataerr({ ...dataerr, country_err: { error: true, message: `This country should contains only characters ${name_reg_string}` } })
        } else if (data.pin_code.length != 6) {
            setdataerr({ ...dataerr, pincode_err: { error: true, message: 'Pincode should be 6 characters' } })
        } else if (!mobile_reg_validation(data.pin_code)) {
            setdataerr({ ...dataerr, pincode_err: { error: true, message: 'Enter a valid Pincode' } })
        } else {

            setdataerr({ ...dataerr, pincode_err: { error: false, message: '' } })



            let send_data = {
                'contact_owner': id,
                'company_name': data.company_name,
                'contact_name': data.contact_name,
                'phone': data.phone,
                'email': data.email,
                'designation': data.designation,
                'contact_source': data.contact_source,
                'website': data.website,
                'industry': data.industry,
                'company_phone': data.company_phone,
                'company_email': data.company_email,
                'secondary_contact_person': data.secondary_contact_person,
                'secondary_contact_phone': data.secondary_contact_phone,
                'secondary_contact_designation': data.secondary_contact_designation,
                'status': data.status,
                'street': data.street,
                'city': data.city,
                'state': data.state,
                'country': data.country,
                'pin_code': data.pin_code,
                'description': data.description,
                'department_id': department[0].id
            }

            axios.post('contact/create', send_data, authHeader)
                .then((res) => {
                    toast.success('Contact created successfully')
                    resetForm()
                    navigate(-1)
                }).catch((err) => {
                    backendErrorMsg(err)
                })

        }
    }

    function editContact() {
        if (!data.contact_name && data.contact_name.length < 3) {
            setdataerr({ ...dataerr, contact_name_err: { error: true, message: 'Contact name should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.contact_name)) {
            setdataerr({ ...dataerr, contact_name_err: { error: true, message: `This contact name should contains only characters ${name_reg_string}` } })
        } else if (!data.phone || data.phone.length !== 10) {
            setdataerr({ ...dataerr, phone_err: { error: true, message: !mobile_reg_validation(data.phone) && data.phone.length > 1 ? 'Enter a valid 10 digit mobile number' : 'This field is required' } })
        } else if (!data.email) {
            setdataerr({ ...dataerr, email_err: { error: true, message: 'This field is required' } })
        } else if (data.email && !email_reg_validation(data.email)) {
            setdataerr({ ...dataerr, email_err: { error: true, message: 'Enter a valid email address' } })
        } else if (!data.designation && data.designation.length < 3) {
            setdataerr({ ...dataerr, designation_err: { error: true, message: 'Designation should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.designation)) {
            setdataerr({ ...dataerr, designation_err: { error: true, message: `This designation should contains only characters ${name_reg_string}` } })
        } else if (!data.company_name && data.company_name.length < 3) {
            setdataerr({ ...dataerr, company_err: { error: true, message: 'Company name should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.company_name)) {
            setdataerr({ ...dataerr, company_err: { error: true, message: `This company should contains only characters ${name_reg_string}` } })
        } else if (!data.contact_source) {
            setdataerr({ ...dataerr, contact_source_err: { error: true, message: 'This field is required' } })
        } else if (!data.industry) {
            setdataerr({ ...dataerr, industry_err: { error: true, message: 'This field is required' } })
        } else if (!data.status) {
            setdataerr({ ...dataerr, contact_status_err: { error: true, message: 'This field is required' } })
        } else if (!data.contact_owner) {
            setdataerr({ ...dataerr, contact_owner_err: { error: true, message: 'This field is required' } })
        } else if (!data.city) {
            setdataerr({ ...dataerr, city_err: { error: true, message: 'City should be atleast 3 characters' } })
        } else if (data.city.length < 3) {
            setdataerr({ ...dataerr, city_err: { error: true, message: 'City should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.city)) {
            setdataerr({ ...dataerr, city_err: { error: true, message: `This city should contains only characters ${name_reg_string}` } })
        } else if (!data.state) {
            setdataerr({ ...dataerr, state_err: { error: true, message: 'State should be atleast 3 characters' } })
        } else if (data.state.length < 3) {
            setdataerr({ ...dataerr, city_err: { error: true, message: 'City should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.state)) {
            setdataerr({ ...dataerr, state_err: { error: true, message: `This state should contains only characters ${name_reg_string}` } })
        } else if (!data.country) {
            setdataerr({ ...dataerr, country_err: { error: true, message: 'Country  should be atleast 3 characters' } })
        } else if (data.country.length < 3) {
            setdataerr({ ...dataerr, city_err: { error: true, message: 'City should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.country)) {
            setdataerr({ ...dataerr, country_err: { error: true, message: `This country should contains only characters ${name_reg_string}` } })
        } else if (data.pin_code.length != 6) {
            setdataerr({ ...dataerr, pincode_err: { error: true, message: 'Pincode should be 6 characters' } })
        } else if (!mobile_reg_validation(data.pin_code)) {
            setdataerr({ ...dataerr, pincode_err: { error: true, message: 'Enter a valid Pincode' } })
        } else {

            setdataerr({ ...dataerr, pincode_err: { error: false, message: '' } })

            let sendData = {
                'id': contact_id,
                'contact_owner': id,
                'company_name': data.company_name,
                'contact_name': data.contact_name,
                'phone': data.phone,
                'email': data.email,
                'designation': data.designation,
                'contact_source': data.contact_source,
                'website': data.website,
                'industry': data.industry,
                'company_phone': data.company_phone,
                'company_email': data.company_email,
                'secondary_contact_person': data.secondary_contact_person,
                'secondary_contact_phone': data.secondary_contact_phone,
                'secondary_contact_designation': data.secondary_contact_designation,
                'status': data.status,
                'street': data.street,
                'city': data.city,
                'state': data.state,
                'country': data.country,
                'pin_code': data.pin_code,
                'description': data.description,
                'department_id': department[0].id
            }

            axios.put(`contact/update/${contact_id}`, sendData, authHeader).then((res) => {
                toast.success('Contact updated successfully')
                navigate(-1)
            }).catch((err) => {
                backendErrorMsg(err)
            })
        }
    }

    function selectedvalue(v) {
        if (v?.id) {
            setdataerr({ ...dataerr, [v.nameerr]: {} })
            setdata({ ...data, [v.name]: v.id });
        }
    }

    function goback() {
        setdata({})
        setdataerr({})
        navigate(-1)
    }

    let email_reg = new RegExp(
        '^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    )
    let mobile_reg = new RegExp('^[0-9]+$')
    function saveandcontinue() {
        if (!data.contact_name && data.contact_name.length < 3) {
            setdataerr({ ...dataerr, contact_name_err: { error: true, message: 'Contact name should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.contact_name)) {
            setdataerr({ ...dataerr, contact_name_err: { error: true, message: 'This contact name should contains only characters ${name_reg_string}' } })
        } else if (!data.phone || data.phone.length !== 10) {
            setdataerr({ ...dataerr, phone_err: { error: true, message: !mobile_reg.test(data.phone) && data.phone.length > 1 ? 'Enter a valid 10 digit mobile number' : 'This field is required' } })
        } else if (!data.email) {
            setdataerr({ ...dataerr, email_err: { error: true, message: 'This field is required' } })
        } else if (data.email && !email_reg.test(data.email)) {
            setdataerr({ ...dataerr, email_err: { error: true, message: 'Enter a valid email address' } })
        } else if (!data.designation && data.designation.length < 3) {
            setdataerr({ ...dataerr, designation_err: { error: true, message: 'Designation should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.designation)) {
            setdataerr({ ...dataerr, designation_err: { error: true, message: 'This designation should contains only characters ${name_reg_string}' } })
        } else if (!data.company_name && data.company_name.length < 3) {
            setdataerr({ ...dataerr, company_err: { error: true, message: 'Company name should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.company_name)) {
            setdataerr({ ...dataerr, company_err: { error: true, message: 'This company should contains only characters ${name_reg_string}' } })
        } else if (!data.contact_source) {
            setdataerr({ ...dataerr, contact_source_err: { error: true, message: 'This field is required' } })
        } else if (!data.industry) {
            setdataerr({ ...dataerr, industry_err: { error: true, message: 'This field is required' } })
        } else if (!data.status) {
            setdataerr({ ...dataerr, contact_status_err: { error: true, message: 'This field is required' } })
        } else if (!data.contact_owner) {
            setdataerr({ ...dataerr, contact_owner_err: { error: true, message: 'This field is required' } })
        } else if (!data.city) {
            setdataerr({ ...dataerr, city_err: { error: true, message: 'City should be atleast 3 characters' } })
        } else if (data.city.length < 3) {
            setdataerr({ ...dataerr, city_err: { error: true, message: 'City should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.city)) {
            setdataerr({ ...dataerr, city_err: { error: true, message: 'This city should contains only characters ${name_reg_string}' } })
        } else if (!data.state) {
            setdataerr({ ...dataerr, state_err: { error: true, message: 'State should be atleast 3 characters' } })
        } else if (data.state.length < 3) {
            setdataerr({ ...dataerr, city_err: { error: true, message: 'City should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.state)) {
            setdataerr({ ...dataerr, state_err: { error: true, message: 'This state should contains only characters ${name_reg_string}' } })
        } else if (!data.country) {
            setdataerr({ ...dataerr, country_err: { error: true, message: 'Country  should be atleast 3 characters' } })
        } else if (data.country.length < 3) {
            setdataerr({ ...dataerr, city_err: { error: true, message: 'City should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.country)) {
            setdataerr({ ...dataerr, country_err: { error: true, message: 'This country should contains only characters ${name_reg_string}' } })
        } else if (data.pin_code.length != 6) {
            setdataerr({ ...dataerr, pincode_err: { error: true, message: 'Pincode should be 6 characters' } })
        } else if (!mobile_reg.test(data.pin_code)) {
            setdataerr({ ...dataerr, pincode_err: { error: true, message: 'Enter a valid Pincode' } })
        } else {

            setdataerr({ ...dataerr, pincode_err: { error: false, message: '' } })


            let send_data = {
                'contact_owner': id,
                'company_name': data.company_name,
                'contact_name': data.contact_name,
                'phone': data.phone,
                'email': data.email,
                'designation': data.designation,
                'contact_source': data.contact_source,
                'website': data.website,
                'industry': data.industry,
                'company_phone': data.company_phone,
                'company_email': data.company_email,
                'secondary_contact_person': data.secondary_contact_person,
                'secondary_contact_phone': data.secondary_contact_phone,
                'secondary_contact_designation': data.secondary_contact_designation,
                'status': data.status,
                'street ': data.street,
                'city': data.city,
                'state': data.state,
                'country': data.country,
                'pin_code': data.pin_code,
                'description': data.description,
                'department_id': department[0].id
            }
            axios.post('contact/create', send_data, authHeader)
                .then((res) => {
                    toast.success('Added Successfully')
                    resetForm()

                }).catch((err) => {
                    toast.error('OOPS! Something Went Wrong')
                })
        }
    }

    const { TextArea } = Input;





    return (
        <div style={{ width: '90%', margin: 'auto', marginBottom: '70px' }}>
            <Toaster />
            <div className="mt-5" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'sticky',
                top: '40px',
                background: 'white',
                zIndex: 100
            }}>
                <div style={{ marginTop: '40px' }}>
                    <h5 style={{ marginBottom: '6px' }}>{!edit ? 'Create Contact' : 'Edit Contact'}</h5>
                    <p style={{
                        fontSize: '14px',
                        fontWeight: '400'
                    }}>{!edit ? "Use the below form to create a New Contact" : "Use the below form to edit a existing Contact"}</p>
                </div>

                <div
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end' }}>

                    <div onClick={() => goback()}>
                        <SecondaryButton btn_name="Cancel" />
                    </div>

                    {!edit ?
                        <div onClick={() => saveandcontinue()}>
                            <SecondaryButton btn_name='Save and New' />
                        </div> : ''
                    }

                    <div onClick={(edit) ? editContact : addcontact}>
                        <PrimaryButton btn_name={edit ? 'Update' : 'Save'} />
                    </div>
                </div>


            </div>

            <div className="row mt-3">
                <div className="col-md-6">
                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <span>{ }</span>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Contact Name</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'contact_name'}
                            error_obj={dataerr.contact_name_err}
                            value={data.contact_name}
                            validators={[{
                                check: Validators.required,
                                message: 'This field is required',
                                errors: (v) => seterrors([...errors, v])
                            }, {
                                check: Validators.only_character_and_space,
                                message: `Contact name should only contain ${name_reg_string}`,
                                errors: (v) => seterrors([...errors, v])
                            }, {
                                check: Validators.minimum_length_required,
                                message: 'Contact name should be atleast 3 characters',
                                errors: (v) => seterrors([...errors, v])
                            },
                            ]}

                        />

                    </div>
                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Phone</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'phone'}
                            error_obj={dataerr.phone_err}
                            value={data.phone}
                            validators={[
                                {
                                    check: Validators.required,
                                    message: 'This field is required',

                                    errors: (v) => seterrors([...errors, v])
                                },
                                {
                                    check: Validators.number_required,
                                    message: 'Enter valid 10 digit phone number',

                                    errors: (v) => seterrors([...errors, v])
                                }
                            ]}

                        />

                    </div>

                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Email</p>
                        <InputField
                            type="email"
                            priority="high"
                            onChange={handleChange}
                            name={'email'}
                            error_obj={dataerr.email_err}
                            value={data.email}
                            validators={[{
                                check: Validators.required,
                                message: 'This field is required',
                                errors: (v) => seterrors([...errors, v])
                            }, {
                                check: Validators.email,
                                message: 'Enter valid Email id',
                                errors: (v) => seterrors([...errors, v])
                            }]}

                        />
                    </div>
                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Designation</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'designation'}
                            error_obj={dataerr.designation_err}
                            value={data.designation}
                            validators={[{
                                check: Validators.required,
                                message: 'This field is required',
                                errors: (v) => seterrors([...errors, v])
                            }, {
                                check: Validators.minimum_length_required,
                                message: 'Designation should be atleast 3 characters',
                                errors: (v) => seterrors([...errors, v])
                            }]}

                        />
                    </div>

                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Secondary Contact Person </p>
                        <InputField
                            type="text"
                            priority="low"
                            onChange={handleChange}
                            name={'secondary_contact_person'}
                            error_obj={dataerr.secondary_con_per_err}
                            value={data.secondary_contact_person}
                            validators={[]}

                        />
                    </div>
                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Secondary Contact Phone </p>
                        <InputField
                            type="text"
                            priority="low"
                            onChange={handleChange}
                            name={'secondary_contact_phone'}
                            error_obj={dataerr.secondary_con_pho_err}
                            value={data.secondary_contact_phone}
                            validators={[]}

                        />
                    </div>
                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Secondary Contact Designation </p>
                        <InputField
                            type="text"
                            priority="low"
                            onChange={handleChange}
                            name={'secondary_contact_designation'}
                            error_obj={dataerr.secondary_con_des_err}
                            value={data.secondary_contact_designation}
                            validators={[]}

                        />

                    </div>

                </div>

                <div className="col-md-6">
                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Company</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'company_name'}
                            error_obj={dataerr.company_err}
                            value={data.company_name}
                            validators={[{
                                check: Validators.required,
                                message: 'This field is required',
                                errors: (v) => seterrors([...errors, v])
                            }, {
                                check: Validators.minimum_length_required,
                                message: 'Company name should be atleast 3 characters',
                                errors: (v) => seterrors([...errors, v])
                            }]}

                        />
                    </div>


                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Company Phone</p>
                        <InputField
                            type="text"
                            priority="low"
                            onChange={handleChange}
                            name={'company_phone'}
                            error_obj={dataerr.company_phone_err}
                            value={data.company_phone}
                            validators={[{
                                check: Validators.number_required,
                                message: 'Enter a valid 10 digit mobile number',
                                errors: (v) => seterrors([...errors, v])
                            }]}

                        />
                    </div>


                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Company Email</p>
                        <InputField
                            type="email"
                            priority="low"
                            onChange={handleChange}
                            name={'company_email'}
                            error_obj={dataerr.company_email_err}
                            value={data.company_email}
                            validators={[]}

                        />
                    </div>

                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Website</p>
                        <InputField
                            type="text"
                            priority="low"
                            onChange={handleChange}
                            name={'website'}
                            error_obj={dataerr.website_err}
                            value={data.website}
                            validators={[]}

                        />
                    </div>
                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Contact Source</p>
                        <DropDown
                            onChange={(v) => selectedvalue(v)}
                            priority={'high'}
                            name='contact_source'
                            nameerr='contact_source_err'
                            value={data.contact_source}
                            error_obj={dataerr.contact_source_err}
                            dropdownValue={(state) ? data.contact_source : ''}
                            options={source_arr} />
                    </div>
                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Industry </p>
                        <DropDown
                            onChange={(v) => selectedvalue(v)}
                            priority={'high'}
                            name='industry'
                            nameerr='industry_err'
                            value={data.industry}
                            error_obj={dataerr.industry_err}
                            dropdownValue={(state) ? data.industry : ''}
                            options={industry_arr} />
                    </div>

                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Contact Status </p>
                        <DropDown
                            onChange={(v) => selectedvalue(v)}
                            priority={'high'}
                            name='status'
                            nameerr='contact_status_err'
                            value={data.status}
                            error_obj={dataerr.contact_status_err}
                            dropdownValue={(state) ? data.status : ''}
                            options={status_arr}
                        />
                    </div>
                    <div className="col-md-12" style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Contact Owner </p>
                        <InputField
                            type="text"
                            priority="high"
                            name={'contact_owner'}
                            value={name}

                        // validators={[{
                        //     check: Validators.required,
                        //     message: 'This field is required'
                        // }, {
                        //     check: Validators.minimum_length_required,
                        //     message: 'Contact owner field is mandatory'
                        // }]}

                        />
                    </div>
                </div>

            </div>


            <div className="row mt-3">
                <h6>Address Information</h6>
                <div className="col-md-6">
                    <div style={{ width: '70%', margin: '10px 0px', }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Street</p>
                        <InputField
                            type="text"
                            priority="low"
                            onChange={handleChange}
                            name={'street'}
                            error_obj={dataerr.street_err}
                            value={data.street}
                            validators={[]}
                        />
                    </div>
                </div>


                <div className="col-md-6">
                    <div style={{ width: '70%', margin: '10px 0px', }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>City</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'city'}
                            error_obj={dataerr.city_err}
                            value={data.city}
                            validators={[{
                                check: Validators.required,
                                message: 'This field is required',
                                errors: (v) => seterrors([...errors, v])
                            }, {
                                check: Validators.minimum_length_required,
                                message: 'cityName should be atleast 3 characters',
                                errors: (v) => seterrors([...errors, v])
                            }, {
                                check: Validators.characterValidator,
                                message: `City Name should only contains ${name_reg_string}`,
                                errors: (v) => seterrors([...errors, v])
                            }, {
                                check: Validators.only_character_and_space,
                                message: `Contact name should only contain ${name_reg_string}`,
                                errors: (v) => seterrors([...errors, v])
                            }]}
                        />
                    </div>
                </div>


                <div className="col-md-6">
                    <div style={{ width: '70%', margin: '10px 0px', }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>State</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'state'}
                            error_obj={dataerr.state_err}
                            value={data.state}
                            validators={[{
                                check: Validators.required,
                                message: 'This field is required',
                                errors: (v) => seterrors([...errors, v])
                            }, {
                                check: Validators.minimum_length_required,
                                message: 'stateName should be atleast 3 characters',
                                errors: (v) => seterrors([...errors, v])
                            }, {
                                check: Validators.characterValidator,
                                message: `State Name should only contains ${name_reg_string}`,
                                errors: (v) => seterrors([...errors, v])
                            }
                            ]}
                        />
                    </div>
                </div>


                <div className="col-md-6">
                    <div style={{ width: '70%', margin: '10px 0px', }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Country</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'country'}
                            error_obj={dataerr.country_err}
                            value={data.country}
                            validators={[{
                                check: Validators.required,
                                message: 'This field is required',
                                errors: (v) => seterrors([...errors, v])
                            }, {
                                check: Validators.minimum_length_required,
                                message: 'Country Name should be atleast 3 characters',
                                errors: (v) => seterrors([...errors, v])
                            }, {
                                check: Validators.characterValidator,
                                message: 'Country Name should only contains characters',
                                errors: (v) => seterrors([...errors, v])
                            }, {
                                check: Validators.only_character_and_space,
                                message: 'Contact name should only contain characters',
                                errors: (v) => seterrors([...errors, v])
                            }
                            ]}
                        />
                    </div>
                </div>

                <div className="col-md-6">
                    <div style={{ width: '70%', margin: '10px 0px', }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Pin code</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'pin_code'}
                            error_obj={dataerr.pincode_err}
                            value={data.pin_code}
                            validators={[
                                {
                                    check: Validators.pin_code_required,
                                    message: 'Invalid Pin Code',

                                    errors: (v) => seterrors([...errors, v])
                                }
                            ]}
                        />
                    </div>
                </div>


                <div className="row mt-3">
                    <h6>Description Information</h6>
                    <TextArea style={{ height: 20, width: '85%', marginBottom: '100px', marginLeft: '1%' }}
                        onChange={(e) => setdata({ ...data, description: e.target.value })}
                        name={'description'}
                        error_obj={dataerr.description_err}
                        value={data.description}
                    />
                </div>
            </div>
        </div>
    )
}

export default CreateContact
