import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import WebContactDetail from './web/ContactDetail'
import MobileContactDetail from './mobile/ContactDetail'

const index2 = () =>{
    if(isMobile){
        return(
            <div>
                <MobileContactDetail />
            </div>
        )
    }else{
        return(
            <div>
                <WebContactDetail />
            </div>
        )
    }
}

export default index2