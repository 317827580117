import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import AddContactSourceWeb from "./web/AddContactSource";
import AddContactSourceMob from "./mobile/AddContactSource";

const index2 =()=>{
    if(isMobile){
        return <AddContactSourceMob/>
    }else {
        return <AddContactSourceWeb />
    }
}

export default index2