import React, { useState, useEffect } from 'react'
import InputField from '../../../components/inputcomponents/InputField';
import { Validators } from '../../../components/Validators/Validators'
import DropDown from '../../../components/inputcomponents/DropDown';
import { Input, Row, Col, message, Modal, Select, Checkbox } from 'antd';

import axios from 'axios';
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { getLead } from '../functions/Apis'
import NavbarMobile1 from '../../mobileNavbar/NavbarMobile1'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { useSelector } from 'react-redux'
import { name_reg_string, name_reg_validation } from '../../../helpers/constants/ConstantReg';
import toast, { Toaster } from "react-hot-toast";

function CreateLead() {

    const { Option } = Select;
    const { TextArea } = Input;

    const roles = useSelector(state => state.Auth.roles)

    const department = useSelector(state => state.Auth.department)
    const navigate = useNavigate()

    const { lead_id } = useParams()

    const { state, pathname } = useLocation()
    let url_start_name = pathname.split('/')[1]

    const [departement_arr, setdepartement_arr] = useState([])
    const [department_id, setdepartment_id] = useState()
    const [dataerr, setdataerr] = useState({ lead_name_err: {}, lead_title_err: {}, measurement_err: {}, zone_err: {}, requirement_err: {}, description_err: {}, contact_name_err: {}, lead_operators_err: {} })
    const [data, setdata] = useState({
        lead_owner: state?.datas?.contact_owner?.name,
        lead_title: '',
        lead_name: '',
        lead_status: '',
        lead_operators: '',
        measurement: [],
        requirement: '',
        zone: '',
        description: '',
        remark: '',
        contact_name: state?.datas?.contact_name,
        contact_id: state?.datas?.id ? state?.datas?.id : state?.datas?._id
    })
    const [edit, setedit] = useState(false)
    const [lead_status, setlead_status] = useState([])
    const [visible, setvisible] = useState(false)

    let lead_status_basic_gallery_options = [{ id: 'Attempted to Contact', value: 'Attempted to Contact' }, { id: 'Contact in Future', value: 'Contact in Future' }, { id: 'Contacted', value: 'Contacted' }, { id: 'Junk lead', value: 'Junk lead' }, { id: 'Lost lead', value: 'Lost lead' }, { id: 'Not Contacted', value: 'Not Contacted' }, { id: 'Pre Qualified', value: 'Pre Qualified' }, { id: 'Not Qualified', value: 'Not Qualified' }, { id: 'Hold', value: 'Hold' }]
    let lead_status_basic_fms_options = [{ id: 'Meeting/Discussion', value: 'Meeting/Discussion' }, { id: 'Assessment', value: 'Assessment' }, { id: ' Proposal sent', value: ' Proposal sent' }, { id: 'Negotiation', value: 'Negotiation' }, { id: 'Final closure', value: 'Final closure' }, { id: 'Agreement', value: 'Agreement' }, { id: 'Hold', value: 'Hold' }]
    let lead_status_basic_project_options = [{ id: 'BD', value: 'BD' }, { id: 'Design', value: 'Design' }, { id: 'QS', value: 'QS' }, { id: 'MEP', value: 'MEP' }, { id: 'BOQ Review', value: 'BOQ Review' }, { id: 'Contact in Future', value: 'Contact in Future' }, { id: 'Closed', value: 'Closed' }, { id: 'Lost', value: 'Lost' }, { id: 'Junk Lead', value: 'Junk Lead' }, { id: 'Hold', value: 'Hold' }]
    let lead_status_basic_trans_options = [{ id: 'RFQ', value: 'RFQ' }, { id: 'Options sent/Provided', value: 'Options sent/Provided' }, { id: 'Inspection', value: 'Inspection' }, { id: 'Meeting/Discussion', value: 'Meeting/Discussion' }, { id: 'LOI', value: 'LOI' }, { id: 'Agreement/Registration', value: 'Agreement/Registration' }, { id: 'Collection', value: 'Collection' }, { id: 'Lead Lost', value: 'Lead Lost' }, { id: 'Negotiation', value: 'Negotiation' }, { id: 'Hold', value: 'Hold' }]
    let lead_status_basic_hr_options = [{ id: 'Follow up', value: 'Follow up' }, { id: 'Waiting for requirement', value: 'Waiting for requirement' }, { id: 'Closed', value: 'Closed' }, { id: 'lost', value: 'lost' }, { id: 'Hold', value: 'Hold' }]

    useEffect(() => {
        if ((roles.includes('db_head') || roles.includes('db_user') || roles.includes('db_sub_user') || roles.includes('admin')) && (department[0].department_name === 'MD Office' || department[0].department_name === 'Database Team') && !lead_id) {
            getDepartements()
            setvisible(true)
        } else {
            setdepartment_id(department[0].id)
        }

        if (!lead_id) {
            if (department[0].department_name === 'Transaction Team') {
                setlead_status(lead_status_basic_trans_options)
            } else if (department[0].department_name === 'Project Team') {
                setlead_status(lead_status_basic_project_options)
            } else if (department[0].department_name === 'Hr Team') {
                setlead_status(lead_status_basic_hr_options)
            } else if (department[0].department_name === 'Gallery Team') {
                setlead_status(lead_status_basic_gallery_options)
            } else if (department[0].department_name === 'FMS Team') {
                setlead_status(lead_status_basic_fms_options)
            }
        }

    }, [])

    useEffect(async () => {
        if (lead_id && pathname.split('/')[1]) {
            setedit(true)
            const leadDatas = await getLead(lead_id)
            const departement_arr_created = []

            await axios.get('department/get', authHeader)
                .then((res) => {
                    res.data.datas.filter(d => d?.department_name !== 'Database Team' && d?.department_name !== 'Media Team' && d?.department_name !== 'MD Office').forEach(d => {
                        let data = {}
                        data['label'] = d.department_name
                        data['value'] = d.department_name
                        data['id'] = d.id
                        departement_arr_created.push(data)
                    })
                    setdepartement_arr(departement_arr_created)

                })
            let department = departement_arr_created?.find(v => v.id === leadDatas[0]?.department_id)

            if (department?.label === 'Transaction Team') {
                setlead_status(lead_status_basic_trans_options)
            } else if (department?.label === 'Project Team') {
                setlead_status(lead_status_basic_project_options)
            } else if (department?.label === 'Hr Team') {
                setlead_status(lead_status_basic_hr_options)
            } else if (department?.label === 'FMS Team') {
                setlead_status(lead_status_basic_fms_options)
            } else if (department?.label === 'Gallery Team') {
                setlead_status(lead_status_basic_gallery_options)
            }



            setdata({
                lead_owner: leadDatas[0]?.contact_id?.contact_owner.name,
                lead_title: leadDatas[0].lead_title,
                lead_name: leadDatas[0].lead_name,
                lead_operators: leadDatas[0].lead_operators,
                phone: leadDatas[0]?.contact_id?.phone,
                email: leadDatas[0]?.contact_id?.email,
                company_name: leadDatas[0]?.contact_id?.company_name,
                website: leadDatas[0]?.contact_id?.website,
                company_email: leadDatas[0]?.contact_id?.company_email,
                company_phone: leadDatas[0]?.contact_id?.company_phone,
                lead_source: leadDatas[0]?.contact_id?.contact_source?.source_name,
                lead_status: leadDatas[0].lead_status,
                industry: leadDatas[0]?.contact_id?.industry?.industry_name,
                street: leadDatas[0]?.contact_id?.street,
                city: leadDatas[0]?.contact_id?.city,
                state: leadDatas[0]?.contact_id?.state,
                pin_code: leadDatas[0]?.contact_id?.pin_code,
                country: leadDatas[0]?.contact_id?.country,
                description: leadDatas[0].description,
                contact_name: leadDatas[0]?.contact_id?.contact_name,
                contact_id: leadDatas[0]?.contact_id?.id,
                measurement: leadDatas[0]?.measurement?.split(','),
                requirement: leadDatas[0]?.requirement,
                zone: leadDatas[0]?.zone,
                remark: leadDatas[0]?.remarks,
            })

        } else {
            setedit(false)
        }
    }, [])

    async function getDepartements() {
        await axios.get('department/get', authHeader)
            .then((res) => {
                const departement_arr_created = []
                res.data.datas.filter(d => d?.department_name !== 'Database Team' && d?.department_name !== 'Media Team' && d?.department_name !== 'MD Office').forEach(d => {
                    let data = {}
                    data['label'] = d.department_name
                    data['value'] = d.department_name
                    data['id'] = d.id
                    departement_arr_created.push(data)
                })
                setdepartement_arr(departement_arr_created)

            })
    }

    function handleChange({ key, value }) {
        if (key === 'lead_name') {
            setdataerr({ ...dataerr, lead_name_err: {} })
        } else if (key === 'lead_title') {
            setdataerr({ ...dataerr, lead_title_err: {} })
        } else if (key === 'lead_status') {
            setdataerr({ ...dataerr, lead_status_err: {} })
        }
        setdata({ ...data, [key]: value })
    }

    function handleChangefun(v, v1) {
        if (departement_arr.find(d => d.id === v)?.value === 'Transaction Team') {
            setlead_status(lead_status_basic_trans_options)
        } else if (departement_arr.find(d => d.id === v)?.value === 'Project Team') {
            setlead_status(lead_status_basic_project_options)
        } else if (departement_arr.find(d => d.id === v)?.value === 'Hr Team') {
            setlead_status(lead_status_basic_hr_options)
        } else if (departement_arr.find(d => d.id === v)?.value === 'FMS Team') {
            setlead_status(lead_status_basic_fms_options)
        } else if (departement_arr.find(d => d.id === v)?.value === 'Gallery Team') {
            setlead_status(lead_status_basic_gallery_options)
        }
        setdepartment_id(v)
        setvisible(false)
    }

    function addlead() {
        if (!data.lead_name && data.lead_name.length < 3) {
            setdataerr({ ...dataerr, lead_name_err: { error: true, message: 'Lead name should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.lead_name)) {
            setdataerr({ ...dataerr, lead_name_err: { error: true, message: `Lead name should contains only ${name_reg_string}` } })
        } else if (!data.lead_title) {
            setdataerr({ ...dataerr, lead_title_err: { error: true, message: 'This field is required' } })
        } else if (!name_reg_validation(data.lead_title)) {
            setdataerr({ ...dataerr, lead_title_err: { error: true, message: `Lead title should contains only ${name_reg_string}` } })
        } else if (!data.lead_status) {
            setdataerr({ ...dataerr, lead_status_err: { error: true, message: 'This field is required' } })
        } else if (data.lead_status && data.lead_status === 'Lead Lost' && !data.remark) {
            setdataerr({ ...dataerr, remark_err: { error: true, message: 'This field is required' } })
        }
        else {
            let sendData = {
                contact_id: data.contact_id,
                lead_name: data.lead_name,
                lead_title: data.lead_title,
                lead_status: data.lead_status,
                lead_operators: data.lead_operators,
                department_id: department_id,
                description: data.description
            }

            if (departement_arr?.find(d => d?.id === department_id)?.value === 'Transaction Team') {
                sendData['measurement'] = data.measurement.join(',')
                sendData['requirement'] = data.requirement
                sendData['zone'] = data.zone
            }
            if (data?.lead_status === 'Lead Lost') {
                sendData['remark'] = data?.remark
            }


            axios.post('lead/create', sendData, authHeader).then((res) => {
                toast.success('Added Successfully')
                setdata({ lead_title: '', lead_name: '', lead_status: '', description: '' })
                setTimeout(() => {
                    navigate('/view_leads')
                }, 1000)
            }).catch((err) => {
                console.log('err kp here', err)
                if (err?.response?.status === 422) {
                    message.error(err?.response?.data?.errors?.lead_name)

                }
            })
        }

    }

    function updateLead() {
        if (!data.lead_name && data.lead_name.length < 3) {
            setdataerr({ ...dataerr, lead_name_err: { error: true, message: 'Lead name should be atleast 3 characters' } })
        } else if (!name_reg_validation(data.lead_name)) {
            setdataerr({ ...dataerr, lead_name_err: { error: true, message: `Lead name should contains only ${name_reg_string}` } })
        } else if (!data.lead_title) {
            setdataerr({ ...dataerr, lead_title_err: { error: true, message: 'This field is required' } })
        } else if (!name_reg_validation(data.lead_title)) {
            setdataerr({ ...dataerr, lead_title_err: { error: true, message: `Lead title should contains only ${name_reg_string}` } })
        } else if (!data.lead_status) {
            setdataerr({ ...dataerr, lead_status_err: { error: true, message: 'This field is required' } })
        } else if (data.lead_status && data.lead_status === 'Lead Lost' && !data.remark) {
            setdataerr({ ...dataerr, remark_err: { error: true, message: 'This field is required' } })
        }
        else {
            let sendData = {
                contact_id: data?.contact_id,
                lead_name: data?.lead_name,
                lead_title: data?.lead_title,
                lead_status: data?.lead_status,
                lead_operators: data.lead_operators,
                description: data?.description,
            }
            if (departement_arr?.find(d => d?.id === department_id)?.value === 'Transaction Team') {
                sendData['measurement'] = data?.measurement?.join(',')
                sendData['requirement'] = data?.requirement
                sendData['zone'] = data?.zone
            }
            if (data.lead_status === 'Lead Lost') {
                sendData['remarks'] = data?.remark
            }

            axios.put(`lead/update/${lead_id}`, sendData, authHeader).then((res) => {
                toast.success('Updated Successfully')
                setTimeout(() => {
                    navigate(-1)
                }, 1000)
            }).catch((err) => {
                message.error('Opps Somenthing went wrong!')
            })
        }
    }

    function selectedvalue(v) {
        if (v?.id) {
            setdataerr({ ...dataerr, [v.nameerr]: {} })
            setdata({ ...data, [v.name]: v.id })
        }
    }

    function goback() {
        setdata({})
        setdataerr({})
        navigate(-1)
    }

    function resetform() {
        setdata({})
        setdataerr({})
    }

    function setmeasumentfun(v) {
        let temp_arr = [...data.measurement]


        if (temp_arr?.includes(v)) {
            temp_arr = temp_arr.splice(temp_arr.splice(temp_arr.findIndex(e => e === v)), 1)
            setdata({ ...data, measurement: temp_arr })
        } else {
            temp_arr.push(v)
            setdata({ ...data, measurement: temp_arr })
        }

    }


    return (
        <div>
            <Toaster />
            <Modal visible={visible} onCancel={() => navigate(-1)} footer={false} style={{ marginTop: '50px', maxWidth: '310px' }}>
                <Row>
                    <Col span={24}>
                        <div style={{ width: '96%', margin: '10px 0px', }}>
                            <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Select Department</h6>

                            <Select
                                showSearch
                                placeholder="Select Department"
                                optionFilterProp="children"
                                onChange={(v) => handleChangefun(v, 'department_id')}
                                size='medium'
                                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{ width: '100%' }}
                            >
                                {
                                    departement_arr.map((d) => (
                                        <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                                    ))
                                }
                            </Select>
                        </div>
                    </Col>
                </Row>
            </Modal>


            <NavbarMobile1 func={resetform} />
            <div style={{ width: '94%', margin: 'auto', zIndex: 1, marginLeft: '3%' }}>
                <h4 style={{ fontWeight: '900', marginBottom: '2px', marginTop: '-10px' }}>{url_start_name === 'create_lead' ? 'Create' : 'Edit'} Lead </h4>
                <h6 style={{ fontSize: '12px', color: 'grey' }}>Let's {url_start_name === 'create_lead' ? 'create' : 'update'}  a {url_start_name === 'create_lead' ? 'new' : 'old'} lead</h6>
            </div>
            <div style={{ width: '94%', margin: 'auto 3%', zIndex: 1, }}>
                <div className="mt-3">
                    <h6>Lead Information</h6>
                    <div className="row" style={{ width: '100%' }}>
                        <div className="col-6"  >
                            <div style={{ width: '100%', margin: '10px 0px' }}>
                                <p style={{ marginBottom: '8px', fontSize: '14px' }}>Lead Name</p>
                                <InputField
                                    type="text"
                                    priority="high"
                                    onChange={handleChange}
                                    name={'lead_name'}
                                    size={"large"}
                                    error_obj={dataerr.lead_name_err}
                                    value={data.lead_name}
                                    validators={[{ check: Validators.required, message: 'This field is required' }, { check: Validators.minimum_length_required, message: 'Lead Name should be atleast 3 characters' }]}
                                />
                            </div>
                        </div>

                        <div className="col-6" >
                            <div style={{ width: '100%', margin: '10px 0px' }}>
                                <p style={{ marginBottom: '8px', fontSize: '14px' }}>{departement_arr?.find(d => d?.id === department_id)?.value === 'Project Team' ? 'Project Scope' : 'Title'}</p>
                                <InputField
                                    type="text"
                                    priority="high"
                                    onChange={handleChange}
                                    name={'lead_title'}
                                    size={"large"}
                                    error_obj={dataerr.lead_title_err}
                                    value={data.lead_title}
                                    validators={[{ check: Validators.required, message: 'This field is required' }, { check: Validators.minimum_length_required, message: 'Title should be atleast 3 characters' }]}
                                />
                            </div>
                        </div>

                        <div className="col-6" >
                            <div style={{ width: '100%', margin: '10px 0px' }}>
                                <p style={{ marginBottom: '8px', fontSize: '14px' }}>Lead Status</p>
                                <DropDown
                                    onChange={(v) => selectedvalue(v)}
                                    priority={'high'}
                                    name='lead_status'
                                    nameerr='lead_status_err'
                                    value={data.lead_status}
                                    error_obj={dataerr.lead_status_err}
                                    options={lead_status}
                                    size={'large'}
                                />
                            </div>
                        </div>

                        <div className="col-6" >
                            <div style={{ width: '100%', margin: '10px auto' }}>
                                <p style={{ marginBottom: '8px', fontSize: '14px' }}>Contact Selected</p>
                                <InputField
                                    type="text"
                                    priority="high"
                                    name={'contact'}
                                    size={"large"}
                                    value={data.contact_name}
                                />
                            </div>
                        </div>

                        {departement_arr?.find(d => d?.id === department_id)?.value === 'Transaction Team' &&
                            <>
                                <div className="col-md-12">
                                    <div style={{ margin: '10px 0px', width: '100%' }}>
                                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Requirement</p>
                                        <InputField
                                            type="text"
                                            size={'large'}
                                            priority="low"
                                            onChange={handleChange}
                                            name={'requirement'}
                                            value={data.requirement}
                                            validators={[]}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div style={{ margin: '10px 0px', width: '100%' }}>
                                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Lead Operators</p>
                                        <InputField
                                            type="text"
                                            size={'large'}
                                            priority="low"
                                            onChange={handleChange}
                                            name={'lead_operators'}
                                            value={data.lead_operators}
                                            validators={[]}
                                        />
                                    </div>
                                </div>

                                <div className="col-7" >
                                    <div style={{ width: '100%', margin: '10px 0px' }}>
                                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Measurement</p>
                                        <Row>
                                            <Col span={8}>
                                                <Checkbox value="sqft" checked={data?.measurement?.includes('sqft')} onChange={() => setmeasumentfun('sqft')} >Sqft</Checkbox>
                                            </Col>
                                            <Col span={12}>
                                                <Checkbox value="seater" checked={data?.measurement?.includes('seater')} onChange={() => setmeasumentfun('seater')} >Seater</Checkbox>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>


                                <div className="col-5" >
                                    <div style={{ width: '100%', margin: '10px 0px' }}>
                                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Zone</p>
                                        <Select
                                            showSearch
                                            value={data.zone}
                                            placeholder="Select Zone"
                                            optionFilterProp="children"
                                            onChange={(v) => handleChange({ key: 'zone', value: v })}
                                            size='medium'
                                            filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            style={{ width: '100%' }}
                                        >
                                            <Option value="North"><div>North</div></Option>
                                            <Option value="South"><div>South</div></Option>
                                            <Option value="West"><div>West</div></Option>
                                            <Option value="East"><div>East</div></Option>
                                            <Option value="Central"><div>Central</div></Option>
                                        </Select>
                                    </div>

                                </div>


                                {data.lead_status === 'Lead Lost' &&
                                    <div className="col-md-12">
                                        <div style={{ margin: '10px 0px', width: '100%' }}>
                                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Reason for Lead Lost</p>
                                            <InputField
                                                type="text"
                                                size={'large'}
                                                priority="high"
                                                onChange={handleChange}
                                                name={'remark'}
                                                error_obj={dataerr.remark_err}
                                                value={data.remark}
                                                validators={[{ check: Validators.required, message: 'This field is required' }, { check: Validators.minimum_length_required, message: 'Remark should be atleast 3 characters' }]}
                                            />
                                        </div>
                                    </div>}

                            </>
                        }



                        <div className="col-md-12" style={{ margin: 'auto' }}>
                            <div style={{ width: '100%', margin: '10px 0px' }}>
                                <h6>Description</h6>
                                <TextArea style={{ height: 60, width: '100%', marginLeft: '0%', borderRadius: '3px' }} onChange={e => setdata({ ...data, description: e.target.value })} name={'description'} value={data.description} />
                            </div>
                        </div>



                    </div>
                </div>
            </div>








            <div style={{ display: 'flex', width: '92%', marginTop: '20px', justifyContent: 'flex-end', paddingBottom: '40px', }}>
                {url_start_name === 'create_lead' &&
                    <div style={{ marginRight: '0px' }} onClick={() => goback()}>
                        <SecondaryButton
                            btn_name='Cancel'
                            padding={'7px 20px'}
                        />
                    </div>
                }
                <div style={{ width: '73px', marginRight: url_start_name !== 'create_lead' && '18px' }} onClick={(edit) ? updateLead : addlead} >
                    <PrimaryButton
                        padding={'7px 20px'}
                        btn_name={url_start_name === 'create_lead' ? "Save" : "Update"}
                    />
                </div>
            </div>

        </div>
    )
}

export default CreateLead
