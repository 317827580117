import React, { useState, useEffect } from 'react'
import { authHeader } from '../../../helpers/usertoken/UserToken';
import axios from 'axios'
import DetailDesign1 from '../../../components/detail_design/DetailDesign1';


function ViewTasks() {

    const [data, setdata] = useState([])

    useEffect(() => {
        gettask()
    }, [])


    function gettask() {
        axios.get(`task/get`, authHeader)
            .then((res) => {
                setdata(res.data)
            })
    }


    function addtaskfunc() {
        window.location.href = '/create_tasks'
    }

    return (
        <div style={{ width: '100%', margin: 'auto', display: 'flex', flexDirection: 'column', marginTop: '0px', background: '#fff' }}>
            <DetailDesign1 getupdatedstate={gettask} btn_name="Add Task" type="Task" func={() => addtaskfunc()} data={data} />
        </div>
    )
}

export default ViewTasks
