import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import WebViewMettings from './web/ViewMettings'
import MobileViewMettings from './android/ViewMettings'

const index = () =>{
    if(isMobile){
        return(
            <div>
                <MobileViewMettings />
            </div>
        )
    }else{
        return(
            <div>
                <WebViewMettings />
            </div>
        )
    }
}

export default index