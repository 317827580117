import './ReactMaterialTable.css'
import React, { useState } from 'react'
import { Modal, Dropdown, Menu, Pagination, Tag } from 'antd'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import Tooltip from '@mui/material/Tooltip';
import { TiTick } from 'react-icons/ti'
import { IoMdClose } from 'react-icons/io'
import { FiEdit } from 'react-icons/fi'
import { IoWallet, } from 'react-icons/io5'
import { SiHandshake } from 'react-icons/si'
import { useSelector } from 'react-redux'
import { CgMoveRight } from 'react-icons/cg'
import { GiAlarmClock } from 'react-icons/gi'
import { MdPermContactCalendar, MdTimeline } from 'react-icons/md'
import { BsCalendar2Week } from 'react-icons/bs'
import {BiMessageDetail} from 'react-icons/bi'
import Reminder from '../detail_design/Reminder'
import toast from 'react-hot-toast'
import Avatar from '@mui/material/Avatar';


import Texttruncate from '../TextTruncater/Texttruncate'
import MeetingModal from '../detail_design/MeetingModal'


function ReactMaterialTable({ data, type, func, func1, editmeeting, selected, gotoleadcreatefunc, editDeal, selected_lead, setselected_lead_fun, setpagefuun, pagination, role_stage, setselected_assigned_lead, getreminderfun, reminder, acceptassignedlead, selected_contact, setselected_contact_func, noshare, tracker }) {

  const name = useSelector(state => state.Auth.name)
  const navigate = useNavigate()


  const [description, setdescription] = useState('')
  const [isvisible, setisvisible] = useState(false)
  const [isvisible1, setisvisible1] = useState(false)
  const [typeId, setTypeId] = useState('')

  const theme_color = useSelector(state=>state.Theme.theme_color)


  const menu = (
    <Menu>
      <div style={{ padding: '5px 10px', maxWidth: '280px' }}>
        <h6 style={{ marginBottom: '0px', fontSize: '13px',fontWeight:'800' }}>Description:</h6>
        <p style={{ fontWeight: '400', fontSize: '12px', paddingBottom: '0px',marginBottom:'0px' }}>{description}</p>
      </div>
    </Menu>
  );

  function gotodeal(d) {
    navigate(`/create_deal`, { state: { datas: d } })
  }

  function assigned_lead_to_deal(d) {
    let obj = {
      contact_id: d?.assigned_lead_id?.lead_id?.contact_id,
      _id: d?.assigned_lead_id?.lead_id?.id,
      lead_name: d?.assigned_lead_id?.lead_id?.lead_name,
      department_id:d?.assigned_lead_id?.lead_id?.department_id
    }
    navigate(`/create_deal`, { state: { datas: obj } })
  }

  const onReminderClick = (d) => {
    getreminderfun(d?.id ? d?.id : d)
    setTypeId(d?.id ? d?.id : d)
    setisvisible(true)
  }

  const onMeetingClick = (d) => {
    // setmeetingtype(type)
    setTypeId(d)
    setisvisible1(true)
  }

  function onAssignClick(v) {
    if (v.accept === 'Pending') {
      toast.error('Please accept before assign!')
    }
    else if (v.accept === 'Rejected') {
      toast.error('You rejected this lead!')
    }
    else if (v.accept === 'Accepted') {
      setselected_lead_fun(v._id)
      setselected_assigned_lead(v)
    }
    else {
      toast.error('Unknown Error')
    }
  }

  const infoItems = (v) => {
    return (
      <div style={{ width: '200px', maxWidth: '200px', background: 'white', padding: '10px' }}>
        {v.remarks &&
          <>
            <h6 style={{ fontWeight: 'bold', fontSize: '13px', marginBottom: '0px' }}>Remarks</h6>
            <p style={{ marginBottom: '0px' }}>{v.remarks}</p>
          </>}
        {v.cancellation_reason &&
          <>
            <h6 style={{ fontWeight: 'bold', fontSize: '13px', marginBottom: '0px', marginTop: '2px' }}>Cancellation Reason</h6>
            <p style={{ marginBottom: '0px', fontSize: '12px' }}>{v.cancellation_reason}</p>
          </>}
      </div>
    )
  }

  if (type === 'lead') {
    return (
      <div style={{ background: 'transparent' }}>
        <MeetingModal visible={isvisible1} func={() => setisvisible1(false)} data_id={typeId} type={type} />
        <Reminder visible={isvisible} func={() => setisvisible(false)} id={typeId} type={type} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />

        {type === 'lead' &&
          <div style={{ border: '0px solid lightgray', overflowY: 'scroll' }}>

            <div className='mateial_table_header'>
              <p style={{ width: '12%', color: 'black' }}>Lead Name</p>
              <p style={{ width: '10%', marginRight: '2%' }}>Company</p>
              <p style={{ width: '22%', marginRight: '2%' }}>Email</p>
              <p style={{ width: '12%' }}>Phone</p>
              <p style={{ width: '10%' }}>Lead Status</p>
              <p style={{ width: '13%' }}>Lead Source</p>
              <p style={{ width: '12%' }}>Lead Owner</p>
              <p style={{ width: '7%' }}>Actions</p>
            </div>

            {data.map((d) => (
              <div key={d.id} className='mateial_table_body'>
                <p style={{ width: '12%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700' }} onClick={() => func(d)}>{<Texttruncate text={d?.lead_name} />}</p>
                <p style={{ width: '10%', wordBreak: 'break-word', marginRight: '2%' }}>{<Texttruncate text={d?.contact_id?.company_name} />}</p>
                <p style={{ width: '22%', wordBreak: 'break-word', marginRight: '2%' }}>{<Texttruncate text={d?.contact_id?.email} />}</p>
                <p style={{ width: '12%', wordBreak: 'break-word' }}>+91{d?.contact_id?.phone}</p>
                <p style={{ width: '10%', wordBreak: 'break-word' }}>{<Texttruncate text={d?.lead_status} />}</p>
                <p style={{ width: '13%', wordBreak: 'break-word' }}>{<Texttruncate text={d?.contact_id?.contact_source?.source_name} />}</p>
                <p style={{ width: '12%', wordBreak: 'break-word' }}>{<Texttruncate text={d?.contact_id?.contact_owner?.name} />}</p>
                <p style={{ width: '7%' }}>
                  <Tooltip title="Convert to Deal">
                    <CgMoveRight size={28} style={{ color: 'black' }} onClick={() => gotodeal(d)} />
                  </Tooltip>
                  <Tooltip title="Add Reminder">
                    <GiAlarmClock size={20} style={{ marginLeft: '10px', color: 'black' }} onClick={() => onReminderClick(d._id)} />
                  </Tooltip>
                  <Tooltip title="Add Meeting" style={{ marginLeft: '5px' }}>
                    <BsCalendar2Week size={15} onClick={() => onMeetingClick(d)} />
                  </Tooltip>
                </p>
              </div>
            ))}

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
              {pagination?.total > 20 &&
                <Pagination defaultCurrent={1} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)} showSizeChanger pageSize={20} />
              }
            </div>

          </div>
        }
      </div>
    )
  } else if (type === 'assign_lead') {
    return (<div style={{ background: 'transparent' }}>
      <MeetingModal visible={isvisible1} func={() => setisvisible1(false)} data_id={typeId} type={type} />
      <Reminder visible={isvisible} func={() => setisvisible(false)} id={typeId} type={type} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
      <div style={{ border: '0px solid lightgray', overflowY: 'scroll' }}>
        <div className='mateial_table_header'>
        <p className='checked_here' ></p> 

          <p style={{ width: '10%', color: 'black',marginLeft:'15px' }}>Lead Name</p>
          <p style={{ width: '12%', marginRight: '2%' }}>Company</p>
          <p style={{ width: '16%', marginRight: '2%' }}>Email</p>
          <p style={{ width: '10%', marginRight: '1%' }}>Phone</p>
          <p style={{ width: '13%', marginRight: '1%' }}>Lead Status</p>
          <p style={{ width: '14%', marginRight: '1%' }}>Lead Source</p>
          <p style={{ width: '14%' }}>Lead Owner</p>
          <p style={{ width: '7%' }}>Actions</p>
        </div>

        {data.map((d, i) => (
          <div key={i} className={selected_lead !== d._id ? 'mateial_table_body' : 'mateial_table_body_active'}>
            <p className={selected_lead === d._id  ? 'checked_here2': 'checked_here'} onClick={()=>selected_lead !== d._id ? setselected_lead_fun(d._id) :setselected_lead_fun() }>
              {selected_lead === d._id && <p className='checked_here1' style={{background:theme_color}}></p>}
            </p>
            <Tooltip  title={d?.lead_name}><p style={{ width: '10%', color: "#0594f2",  fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px',paddingRight:'0.7%' }} onClick={() => func(d)}>{d?.lead_name}</p></Tooltip>
            <Tooltip  title={d?.contact_id?.company_name}><p style={{ width: '12%',  marginRight: '2%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-5px' }}>{d?.contact_id?.company_name}</p></Tooltip>
            <Tooltip  title={d?.contact_id?.email}><p style={{ width: '16%',  marginRight: '2%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{d?.contact_id?.email}</p></Tooltip>
            <p style={{ width: '10%',  marginRight: '1%',fontSize:'12px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontWeight:'800' }}>{d?.contact_id?.phone}</p>
            <Tooltip  title={d?.lead_status}><p style={{ width: '13%',  marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{d?.lead_status?.split(' ')[0]}</p></Tooltip>
            <p style={{ width: '14%',  marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{d?.contact_id?.contact_source?.source_name}</p>
            <Tooltip  title={d?.lead_owner?.name}><p style={{ width: '14%',fontSize:'13px',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }}>{d?.lead_owner?.name}</p></Tooltip>
            <p style={{ width: '6%', display: 'flex',alignItems:'center', justifyContent: 'space-between', }}>
              <Tooltip title="Convert to Deal" >
                <CgMoveRight size={24} onClick={() => !d.isConverted && gotodeal(d)} style={{ ...d.isConverted && { color: '#d4d4d4' },marginLeft:'-10px' }} />
              </Tooltip>
              <Tooltip title="Add Reminder" >
                <GiAlarmClock size={19} onClick={() => !d.isConverted && onReminderClick(d._id)} style={{ ...d.isConverted && { color: '#d4d4d4' } }} />
              </Tooltip>
              <Tooltip title="Add Meeting"  >
                <BsCalendar2Week size={13} onClick={() => !d.isConverted && onMeetingClick(d)} style={{ marginLeft: '4px',...d.isConverted && { color: '#d4d4d4' } }} />
              </Tooltip>
            </p>
          </div>
        ))}

        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
          {pagination?.total > 20 &&
            <Pagination defaultCurrent={1} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)} showSizeChanger pageSize={20} />
          }
        </div>
      </div>
    </div>
    )
  } else if (type === 'assign_lead1') {
    return (<div style={{ background: 'transparent' }}>
      <MeetingModal visible={isvisible1} func={() => setisvisible1(false)} data_id={typeId} type={type} />
      <Reminder visible={isvisible} func={() => setisvisible(false)} id={typeId} type={type} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
      <div style={{ border: '0px solid lightgray', }}>
        <div className='mateial_table_header'>
          {!noshare &&
                    <p className='checked_here' ></p> 
                  }
          <p style={{ width: '10%', maxWidth: '10%', color: 'black',marginLeft:'15px' }}>Lead Name</p>
          <p style={{ width: '10%', maxWidth: '10%' }}>Company</p>
          <p style={{ width: '16%', maxWidth: '16%' }}>Email</p>
          <p style={{ width: '10%', maxWidth: '10%' }}>Phone</p>
          <p style={{ width: '10%', maxWidth: '10%' }}>Assigned By</p>
          <p style={{ width: '10%', maxWidth: '10%' }}>Assigned Date</p>
          <p style={{ width: '12%', maxWidth: '12%' }}>Accept status</p>
          <p style={{ width: '10%', maxWidth: '10%' }}>Info</p>
          <p style={{ width: '8%', maxWidth: '8%' }}>Actions</p>
        </div>
        {data.map((d, i) => (
          <div key={i} className={selected_lead !== d._id ? 'mateial_table_body' : 'mateial_table_body_active'}>
            {/* {!noshare &&
              <>
                {selected_lead !== d._id ? <ImRadioUnchecked size={14} style={{ minWidth: '4%', maxWidth: '4%', marginTop: '0px', color: '#c2c2c2', cursor: d?.assigned_lead_id?.lead_id?.isConverted ? 'not-allowed' : 'pointer' }} onClick={() => !d?.assigned_lead_id?.lead_id?.isConverted && onAssignClick(d)} /> :
                  <ImRadioChecked2 size={14} style={{ minWidth: '4%', maxWidth: '4%', marginTop: '0px', color: '#0073BA' }} onClick={() => setselected_lead_fun('')} />}
              </>} */}
            <p className={selected_lead === d._id  ? 'checked_here2': 'checked_here'} onClick={()=>!d?.assigned_lead_id?.lead_id?.isConverted && onAssignClick(d) }>
              {selected_lead === d._id && <p className='checked_here1' style={{background:theme_color}}></p>}
            </p>  
            {(d.accept === 'Pending') ?
            <p style={{ width: '10%', maxWidth: '10%',fontSize:'13px', color: "black", wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', fontWeight: '700', cursor: 'pointer',paddingRight:'1%',paddingLeft:'10px',}} >{d?.assigned_lead_id?.lead_id?.lead_name}</p> :
            <p style={{ width: '10%', maxWidth: '10%',fontSize:'13px', color: "#0594F2", wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', fontWeight: '700',paddingRight:'1%',paddingLeft:'10px', cursor: d.accept === 'Rejected' ? 'not-allowed' : 'pointer' }} onClick={() => (d.accept === 'Accepted' || d.accept === 'Pending') && func(d?.assigned_lead_id?.lead_id?.id)}>{d?.assigned_lead_id?.lead_id?.lead_name}</p>}
            <p style={{ width: '10%', maxWidth: '10%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-6px',paddingRight:'1%',paddingLeft:'10px' }}>{d?.assigned_lead_id?.lead_id?.contact_id?.company_name}</p>
            <p style={{ width: '16%', maxWidth: '16%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingRight:'1.4%',paddingLeft:'10px'}}>{d?.assigned_lead_id?.lead_id?.contact_id?.email}</p>
            <p style={{ width: '10%', maxWidth: '10%',fontSize:'12px',marginLeft:'-10px', wordBreak: 'break-word',fontWeight:'800',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'10px' }}>{d?.assigned_lead_id?.lead_id?.contact_id?.phone}</p>
            <p style={{ width: '10%', maxWidth: '10%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', fontSize: '13px', marginLeft: '-3px',paddingLeft:'10px' }}>{d?.assigned_by?.name}</p>
            <p style={{ width: '10%', maxWidth: '10%', wordBreak: 'break-word',fontWeight:'800',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', fontSize: '13px', marginLeft: '-4px',paddingLeft:'10px' }}>{moment(d?.createdAt).format('l')}</p>
            <p style={{ width: '12%', maxWidth: '12%', fontSize: '13px',paddingLeft:'10px' }}><Tag color={(d.accept === 'Pending' && '#108ee9') || (d.accept === 'Accepted' && '#87d068') || (d.accept === 'Rejected' && '#f50')}>{d?.accept}</Tag></p>
            <p style={{ width: '10%', maxWidth: '10%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', fontSize: '13px', marginLeft: '-10px' }}>
              <Dropdown  overlay={() => infoItems(d)} placement="bottomLeft" >
                <BiMessageDetail size={22}  style={{background:theme_color,padding:'4px',color:'#fff',borderRadius:'15px',zIndex:100,marginLeft:'10px'}}  />
              </Dropdown>
            </p>
            {
              (d.accept === 'Pending') &&
              <p style={{ maxWidth: '10%', display: 'flex',alignItems:'center' }}>
                <Tooltip title="Accept" >
                  <TiTick size={22} style={{ color: 'black' }} onClick={() => Modal.confirm({
                    title: 'Confirmation',
                    content: 'By clicking on approve you have access to this lead',
                    okText: 'Approve',
                    cancelText: 'Cancel',
                    onOk: () => acceptassignedlead(d, 1),
                    onCancel: ''

                  }

                  )} />
                </Tooltip>
                <Tooltip title="Dont Accept" >
                  <IoMdClose size={20} style={{ marginLeft: '10px', color: 'black' }} onClick={() => acceptassignedlead(d, 2)} />
                </Tooltip>
              </p>
            }
            {
              (d.accept === 'Accepted') &&
              <p style={{ maxWidth: '8%', display: 'flex', justifyContent: 'space-between' }}>
                <Tooltip title="Convert to Deal" >
                  <CgMoveRight size={26} onClick={() => !d?.assigned_lead_id?.lead_id?.isConverted && assigned_lead_to_deal(d)} style={{ ...d?.assigned_lead_id?.lead_id?.isConverted ? { color: '#d4d4d4',  } : { color: 'black' } }} />
                </Tooltip>
                <Tooltip title="Add Reminder" >
                  <GiAlarmClock size={20} onClick={() => !d?.assigned_lead_id?.lead_id?.isConverted && onReminderClick(d?.assigned_lead_id?.lead_id?.id)} style={{ ...d?.assigned_lead_id?.lead_id?.isConverted ? { color: '#d4d4d4',  marginLeft: '10px' } : { marginLeft: '10px', color: 'black' } }} />
                </Tooltip>
                <Tooltip title="Add Meeting"  >
                  <BsCalendar2Week size={15} onClick={() => !d?.assigned_lead_id?.lead_id?.isConverted && onMeetingClick(d)} style={{ marginLeft: '15px',...d?.assigned_lead_id?.lead_id?.isConverted && { color: '#d4d4d4',} }} />
                </Tooltip>
              </p>
            }


          </div>
        ))}
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
          {pagination.total > 20 &&
            <Pagination defaultCurrent={1} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)} showSizeChanger pageSize={20} />
          }
        </div>
      </div>
    </div>)
  } else if (type === 'assign_lead2') {
    return (
      <div style={{ background: 'transparent' }}>
        <Reminder visible={isvisible} func={() => setisvisible(false)} id={typeId} type={type} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
        <>
          <div style={{ border: '0px solid lightgray', }}>
            <div className='mateial_table_header'>
              <p style={{ minWidth: '12%', color: 'black' }}>Lead Name </p>
             <p style={{ minWidth: '13%', marginRight: '0.5%' }}>Company</p>
              <p style={{ minWidth: '18%', marginRight: '0.5%' }}>Email</p>
              <p style={{ minWidth: '10%' }}>Phone</p>
              <p style={{ minWidth: '12%' }}>Assigned To</p>
              <p style={{ minWidth: '12%', marginRight: '0.5%' }}>Assigned Date</p>
              <p style={{ minWidth: '10%' }}>Accept status</p>
              <p style={{ minWidth: '5%' }}>Info</p>
              <p style={{ minWidth: '5%' }}>Track Status</p>
            </div>
            {data.map((d) => (
              <div key={d._id} className={selected_lead !== d._id ? 'mateial_table_body' : 'mateial_table_body_active'}>
                <Tooltip title = {d?.assigned_lead_id?.lead_id?.lead_name} ><p style={{ minWidth: '12%',fontSize:'13px', maxWidth: '12%',marginRight:'0%', color: d.status ? "#0594F2" : "black", wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingRight:'0.5%', fontWeight: '700' }} onClick={() => func(d?.assigned_lead_id?.lead_id?.id)}>{d?.assigned_lead_id?.lead_id?.lead_name}</p></Tooltip>
                <Tooltip title = {d?.assigned_lead_id?.lead_id?.contact_id?.company_name} ><p style={{ minWidth: '13%',maxWidth: '10%',fontSize:'13px',  paddingRight: '0.5%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'  }}>{d?.assigned_lead_id?.lead_id?.contact_id?.company_name}</p></Tooltip>
                <Tooltip title = {d?.assigned_lead_id?.lead_id?.contact_id?.email} ><p style={{ minWidth: '18%',fontSize:'13px',  paddingRight: '0.5%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'  }}>{d?.assigned_lead_id?.lead_id?.contact_id?.email}</p></Tooltip>
                <p style={{ minWidth: '10%',fontSize:'12px',fontWeight:'800', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'  }}>{d?.assigned_lead_id?.lead_id?.contact_id?.phone}</p>
                <Tooltip title = {d?.user_id?.name} ><p style={{ minWidth: '12%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingRight:'1.5%'  }}>{d?.user_id?.name}</p></Tooltip>
                <p style={{ minWidth: '12%',fontSize:'12px',fontWeight:'800', wordBreak: 'break-word', marginRight: '0.5%' }}>{moment(d?.createdAt).format('l')}</p>
                <p style={{ minWidth: '10%',fontSize:'13px' }}>{<Tag color={(d.accept === 'Pending' && '#108ee9') || (d.accept === 'Accepted' && '#87d068') || (d.accept === 'Rejected' && '#f50')}>{d?.accept}</Tag>}</p>
                <p style={{ minWidth: '5%', maxWidth: '5%' }}>
                  <Dropdown style={{marginLeft:'-2%' }} overlay={() => infoItems(d)} placement="bottomLeft">
                    <BiMessageDetail size={20} style={{background:theme_color,padding:'4px',color:'#fff',marginLeft:'-10px',borderRadius:'15px'}} />
                  </Dropdown>
                </p>
                <p style={{ minWidth: '5%', maxWidth: '5%', alignItems: 'center', }} onClick={() => tracker(d)}>
                  <MdTimeline size={20} style={{ color: 'black', marginLeft: '0px' }} />
                </p>
              </div>
            ))}
            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
              {pagination.total > 20 &&
                <Pagination defaultCurrent={1} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)} showSizeChanger pageSize={20} />
              }
            </div>
          </div>
        </>
      </div>
    )
  } else if (type === 'assign_lead3') {
    return (<div style={{ background: 'transparent' }}>
      <MeetingModal visible={isvisible1} func={() => setisvisible1(false)} data_id={typeId} type={type} />
      <Reminder visible={isvisible} func={() => setisvisible(false)} id={typeId} type={type} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
      <div style={{ border: '0px solid lightgray', }}>
        <div className='mateial_table_header'>
          {/* {!noshare &&
                    <p className='checked_here' ></p> 
                  } */}
          <p style={{ width: '10%', maxWidth: '10%', color: 'black',marginLeft:'15px' }}>Lead Name</p>
          <p style={{ width: '10%', maxWidth: '10%' }}>Company</p>
          <p style={{ width: '16%', maxWidth: '16%' }}>Email</p>
          <p style={{ width: '10%', maxWidth: '10%' }}>Phone</p>
          <p style={{ width: '10%', maxWidth: '10%' }}>Assigned By</p>
          <p style={{ width: '10%', maxWidth: '10%' }}>Assigned Date</p>
          <p style={{ width: '12%', maxWidth: '12%' }}>Accept status</p>
          <p style={{ width: '10%', maxWidth: '10%' }}>Info</p>
          <p style={{ width: '8%', maxWidth: '8%' }}>Actions</p>
        </div>
        {data.map((d, i) => (
          <div key={i} className={selected_lead !== d._id ? 'mateial_table_body' : 'mateial_table_body_active'}>
            {/* {!noshare &&
              <>
                {selected_lead !== d._id ? <ImRadioUnchecked size={14} style={{ minWidth: '4%', maxWidth: '4%', marginTop: '0px', color: '#c2c2c2', cursor: d?.assigned_lead_id?.lead_id?.isConverted ? 'not-allowed' : 'pointer' }} onClick={() => !d?.assigned_lead_id?.lead_id?.isConverted && onAssignClick(d)} /> :
                  <ImRadioChecked2 size={14} style={{ minWidth: '4%', maxWidth: '4%', marginTop: '0px', color: '#0073BA' }} onClick={() => setselected_lead_fun('')} />}
              </>} */}
            {/* <p className={selected_lead === d._id  ? 'checked_here2': 'checked_here'} onClick={()=>!d?.assigned_lead_id?.lead_id?.isConverted && onAssignClick(d) }>
              {selected_lead === d._id && <p className='checked_here1' style={{background:theme_color}}></p>}
            </p>   */}
            {(d.accept === 'Pending') ?
            <p style={{ width: '10%', maxWidth: '10%',fontSize:'13px', color: "black", wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', fontWeight: '700', cursor: 'pointer',paddingRight:'1%',paddingLeft:'10px',}} >{d?.assigned_lead_id?.lead_id?.lead_name}</p> :
            <p style={{ width: '10%', maxWidth: '10%',fontSize:'13px', color: "#0594F2", wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', fontWeight: '700',paddingRight:'1%',paddingLeft:'10px', cursor: d.accept === 'Rejected' ? 'not-allowed' : 'pointer' }} onClick={() => (d.accept === 'Accepted' || d.accept === 'Pending') && func(d?.assigned_lead_id?.lead_id?.id)}>{d?.assigned_lead_id?.lead_id?.lead_name}</p>}
            <p style={{ width: '10%', maxWidth: '10%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-6px',paddingRight:'1%',paddingLeft:'10px' }}>{d?.assigned_lead_id?.lead_id?.contact_id?.company_name}</p>
            <p style={{ width: '16%', maxWidth: '16%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingRight:'1.4%',paddingLeft:'10px'}}>{d?.assigned_lead_id?.lead_id?.contact_id?.email}</p>
            <p style={{ width: '10%', maxWidth: '10%',fontSize:'12px',marginLeft:'-10px', wordBreak: 'break-word',fontWeight:'800',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'10px' }}>{d?.assigned_lead_id?.lead_id?.contact_id?.phone}</p>
            <p style={{ width: '10%', maxWidth: '10%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', fontSize: '13px', marginLeft: '-3px',paddingLeft:'10px' }}>{d?.assigned_by?.name}</p>
            <p style={{ width: '10%', maxWidth: '10%', wordBreak: 'break-word',fontWeight:'800',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', fontSize: '13px', marginLeft: '-4px',paddingLeft:'10px' }}>{moment(d?.createdAt).format('l')}</p>
            <p style={{ width: '12%', maxWidth: '12%', fontSize: '13px',paddingLeft:'10px' }}><Tag color={(d.accept === 'Pending' && '#108ee9') || (d.accept === 'Accepted' && '#87d068') || (d.accept === 'Rejected' && '#f50')}>{d?.accept}</Tag></p>
            <p style={{ width: '10%', maxWidth: '10%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', fontSize: '13px', marginLeft: '-10px' }}>
              <Dropdown  overlay={() => infoItems(d)} placement="bottomLeft" >
                <BiMessageDetail size={22}  style={{background:theme_color,padding:'4px',color:'#fff',borderRadius:'15px',zIndex:100,marginLeft:'10px'}}  />
              </Dropdown>
            </p>
            {
              (d.accept === 'Pending') &&
              <p style={{ maxWidth: '10%', display: 'flex',alignItems:'center' }}>
                <Tooltip title="Accept" >
                  <TiTick size={22} style={{ color: 'black' }} onClick={() => Modal.confirm({
                    title: 'Confirmation',
                    content: 'By clicking on approve you have access to this lead',
                    okText: 'Approve',
                    cancelText: 'Cancel',
                    onOk: () => acceptassignedlead(d, 1),
                    onCancel: ''

                  }

                  )} />
                </Tooltip>
                <Tooltip title="Dont Accept" >
                  <IoMdClose size={20} style={{ marginLeft: '10px', color: 'black' }} onClick={() => acceptassignedlead(d, 2)} />
                </Tooltip>
              </p>
            }
            {
              (d.accept === 'Accepted') &&
              <p style={{ maxWidth: '8%', display: 'flex', justifyContent: 'space-between' }}>
                <Tooltip title="Convert to Deal" >
                  <CgMoveRight size={26} onClick={() => !d?.assigned_lead_id?.lead_id?.isConverted && assigned_lead_to_deal(d)} style={{ ...d?.assigned_lead_id?.lead_id?.isConverted ? { color: '#d4d4d4',  } : { color: 'black' } }} />
                </Tooltip>
                <Tooltip title="Add Reminder" >
                  <GiAlarmClock size={20} onClick={() => !d?.assigned_lead_id?.lead_id?.isConverted && onReminderClick(d?.assigned_lead_id?.lead_id?.id)} style={{ ...d?.assigned_lead_id?.lead_id?.isConverted ? { color: '#d4d4d4',  marginLeft: '10px' } : { marginLeft: '10px', color: 'black' } }} />
                </Tooltip>
                <Tooltip title="Add Meeting"  >
                  <BsCalendar2Week size={15} onClick={() => !d?.assigned_lead_id?.lead_id?.isConverted && onMeetingClick(d)} style={{ marginLeft: '15px',...d?.assigned_lead_id?.lead_id?.isConverted && { color: '#d4d4d4',} }} />
                </Tooltip>
              </p>
            }


          </div>
        ))}
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
          {pagination.total > 20 &&
            <Pagination defaultCurrent={1} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)} showSizeChanger pageSize={20} />
          }
        </div>
      </div>
    </div>)
  } else if (type === 'deal') {
    return (
      <div style={{ background: 'transparent' }}>
        <MeetingModal visible={isvisible1} func={() => setisvisible1(false)} data_id={typeId} type={type} />
        <Reminder visible={isvisible} func={() => setisvisible(false)} id={typeId} type={type} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
        <div style={{ border: '0px solid lightgray', }}>
          <div className='mateial_table_header'>
            <p style={{ width: '12%' }}>Deal Name</p>
            <p style={{ width: '14%' }}>Company</p>
            <p style={{ width: '15%' }}>Stage</p>
            <p style={{ width: '15%' }}>Closing Date</p>
            <p style={{ width: '13%' }}>Phone</p>
            <p style={{ width: '20%', marginRight: '1%' }}>Email</p>
            <p style={{ width: '12%' }}>Deal Owner</p>
            <p style={{ width: '10%' }}>Actions</p>
          </div>

          {data.map((d) => (
            <div key={d.id} className='mateial_table_body'>
              <Tooltip title={d?.deal_name} ><p style={{ width: '12%',fontSize:'12.5px', color: "#0594f2", cursor: 'pointer', fontWeight: '700', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}
                onClick={() => func(d)}>{d?.deal_name}</p></Tooltip>
              <Tooltip title={d?.lead_id?.contact_id?.company_name} ><p style={{ width: '14%',fontSize:'12.5px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingRight:'0.5%' }}>{d?.lead_id?.contact_id?.company_name}</p></Tooltip>
              <p style={{ width: '15%',fontSize:'12.5px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}><Tag color='#17485c'>{d?.deal_stage}</Tag></p>
              <p style={{ width: '15%',fontSize:'11px',paddingLeft:'5px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontWeight:'800' }}>{moment(d?.closing_date).format('l')}</p>
              <p style={{ width: '13%',fontSize:'12px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontWeight:'800',textOverflow:'ellipsis' }}>{d?.lead_id?.contact_id?.phone}</p>
              <Tooltip title={d?.lead_id?.contact_id?.email}><p style={{ width: '20%',fontSize:'12.5px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', marginRight: '1%' }}>{d?.lead_id?.contact_id?.email}</p></Tooltip>
              <Tooltip title={d?.deal_owner?.name} ><p style={{ width: '12%',fontSize:'11.5px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'8px',paddingRight:'0.5%' }}>{d?.deal_owner?.name}</p></Tooltip>
              <p style={{ width: '10%',fontSize:'12.5px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'10px' }}>
                <Tooltip title="Add Reminder" >
                  <GiAlarmClock size={18} style={{ marginRight: '10px', color: 'black' }} onClick={() => onReminderClick(d?.id || d?._id)} />
                </Tooltip>
                <Tooltip title="Edit Deal" >
                  <FiEdit size={15} style={{ marginRight: '10px', color: 'black' }} onClick={() => editDeal(d?.id)} />
                </Tooltip>
                <Tooltip title="Add Meeting"  >
                  <BsCalendar2Week size={14} onClick={() => onMeetingClick(d)} style={{ marginLeft: '2px' }} />
                </Tooltip>
              </p>
            </div>
          ))}

          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
            {pagination?.total > 20 &&
              <Pagination defaultCurrent={1} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)} showSizeChanger pageSize={20} />
            }
          </div>

        </div>

      </div>
    )
  } else if (type === 'contact') {
    return (
      <div style={{ background: 'transparent' }}>
        <MeetingModal visible={isvisible1} func={() => setisvisible1(false)} data_id={typeId} type={type} />
        <Reminder visible={isvisible} func={() => setisvisible(false)} id={typeId} type={type} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
        <div style={{ border: '0px solid lightgray', }}>
          <div className='mateial_table_header'>
            <p className='checked_here' ></p> 
            <p  style={{ width: '10%', fontWeight: '700',paddingLeft: '0.5%',marginLeft:'15px',textTransform:'capitalize', }} >Company </p>
            <p style={{ width: '12%', paddingLeft: '0.5%' }}>Contact Name</p>
            <p style={{ width: '12%' }}>Phone</p>
            <p style={{ width: '20%' }}>Email</p>
            <p style={{ width: '12%' }}>Designation</p>
            <p style={{ width: '12%' }}>Contact Source</p>
            <p style={{ width: '10%' }}>Contact Status</p>
            <p style={{ width: '9%' }}>Actions</p>
          </div>
          {data.map((d) => (
            <div key={d.id} className={selected_contact !== (d.id ? d.id : d._id) ? 'mateial_table_body' : 'mateial_table_body_active'} >
              <p className={selected_contact === (d.id ? d.id : d._id) ? 'checked_here2': 'checked_here'} onClick={()=>selected_contact !== '' ? setselected_contact_func(d.id ? d.id : d._id) : setselected_contact_func()}>
                {selected_contact === (d.id ? d.id : d._id) && <p className='checked_here1' style={{background:theme_color}}></p>}
              </p>
              <p className='itemp' style={{ width: '10%', fontWeight: '700', color: "#0594f2", wordBreak: 'break-word', marginLeft:'15px',textTransform:'capitalize' }} onClick={()=>func(d)} ><Tooltip  title={d?.company_name}><span>{d?.company_name?.slice(0, 12)}</span></Tooltip> </p>
              <Tooltip  title={d?.contact_name}><p className='itemp' style={{ width: '11%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{d?.contact_name}</p></Tooltip>
              <p className='itemp' style={{ width: '12%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontWeight:'800'  }}>{d?.phone}</p>
              <Tooltip  title={d?.email}><p className='itemp' style={{ width: '20%',paddingRight:'0.5%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'  }}>{d?.email}</p></Tooltip>
             
              <Tooltip  title={d?.designation}><p className='itemp' style={{ width: '12%',paddingRight:'0.5%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'  }}>{d?.designation}</p></Tooltip>
              <Tooltip  title={d?.contact_source?.source_name}><p className='itemp' style={{ width: '12%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'  }}>{d?.contact_source?.source_name} </p></Tooltip>
              <Tooltip  title={d?.status}><p className='itemp' style={{ width: '10%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'  }}>{d?.status} </p></Tooltip>
              <p className='itemp' style={{ width: '9%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title="Convert to Lead">
                    <CgMoveRight size={23} style={{ marginRight: '5px', color: 'black' }} onClick={() => gotoleadcreatefunc(d)} />
                  </Tooltip>
                  <Tooltip title="Add Reminder">
                    <GiAlarmClock size={17} style={{ marginRight: '9px', color: 'black' }} onClick={() => onReminderClick(d)} />
                  </Tooltip>
                  <Tooltip title="Add Meeting"  style={{ marginLeft: '0px' }}>
                    <BsCalendar2Week size={13} onClick={() => onMeetingClick(d)} />
                  </Tooltip>

                  <Avatar  sx={{bgcolor: theme_color,width: 20, height: 20,fontSize:'10px',color:'white',fontWeight:'800',marginLeft:'10px'}}>{d?.conversion_count}</Avatar>

                </div>
              </p>
            </div>
          ))}

          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
            {pagination?.total > 20 &&
              <Pagination defaultCurrent={1} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)} showSizeChanger pageSizeOptions={[20]} pageSize={20} />
            }
          </div>
        </div>
      </div>
    )
  } else if (type === 'Referred by me') {
    return (
      <div style={{ background: 'transparent' }}>
        <Reminder visible={isvisible} func={() => setisvisible(false)} id={typeId} type={type} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
        <div style={{ border: '0px solid lightgray', }}>
          <div className='mateial_table_header'>
            <p style={{ width: '1.5%' }}></p>
            <p style={{ width: '11%', color: 'black' }}>Company </p>
            <p style={{ width: '13%', paddingLeft: '0.5%' }}>Contact Name</p>
            <p style={{ width: '12%' }}>Phone</p>
            <p style={{ width: '22%' }}>Email</p>
            <p style={{ width: '10%' }}>Contact Status</p>
            <p style={{ width: '13%' }}>Contact Owner</p>
            <p style={{ width: '14%' }}>Referred Department</p>
            <p style={{ width: '13%' }}>Referred Person</p>
            <p style={{ width: '2%' }}></p>
          </div>
          {data.map((d) => (
            <div key={!d.id ? d._id : d.id} className={'mateial_table_body'} >
              <p style={{ width: '1.2%' }}></p>
              <Tooltip  title={d?.contact_id?.company_name}><p style={{ width: '11%',fontSize:'13px', fontWeight: '700', color: "#0594f2",wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'  }} onClick={() => func1(d?.contact_id?.id)}>{d?.contact_id?.company_name}</p></Tooltip>
              <Tooltip  title={d?.contact_id?.contact_name}><p style={{ width: '13%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft: '0.5%',paddingRight:'0.5%' }}>{d?.contact_id?.contact_name}</p></Tooltip>
              <p style={{ width: '12%',fontSize:'12px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',fontWeight: 'bold' }}>{d?.contact_id?.phone}</p>
              <Tooltip  title={d?.contact_id?.email}><p style={{ width: '22%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',  }}>{d?.contact_id?.email}</p></Tooltip>
              <p style={{ width: '10%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'10px'  }}><Tag color="#17485c">{d?.contact_id?.status}</Tag></p>
              <Tooltip  title={d?.contact_id?.contact_owner?.name}><p style={{ width: '13%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'8px'  }}>{d?.contact_id?.contact_owner?.name}</p></Tooltip>
              <p style={{ width: '14%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'8px'  }}>{d?.department_id?.department_name}</p>
              <Tooltip  title={d?.referred_to?.name}><p style={{ width: '13%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'10px'  }}>{d?.referred_to?.name}</p></Tooltip>
              <p style={{ width: '2%' }}>
              <Avatar  sx={{bgcolor:theme_color,width: 20, height: 20,fontSize:'10px',color:'#fff',fontWeight:'800',marginLeft:'10px'}}>{d?.contact_id?.conversion_count}</Avatar>
              </p>
            </div>
          ))}

          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
            {pagination?.total > 20 &&
              <Pagination defaultCurrent={1} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)} showSizeChanger pageSizeOptions={[20]} pageSize={20} />
            }
          </div>
        </div>

      </div>
    )
  } else if (type === 'Referred To me') {
    return (
      <div style={{ background: 'transparent' }}>
        <MeetingModal visible={isvisible1} func={() => setisvisible1(false)} data_id={typeId} type={type} />
        <Reminder visible={isvisible} func={() => setisvisible(false)} id={typeId} type={'contact'} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
        <div style={{ border: '0px solid lightgray', }}>
          <div className='mateial_table_header'>
            <p className='checked_here'></p>
            <p style={{ width: '10%', minWidth: '10%', maxWidth: '10%',marginLeft:'15px', color: 'black' }}>Company </p>
            <p style={{ width: '13%', paddingLeft: '0.5%' }}>Contact Name</p>
            <p style={{ width: '9%' }}>Phone</p>
            <p style={{ width: '18%' }}>Email</p>
            <p style={{ width: '12%' }}>Contact Status</p>
            <p style={{ width: '13%' }}>Contact owner</p>
            <p style={{ width: '13%' }}>Transferred By</p>
            <p style={{ width: '10%' }}>Actions</p>
          </div>
          {data.map((d) => (
            <div key={d._id ? d._id : d.id} className={selected_contact !== d._id ? 'mateial_table_body' : 'mateial_table_body_active'}>
            
              <p style={{minWidth:'16px'}} className={selected_contact === d._id ? 'checked_here2': 'checked_here'} onClick={()=>selected_contact !== '' ? setselected_contact_func(d._id, d?.contact_id?.id) : setselected_contact_func()}>
                {selected_contact === d._id && <p className='checked_here1' style={{background:theme_color}}></p>}
              </p>
              
              <Tooltip title={d?.contact_id?.company_name}><p style={{ maxWidth: '10%',minWidth:'10%',fontSize:'13px', fontWeight: '700', color: "#0594f2",marginLeft:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }} onClick={() => func1(d?.contact_id?.id)}>{d?.contact_id?.company_name}</p></Tooltip>
              <Tooltip title={d?.contact_id?.contact_name}><p style={{ minWidth: '12%',fontSize:'13px', maxWidth: '10%',paddingRight:'0.5%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{d?.contact_id?.contact_name}</p></Tooltip>
              <p style={{ minWidth: '9%',fontSize:'12px', maxWidth: '10%', wordBreak: 'break-word', fontWeight: '800', whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{d?.contact_id?.phone}</p>
              <Tooltip title={d?.contact_id?.email}><p style={{ minWidth: '18%',fontSize:'13px', maxWidth: '18%', wordBreak: 'break-word',whiteSpace:'nowrap',paddingRight:'2%',overflow:'hidden',textOverflow:'ellipsis' }}>{d?.contact_id?.email}</p></Tooltip>
              <p style={{ minWidth: '12%',marginLeft:'-5px',fontSize:'13px', maxWidth: '16%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingRight:'0.5%'}}><Tag color={theme_color}>{d?.contact_id?.status}</Tag></p>
              <Tooltip title={d?.contact_id?.contact_owner?.name}><p style={{ minWidth: '13%',fontSize:'13px', maxWidth: '12%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-5px' }}>{d?.contact_id?.contact_owner?.name}</p></Tooltip>
              <Tooltip title={d?.referred_by?.name}><p style={{ minWidth: '13%',fontSize:'13px', maxWidth: '13%', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-5px',paddingRight:'2%' }}>{d?.referred_by?.name}</p></Tooltip>
              <p style={{ maxWidth: '10%',fontSize:'13px',alignItems:'center',display:'flex',justifyItems:'flex-start',marginLeft:'-10px' }}>


                  <Tooltip title="Convert to Lead" >
                    <CgMoveRight size={23} style={{ marginRight: '6px', color: 'black' }} onClick={() => gotoleadcreatefunc(d?.contact_id)} />
                  </Tooltip>
                  <Tooltip title="Add Reminder" >
                    <GiAlarmClock size={19} style={{ marginRight: '5px', color: 'black' }} onClick={() => onReminderClick(d?.contact_id?.id)} />
                  </Tooltip>
                  <Tooltip title="Add Meeting"  style={{ marginLeft: '4px' }}>
                    <BsCalendar2Week size={14} onClick={() => onMeetingClick(d)} />
                  </Tooltip>
                  <Avatar  sx={{bgcolor:theme_color,color:'#fff',width: 20, height: 20,fontSize:'10px',fontWeight:'800',marginLeft:'10px'}}>{d?.contact_id?.conversion_count}</Avatar>
              </p>
            </div>
          ))}

          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
            {pagination?.total > 20 &&
              <Pagination defaultCurrent={1} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)} showSizeChanger pageSizeOptions={[20]} pageSize={20} />
            }
          </div>
        </div>

      </div>
    )
  } else if (type === 'meeting') {
    return (
      <div style={{ background: 'transparent' }}>
        <Reminder visible={isvisible} func={() => setisvisible(false)} id={typeId} type={type} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
        <div style={{ border: '0px solid lightgray', }}>
          <div className='mateial_table_header'>
            <p style={{ width: '13%', fontWeight: '800', color: 'black' }}>Title</p>
            <p style={{ width: '13%' }}>Date</p>
            <p style={{ width: '7%' }}>Time</p>
            <p style={{ width: '10%' }}>Related To</p>
            <p style={{ width: '20%' }}>Contact Name</p>
            <p style={{ width: '15%' }}>Location</p>
            <p style={{ width: '12%' }}>Host</p>
            <p style={{ width: '10%' }}>Actions</p>
          </div>
          {data?.map((d, i) => (
            <div key={d.id} className={selected === d.id ? 'mateial_table_body_active' : 'mateial_table_body'}>
              <Dropdown arrow overlay={menu} onClick={() => setdescription(d.description)} trigger={['click']}><p style={{ width: '13%', color: "#3886c4",wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingRight:'0.6%',fontWeight:'800' }}>{d.title}</p></Dropdown>
              <p style={{ width: '13%',fontSize:'13px',fontWeight: '800', wordBreak: 'break-word' }}>{moment(d.date_time).format('LL')}</p>
              <p style={{ width: '7%',fontWeight:'800',fontSize:'13px',wordBreak: 'break-word',marginLeft:'-5px' }}>{moment(d.date_time).format('LT')}</p>
              <p style={{ width: '10%',fontSize:'13px',fontWeight: '600',  paddingLeft: '4px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{d.related_to}</p>
              <p style={{ width: '20%',fontSize:'13px',fontWeight: '600', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{d.related_to === 'Lead' && <SiHandshake size={16} style={{ marginRight: '10px', color: '#ff9a3b' }} />} {d.related_to === 'Lead' && d?.contact_name?.lead_name} {d.related_to === 'Deal' && <IoWallet size={18} style={{ marginRight: '10px', color: '#31d45f' }} />} {d.related_to === 'Deal' && d?.contact_name?.deal_name}  {d.related_to === 'Contact' && <MdPermContactCalendar size={18} style={{ marginRight: '5px', color: '#3badff' }} />} {d.related_to === 'Contact' && d?.contact_name?.contact_name} </p>
              <p style={{ width: '15%',fontSize:'13px',fontWeight: '600', paddingLeft: '4px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingRight:'1%' }}>{d.location}</p>
              <p style={{ width: '12%',fontSize:'13px',  paddingLeft: '4px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingRight:'1%' }}>{name}</p>
              <p style={{ width: '10%',fontSize:'13px', marginLeft: '5px', wordBreak: 'break-word' }}><GiAlarmClock size={17} style={{ marginRight: '10px', color: 'black' }} onClick={() => onReminderClick(d.id)} /><FiEdit size={14} style={{ color: 'black' }} onClick={() => editmeeting(d)} /> </p>
            </div>))}


          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
            {pagination?.total > 20 &&
              <Pagination defaultCurrent={1} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)} showSizeChanger pageSize={20} />
            }
          </div>

        </div>

      </div>
    )
  } else {
    return null
  }
}

export default ReactMaterialTable
