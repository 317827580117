import React from 'react'
import BreadCrumbs from '../../../components/breadcrumbs/BreadCrumbs'
import DetailBoby from '../../../components/detail_design/DetailBoby'
import DetailHeader from '../../../components/detail_design/DetailHeader'


function TaskDetail() {
    
    const type= "Task"
    const label = ['Subject', 'Due Date', 'Status', 'Priority', 'Related To', 'Contact Name', 'Task Owner', 'Activity Type']
    const label1 = ['Description',]
    const section = ['Task Information','Description']
    const values = ['Register for upcoming CRM Webinars','Dec 14, 2021','waiting for input','High','infotech','Kris Master','Amrutha','Task',]
    const values1 = ['sandkjah jfabhskjdnf jkdsbngjkd fjdshgjkhak dghkjfhdjks ghjkdhgk dhkj']

    return (
        <div style={{width:'70%',margin:'auto'}}>
            <BreadCrumbs />
            <DetailHeader type={type} />
            <div style={{width:'100%',}}>
            <DetailBoby section={section} values={[values,values1]} labels = {[label,label1]} type={type} />
            </div>
        </div>
    )
}

export default TaskDetail
