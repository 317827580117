import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import WebViewTasks from './web/ViewTasks'
import MobileViewTasks from './android/ViewTasks'

const index = () =>{
    if(isMobile){
        return(
            <div>
                {/* <h1>Amma</h1> */}
                <MobileViewTasks />
            </div>
        )
    }else{
        return(
            <div>
                <WebViewTasks />
            </div>
        )
    }
}

export default index