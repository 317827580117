import React, { useState } from 'react'
import { Button, Tooltip } from 'antd';
import { BiLock } from 'react-icons/bi'
import { GoCalendar } from 'react-icons/go'
import { DiOpensource } from 'react-icons/di'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { IoDocumentAttachOutline } from 'react-icons/io5'
import { IoAttach } from 'react-icons/io5'
import { Modal } from 'antd';
import DateField from '../inputcomponents/DateField';
import TimeField from '../inputcomponents/TimeField';
import DropDown from '../inputcomponents/DropDown';
import { HiLocationMarker } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom';


function DetailHeader({ type, datas, headerValues }) {
    const [visible, setvisible] = useState(false)

    const [data, setdata] = useState({ on_date: '', on_time: '', repeat_type: '', notify: '' })
    const [dataerr, setdataerr] = useState({ on_date_err: {}, on_time_err: {}, repeat_type_err: {}, notify_err: {} })
    const navigate = useNavigate()

    function addRemindar() {
        if (!data.on_date) {
            setdataerr({ ...dataerr, on_date_err: { error: true, message: 'This field is required' } })
        } else if (!data.on_time) {
            setdataerr({ ...dataerr, on_time_err: { error: true, message: 'This field is required' } })
        } else if (!data.repeat_type) {
            setdataerr({ ...dataerr, repeat_type_err: { error: true, message: 'This field is required' } })
        } else if (!data.notify) {
            setdataerr({ ...dataerr, notify_err: { error: true, message: 'This field is required' } })
        }


    }

    function handleChange({ key, value }) {
        if (key === 'on_date') {
            setdataerr({ ...dataerr, on_date_err: {} })
        } else if (key === 'on_time') {
            setdataerr({ ...dataerr, on_time_err: {} })
        }
        setdata({ ...data, [key]: value })
    }

    function selectedvalue(v) {
        if (v?.d?.id) {
            setdataerr({ ...dataerr, [v.nameerr]: {} })
            setdata({ ...data, [v.name]: v.d.value })
        }
    }

    const loadEditPage = () => {
        if (type === 'Lead') {
            navigate('/create_lead', {
                state: {
                    datas: datas
                }
            })
        }
        if (type === 'Contact') {
            navigate('/create_contact', {
                state: {
                    datas: datas
                }
            })
        }
    }

    return (
        <div style={{ position: 'sticky', top: '40px', background: 'white', zIndex: 100 }}>
            <Modal
                centered
                visible={visible}
                onOk={() => addRemindar()}
                onCancel={() => setvisible(false)}>
                <div className='row'>
                    <h5>Add Reminder</h5>
                    <div className="col-md-6 mt-3">
                        <h6 style={{ fontSize: '14px' }}>On Date</h6>
                        <DateField
                            priority={'high'}
                            name={'on_date'}
                            onChangefun={handleChange}
                            error_obj={dataerr.on_date_err}
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <h6 style={{ fontSize: '14px' }}>On Time</h6>
                        <TimeField
                            priority="high"
                            name={'on_time'}
                            onChangefun={handleChange}
                            error_obj={dataerr.on_time_err}
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <h6 style={{ fontSize: '14px' }}>Repeat Type</h6>
                        <DropDown
                            onChange={(v) => selectedvalue(v)}
                            priority={'high'}
                            name='repeat_type'
                            nameerr='repeat_type_err'
                            error_obj={dataerr.repeat_type_err}
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <h6 style={{ fontSize: '14px' }}>Notify</h6>
                        <DropDown
                            onChange={(v) => selectedvalue(v)}
                            priority={'high'}
                            name='notify'
                            nameerr='notify_err'
                            error_obj={dataerr.notify_err}
                        />
                    </div>
                </div>
            </Modal>

            {type === 'Lead' &&
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0px' }}>
                            <p style={{ fontWeight: '500', fontSize: '30px', background: '#faf5ff', width: '60px', height: '60px', borderRadius: '14px', display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>{headerValues[0].charAt(0)}</p>
                            <div style={{ marginLeft: '20px' }}>
                                <h5 style={{ marginBottom: '0px', fontWeight: '500' }}>{headerValues[0]} <Tooltip placement="top" color={'black'} title={`Your ${type} is secure in our database and this 
                        ${type} is not shared by any team members `}><BiLock style={{ marginLeft: '10px', color: 'grey', cursor: 'pointer' }} /></Tooltip></h5>
                                <p style={{ fontSize: '13px', marginTop: '5px', cursor: 'pointer' }}><span style={{ fontWeight: '600' }}> {headerValues[1]}</span> </p>
                            </div>
                        </div>
                        <div style={{ width: '60px', display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                            <Tooltip title="Reminders" color={'black'}><IoMdNotificationsOutline size={25} style={{ cursor: 'pointer' }} onClick={() => setvisible(true)} /></Tooltip>
                            {type !== 'Task' && type !== 'Meeting' && <Tooltip title="Attach File" color={'black'}><IoDocumentAttachOutline size={25} style={{ cursor: 'pointer' }} /></Tooltip>}
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginRight: '10px' }}><GoCalendar size={20} color="black" /><span style={{ fontWeight: '500', fontSize: '12px', paddingLeft: '5px', marginRight: '10px' }}><strong>{headerValues[2]} </strong></span></p>
                        {type !== 'Meeting' && <p><DiOpensource size={22} color="black" /><span style={{ fontWeight: '500', fontSize: '12px', paddingLeft: '5px', marginRight: '10px' }}><strong>Status </strong>{headerValues[3]}</span></p>}
                        {type === 'Meeting' && <p><HiLocationMarker size={22} color="black" /><span style={{ fontWeight: '500', fontSize: '12px', paddingLeft: '5px', marginRight: '10px' }}><strong>Banglore</strong></span></p>}
                        {type === 'Task' && <p><HiLocationMarker size={22} color="black" /><span style={{ fontWeight: '500', fontSize: '12px', paddingLeft: '5px', marginRight: '10px' }}><strong></strong>InfoTech</span></p>}
                        {type !== 'Meeting' && type !== 'Task' && <p><IoAttach size={22} color="black" /><span style={{ fontWeight: '500', fontSize: '12px', paddingLeft: '5px', marginRight: '10px' }}><strong style={{ fontSize: '15px' }}>0 </strong>Attachments</span></p>}
                        <p><IoMdNotificationsOutline size={22} color="black" /><span style={{ fontWeight: '500', fontSize: '12px', paddingLeft: '5px', marginRight: '10px' }}><strong style={{ fontSize: '15px' }}>0</strong> Reminders</span></p>
                    </div>

                    <p style={{ border: '4px solid #1d75cb', borderRadius: '20px' }}></p>

                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 20px', border: '1px solid #dbdbdb', background: '#fafafa', borderRadius: '4px', borderBottom: '2px solid  #dbdbdb' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src="https://secure.gravatar.com/avatar/e36bf678dc8cb3b89f320c40bbb30fed?s=180&d=identicon" style={{ width: '40px', height: '40px', borderRadius: '50%' }} alt="required" />
                            <div style={{ marginLeft: '10px', marginTop: '2px' }}>
                                <h6 style={{ fontWeight: '700', fontSize: '14px', marginBottom: '2px' }}>{/* {type === 'Meeting' ? 'Contact' : type } Name */}{headerValues[4]} </h6>
                                <h5 style={{ fontSize: '12px' }}><strong>{headerValues[5]}</strong> <span style={{ fontWeight: '400', textTransform: 'lowercase' }}> handling the {type}</span></h5>
                            </div>
                        </div>
                        <div style={{ width: '260px', display: 'flex', justifyContent: 'space-between' }}>
                            <Button type="primary" >Send Email</Button>
                            <Button>Convert</Button>
                            <Button onClick={loadEditPage}>Edit</Button>
                        </div>
                    </div>
                </>
            }

            {type === 'Contact' &&
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0px' }}>
                            <p style={{ fontWeight: '500', fontSize: '30px', background: '#faf5ff', width: '60px', height: '60px', borderRadius: '14px', display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>{'C'}</p>
                            <div style={{ marginLeft: '20px' }}>
                                <h5 style={{ marginBottom: '0px', fontWeight: '500' }}>{'om'} <Tooltip placement="top" color={'black'} title={`Your ${type} is secure in our database and this 
                        ${type} is not shared by any team members `}><BiLock style={{ marginLeft: '10px', color: 'grey', cursor: 'pointer' }} /></Tooltip></h5>
                                <p style={{ fontSize: '13px', marginTop: '5px', cursor: 'pointer' }}><span style={{ fontWeight: '600' }}> sections</span> </p>
                            </div>
                        </div>
                        <div style={{ width: '60px', display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                            <Tooltip title="Reminders" color={'black'}><IoMdNotificationsOutline size={25} style={{ cursor: 'pointer' }} onClick={() => setvisible(true)} /></Tooltip>
                            {type !== 'Task' && type !== 'Meeting' && <Tooltip title="Attach File" color={'black'}><IoDocumentAttachOutline size={25} style={{ cursor: 'pointer' }} /></Tooltip>}
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginRight: '10px' }}><GoCalendar size={20} color="black" /><span style={{ fontWeight: '500', fontSize: '12px', paddingLeft: '5px', marginRight: '10px' }}><strong>{'anna'} </strong></span></p>
                        {type !== 'Meeting' && <p><DiOpensource size={22} color="black" /><span style={{ fontWeight: '500', fontSize: '12px', paddingLeft: '5px', marginRight: '10px' }}><strong>Status </strong>{'Pending'}</span></p>}
                        {type === 'Meeting' && <p><HiLocationMarker size={22} color="black" /><span style={{ fontWeight: '500', fontSize: '12px', paddingLeft: '5px', marginRight: '10px' }}><strong>Banglore</strong></span></p>}
                        {type === 'Task' && <p><HiLocationMarker size={22} color="black" /><span style={{ fontWeight: '500', fontSize: '12px', paddingLeft: '5px', marginRight: '10px' }}><strong></strong>InfoTech</span></p>}
                        {type !== 'Meeting' && type !== 'Task' && <p><IoAttach size={22} color="black" /><span style={{ fontWeight: '500', fontSize: '12px', paddingLeft: '5px', marginRight: '10px' }}><strong style={{ fontSize: '15px' }}>0 </strong>Attachments</span></p>}
                        <p><IoMdNotificationsOutline size={22} color="black" /><span style={{ fontWeight: '500', fontSize: '12px', paddingLeft: '5px', marginRight: '10px' }}><strong style={{ fontSize: '15px' }}>0</strong> Reminders</span></p>
                    </div>

                    <p style={{ border: '4px solid #1d75cb', borderRadius: '20px' }}></p>

                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 20px', border: '1px solid #dbdbdb', background: '#fafafa', borderRadius: '4px', borderBottom: '2px solid  #dbdbdb' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src="https://secure.gravatar.com/avatar/e36bf678dc8cb3b89f320c40bbb30fed?s=180&d=identicon" style={{ width: '40px', height: '40px', borderRadius: '50%' }} alt="required" />
                            <div style={{ marginLeft: '10px', marginTop: '2px' }}>
                                <h6 style={{ fontWeight: '700', fontSize: '14px', marginBottom: '2px' }}>{/* {type === 'Meeting' ? 'Contact' : type } Name */}{'TYPE'} </h6>
                                <h5 style={{ fontSize: '12px' }}><strong>{'HEADING'}</strong> <span style={{ fontWeight: '400', textTransform: 'lowercase' }}> handling the {type}</span></h5>
                            </div>
                        </div>
                        <div style={{ width: '260px', display: 'flex', justifyContent: 'space-between' }}>
                            <Button type="primary" >Send Email</Button>
                            <Button>Convert</Button>
                            <Button onClick={loadEditPage}>Edit</Button>
                        </div>
                    </div>
                </>
            }


        </div>
    )
}

export default DetailHeader
