import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import WebViewContact from './web/ViewContact'
import MobileViewContact from './mobile/ViewContact'

const index = () =>{
    if(isMobile){
        return(
            <div>
                <MobileViewContact />
            </div>
        )
    }else{
        return(
            <div>
                <WebViewContact />
            </div>
        )
    }
}

export default index