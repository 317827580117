import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import axios from 'axios'
import DetailDesign2 from '../../../components/detail_design/DetailDesign2'
import moment from 'moment'
import { useSelector } from 'react-redux'

function LeadDetail() {
    const { id } = useParams();
    const department = useSelector(state => state.Auth.department)

    const type = 'Lead'

    const label = ['Contact Name', 'Company', 'Phone', 'Company Phone', 'Email', 'Company Email', 'Designation', 'Website', 'Secondary Contact Person', 'Contact Source', 'Secondary Contact Phone', 'Industry', 'Secondary Contact Designation', 'Contact Status',]
    const label1 = ['Street', 'City', 'State', 'Pincode', 'Country',]
    const label2 = ['Description']

    const section = ['Contact Information', 'Address Information', 'Description']
    const [label3, setlabel3] = useState([])


    const label4 = ['Description']

    const section1 = ['Lead Information', 'Description']

    const [values, setvalues] = useState([])
    const [values1, setvalues1] = useState([])
    const [values2, setvalues2] = useState([])
    const [values3, setvalues3] = useState([])
    const [values4, setvalues4] = useState([])
    const [departement_arr, setdepartement_arr] = useState([])

    const section_arr = [section, section1]
    const labels_arr = [[label, label1, label2], [label3, label4],]
    const values_arr = [[values, values1, values2], [values3, values4],]


    useEffect(async() => {

        const departement_arr_created = []
        await axios.get('department/get', authHeader)
        .then((res) => {
            res.data.datas.filter(d => d?.department_name !== 'Database Team' && d?.department_name !== 'Media Team' && d?.department_name !== 'MD Office').forEach(d => {
                let data = {}
                data['label'] = d.department_name
                data['value'] = d.department_name
                data['id'] = d.id
                departement_arr_created.push(data)
            })
            //    console.log('departement_arr_created',departement_arr_created)
            setdepartement_arr(departement_arr_created)

        })
        

       

        axios.get(`lead/get/${id}`, authHeader).then((res) => {


            let d = res.data.datas[0]?.contact_id
            let md = res.data.datas[0]


            let department_find =  departement_arr_created.find(d=>d.id === md.department_id)


            if (department_find?.value === 'Transaction Team') {
                setlabel3(['Lead Name', 'Title', 'Lead Status', 'Modified Date', 'Contact Name','Lead Operators', 'Requirement','Measurement','Zone', 'Lead Owner'])
            } else if(department_find?.value === 'Project Team') {
                setlabel3(['Lead Name', 'Project Scope', 'Lead Status', 'Modified Date', 'Contact Name','Lead Owner'])
            } else{
                setlabel3(['Lead Name', 'Title', 'Lead Status', 'Modified Date', 'Contact Name','Lead Owner'])
            }






            let contact_info = {
                'contact_name': d?.contact_name,
                'company_name': d?.company_name,

                'phone': d?.phone,
                'company_phone': d?.company_phone,

                'email': d?.email,
                'company_email': d?.company_email,

                'designation': d?.designation,
                'website': d?.website,

                'secondary_contact_person': d?.secondary_contact_person,
                'contact_source': d?.contact_source?.source_name,

                'secondary_contact_phone': d?.secondary_contact_phone,
                'industry': d?.industry?.industry_name,

                'secondary_contact_designation': d?.secondary_contact_designation,
                'status': d?.status,
            }

            let address_info = {
                'street': d?.street,
                'city': d?.city,
                'state': d?.state,
                'pincode': d?.pincode,
                'country': d?.country
            }

            let description = {
                'description': md?.description
            }

            setvalues(contact_info)
            setvalues1(address_info)
            setvalues2(description)

            let lead_info = {
                'lead_name': md?.lead_name,
                'lead_title': md?.lead_title,
                'lead_status':md?.lead_status === 'Lead Lost' ?  md?.lead_status + ` Reason : (${md?.remarks})` : md?.lead_status,
                'industry': moment(md?.updatedAt).format('MMMM Do YYYY, h:mm:ss a'),
                'contact_name': d?.contact_name,
                'lead_operators':md?.lead_operators,

            }

            if (department_find?.value === 'Transaction Team') {
                lead_info['Requirement'] = md?.requirement
                lead_info['Measurement'] = md?.measurement
                lead_info['Zone'] = md?.zone
                lead_info['Lead Owner'] = md?.lead_owner?.name
            } else {
                lead_info['Lead Owner'] = md?.lead_owner?.name
            }


            setvalues3(lead_info)
            setvalues4(description)
        })
    }, [])


   


    return (
        <div style={{ margin: 'auto' }}>
            <DetailDesign2 id={id} activetab="2" section={section_arr} values={values_arr} labels={labels_arr} type={type} converted_status={true} />
        </div>
    )
}

export default LeadDetail
