import React, { useState, useEffect } from 'react'
import MobileTableHeader from '../../../components/bdcomponenttable/mobileComponents/MobileTableHeader'
import SubViewContactTabs from './SubViewContactTabs';
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { Drawer, Input, Row, Col, Button, Upload, Select } from 'antd'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import DateField from '../../../components/inputcomponents/DateField';
import { IoClose } from 'react-icons/io5'
import { Tag, Modal } from 'antd';
import readXlsxFile from 'read-excel-file'
import Loader from '../../../components/Loaders/Loader';
import xlsxixon from '../../../images/xls.png'
import { MdOutlineDeleteOutline } from 'react-icons/md'
import { useSelector } from 'react-redux';
import { main_bg } from '../../../components/constants/Constants1';
import toast, { Toaster } from 'react-hot-toast';


function ViewContact() {

    const fcpl_id = useSelector(state => state.Auth.fcpl_id)

    const [datas, setDatas] = useState([])
    const [pagination, setpagination] = useState({})
    const [loader, setloader] = useState(false)
    const [reminder, setReminders] = useState([])


    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);
    const [current_tab, setcurrent_tab] = useState('1')
    const [shareContactVisible, setshareContactVisible] = useState(false);
    const [departement_arr, setdepartement_arr] = useState([])
    const [employees_arr, setemployees_arr] = useState([])

    const [sharecontact_data, setsharecontact_data] = useState({ department_id: '', employee_id: '', sender_id: '' })
    const [selected_contact, setselected_contact] = useState('')
    const [selected_sharedcontact, setselected_sharedcontact] = useState('')




    const [page, setpage] = useState(1)


    const [file, setfile] = useState()
    const [xl_valid, setxl_valid] = useState(false)

    const { Dragger } = Upload;
    const { Option } = Select;

    const [search_contact, setsearch_contact] = useState({ search_text: '', contact_status: '', from_date: '', to_date: '' })
    const [tempsearch_contact, settempsearch_contact] = useState({ search_text: '', contact_status: '', from_date: '', to_date: '' })

    const contact_status_filter = [{
        id: "Active",
        value: "Active"
    },
    {
        id: "In Active",
        value: "In Active"
    },
    {
        id: "Connected",
        value: "Connected"
    },
    {
        id: "Not Connected",
        value: "Not Connected"
    },
    {
        id: "Old Client",
        value: "Old Client"
    }]

    const navigate = useNavigate()

    function gotoaddContact() {
        navigate('/create_contact')
    }

    useEffect(() => {
        getContacts()
        getDepartements()
    }, [])

    function getDepartements() {
        axios.get('department/get', authHeader)
            .then((res) => {
                const departement_arr_created = []
                res.data.datas.filter(d => d?.department_name !== 'Database Team' && d?.department_name !== 'Media Team' && d?.department_name !== 'MD Office').forEach(d => {
                    let data = {}
                    data['label'] = d.department_name
                    data['value'] = d.department_name
                    data['id'] = d.id
                    departement_arr_created.push(data)
                })
                setdepartement_arr(departement_arr_created)
            })
    }

    function getusersbydepartmentId(v) {
        axios.get(`user/get-users-by-departmentId/${v}`, authHeader)
            .then((res) => {
                let temp_Arr = []
                res.data.datas.filter(e => (e.employee_id !== fcpl_id && e.isActive === true)).map((d) => {
                    let obj = {}
                    obj['label'] = d.name
                    obj['value'] = d.name
                    obj['id'] = d._id
                    let des = ''
                    d.user_roles.forEach((u) => {
                        des = des + u.roles[0].display_name + ','
                    })
                    obj['label'] = obj['label'] + `(${d.employee_id})` + `(${des})`
                    obj['value'] = obj['value'] + `(${d.employee_id})` + `(${des})`
                    temp_Arr.push(obj)
                })
                setemployees_arr(temp_Arr)
            }).catch((err) => {
            })
    }

    function contactdetailfunc(v) {
        navigate(`/contact_detail/${v}`)
    }
    function editContact(v) {
        navigate('/edit_contact/' + v)
    }
    function setvisiblefun() {
        setVisible(true)
    }
    function setvisiblefun1() {
        setVisible1(true)
    }
    function handleChange({ key, value }) {
        settempsearch_contact({ ...tempsearch_contact, [key]: value })
        if (tempsearch_contact.from_date && tempsearch_contact.to_date) {
            setsearch_contact({ ...search_contact, from_date: tempsearch_contact.from_date, to_date: tempsearch_contact.to_date })
        }
    }
    function contactdetailfunc(id) {
        navigate(`/contact_detail/${id}`)
    }
    function setselected_contact_func(v) {
        setselected_contact(v)
    }
    function getContacts() {
        setloader(true)
        axios.get(`contact/get/1`, authHeader).then((res) => {
            setloader(false)
            setpagination(res.data.pagination)
            setDatas(res.data.datas)
        }).catch((err) => {
            setloader(false)
        })
    }
    function getcontactsreferedbyme() {
        setloader(true)
        axios.get(`contact/referred-by-me/${page}`, authHeader)
            .then((res) => {
                setloader(false)
                setDatas(res.data.datas)
                setpagination(res.data.pagination)
            }).catch((err) => {
                setloader(false)
            })
    }
    function getcontactsreferedtome() {
        setloader(true)
        axios.get(`contact/referred-to-me/${page}`, authHeader)
            .then((res) => {
                setloader(false)
                setDatas(res.data.datas)
                setpagination(res.data.pagination)
            }).catch((err) => {
                setloader(false)
            })
    }
    async function setactivetab(v) {
        setpage(1)
        setcurrent_tab(v)
        if (v === '1') {
            getContacts()
        } else if (v === '2') {
            getcontactsreferedtome()
        }
    }
    async function setactivetab1(v) {
        setpage(1)
        if (v === '1') {
            getcontactsreferedtome()
        } else if (v === '2') {
            getcontactsreferedbyme()
        }
    }
    const handleUpload = (v) => {
        setfile(v.fileList[0].originFileObj)

        let xl_temp_format = [
            "Comapany Name",
            "Company Phone",
            "Company Email",
            "Website",
            "Industry",
            "Contact Name",
            "Phone",
            "Email",
            "Designation",
            "Secondary Contact Person",
            "Secondary Contact Phone",
            "Secondary Contact Designation",
            "Street",
            "City",
            "State",
            "Country",
            "Pin",
            "Description",
        ]

        readXlsxFile(v.fileList[0].originFileObj).then((rows) => {

            if (rows[0]?.sort()?.join(',') === xl_temp_format?.sort()?.join(',')) {
                toast.success('EXCEL format validated')
                setxl_valid(true)
            } else {
                toast.success('EXCEL format invalid')
                setxl_valid(false)

            }
            //  console.log('readXlsxFile(v.fileList[0].originFileObj)',rows)

        })

    }
    const reselectfile = () => {
        setfile()
    }
    function uploadxl() {
        if (file?.name?.split('.')[file?.name?.split('.').length - 1] === 'xlsx') {
            authHeader['headers']['Content-Type'] = 'multipart/form-data'
            const data = new FormData()
            data.append('file', file)
            axios.post(`contact/excel-import`, data, authHeader)
                .then((res) => {
                    if (res.status === 201) {
                        toast.success(res.data.msg)
                    } else if (res.status === 206) {
                        toast.success('Partially data uploaded')
                    }
                    // getAllContact()
                    setfile()
                    // setIsModalVisible(false)
                })
        } else {
            toast.error('Invalid file format unable to upload')
        }

    }
    function applyfilter() {
        if (search_contact.search_text || search_contact.contact_status || search_contact.from_date || search_contact.to_date) {
            setloader(true)
            axios.get(`contact/search?search_text=${search_contact.search_text}&contact_status=${search_contact.contact_status}&from_date=${search_contact.from_date}&to_date=${search_contact.to_date}&page=${page}`, authHeader)
                .then((res) => {
                    setsearch_contact({ ...search_contact, filter_status: true })
                    setpagination(res.data.pagination)
                    setDatas(res.data.datas)
                    setVisible(false)
                    setloader(false)
                    settempsearch_contact({ ...tempsearch_contact, search_text: '', contact_status: '', })
                })
        } else {
            setloader(false)
            toast.info('Select any filter option before applying ')
        }
    }
    function resetfilter() {
        setsearch_contact({ search_text: '', contact_status: '', from_date: '', to_date: '', filter_status: false })
        settempsearch_contact({ search_text: '', contact_status: '', from_date: '', to_date: '' })
        getContacts()
        setVisible(false)
    }
    function setshareContactVisiblefun() {
        setshareContactVisible(!shareContactVisible)
    }
    function handleChangefun(v, v1) {
        if (v1 === 'department_id') {
            getusersbydepartmentId(v)
            setsharecontact_data({ ...sharecontact_data, [v1]: v, employee_id: '' })
        } else {
            setsharecontact_data({ ...sharecontact_data, [v1]: v })
        }
    }
    function sharecontactfun() {
        const send_data = {
            contact_id: selected_contact ? selected_contact : selected_sharedcontact,
            department_id: sharecontact_data.department_id,
            referred_to: sharecontact_data.employee_id,
        }

        axios.post(`contact/refer`, send_data, authHeader)
            .then((res) => {
                if (res.data.status) {
                    toast.success('Contact shared successfully')
                    setsharecontact_data({ ...sharecontact_data, department_id: '', employee_id: '', contact_id: '' })
                    setshareContactVisible(false)
                    setselected_contact('')
                    setselected_sharedcontact('')
                }
            }).catch((err) => {
            })
    }
    function paginateddata(v) {
        setloader(true)
        axios.get(`contact/get/${v}`, authHeader)
            .then((res) => {
                setloader(false)
                setpage(v)
                setDatas(res.data.datas)
                setpagination(res.data.pagination)
            })
    }
    const paginateddata1 = (v) => {
        setloader(true)
        axios.get(`contact/referred-by-me/${v}`, authHeader)
            .then((res) => {
                setloader(false)
                setpage(v)
                setpagination(res.data.pagination)
                setDatas(res.data.datas)
            }).catch((err) => {
            })
    }
    const paginateddata2 = (v) => {
        setloader(true)
        axios.get(`contact/referred-to-me/${v}`, authHeader)
            .then((res) => {
                setloader(false)
                setpage(v)
                setpagination(res.data.pagination)
                setDatas(res.data.datas)
            }).catch((err) => {
            })
    }
    async function getReminder(id) {
        await axios.get(`reminder/get?type=${'Contact'}&type_id=${id}`, authHeader).then((res) => {
            setReminders(res.data.datas.reverse())
        })
    }
    function gotoleadcreatefunc(v) {
        navigate(`/create_lead`, { state: { datas: v } })
    }


    return (
        <div style={{ height: '100%', zIndex: 10 }}>
            {loader && <Loader />}
            <Toaster />

            <Modal visible={shareContactVisible} footer={false} onCancel={() => setshareContactVisiblefun()} style={{ maxWidth: '280px' }} centered>
                <div style={{ maxWidth: '200px', }}>
                    <h6 style={{ padding: '2%', fontWeight: '500', fontSize: '15px' }}>Share Contact</h6>

                    <h6 style={{ fontSize: '11px', width: '112%', wordBreak: 'break-word', padding: '8px', background: '#e6feff', border: '1px solid #0cd4eb', borderRadius: '4px' }}><strong>Note:</strong>Once you share contact you cant revert this shared contact back be sure that person your transferring is trust worthy</h6>
                    <Row style={{ width: '300px', margin: 'auto', display: 'flex' }}>
                        <Col span={24} style={{ background: 'white', padding: '0px 5px', width: '80%', maxWidth: '230px' }}>
                            <h6 style={{ fontSize: '12px' }}>Select Department</h6>
                            <Select
                                showSearch
                                placeholder="Select Department"
                                optionFilterProp="children"
                                value={sharecontact_data.department_id}
                                onChange={(v) => handleChangefun(v, 'department_id')}
                                size='medium'
                                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{ width: '100%' }}
                            >
                                {
                                    departement_arr.map((d) => (
                                        <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                                    ))
                                }
                            </Select>
                        </Col>

                        {sharecontact_data.department_id &&
                            <Col span={24} style={{ background: 'white', padding: '0px 5px', width: '80%', maxWidth: '230px', marginTop: '10px' }}>
                                <h6 style={{ fontSize: '12px' }}>Select Employee</h6>
                                <Select
                                    showSearch
                                    placeholder="Select Employee"
                                    optionFilterProp="children"
                                    value={sharecontact_data.employee_id}
                                    onChange={(v) => handleChangefun(v, 'employee_id')}
                                    size='medium'
                                    filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    style={{ width: '100%' }}
                                // options={employees_arr}
                                >
                                    {
                                        employees_arr.map((d) => (
                                            <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                                        ))

                                    }
                                </Select>
                            </Col>}

                        {(sharecontact_data.department_id && sharecontact_data.employee_id) &&
                            <div style={{ marginLeft: '5px' }} onClick={sharecontactfun}>
                                <PrimaryButton btn_name={' Share Contact'} />
                            </div>}
                    </Row>
                </div>
            </Modal>

            <Drawer placement={'bottom'} visible={visible} height={'300px'} width={'85%'} closable={false} maskClosable={false} bodyStyle={{ background: '#fff', padding: '5%' }}>
                <div style={{ backgroundColor: '#fff' }}>
                    <div style={{ zIndex: 100, background: '#fff', position: 'relative' }}>
                        <div style={{ alignItems: 'center', margin: '0px 0px', marginBottom: '20px' }}>
                            <h5 style={{ fontWeight: '800', textAlign: 'center' }}>Filter</h5>

                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', background: '#f0f0f0', borderRadius: 4, padding: 5, position: 'absolute', left: '0px', top: '0px' }} onClick={() => setVisible(false)}>
                            <IoClose size={12} color='black' />
                        </div>

                        <h6 style={{ position: 'absolute', right: '0px', top: '0px', fontWeight: '800', color: '#d9d9d9' }} onClick={resetfilter}>Reset</h6>
                    </div>


                    <div style={{ width: '100%', overflow: 'scroll' }}>
                        <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px' }}>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Add Search Query</h6>
                                </div>

                                {search_contact.search_text ?
                                    <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '12px', borderRadius: '2px', maxWidth: '100%', position: 'relative' }}>
                                        <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_contact.search_text.slice(0, 20)}{search_contact.search_text.length > 20 && '...'}</h6>
                                        <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px' }} onClick={() => setsearch_contact({ ...search_contact, search_text: '' })} />
                                    </div>
                                    :
                                    <div style={{ display: 'flex', width: '100%', border: '1px solid black', height: '40px', borderRadius: '4px', borderColor: '#f5f5f5', overflow: 'hidden', borderRightColor: '#fff', backgroundColor: '#fafafa' }}>
                                        <Input type={"text"} style={{ width: '100%', margin: 'auto', marginLeft: '0%', padding: '5px', border: 'none', background: '#fafafa', boxShadow: 'none' }} value={tempsearch_contact.search_text} onChange={e => settempsearch_contact({ ...tempsearch_contact, search_text: e.target.value })} />
                                        <div style={{ marginTop: '-10px' }}>
                                            <Button style={{ fontWeight: '800', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '28px 12px', background: `${main_bg}`, color: '#fff' }} onClick={() => setsearch_contact({ ...search_contact, search_text: tempsearch_contact.search_text })}>+</Button>
                                        </div>
                                    </div>}
                            </div>
                        </div>


                        <div style={{ margin: '10px 0px', paddingBottom: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Date Interval</h6>
                                {/* {step === '3' ? <HiChevronUp size={22}  color="#c7c7c7"  onClick={()=>setstep('')} /> : <HiChevronDown size={22} color="#c7c7c7"  onClick={()=>setstep('2')}/>} */}
                            </div>

                            <div>
                                <div>
                                    {tempsearch_contact.from_date && tempsearch_contact.to_date ?
                                        <div style={{ width: '100%', background: '#fafafa', padding: '2px', height: '40px', borderRadius: '5px', position: 'relative' }}>
                                            <p style={{ paddingBottom: '0px', marginTop: '8px', paddingLeft: '10px' }}>{tempsearch_contact.from_date} - {tempsearch_contact.to_date}</p>
                                            <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px', top: '12px' }} onClick={() => settempsearch_contact({ ...tempsearch_contact, from_date: '', to_date: '' })} />

                                        </div>
                                        :
                                        <Row style={{ width: '100%', margin: 'auto', display: 'flex' }}>
                                            <Col span={12} style={{ background: 'white', padding: '0px 5px', width: '100%' }}>
                                                <h6 style={{ fontSize: '12px', color: 'gray' }}>From Date</h6>
                                                {/* {tempsearch_contact.from_date ? <Tag style={{fontWeight:'600',fontSize:'13px',margin:'1.5%',padding:'4px 6px'}}>{tempsearch_contact.from_date}</Tag> : */}
                                                <DateField
                                                    onChangefun={handleChange}
                                                    name={'from_date'}
                                                    type="1"
                                                    size={'large'}
                                                    style={{ width: '100%', margin: 'auto', marginLeft: '0%', padding: '5px', border: 'none', background: '#fafafa', boxShadow: 'none' }}
                                                    date_value={tempsearch_contact.from_date}
                                                />
                                                {/* } */}
                                            </Col>

                                            <Col span={12} style={{ background: 'white', padding: '0px 5px' }}>
                                                <h6 style={{ fontSize: '12px', color: 'gray' }}>To Date</h6>
                                                {/* {tempsearch_contact.to_date ? <Tag style={{fontWeight:'600',fontSize:'13px',margin:'1.5%',padding:'4px 6px'}}>{tempsearch_contact.to_date}</Tag> : */}
                                                <DateField
                                                    name={'to_date'}
                                                    type="1"
                                                    size={'large'}
                                                    onChangefun={handleChange}
                                                    date_value={tempsearch_contact.to_date}
                                                    style={{ width: '100%' }} />
                                                {/* } */}
                                            </Col>

                                        </Row>}
                                </div>
                            </div>
                        </div>


                        <div style={{ margin: '10px 0px', paddingBottom: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Contact Status</h6>
                            </div>

                            <div>
                                <Row style={{ width: '100%', margin: 'auto' }}>
                                    {contact_status_filter?.map((s, i) => (
                                        <>
                                            <Tag onClick={() => setsearch_contact({ ...search_contact, contact_status: search_contact.contact_status === s.value ? '' : s.value })} color={search_contact.contact_status === s.value ? "#0e68bf" : "default"} style={{ fontWeight: '600', fontSize: '13px', margin: '1.5%', padding: '4px 6px' }}>{s.value}</Tag>
                                        </>
                                    ))}
                                </Row>
                            </div>
                        </div>



                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                            {/* <div style={{display:'flex',justifyContent:'center',marginTop:'10px',marginRight:'10px'}} onClick={resetfilter}>
                    <Button size='large' style={{background:'#f5f5f7',border:'none',borderRadius:'5px',color:'black',fontWeight:'500',display:'flex',justifyContent:'center',justifyItems:'center'}}>Reset Filter</Button>
                    </div> */}
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', width: '100%' }} onClick={applyfilter}>
                                <Button size='large' style={{ background: `${main_bg}`, border: 'none', borderRadius: '2px', color: 'white', fontWeight: '500', display: 'flex', justifyContent: 'center', justifyItems: 'center', width: '100%' }}>Apply Filter</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>

            <Drawer placement={'top'} visible={visible1} height={'250px'} width={'85%'} closable={false} maskClosable={false} bodyStyle={{ background: '#fff', margin: '5%', padding: '0px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', overflow: 'hidden' }}>
                <div style={{ display: 'flex', alignItems: 'center', background: '#f0f0f0', borderRadius: 4, padding: 5, position: 'absolute', left: '20px', top: '20px' }} onClick={() => setVisible1(false)}>
                    <IoClose size={12} color='black' />
                </div>
                <div style={{ alignItems: 'center', margin: '0px 0px' }}>
                    <h5 style={{ fontWeight: '800', textAlign: 'center' }}>Upload Files</h5>
                    <p style={{ textAlign: 'center' }}>We will be allowed only <Tag color="green">xlsx</Tag> format</p>
                </div>


                {file === undefined ?
                    <Dragger style={{ marginTop: '15px' }}
                        onChange={handleUpload}
                        beforeUpload={() => false}
                        showUploadList={false}>
                        <img src={xlsxixon} style={{ width: '40px', marginTop: '-150px', objectFit: 'contain' }} />
                        <p style={{ fontSize: '13px', marginTop: '-50px' }}>Click or drag file to this area to upload</p>
                        <p style={{ fontSize: '11px' }}>
                            Support for a single file upload. Strictly used from uploading company data
                        </p>
                    </Dragger>
                    :
                    <div>
                        <h6 style={{ fontWeight: '800' }}>File uploaded</h6>
                        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: '2px 10px', position: 'relative', marginTop: '20px' }}>
                            <img src={'https://fidecrms3bucket.s3.amazonaws.com/xlsx.png'} style={{ width: '30px', objectFit: 'contain' }} />
                            <div style={{ marginLeft: '10px' }}>
                                <h6 style={{ fontSize: '14px', fontWeight: '600', marginBottom: '2px' }}>{file.name}</h6>
                                <h6 style={{ fontSize: '10px', color: 'gray' }}>{file.size} bytes</h6>
                            </div>
                            <MdOutlineDeleteOutline size={22} style={{ position: 'absolute', right: '10px' }} onClick={reselectfile} />
                        </div>

                        {xl_valid &&
                            <div onClick={uploadxl}>
                                <PrimaryButton btn_name={'Upload Xl'} />
                            </div>}
                    </div>}




            </Drawer>

            <div style={{ backgroundColor: 'white' }}>
                <MobileTableHeader
                    btn_val="Add Contact"
                    type="Contact"
                    setvisiblefun={setvisiblefun}
                    setvisiblefun1={setvisiblefun1}
                    addfun={() => gotoaddContact()}
                    selected_contact={selected_contact}
                    setshareContactVisiblefun={setshareContactVisiblefun}
                />
            </div>

            <div style={{ width: '100%', height: '100%' }}>
                <SubViewContactTabs
                    current_tab={current_tab}
                    data={datas}
                    pagination={pagination}
                    page={page}
                    reminder={reminder}
                    getreminderfun={(v) => getReminder(v)}
                    func={(v) => contactdetailfunc(v)}
                    editContact={(v) => editContact(v)}
                    setactivetab={v => setactivetab(v)}
                    setactivetab1={v => setactivetab1(v)}
                    setvisiblefun={setvisiblefun}
                    paginateddata={(v) => paginateddata(v)}
                    paginateddata1={(v) => paginateddata1(v)}
                    paginateddata2={(v) => paginateddata2(v)}
                    selected_contact={selected_contact}
                    detailfun={(v) => contactdetailfunc(v)}
                    gotoleadcreatefunc={(v) => gotoleadcreatefunc(v)}
                    setselected_contact_func={v => setselected_contact_func(v)} />
            </div>
        </div>
    )
}

export default ViewContact
