import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import WebCreateLead from './web/CreateLead'
import MobileCreateLead from './mobile/CreateLead'

const index = () =>{
    if(isMobile){
        return(
            <div>
                <MobileCreateLead />
            </div>
        )
    }else{
        return(
            <div>
                <WebCreateLead />
            </div>
        )
    }
}

export default index