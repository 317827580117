import React from 'react'
import { DatePicker } from 'antd';
import Inputerror from '../errors/Inputerror';
import moment from 'moment'

function DateField({priority,name,onChangefun,error_obj,date_value,size,type,disableprev}) {

    function onChange(value,v1){
        onChangefun({key:name,value:v1})
    
    }

    function disabledDate(current){
        return current && current < moment().startOf('day');

    }

    return (
        <div>
            {(type === '' || type === null || type === undefined  ) ?
            <DatePicker placeholder='' size={size!== '' && size} value={date_value !== '' ? moment(date_value) : null}  disabledDate={disableprev !== 'true' ? disabledDate : ''} style={{width:'100%',borderLeft:priority === 'high' &&  '4px solid #292961',borderRadius:'5px'}} onChange={onChange} /> :
            <DatePicker placeholder='' size={size!== '' && size} value={date_value !== '' ? moment(date_value) : null}  style={{width:'100%',borderLeft:priority === 'high' &&  '4px solid #292961',borderRadius:'5px'}} onChange={onChange} /> }
            <Inputerror message={error_obj?.message} />
            {error_obj?.message === undefined  && <Inputerror message={error_obj} />}
        </div>
    )
}

export default DateField
