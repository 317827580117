import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import WebLeadDetail from './web/LeadDetail'
import MobileLeadDetail from './mobile/LeadDetail'

const index2 = () =>{
    if(isMobile){
        return(
            <div>
                <MobileLeadDetail />
            </div>
        )
    }else{
        return(
            <div>
                <WebLeadDetail />
            </div>
        )
    }
}

export default index2