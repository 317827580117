import React,{useState,useEffect} from 'react'
import axios from 'axios'
import { Validators } from '../../../components/Validators/Validators'
import InputField from '../../../components/inputcomponents/InputField'

import {Row,Col, message} from 'antd'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import {authHeader} from '../../../helpers/usertoken/UserToken'
import {Modal} from 'antd'
import {FiEdit} from 'react-icons/fi'
import {FcFullTrash} from 'react-icons/fc'
import {IoMenu} from 'react-icons/io5'
import RootNavbar from '../../../screens/mobileNavbar/RootNavbar';
import { main_bg } from '../../../components/constants/Constants1'
import toast, { Toaster } from 'react-hot-toast'
function AddContactSource() {

    const [data,setdata] = useState({source_name:'',source_id:'',edit:false})

    const [source_data,setsource_data] = useState([])
 
    const [visible,setvisible] = useState(false)
    const [visible1,setvisible1] = useState(false)
    const [visible2,setvisible2] = useState(false)
     
    useEffect(()=>{
        getContactSource()
    },[])


    const handleChange = ({key, value}) =>{ 
        setdata({...data, [key]: value})
    }

    function addContactSource(){

        const send_data = {}
        send_data['source_name'] = data.source_name
            if(!data.edit){
                axios.post(`source/create`,send_data,authHeader)
                .then((res)=>{
                    getContactSource()
                    toast.success('Added Successfully ')
                    setdata({...data,source_name:'',source_id:'',edit:false})
                    setvisible(false)
                })
                .catch((err)=>{
                    // console.log('err here',err.response)
                    if(err?.response?.status === 422){
                        toast.error(err?.response?.data.errors?.source_name)
                    }
                })
            }else if(data.edit){
                axios.put(`source/update/${data.source_id}`,send_data,authHeader)
            .then((res)=>{
                getContactSource()
                toast.success('Contact Source Updated Successfully ')
                setdata({...data,source_name:'',source_id:'',edit:false})
                setvisible(false)
            })
            .catch((err)=>{
                if(err?.response?.status === 422){
                    toast.error(err?.response?.data.errors?.source_name)
                }
            })
        }
        
    }

    function addContactSourcebtn(){
       setdata({...data,industry_name:'',industry_id:'',description:'',edit:false})
       setvisible(true)
    }

    function getContactSource(){
        axios.get(`source/get`,authHeader)
        .then((res)=>{
            setsource_data(res.data.datas)
        })
    }

    function seteditfun(d){
        setdata({...data,source_name:d.source_name,source_id:d.id,edit:true})
        setvisible(true)
    }

    function deleteindustry(d){
        setdata({...data,source_name:d.source_name,source_id:d.id,edit:true})
        setvisible1(true)
    }

    function deleteselected(){
        axios.delete(`source/delete/${data.source_id}`,authHeader)
        .then((res)=>{
            toast.success('Contact Source deleted Successfully')
            getContactSource()
            setvisible1(false)
        })
    }



  return (
        <div style={{maxHeight:'100vh'}}>
            <Toaster />
         <RootNavbar visible={visible2} func={()=>setvisible2(false)} /> 
           <div style={{display:'flex',alignItems:'center',width:'100%',background:`${main_bg}`,height:'50px',position:'sticky',top:'0px',left:'0px'}} onClick={()=>setvisible2(true)}>
            <IoMenu size={30} style={{marginRight:5,color:'white',padding:5,borderRadius:4}} />
            <h6 style={{fontSize:'15px',fontWeight:'600',marginBottom:'0px',color:'#fff'}}>Add Contact Source</h6>
           </div> 

            <Modal visible={visible} onCancel={()=>setvisible(false)} style={{maxWidth:'310px'}} centered footer={false}>
            <Row>
                    <Col span={24} > 
                        <h4 style={{fontSize:'18px',fontWeight:'700'}}>{data.edit ? 'Update' : 'Add' } Contact Source</h4>
                        <div style={{width:'100%'}}>
                            <p style={{marginBottom: '8px', fontSize: '14px'}}>Source Name</p>
                            <InputField
                                type="text"
                                size={'large'}
                                priority="high"
                                onChange={handleChange}
                                name={'source_name'}
                                value={data.source_name}
                                validators={[{
                                    check: Validators.required,
                                    message: 'This field is required'
                                }, {
                                    check: Validators.minimum_length_required,
                                    message: 'source_name Name should be atleast 3 characters'
                                }]}
                            />
                            </div>
                            
                            <div onClick={addContactSource} style={{marginTop:'5px'}}>
                            <PrimaryButton btn_name ={`${data.edit ? 'Update' : 'Add' } Contact Source`} />
                            </div>
                    </Col>



            </Row> 
            </Modal>

            <Modal visible={visible1} onCancel={()=>setvisible1(false)} style={{maxWidth:'300px'}}  centered footer={false}>
                <div style={{display:'grid',alignItems:'center',justifyContent:'center'}}>
                    <h6 style={{fontWeight:'bolder'}}>Confirmation message</h6>
                    <p style={{fontSize:'13px',textAlign:'center'}}>Do you want to delete a contact  with a name <strong>{data.source_name}</strong> and having a id <strong>{data.source_id}</strong>to be deleted</p>
                    <div style={{display:'flex',justifyContent:'center'}} onClick={deleteselected}>
                        <PrimaryButton btn_name={"I'm Sure"} />
                    </div>
                </div>
            </Modal>
         

         <div style={{width:'96%',margin:'20px auto'}}>
            
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'20px'}}>
            <h6 style={{fontWeight:'bold'}}>Contact Source Added ({source_data?.length})</h6> 
            <div onClick={addContactSourcebtn}>
                <PrimaryButton btn_name="Contact Source" />
            </div>
            </div>  

             {source_data.length > 0 ? 
            <div style={{ border:'0px solid lightgray',overflowY:'scroll'}}>
                <div className='mateial_table_header'>
                    <p style={{width: '10%',color:'black'}}> Id</p>
                    <p style={{width: '65%',marginRight:'2%'}}>Name</p>
                    {/* <p style={{width: '35%'}}>Created At</p> */}
                    <p style={{width: '20%'}}>Options</p>
                </div>

                {source_data.map((d,i) => (
                    <div key={d.id} className={'mateial_table_body'}>
                      
                        <p style={{width: '10%', color: "#0594f2",wordBreak:'break-word',fontWeight:'700'}}>{i+1}</p>
                        <p style={{width: '65%',wordBreak:'break-word',marginRight:'2%'}}>{d?.source_name}</p>
                        {/* <p style={{width: '35%',wordBreak:'break-word'}}>{moment(d?.createdAt).format('l')}</p> */}
                        <p style={{width: '20%',paddingLeft:'10px'}}>
                            <FiEdit size={17} style={{marginRight:'15px',marginLeft:'-5px',opacity:0.8}} onClick={()=>seteditfun(d)} /> 
                            <FcFullTrash size={18} style={{opacity:0.8}} onClick={()=>deleteindustry(d)}/>
                        </p>
  
                    </div>
                ))}



               
            </div>
            :
            <div style={{display:'grid',justifyContent:'center',alignItems:'center',marginTop:'100px'}}>
            <img src={"https://fidecrms3bucket.s3.amazonaws.com/nodatafound.svg"} alt="nodatafouund" style={{margin:'auto',width:'120px',objectFit:'contain'}} />
            <h4 style={{fontSize:'14px',fontWeight:'700',marginTop:'10px',textAlign:'center'}}>No Contact Source Found</h4>     
            <h6 style={{color:'#747474',fontSize:'13px',maxWidth:'80%',textAlign:'center',margin:'auto'}}>{"Click on a add contact source Button. Then, you can find the Contact Source added on this page."}</h6>
            </div> 
            }
         </div>
        </div>
  )
}

export default AddContactSource