import React,{useState,useEffect} from 'react'
import PrimaryButton,{SecondaryButton} from '../../../components/buttons/PrimaryButton'
import {BiEdit} from 'react-icons/bi'
import './DDR_Adding.css'
import {Checkbox} from 'antd'
import InputField from '../../../components/inputcomponents/InputField'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { Validators } from '../../../components/Validators/Validators'

function DesignationList({designation_arr,single_designation_sent,edit,editfunc,canceleditfun,fetch_all_designation}) {

    const [single_designation, setsingle_designation] = useState({designation_name:single_designation_sent?.designation_name,isActive:single_designation_sent?.isActive,id:single_designation_sent?.id})
    const [designation_name_err,setdesignation_name_err] = useState({})

    useEffect(() => {
       if(edit){
           setdesignation_name_err({})
           setsingle_designation({...single_designation,designation_name:single_designation_sent.designation_name,isActive:single_designation_sent.isActive,id:single_designation_sent.id})
       } else{
            setdesignation_name_err({})
            setsingle_designation({...single_designation,id:'',designation_name:'',isActive:''})
       } 
    }, [edit])



    function adddesignation(){
        if(!single_designation.designation_name){
            setdesignation_name_err({error:true,message:'This field is required'})
        }
        else{
            let send_data = {
                'designation_name':single_designation.designation_name,
            }
            axios.post('designation/create',send_data,authHeader)
            .then((res)=>{
                setsingle_designation({})
                fetch_all_designation()
            }).catch((err)=>{
                if(err.response.status === 422){
                    setdesignation_name_err({error:true,message:err.response.data.errors.designation_name})
                }
            })
            }
    }

    function updatedesignation(){
        let send_data = {
            designation_name:single_designation.designation_name,
            isActive:single_designation.isActive,
            id:single_designation.id
        }
        axios.put(`designation/update/${single_designation.id}`,send_data,authHeader)
        .then((res)=>{
            setsingle_designation({})
            fetch_all_designation()
            canceleditfun()
            // console.log('edit status',res.data)
        }).catch((err)=>{
            console.log('err obtained',err)
        })
    }

    function handleChange({key,value}){
        if(key === 'designation_name'){
            setdesignation_name_err({})
        }
        setsingle_designation({...single_designation,[key]:value})
    }

    return (
        <div style={{display:'flex',justifyContent:'space-between'}}> 
        <div style={{flex:0.4,flexShrink:3}}>
        <h6>{edit ? 'Edit' : 'Add' } Designation</h6>
        <InputField 
        placeholder={'Enter designation name'}
        value={single_designation.designation_name}
        priority={'high'}
        type="text"
        name="designation_name"
        nameerr="departement_name_err"
        error_obj={designation_name_err}
        validators={[{check:Validators.required,message:'This field is required'},{check:Validators.minimum_length_required,message:'Designation Name should be atleast 3 characters'}]}
        onChange={handleChange}
         />
        <Checkbox checked={single_designation.isActive === true}  onChange={()=>setsingle_designation({...single_designation,isActive:!single_designation.isActive})}  style={{ margin:'15px 0px'}}>isActive</Checkbox>
        <div style={{display:'flex'}}>
                <div style={{width:'150px'}} onClick={()=>{edit ? updatedesignation() : adddesignation()}}>
                <PrimaryButton  btn_name = {`${edit ? 'Edit' : 'Add' } Designation`}  />
                </div>
               {edit && <div  onClick={()=>canceleditfun()}>
                <SecondaryButton btn_name = 'cancel' />
                </div>}
            </div>
        </div>
    {/* <div style={{display:'flex',flexDirection:'row',justifyItems:'flex-end',alignSelf:'flex-end',justifyContent:'flex-end',width:'100%',}}>
         <PrimaryButton btn_name = "Add Departement"  />
    </div> */}
    <div style={{flex: 0.55}}>
    <h6>All Designation</h6>
    <p style={{color:'#57606a'}}>Showing You all the designation added till now.</p>
    <div className='table_ddr' style={{boxShadow: '0 1px 1px 0 var(--awsui-color-shadow-medium,rgba(0,28,36,.3)),1px 1px 1px 0 var(--awsui-color-shadow-side,rgba(0,28,36,.15)),-1px 1px 1px 0 var(--awsui-color-shadow-side,rgba(0,28,36,.15))'}}> 
    <div style={{display:'flex',width:'100%',background:'#fafafa',padding:'10px'}}>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'6%',borderRight:'1px solid #dbdbdb'}}>ID</p>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'60%',borderRight:'1px solid #dbdbdb'}}>Designation Name</p>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'26%',borderRight:'1px solid #dbdbdb'}}>Designation Status</p>
        <p></p>
    </div>
   {designation_arr?.map((d,i)=>(
    <div key={d.id} style={{display:'flex',width:'100%',background:'#fafafa',padding:'5px 10px',borderTop:'1px solid #dbdbdb',backgroundColor:'white'}}>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'6%'}}>{i+1}</p>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'60%'}}>{d.designation_name}</p>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'28%'}}>{d.isActive ? <span style={{backgroundColor:'#edffe0',padding:'4px',borderRadius:'4px',textAlign:'center'}}>Yes</span> : <span style={{backgroundColor:'#fae3d9',padding:'4px',borderRadius:'4px',textAlign:'center'}}>No</span>} </p>
        <BiEdit  size={20} color="#292961" style={{cursor:'pointer'}} onClick={()=>editfunc(d)}/>
    </div>
    ))}
    </div>
    </div>
    </div>
    )
}

export default DesignationList
