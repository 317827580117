import React, { useState, useEffect } from 'react'
import { Input } from 'antd';
import InputField from '../../../components/inputcomponents/InputField'
import DropDown from '../../../components/inputcomponents/DropDown';
import { Validators } from '../../../components/Validators/Validators'
import DateField from '../../../components/inputcomponents/DateField';
import { useSelector } from 'react-redux'
import axios from 'axios';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import toast,{ Toaster } from 'react-hot-toast';

function CreateTasks() {

    let { id } = useParams();
    let { pathname } = useLocation();
    const department = useSelector(state => state.Auth.department)


    let url_start_name = pathname.split('/')[1]

    const [data, setdata] = useState({ subject: '', status: '', related_to: '', due_date: '', priority: '', contact_name: '', contact_id: '', description: '', id: '', edit: false })
    const [dataerr, setdataerr] = useState({ subject_err: {}, status_err: {}, related_to_err: {}, due_date_err: {}, priority_err: {}, contact_name_err: {}, description_err: {} })


    const [contacts, setcontacts] = useState([])



    const navigate = useNavigate()

    const username = useSelector(state => state.Auth.name)
    const user_id = useSelector(state => state.Auth.id)






    useEffect(async () => {
        if (id) {
            axios.get(`task/get/${id}`, authHeader)
                .then((res) => {
                    let rd = res.data.datas[0]
                    if (rd?.related_to === 'Deal') {
                        setdata({
                            ...data,
                            id: rd.id,
                            subject: rd.subject,
                            status: rd.status,
                            due_date: rd.due_date,
                            description: rd.description,
                            related_to: rd.related_to,
                            priority: rd.priority,
                            contact_name: rd?.contact_name?.deal_name,
                            contact_id: rd?.contact_name?.id,
                            edit: true
                        })
                        getDeals()
                    } else if (rd?.related_to === 'Lead') {
                        setdata({
                            ...data,
                            id: rd.id,
                            subject: rd.subject,
                            status: rd.status,
                            due_date: rd.due_date,
                            description: rd.description,
                            related_to: rd.related_to,
                            priority: rd.priority,
                            // contact_name:rd?.contact_name?.id,
                            contact_name: rd?.contact_name?.lead_name,
                            contact_id: rd?.contact_name?.id,
                            edit: true
                        })
                        getLeads()
                    } else if (rd?.related_to === 'Contact') {
                        setdata({
                            ...data,
                            id: rd.id, subject: rd.subject,
                            status: rd.status,
                            due_date: rd.due_date,
                            description: rd.description,
                            related_to: rd.related_to,
                            priority: rd.priority,
                            contact_name: rd?.contact_name?.contact_name,
                            contact_id: rd?.contact_name?.id,
                            edit: true
                        })
                        getContacts()
                    }
                })
        } else {
            setdata(({ ...data, edit: false }))
        }
    }, [])

    async function getLeads() {
        await axios.get('lead/get', authHeader)
            .then((res) => {
                const temp_arr = []
                res.data.datas.forEach(e => {
                    const data = {}
                    data['id'] = e._id
                    data['value'] = e.lead_name
                    temp_arr.push(data)
                })
                setcontacts(temp_arr)
            })
    }

    async function getDeals() {
        await axios.get('deal/get', authHeader)
            .then((res) => {
                const temp_arr = []
                res.data.datas.forEach(e => {
                    const data = {}
                    data['id'] = e.id
                    data['value'] = e.deal_name
                    temp_arr.push(data)
                })
                setcontacts(temp_arr)
            })
    }

    async function getContacts() {
        await axios.get('contact/get', authHeader)
            .then((res) => {
                const temp_arr = []
                res.data.datas.forEach(e => {
                    const data = {}
                    data['id'] = e.id
                    data['value'] = e.contact_name
                    temp_arr.push(data)
                })

                setcontacts(temp_arr)
            })
    }

    const priority = [
        {
            value: 'High',
            id: 'High'
        },
        {
            value: 'Medium',
            id: 'Medium'
        },
        {
            value: 'Low',
            id: 'Low'
        },
    ]

    const related_to = [
        {
            value: 'Lead',
            id: 'Lead'
        },
        {
            value: 'Deal',
            id: 'Deal'
        },
        {
            value: 'Contact',
            id: 'Contact'
        },
    ]

    const status = [
        {
            value: 'Not Started',
            id: 'Not Started'
        },
        {
            value: 'Deferred',
            id: 'Deferred'
        },
        {
            value: 'In Progress',
            id: 'In Progress'
        },
        {
            value: 'Completed',
            id: 'Completed'
        },
        {
            value: 'Waiting For Input',
            id: 'Waiting For Input'
        },
    ]


    const status1 = [
        {
            value: 'Agreement',
            id: 'Agreement'
        },
        {
            value: 'Advance Collection',
            id: 'Advance Collection'
        },
        {
            value: 'Registration',
            id: 'Registration'
        },
        {
            value: 'calls/follow up',
            id: 'calls/follow up'
        },
        {
            value: 'In Progress',
            id: 'In Progress'
        },
        {
            value: 'Inspection',
            id: 'Inspection'
        },
        {
            value: 'Invoice Raised',
            id: 'Invoice Raised'
        },
        {
            value: 'LOI',
            id: 'LOI'
        },
        {
            value: 'Meeting/Discussion',
            id: 'Meeting/Discussion'
        },
        {
            value: 'Negotiation',
            id: 'Negotiation'
        },
        {
            value: 'Options sent/provided',
            id: 'Options sent/provided'
        },
        {
            value: 'Payment Pending follow up',
            id: 'Payment Pending follow up'
        },
        {
            value: 'Payment Collection',
            id: 'Payment Collection'
        },
        {
            value: 'RFQ',
            id: 'RFQ'
        },
        {
            value: 'Waiting for Input',
            id: 'Waiting for Input'
        },
    ]


    const { TextArea } = Input;

    function handleChange({ key, value }) {
        if (key === 'subject') {
            setdataerr({ ...dataerr, subject_err: {} })
        } else if (key === 'due_date' && value !== null) {
            setdataerr({ ...dataerr, due_date_err: {} })
        } else if (key === 'status') {
            setdataerr({ ...dataerr, status_err: {} })
        } else if (key === 'priority') {
            setdataerr({ ...dataerr, priority_err: {} })
        } else if (key === 'activity_owner') {
            setdataerr({ ...dataerr, activity_owner_err: {} })
        } else if (key === 'related_to') {
            setdataerr({ ...dataerr, related_to_err: {} })
        } else if (key === '') {
            setdataerr({ ...dataerr, contact_name_err: {} })
        }
        setdata({ ...data, [key]: value })

    }

    function addTask() {
        if (!data.subject) {
            setdataerr({ ...dataerr, subject_err: { error: true, message: 'This field is required' } })
        } else if (!data.due_date) {
            setdataerr({ ...dataerr, due_date_err: { error: true, message: 'This field is required' } })
        } else if (!data.status) {
            setdataerr({ ...dataerr, status_err: { error: true, message: 'This field is required' } })
        } else if (!data.priority) {
            setdataerr({ ...dataerr, priority_err: { error: true, message: 'This field is required' } })
        } else if (!data.related_to) {
            setdataerr({ ...dataerr, related_to_err: { error: true, message: 'This field is required' } })
        } else if (!data.contact_name) {
            setdataerr({ ...dataerr, contact_name_err: { error: true, message: 'This field is required' } })
        } else {
            const send_data = {
                'task_owner': user_id,
                'subject': data.subject,
                'due_date': data.due_date,
                'related_to': data.related_to,
                'contact_name': data.contact_id,
                'status': data.status,
                'priority': data.priority,
                'description': data.description
            }

            // console.log('send_data',send_data)
            if (url_start_name === 'create_tasks') {
                axios.post('task/create', send_data, authHeader)
                    .then((res) => {
                        resetform()
                        toast.success('Task created successfully')
                        navigate(-1)
                    }).catch((err) => {
                        // console.log('err',err)
                    })
            } else {
                axios.put(`task/update/${data.id}`, send_data, authHeader)
                    .then((res) => {
                        resetform()
                        toast.success('Task updated successfully')
                        navigate(-1)
                    }).catch((err) => {
                        // console.log('err',err)
                    })
            }

        }
    }

    function addTask1() {
        if (!data.subject) {
            setdataerr({ ...dataerr, subject_err: { error: true, message: 'This field is required' } })
        } else if (!data.due_date) {
            setdataerr({ ...dataerr, due_date_err: { error: true, message: 'This field is required' } })
        } else if (!data.status) {
            setdataerr({ ...dataerr, status_err: { error: true, message: 'This field is required' } })
        } else if (!data.priority) {
            setdataerr({ ...dataerr, priority_err: { error: true, message: 'This field is required' } })
        } else if (!data.related_to) {
            setdataerr({ ...dataerr, related_to_err: { error: true, message: 'This field is required' } })
        } else if (!data.contact_name) {
            setdataerr({ ...dataerr, contact_name_err: { error: true, message: 'This field is required' } })
        } else {
            const send_data = {
                'task_owner': user_id,
                'subject': data.subject,
                'due_date': data.due_date,
                'related_to': data.related_to,
                'contact_name': data.contact_name,
                'status': data.status,
                'priority': data.priority,
                'description': data.description
            }

            if (url_start_name === 'create_tasks') {
                axios.post('task/create', send_data, authHeader)
                    .then((res) => {
                        resetform()
                        toast.success('Task created successfully')
                    }).catch((err) => {
                    })
            } else {
                axios.put(`task/update/${data.id}`, send_data, authHeader)
                    .then((res) => {
                        resetform()
                        toast.success('Task updated successfully')
                        navigate(-1)
                    }).catch((err) => {
                    })
            }

        }
    }





    function resetform() {
        setdata({ ...data, subject: '', status: '', related_to: '', due_date: '', priority: '', contact_name: '', contact_id: '', description: '' })
        setdataerr({ ...dataerr, subject_err: {}, status_err: {}, related_to_err: {}, due_date_err: {}, priority_err: {}, contact_name_err: {}, description_err: {} })
    }


    function selectedvalue(v) {
        if (data.edit && v?.id !== data.related_to) {
            setdata({ ...data, contact_name: '', contact_id: '' })
        }
        if (v?.id && !data.edit) {
            setdataerr({ ...dataerr, [v.nameerr]: {} })
            setdata({ ...data, [v.name]: v?.id, [v.id_name]: v?.id })
            // setdata({...data,})
            if (v?.id === 'Deal') {
                getDeals()
            } else if (v?.id === 'Lead') {
                getLeads()
            } else if (v?.id === 'Contact') {
                getContacts()
            }
        } if (v?.id !== data.related_to && data.edit) {
            if (v?.id !== data.related_to && v?.name === 'related_to') {
                setdata({ ...data, contact_id: '', contact_name: '', [v.name]: v?.id, [v.id_name]: v?.id })
                if (v?.id === 'Deal') {
                    getDeals()
                } else if (v?.id === 'Lead') {
                    getLeads()
                } else if (v?.id === 'Contact') {
                    getContacts()
                }
            } else {
                setdataerr({ ...dataerr, [v.nameerr]: {} })
                setdata({ ...data, [v.name]: v?.id, [v.id_name]: v?.id })

                if (v?.id === 'Deal') {
                    getDeals()
                } else if (v?.id === 'Lead') {
                    getLeads()
                } else if (v?.id === 'Contact') {
                    getContacts()
                }
            }
        }
    }



    return (
        <div style={{ width: '90%', margin: 'auto', marginTop: '70px' }}>
            <Toaster />

            <div style={{ position: 'sticky', top: '40px', background: 'white', zIndex: 100 }}>
                <div className="mt-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h5 style={{ marginBottom: '6px' }}>{url_start_name === 'create_tasks' ? 'Create' : 'Edit'} Tasks</h5>
                        <p style={{ fontSize: '14px', fontWeight: '400' }}>Let's {url_start_name === 'create_tasks' ? 'create' : 'update'} a {url_start_name === 'create_tasks' ? 'new' : 'old'} task</p>
                    </div>


                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                        <div onClick={() => navigate(-1)}>
                            <SecondaryButton btn_name="Cancel" />
                        </div>
                        {url_start_name === 'create_tasks' &&
                            <div onClick={() => addTask1()}>
                                <SecondaryButton btn_name="Save and Continue" />
                            </div>}

                        <div onClick={() => addTask()}>
                            <PrimaryButton btn_name="Save" />
                        </div>
                    </div>
                </div>
            </div>


            <div className="row mt-3">
                <h6>Tasks Information</h6>
                <div className="col-md-6">
                    <div style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Subject</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'subject'}
                            error_obj={dataerr.subject_err}
                            value={data.subject}
                            validators={[{ check: Validators.required, message: 'This field is required' }, { check: Validators.minimum_length_required, message: 'Subject should be atleast 3 characters' }]}

                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Due Date</p>
                        <DateField
                            date_value={data.due_date}
                            priority={'high'}
                            name={'due_date'}
                            onChangefun={handleChange}
                            error_obj={dataerr.due_date_err}
                        />

                    </div>
                </div>
                <div className="col-md-6">
                    <div style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Status</p>
                        <DropDown
                            value={data.status}
                            onChange={(v) => selectedvalue(v)}
                            priority={'high'}
                            name='status'
                            id_name='status'
                            nameerr='status_err'
                            error_obj={dataerr.status_err}
                            options={department[0].department_name === 'Transaction Team' ? status1 : status}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Priority</p>
                        <DropDown
                            value={data.priority}
                            onChange={(v) => selectedvalue(v)}
                            priority={'high'}
                            name='priority'
                            id_name='priority'
                            nameerr='priority_err'
                            error_obj={dataerr.priority_err}
                            options={priority}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Related To </p>
                        <DropDown
                            value={data.related_to}
                            onChange={(v) => selectedvalue(v)}
                            priority={'high'}
                            name='related_to'
                            id_name='related_to'
                            nameerr='related_to_err'
                            error_obj={dataerr.related_to_err}
                            options={related_to}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px', textTransform: 'capitalize' }}><span style={{ textTransform: 'capitalize' }}>{data.related_to}</span> Name  </p>
                        <DropDown
                            value={data.contact_name}
                            onChange={(v) => selectedvalue(v)}
                            priority={'high'}
                            name='contact_name'
                            id_name='contact_id'
                            nameerr='contact_name_err'
                            error_obj={dataerr.contact_name_err}
                            options={contacts}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div style={{ margin: '10px 0px', width: '70%' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Task Owner</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'activity_owner'}
                            error_obj={dataerr.activity_owner_err}
                            value={username}
                            validators={[
                                {
                                    check: Validators.required, message: 'This field is required'
                                },
                                {
                                    // check:Validators.minimum_length_required,message:'Activity owner field is required'
                                    check: Validators.minimum_length_required, message: 'Task owner field is mandatory'
                                }
                            ]}

                        />
                    </div>
                </div>


            </div>

            <div className="row mt-3">
                <h6>Description Information</h6>
                <TextArea value={data.description} style={{ height: 20, width: '84%', marginLeft: '1%', marginTop: '20px' }} onChange={e => setdata({ ...data, description: e.target.value })} />
            </div>
        </div>
    )
}

export default CreateTasks
