import React, { useState } from 'react'
import { Input, Drawer, DatePicker } from 'antd';
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Validators } from '../Validators/Validators';
import PrimaryButton, { SecondaryButton } from '../buttons/PrimaryButton';
import InputField from '../inputcomponents/InputField';
import moment from 'moment'
import Inputerror from '../errors/Inputerror';
import { authHeader } from '../../helpers/usertoken/UserToken';
import { IoMdClose } from 'react-icons/io';
import toast,{ Toaster } from 'react-hot-toast';


function MeetingModalMobile({ visible, func, data_id, type }) {



    const user_id = useSelector(state => state.Auth.id)
    // const [modal, setmodal] = useState(visible)
    const { TextArea } = Input;

    const [data, setdata] = useState({ title: '', related_to: type, contact: '', contact_id: '', date_time: '', location: '', description: '', id: '', edit: false })
    const [dataerr, setdataerr] = useState({ titleerr: {}, related_to_err: '', contacterr: {}, date_time_err: {}, location_err: {}, description_err: {} })

    const [remindar_data, setremindar_data] = useState({ on_date: '', on_time: '', repeat_type: '', notify: '' })
    const [remindar_data_err, setremindar_data_err] = useState({ on_date_err: {}, on_time_err: {}, repeat_type_err: {}, notify_err: {} })



    function handleChange({ key, value }) {
        if (key === 'title') {
            setdataerr({ ...dataerr, titleerr: {} })
        } else if (key === 'from_date') {
            setdataerr({ ...dataerr, from_date_err: {} })
        } else if (key === 'to_date') {
            setdataerr({ ...dataerr, to_date_err: {} })
        } else if (key === 'location') {
            setdataerr({ ...dataerr, location_err: {} })
        } else if (key === 'on_date') {
            setremindar_data_err({ ...remindar_data_err, on_date_err: {} })
        } else if (key === 'on_time') {
            setremindar_data_err({ ...remindar_data_err, on_time_err: {} })
        } else if (key === 'repeat_type') {
            setremindar_data_err({ ...remindar_data_err, repeat_type_err: {} })
        } else if (key === 'notify') {
            setremindar_data_err({ ...remindar_data_err, notify_err: {} })
        }
        if (key !== 'on_date' && key !== 'on_time' && key !== 'repeat_type' && key !== 'notify') {
            setdata({ ...data, [key]: value })
        } else {
            setremindar_data({ ...remindar_data, [key]: value })
        }
    }

    function addMetting() {

        const send_data = {}
        send_data['title'] = data.title
        if (type === 'Contact') {
            send_data['related_to'] = 'Contact'
            send_data['contact_name'] = data_id?.id ? data_id?.id : data_id?._id
        } else if (type === 'Shared To Me') {
            send_data['related_to'] = 'Contact'
            send_data['contact_name'] = data_id?.contact_id?.id ? data_id?.contact_id?.id : data_id?.contact_id?._id
        } else if (type === 'My Leads') {
            send_data['related_to'] = 'Lead'
            send_data['contact_name'] = data_id?.id ? data_id?.id : data_id?._id
        } else if (type === 'Leads Assigned To Me') {
            send_data['related_to'] = 'Lead'
            send_data['contact_name'] = data_id?.assigned_lead_id?.lead_id?.id ? data_id?.assigned_lead_id?.lead_id?.id : data_id?.assigned_lead_id?.lead_id?._id
        } else if (type === 'Deal') {
            send_data['related_to'] = 'Deal'
            send_data['contact_name'] = data_id?.id ? data_id?.id : data_id?._id
        }
        send_data['date_time'] = data.date_time
        send_data['location'] = data.location
        send_data['description'] = data.description
        send_data['host'] = user_id
        axios.post('meeting/create', send_data, authHeader)
            .then((res) => {
                resetform()
                toast.success('Meeting created successfully')
            }).catch((err) => {
                setdataerr({ ...dataerr, date_time_err: { error: true, message: err?.response?.data?.errors?.date_time } })
            })

    }

    function resetform() {
        func()
        setdata({ ...data, title: '', related_to: '', contact: '', from_date: '', from_time: '', location: '', description: '' })
        setdataerr({ ...dataerr, titleerr: {}, related_to_err: '', contacterr: {}, from_date_err: {}, from_time_err: {}, location_err: {}, description_err: {} })
    }

   

    function disabledDate(current) {
        return current && current < moment().startOf('day');
    }

    function handledatechange(v, v1) {
        setdata({ ...data, date_time: v1 })
        setdataerr({ ...dataerr, date_time_err: { status: false, message: '' } })
    }

    function handleok(v, v1) {
        console.log('handleok', v, v1)
    }

    return (
        <Drawer placement="bottom" visible={visible} onClose={() => func()} height={'300px'} width={'85%'} closable={false} maskClosable={false} bodyStyle={{ background: '#fff', margin: '5%', padding: '0px', overflow: 'hidden' }}>
            <Toaster />
            <IoMdClose onClick={() => func()} size={22} style={{ position: 'absolute', right: '20px', top: '15px' }} />
            <h6 style={{ opacity: 1, fontWeight: '900', display: 'flex' }}>Add Meeting</h6>

            <div className='row'>
                <div className="col-6">
                    <div style={{ margin: '2px' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Title</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'title'}
                            error_obj={dataerr.titleerr}
                            value={data.title}
                            validators={[{ check: Validators.required, message: 'This field is required' }, { check: Validators.minimum_length_required, message: 'Title should be atleast 3 characters' }]}

                        />
                    </div>
                </div>

                <div className="col-6">
                    <div style={{ margin: '2px', width: '100%', alignItems: 'center' }}>
                        <p style={{ fontSize: '14px', marginRight: '10px', marginBottom: '5px' }}>Date</p>
                        <DatePicker
                            style={{ marginTop: '-4px', width: '90%', borderLeft: '4px solid #292961', borderRadius: '4px' }}
                            showTime
                            disabledDate={disabledDate}
                            onChange={handledatechange} onOk={handleok}
                            format="YYYY-MM-DD HH:mm"
                            value={data.date_time !== '' ? moment(data.date_time) : null}
                        />
                        <Inputerror message={dataerr.date_time_err.message} />
                    </div>
                </div>

                <div className="col-6">
                    <div style={{ margin: '2px' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Location</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'location'}
                            error_obj={dataerr.location_err}
                            value={data.location}
                            validators={[{ check: Validators.required, message: 'This field is required' }, { check: Validators.minimum_length_required, message: 'Location field should be required' }]}

                        />
                    </div>
                </div>

                <div className="col-6">
                    <div style={{ margin: '2px' }}>
                        <p style={{ marginBottom: '8px', fontSize: '14px' }}>Description</p>
                        <TextArea value={data.description} onChange={(e) => setdata({ ...data, description: e.target.value })} size="medium" style={{ width: '100%', outline: 'none', boxShadow: 'none' }} />
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <div onClick={resetform}>
                        <SecondaryButton btn_name="Cancel" />
                    </div>
                    <div onClick={addMetting}>
                        <PrimaryButton btn_name={data.edit ? "Update" : "Save"} />
                    </div>
                </div>

            </div>
        </Drawer>
    )
}

export default MeetingModalMobile