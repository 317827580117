import React,{useState,useEffect} from 'react'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
import {BiEdit} from 'react-icons/bi'
import './DDR_Adding.css'
import {Modal} from 'antd'
import InputField from '../../../components/inputcomponents/InputField'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import axios from 'axios'
import { Validators } from '../../../components/Validators/Validators'

function RolesList({roles_arr,single_role_sent,edit,editfunc,canceleditfun,fetch_all_roles}) {

    const [single_role, setsingle_role] = useState({role_name:single_role_sent?.role_name,display_name:single_role_sent?.display_name,id:single_role_sent?.id})
    const [role_err,setrole_err] = useState({})
    const [display_name_err,setdisplay_name_err] = useState({})
    const [modalVisible,setmodalVisible] = useState(false)

    useEffect(() => {
        if(edit){
            setrole_err({})
            setsingle_role({...single_role,role_name:single_role_sent.role_name,display_name:single_role_sent.display_name,id:single_role_sent.id})
        } else{
             setrole_err({})
             setsingle_role({...single_role,id:'',role_name:'',display_name:''})
        } 
     }, [edit])
 
     function addrole(){
         if(!single_role.role_name){
             setrole_err({error:true,message:'This field is required'})
         }
         else if(!single_role.display_name){
             setdisplay_name_err({error:true,message:'This field is required'}) 
         }
         else{
             let send_data = {
                 'role_name':single_role.role_name,
                 'display_name':single_role.display_name
             }
             axios.post('role/create',send_data,authHeader)
             .then((res)=>{
                 setsingle_role({})
                 fetch_all_roles()
                 setmodalVisible(false)
             }).catch((err)=>{
                 if(err.response.status === 422){
                     if(err.response.data.errors.role_name){
                         setrole_err({error:true,message:err.response.data.errors.role_name})
                     }else{
                         setdisplay_name_err({error:true,message:err.response.data.errors.display_name})
                     }
                 }
             })
             }
     }
 
     function updaterole(){
         let send_data = {
             role_name:single_role.role_name,
             display_name:single_role.display_name,
             id:single_role.id
         }
         axios.put(`role/update/${single_role.id}`,send_data,authHeader)
         .then((res)=>{
             setsingle_role({})
             fetch_all_roles()
             canceleditfun()
             setmodalVisible(false)
             // console.log('edit status',res.data)
         }).catch((err)=>{
             console.log('err obtained',err)
         })
     }
 
     function handleChange({key,value}){
         if(key === 'role_name'){
             setrole_err({})
         }else if(key === 'display_name'){
             setdisplay_name_err({})
         }
         setsingle_role({...single_role,[key]:value})
     }


    return (
        <div style={{display:'flex',justifyContent:'space-between'}}> 
         <Modal visible={modalVisible} 
        onCancel={()=>setmodalVisible(false)}
        footer={false}
        style={{width:'90%',margin:'30% auto',minHeight:'300px'}}
        
        >
       <div style={{height:'160px'}}>
        <h6>{edit ? 'Edit' : 'Add' } Roles</h6>
        <InputField 
            placeholder={'Enter your role name'}
            priority={'high'} 
            value={single_role.role_name}
            type="text"
            size='large'
            name="role_name"
            nameerr="role_err"
            error_obj={role_err}
            validators={[{check:Validators.required,message:'This field is required'},{check:Validators.minimum_length_required,message:'Role Name should be atleast 3 characters'}]}
            onChange={handleChange}
        />
        <div style={{marginTop:'10px'}}>
         <InputField 
            placeholder={'Enter your role display name'}
            priority={'high'} 
            size='large'
            value={single_role.display_name}
            type="text"
            name="display_name"
            nameerr="display_name_err"
            error_obj={display_name_err}
            validators={[{check:Validators.required,message:'This field is required'},{check:Validators.minimum_length_required,message:'Role Name should be atleast 3 characters'}]}
            onChange={handleChange}
        />
        </div>
        <div style={{display:'flex'}}>
                <div style={{width:'120px'}} onClick={()=>{edit ? updaterole() : addrole()}}>
                <PrimaryButton  btn_name = {`${edit ? 'Update' : 'Add' } Roles`}  />
                </div>
               {edit && <div style={{marginLeft:'-10px'}}  onClick={()=>setmodalVisible(false)}>
                <SecondaryButton btn_name = 'cancel' />
                </div>}
            </div>
        </div> 
        </Modal>
   
        <div style={{flex: 1}}>
            <div style={{display:'flex',width:'100%',alignItems:'flex-start',justifyContent:'space-between'}}>
                <div style={{flex:0.79}}>
                <h6>All Roles</h6>
                <p style={{color:'#57606a'}}>Showing You all the roles added till now.</p>
                </div>
                <div style={{flex:0.21}} onClick={()=>setmodalVisible(true)}>
                <PrimaryButton btn_name="Add Roles" />
                </div>
            </div>
        <div style={{border:'1px solid #dbdbdb'}} className='table_ddr'> 
        <div style={{display:'flex',width:'100%',background:'#fafafa',padding:'10px'}}>
            <p style={{marginBottom:'0px',padding:'0px 10px',width:'10%',borderRight:'1px solid #dbdbdb'}}>ID</p>
            <p style={{marginBottom:'0px',padding:'0px 10px',width:'50%',borderRight:'1px solid #dbdbdb'}}>Role Name</p>
            <p style={{marginBottom:'0px',padding:'0px 10px',width:'30%',borderRight:'1px solid #dbdbdb'}}>Display Name</p>
            <p></p>
        </div>
    {roles_arr?.map((d,i)=>(
        <div style={{display:'flex',width:'100%',background:'#fafafa',padding:'10px',borderTop:'1px solid #dbdbdb',backgroundColor:'white'}}>
            <p style={{marginBottom:'0px',padding:'0px 10px',width:'10%'}}>{i+1}</p>
            <p style={{marginBottom:'0px',padding:'0px 10px',width:'50%'}}>{d.role_name}</p>
            <p style={{marginBottom:'0px',padding:'0px 10px',width:'30%'}}><span style={{backgroundColor:'#edffe0',padding:'4px',borderRadius:'4px',textAlign:'center'}}>{d.display_name}</span> </p>
            <BiEdit  size={20} color="#292961" style={{cursor:'pointer'}} onClick={()=>{editfunc(d);setmodalVisible(true)}} />
        </div>
        ))}
        </div>
        </div>
    </div>
    )
}

export default RolesList
