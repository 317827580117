import React from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {useNavigate} from 'react-router-dom'

function BreadCrumbs() {

    const navigate = useNavigate()

    function handleClick() {
        navigate(path)
      }

      let path = window.location.pathname

    const breadcrumbs = [
        <Link style={{fontSize:'12px',color:'grey'}} underline="hover" key="1" color="inherit" href="/bd_dashboard" onClick={handleClick}>
          Dashboard
        </Link>,
        <Typography key={'2'} style={{fontSize:'12px',color:'black',fontWeight:'550',fontFamily:'Arial',cursor:'pointer'}}>
           <Link style={{fontSize:'12px',color:'grey'}} underline="hover"  color="inherit"  onClick={handleClick}>
           {path.slice(1,)}
          </Link>
        </Typography>,
      ];


    return (
        <div style={{marginTop:'50px'}}>
              <Breadcrumbs separator="›" aria-label="breadcrumb" style={{borderBottom:'1px solid lightgrey',padding:'10px 0px'}}>
               {breadcrumbs}
             </Breadcrumbs>
        </div>
    )
}

export default BreadCrumbs
