import React from 'react'
import KrishnaDashboard from '../dashboard/KrishnaDashboard'
import DeveloperMain from './DeveloperMain'


function DeveloperDashboard() {
  return (
    <DeveloperMain>
      <div style={{ marginLeft: '40px', marginTop: '40px' }}>
        <h3 style={{ fontWeight: 'bolder', fontSize: '15px' }}>Radha Krishna Dashboard</h3>
        <h6>What I Think Is What I Design</h6>
      </div>
    </DeveloperMain>
  )
}

export default DeveloperDashboard