import React, { useState, useEffect } from 'react'
import MobileTableHeader from '../../../components/bdcomponenttable/mobileComponents/MobileTableHeader'
import { Card, Drawer, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken';
import moment from 'moment'
import { FiEdit } from 'react-icons/fi';
import { AiOutlineCalendar } from 'react-icons/ai'
import { GrCompliance } from 'react-icons/gr'
import { IoClose } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { BsCalendar2Date } from 'react-icons/bs'


function ViewTasks() {


  const [data, setdata] = useState([])
  const [data1, setdata1] = useState([])
  const department = useSelector(state => state.Auth.department)



  const task_status_1 = ['Not Started', 'Deferred', 'Waiting For Input', 'In Progress', 'Completed']
  const task_status_2 = ['Agreement', 'Advance Collection', 'Registration', 'calls/follow up', 'In Progress', 'Inspection', 'Invoice Raised', 'LOI', 'Meeting/Discussion', 'Negotiation', 'Options sent/provided', 'Payment Pending follow up', 'Payment Collection', 'RFQ', 'Waiting for Input']


  const [singletask, setsingletask] = useState({})
  const [modalVisible, setmodalVisible] = useState(false)
  const [modalVisible1, setmodalVisible1] = useState(false)

  const [readmore, setreadmore] = useState(false)

  const user_id = useSelector(state => state.Auth.id)


  let date = new Date().toLocaleDateString().split('/').reverse()
  date = date.join('-')

  const navigate = useNavigate()

  const gototaskcreatefun = () => {
    navigate('/create_tasks')
  }


  useEffect(() => {
    gettask()
  }, [])

  function gettask() {
    axios.get(`task/get`, authHeader)
      .then((res) => {
        setdata(res.data.datas.filter(d => d.due_date.slice(0, 10) === date))
        setdata1({ ...data1, status: true, datas: res.data.datas.filter(d => d.due_date.slice(0, 10) === date) })
      })
  }

  function expandmodal(v) {
    setsingletask(v)
    setmodalVisible1(true)
  }

  function edittaskfunc(v) {
    navigate(`/edit_task/${v.id}`)
  }

  function updatestatusfun(v) {
    setsingletask(v)
    setmodalVisible(true)
  }

  function updatestatus(v) {
    const send_data = {
      'task_owner': user_id,
      'subject': singletask.subject,
      'due_date': singletask.due_date,
      'related_to': singletask.related_to,
      'contact_name': singletask?.contact_name?.id,
      'status': v,
      'priority': singletask.priority,
      'description': singletask.description
    }
    axios.put(`task/update/${singletask.id}`, send_data, authHeader)
      .then((res) => {
        gettask()
        setmodalVisible(false)
      })
  }



  return (
    <div style={{ marginTop: '0vh', background: 'white', maxWidth: '100vw', minWidth: '100%' }}>

      <Drawer placement={'bottom'} visible={modalVisible} height={'33vh'} width={'85%'} closable={false} maskClosable={false} bodyStyle={{ background: '#fff', margin: '5%', padding: '0px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', overflow: 'hidden' }}>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h6 style={{ width: '80px', height: '6px', borderRadius: '20px', opacity: 0.6, background: '#d9d9d9', display: 'flex', justifyContent: 'center', justifySelf: 'center', alignSelf: 'center' }}></h6>
        </div>
        <div style={{ alignItems: 'center', margin: '10px 0px', marginBottom: '20px' }}>
          <h5 style={{ fontWeight: '800', textAlign: 'center' }}>Update Task Status</h5>

        </div>
        <div style={{ display: 'flex', alignItems: 'center', background: '#f0f0f0', borderRadius: 4, padding: 5, position: 'absolute', left: '20px', top: '40px' }} onClick={() => setmodalVisible(false)}>
          <IoClose size={12} color='black' />
        </div>
        {(department[0].department_name !== 'Transaction Team' ? task_status_1 : task_status_2).map((t, i) => (
          <Tag size="large" onClick={() => updatestatus(t)} style={{ marginBottom: '10px', padding: '5px', backgroundColor: singletask.status === t ? '#3e4f61' : '#fff', color: singletask.status === t ? 'white' : 'black' }} >{t}</Tag>
        ))}
      </Drawer>


      <Drawer placement={'top'} visible={modalVisible1} height={'40vh'} width={'85%'} closable={false} maskClosable={false} bodyStyle={{ background: '#fff', margin: '5%', padding: '0px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', overflow: 'hidden' }}>

        <div style={{ alignItems: 'center', margin: '10px 0px', marginBottom: '20px' }}>
          <h5 style={{ fontWeight: '800', textAlign: 'center', fontSize: '16px' }}>Task Detail Info</h5>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', background: '#f0f0f0', borderRadius: 4, padding: 5, position: 'absolute', left: '20px', top: '30px' }} onClick={() => setmodalVisible1(false)}>
          <IoClose size={12} color='black' />
        </div>


        <h6 style={{ fontWeight: '700', marginBottom: '0px', width: '100%', }}>{singletask.subject}</h6>
        <p style={{ marginBottom: '10px' }}>{singletask?.task_owner?.name}</p>
        <p style={{ marginBottom: '0px', fontSize: '12px', fontWeight: '800' }}><BsCalendar2Date /> Due date: {moment(singletask?.due_date).format('LL')}</p>
        <h6 style={{ position: 'absolute', top: '20px', right: '10px', fontSize: '12px', fontWeight: '900' }}>Priority:<span style={{ background: singletask.priority === 'High' ? 'red' : singletask.priority === 'Low' ? '#0fa336' : '#ffa230', padding: '4px', fontSize: '10px', borderRadius: '2px', fontWeight: 'bold', marginLeft: '5px', color: 'white' }}>{singletask.priority}</span></h6>
        {singletask.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', display: 'block', color: 'black', left: '0px', margin: '5px 0px', position: 'relative', paddingLeft: '4px' }}> LEAD NAME : <strong>{singletask?.contact_name?.lead_name}</strong></p>)}
        {singletask.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', display: 'block', color: 'black', left: '0px', margin: '5px 0px', position: 'relative', paddingLeft: '4px' }}> CONTACT NAME : <strong>{singletask?.contact_name?.contact_name}</strong></p>)}
        {singletask.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', display: 'block', color: 'black', left: '0px', margin: '5px 0px', position: 'relative', paddingLeft: '4px' }}> DEAL NAME : <strong>{singletask?.contact_name?.deal_name}</strong></p>}
        <p style={{ fontWeight: '800', fontSize: '13px', marginTop: '10px', marginBottom: '0px' }}>Description</p>
        {!readmore ? <p style={{ color: 'black', fontSize: '12px' }}>{singletask?.description?.slice(0, 100)}{singletask?.description?.length > 100 && <p style={{ color: '#2789f2', fontWeight: '800' }} onClick={() => setreadmore(true)}> ... Read More</p>} </p> :
          <p style={{ color: 'black', maxHeight: '100px', overflow: 'scroll', fontSize: '12px' }}>{singletask?.description} <p style={{ color: '#2789f2', fontWeight: '800' }} onClick={() => setreadmore(false)}> ... Read Less</p> </p>}
      </Drawer>

      <div style={{ maxHeight: '5vh', position: 'fixed', top: '0vh', backgroundColor: 'white' }}>
        <MobileTableHeader btn_val="Add Deal" type="Task" addfun={() => gototaskcreatefun()} />
      </div>


      {department[0].department_name !== 'Transaction Team' ?
        <div style={{ marginTop: '6vh', width: '94%', margin: 'auto', paddingTop: '20px' }}>

          <h6 style={{ fontSize: '13px', color: '#677078', paddingTop: '50px' }}> <strong style={{ color: 'black' }}>({data?.length})</strong> Tasks found</h6>

          <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', marginTop: '20px', minHeight: '70vh' }}>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Not Started</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'Not Started').length}</span>) Task Found</h6>
              </div>

              {data?.filter(d => d.status === 'Not Started').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}

            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Deferred</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'Deferred').length}</span>) Task Found</h6>
              </div>

              {data?.filter(d => d.status === 'Deferred').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}
            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Waiting For Input</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'Waiting For Input').length}</span>) Task Found</h6>
              </div>

              {data?.filter(d => d.status === 'Waiting For Input').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}
            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>In Progress</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'In Progress').length}</span>) Task Found</h6>
              </div>

              {data?.filter(d => d.status === 'In Progress').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}

            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Completed</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'Completed').length}</span>) Task Found</h6>
              </div>
              {data?.filter(d => d.status === 'Completed').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}
            </div>
          </div>
        </div>
        :
        <div style={{ marginTop: '6vh', width: '94%', margin: 'auto', paddingTop: '20px' }}>

          <h6 style={{ fontSize: '13px', color: '#677078', paddingTop: '50px' }}> <strong style={{ color: 'black' }}>({data?.length})</strong> Tasks found</h6>

          <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', marginTop: '20px', minHeight: '70vh' }}>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Agreement</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'Agreement').length}</span>) Task Found</h6>
              </div>

              {data?.filter(d => d.status === 'Agreement').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}

            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Advance Collection</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'Advance Collection').length}</span>) Task Found</h6>
              </div>

              {data?.filter(d => d.status === 'Advance Collection').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}
            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Registration</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'Registration').length}</span>) Task Found</h6>
              </div>

              {data?.filter(d => d.status === 'Registration').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}
            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Calls/Follow up</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'calls/follow up').length}</span>) Task Found</h6>
              </div>

              {data?.filter(d => d.status === 'calls/follow up').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}

            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>In Progress</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'In Progress').length}</span>) Task Found</h6>
              </div>
              {data?.filter(d => d.status === 'In Progress').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}
            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Inspection</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'Inspection').length}</span>) Task Found</h6>
              </div>
              {data?.filter(d => d.status === 'Inspection').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}
            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', borderRight: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Invoice Raised</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'Invoice Raised').length}</span>) Task Found</h6>
              </div>
              {data?.filter(d => d.status === 'Invoice Raised').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}
            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', borderRight: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>LOI</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'LOI').length}</span>) Task Found</h6>
              </div>
              {data?.filter(d => d.status === 'LOI').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}
            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', borderRight: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Meeting/Discussion</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'Meeting/Discussion').length}</span>) Task Found</h6>
              </div>
              {data?.filter(d => d.status === 'Meeting/Discussion').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}
            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', borderRight: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Negotiation</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'Negotiation').length}</span>) Task Found</h6>
              </div>
              {data?.filter(d => d.status === 'Negotiation').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}
            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', borderRight: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Options sent/provided</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'Options sent/provided').length}</span>) Task Found</h6>
              </div>
              {data?.filter(d => d.status === 'Options sent/provided').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}
            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', borderRight: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Payment Pending follow up</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'Payment Pending follow up').length}</span>) Task Found</h6>
              </div>
              {data?.filter(d => d.status === 'Payment Pending follow up').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}
            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', borderRight: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Payment Collection</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'Payment Collection').length}</span>) Task Found</h6>
              </div>
              {data?.filter(d => d.status === 'Payment Collection').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}
            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', borderRight: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>RFQ</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'RFQ').length}</span>) Task Found</h6>
              </div>
              {data?.filter(d => d.status === 'RFQ').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}
            </div>
            <div style={{ minWidth: '180px', maxWidth: '180px', borderLeft: '1.5px solid #e8e8e8', borderRight: '1.5px solid #e8e8e8', padding: '5px' }}>
              <div style={{ borderBottom: '1.5px solid #e8e8e8' }}>
                <h6 style={{ fontSize: '14px', fontWeight: '900' }}>Waiting for Input</h6>
                <h6 style={{ fontSize: '12px', fontWeight: '500', color: '#0073ba' }}>(<span style={{ color: 'black' }}>{data?.filter(d => d.status === 'Waiting for Input').length}</span>) Task Found</h6>
              </div>
              {data?.filter(d => d.status === 'Waiting for Input').map((t, i) => (
                <Card key={i} style={{ width: '99%', margin: '0.5%', borderRadius: '0px', border: 'none', cursor: 'pointer', position: 'relative', background: '#fff', borderBottom: '1px solid #ededed' }}>

                  {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                  {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '15px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                  <h6 style={{ fontWeight: '900', fontSize: '13px', marginBottom: '0px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                  <p style={{ marginBottom: '5px' }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', padding: '0px', borderRadius: '5px' }}>
                      <AiOutlineCalendar size={16} color="#616161" />
                      <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('LL')}</span>
                    </div>

                    <div style={{ display: 'flex', width: '23%', justifyContent: 'space-between', position: 'absolute', right: '10px', top: '10px' }}>
                      <FiEdit color="#616161" size={16} style={{ marginRight: '10px' }} onClick={() => edittaskfunc(t)} />
                      <GrCompliance color="#616161" size={16} style={{ opacity: '0.8' }} onClick={() => updatestatusfun(t)} />
                    </div>
                  </div>

                </Card>
              ))}
            </div>
          </div>
        </div>}
    </div>
  )
}

export default ViewTasks
