import React,{useState,useEffect} from 'react'
import axios from 'axios'
import { Validators } from '../../../components/Validators/Validators'
import InputField from '../../../components/inputcomponents/InputField'

import {Row,Col, message} from 'antd'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import {authHeader} from '../../../helpers/usertoken/UserToken'
import {Modal} from 'antd'
import moment from 'moment'
import {FiEdit} from 'react-icons/fi'
import {FcFullTrash} from 'react-icons/fc'
import { main_bg } from '../../../components/constants/Constants1'
import RootNavbar from '../../../screens/mobileNavbar/RootNavbar';
import {IoMenu} from 'react-icons/io5'
import toast,{Toaster} from 'react-hot-toast';

function AddIndustry() {

    const [data,setdata] = useState({industry_name:'',industry_id:'',description:'',edit:false})

    const [industry_data,setindustry_data] = useState([])
 
    const [visible,setvisible] = useState(false)
    const [visible1,setvisible1] = useState(false)
    const [visible2,setvisible2] = useState(false)

     
    useEffect(()=>{
        getIndustry()
    },[])
    const handleChange = ({key, value}) =>{ 
        setdata({...data, [key]: value})
    }

    function addIndustry(){

        const send_data = {}
        send_data['industry_name'] = data.industry_name
        send_data['description'] = data.description
            if(!data.edit){
                axios.post(`industry/create`,send_data,authHeader)
                .then((res)=>{
                    getIndustry()
                    toast.success('Industry Created Successfully ')
                    setdata({...data,industry_name:'',industry_id:'',description:'',edit:false})
                    setvisible(false)
                })
                .catch((err)=>{
                    if(err?.response?.status === 422){
                    toast.error(err?.response?.data.errors?.industry_name)
                    }
                })
            }else if(data.edit){
                axios.put(`industry/update/${data.industry_id}`,send_data,authHeader)
            .then((res)=>{
                getIndustry()
                toast.success('Industry Updated Successfully ')
                setdata({...data,industry_name:'',industry_id:'',description:'',edit:false})
                setvisible(false)
            })
            .catch((err)=>{
                if(err?.response?.status === 422){
                toast.error(err?.response?.data.errors?.industry_name)
                }
            })
        }
        
    }

    function addIndustryFun(){
       setdata({...data,industry_name:'',industry_id:'',description:'',edit:false})
       setvisible(true)
    }

    function getIndustry(){
        axios.get(`industry/get`,authHeader)
        .then((res)=>{
            setindustry_data(res.data.datas)
        })
    }

    function seteditfun(d){
        setdata({...data,industry_name:d.industry_name,description:d.description,industry_id:d.id,edit:true})
        setvisible(true)
    }

    function deleteindustry(d){
        setdata({...data,industry_name:d.industry_name,description:d.description,industry_id:d.id,edit:true})
        setvisible1(true)
    }

    function deleteselected(){
        axios.delete(`industry/delete/${data.industry_id}`,authHeader)
        .then((res)=>{
            toast.success('Indusry deleted Successfully')
            getIndustry()
            setvisible1(false)
        })
    }



  return (
     <div style={{maxHeight:'100vh'}}>
        <Toaster />
        <RootNavbar visible={visible2} func={()=>setvisible2(false)} /> 
        <div style={{display:'flex',alignItems:'center',width:'100%',background:`${main_bg}`,height:'50px',position:'sticky',top:'0px',left:'0px'}} onClick={()=>setvisible2(true)}>
        <IoMenu size={30} style={{marginRight:5,color:'white',padding:5,borderRadius:4}} />
        <h6 style={{fontSize:'15px',fontWeight:'600',marginBottom:'0px',color:'#fff'}}>Add Industry </h6>
        </div> 
        <Modal visible={visible} onCancel={()=>setvisible(false)} style={{maxWidth:'310px'}}  centered footer={false}>
        <Row>
                  <Col span={24} > 
                     <h4 style={{fontSize:'18px',fontWeight:'700'}}>Add Industry</h4>
                     <div style={{width:'95%'}}>
                         <p style={{marginBottom: '8px', fontSize: '14px'}}>Industry Name</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'industry_name'}
                            value={data.industry_name}
                            validators={[{
                                check: Validators.required,
                                message: 'This field is required'
                            }, {
                                check: Validators.minimum_length_required,
                                message: 'Industry Name should be atleast 3 characters'
                            }]}
                        />
                        </div>

                        <div style={{width:'95%'}}>
                        <p style={{marginBottom: '8px', fontSize: '14px'}}>Industry Description</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'description'}
                            value={data.description}
                            validators={[{
                                check: Validators.required,
                                message: 'This field is required'
                            }, {
                                check: Validators.minimum_length_required,
                                message: 'Description field should be required'
                            }]}
                        />
                        </div>
                        
                        <div onClick={addIndustry}>
                        <PrimaryButton btn_name ="Add Industry" />
                        </div>
                 </Col>



        </Row> 
        </Modal>

        <Modal visible={visible1} onCancel={()=>setvisible1(false)} style={{maxWidth:'300px'}}  centered footer={false}>
              <div style={{display:'grid',alignItems:'center',justifyContent:'center'}}>
                  <h6 style={{fontWeight:'bolder'}}>Confirmation message</h6>
                  <p style={{fontSize:'13px',textAlign:'center'}}>Do you want to delete a industry witha name <strong>{data.industry_name}</strong> and having a id <strong>{data.industry_id}</strong>to be deleted</p>
                  <div style={{display:'flex',justifyContent:'center'}} onClick={deleteselected}>
                      <PrimaryButton btn_name={"I'm Sure"} />
                  </div>
              </div>
        </Modal>
         
        <div style={{width:'96%',margin:'20px auto',maxHeight:'80vh'}}>
            
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'20px'}}>
            <h6 style={{fontWeight:'bold'}}>Industry Added ({industry_data?.length})</h6> 
            <div onClick={addIndustryFun}>
                <PrimaryButton btn_name="Add Industry" />
            </div>
            </div>

            <div>
             {industry_data.length > 0 ? 
            <div style={{ border:'0px solid lightgray',overflowY:'scroll'}}>
                <div className='mateial_table_header'>
                    <p style={{width: '50px',color:'black'}}> Id</p>
                    <p style={{width: '180px',marginRight:'2%'}}>Name</p>
                    <p style={{width: '180px',marginRight:'2%'}}>Description</p>
                    <p style={{width: '100px'}}>Options</p>
                </div>

                {industry_data.map((d,i) => (
                    <div key={d.id} className={'mateial_table_body'}>
                      
                        <p style={{width: '50px', color: "#0594f2",wordBreak:'break-word',fontWeight:'700'}}>{i+1}</p>
                        <p style={{width: '180px',wordBreak:'break-word',marginRight:'2%'}}>{d?.industry_name}</p>
                        <p style={{width: '180px',wordBreak:'break-word',marginRight:'2%'}}>{d?.description}</p>
                        <p style={{width: '100px',paddingLeft:'10px'}}>
                            <FiEdit size={17} style={{marginRight:'15px',opacity:0.9}} onClick={()=>seteditfun(d)} /> 
                            <FcFullTrash size={18} onClick={()=>deleteindustry(d)}/>
                        </p>
  
                    </div>
                ))}



               
            </div>
            :
            <div style={{display:'grid',justifyContent:'center',alignItems:'center',marginTop:'100px'}}>
            <img src={"https://fidecrms3bucket.s3.amazonaws.com/nodatafound.svg"} alt="nodatafouund" style={{margin:'auto',width:'200px',objectFit:'contain'}} />
            <h4 style={{fontSize:'18px',fontWeight:'700',marginTop:'10px',textAlign:'center'}}>No Industry Found</h4>     
            <h6 style={{color:'#747474',fontSize:'13px',maxWidth:'80%',textAlign:'center',margin:'auto'}}>{"Click on a add industry Button. Then, you can find the Indusry on this page."}</h6>
            </div> 
            }
         </div>
        </div> 
        </div>
  )
}

export default AddIndustry