
let name_reg_string = '^[a-zA-Z0-9_.,/&@ ]+$'
let email_reg_string = '^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
let mobile_reg_string = '^[0-9]+$'


const name_reg_validation = (v) => {
    if (!new RegExp('^[a-zA-Z0-9_.,/&@ ]+$').test(v)) {
        return false
    } else {
        return true
    }
}

const email_reg_validation = (v) => {
    if (!new RegExp(
        '^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
    ).test(v)) {
        return false
    } else {
        return true
    }
}

const mobile_reg_validation = (v) => {
    if (!new RegExp('^[0-9]+$').test(v)) {
        return false
    } else {
        return true
    }
}

export { name_reg_string, email_reg_string, mobile_reg_string, name_reg_validation, email_reg_validation, mobile_reg_validation }