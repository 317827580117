import React, { useEffect, useState } from 'react'
import { Card, Input, Tooltip, message, Modal, Button } from 'antd'
import { Upload, Drawer } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { GrDocumentNotes } from 'react-icons/gr'
import { IoSendSharp } from 'react-icons/io5'
import { FiEdit } from 'react-icons/fi'
import { useNavigate } from "react-router-dom";
import Reminder from './Reminder';
import axios from "axios";
import { authHeader } from "../../helpers/usertoken/UserToken";
import { GiAlarmClock } from 'react-icons/gi'
import toast, { Toaster } from "react-hot-toast";
import fileDownload from "js-file-download";
import { CloudDownloadOutlined, DeleteOutlined } from "@mui/icons-material";
import moment from "moment";
import { base_url } from '../../App';
import { MdTimeline } from 'react-icons/md';



import { Timeline } from '@mui/lab';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';


import { IoCreateOutline } from 'react-icons/io5'
import { AiOutlineClockCircle, AiOutlineEdit } from 'react-icons/ai'
import MessageBox from '../messagebox/MessageBox';


function ContactComponent({ section, id, values, labels, type, getReminderOut }) {

    const navigate = useNavigate()
    const [visible, setvisible] = useState(false)
    const [visible1, setvisible1] = useState(false)
    const [data, setData] = useState({ 'note_title': '', 'summary': '' })
    const [contactNote, setContactNote] = useState([])
    const { Dragger } = Upload;
    const [attachement, setAttachements] = useState([])
    const [deleteAttachment, setdeleteAttachment] = useState(false)
    const [selectedAttachment, setselectedAttachment] = useState()
    const [note_id, setnote_id] = useState('')

    const [reminder, setReminders] = useState([])

    const [contact_tracker, setcontact_tracker] = useState([])
    const [task_tracker, settask_tracker] = useState([])
    const [meeting_tracker, setmeeting_tracker] = useState([])




    useEffect(() => {
        tracker()
    }, [])

    function tracker() {
        axios.get(`activity/get-timeline/Contact/${id}`, authHeader)
            .then((res) => {
                setcontact_tracker(res.data.datas)
                let tracker_arrange = []
                let tracker_arrange1 = []
                res.data.datas.sub.filter(t => t.type === 'Task' && t.history === '<p>Task created</p>' && t.event === 'create').forEach(e1 => {
                    tracker_arrange.push(e1)
                    if (res.data.datas.sub.filter(t1 => t1.type === 'Task' && t1.event === 'update' && t1.type_id === e1.type_id).length > 0) {
                        res.data.datas.sub.filter(t1 => t1.type === 'Task' && t1.event === 'update' && t1.type_id === e1.type_id).forEach(e => {
                            tracker_arrange.push(e)
                        })

                    }
                })
                settask_tracker(tracker_arrange)

                res.data.datas.sub.filter(t => t.type === "Meeting" && t.history === '<p>Meeting created</p>' && t.event === 'create').forEach(e1 => {
                    tracker_arrange1.push(e1)
                    if (res.data.datas.sub.filter(t1 => t1.type === 'Meeting' && t1.event === 'update' && t1.type_id === e1.type_id).length > 0) {
                        res.data.datas.sub.filter(t1 => t1.type === 'Meeting' && t1.event === 'update' && t1.type_id === e1.type_id).forEach(e => {
                            tracker_arrange1.push(e)
                        })
                    }
                })
                setmeeting_tracker(tracker_arrange1)
            }).catch((err) => {
            })
    }

    function editContact() {
        navigate('/edit_contact/' + id)
    }

    function resetNote() {
        setData({
            'note_title': '',
            'summary': ''
        })
    }

    const handleUpload = (v) => {
        authHeader['Content-Type'] = 'multiplart/form-data'
        let fd = new FormData()
        fd.append('attachment_type', type)
        fd.append('type_id', id)
        fd.append('file', v.fileList[v.fileList.length - 1].originFileObj)


        axios.post('activity/create-attachment', fd, authHeader).then((res) => {
            getAttachement()
        })
    }

    const addNotes = (v) => {
        authHeader['Content-Type'] = 'multiplart/form-data'
        let fd = new FormData()
        fd.append('note_type', type)
        fd.append('type_id', id)
        fd.append('note_title', data.note_title)
        fd.append('summary', data.summary)
        axios.post('activity/create-note', fd, authHeader).then((res) => {
            getNotes()
            message.success('Note added successfully')
            resetNote()
        }).catch((err) => {
            // console.log('err',err.response)
            message.error('Please add both heading and description')
        })
    }

    useEffect(() => {
        getAttachement()
        getNotes()
    }, [])

    const getAttachement = () => {
        axios.get(`activity/get-attachement?type=${type}&type_id=${id}`, authHeader).then((res) => {
            setAttachements(res.data.datas)
        })
    }

    const getNotes = () => {
        axios.get(`activity/get-note?type=${type}&type_id=${id}`, authHeader).then((res) => {
            setContactNote(res.data.datas.reverse())
        })
    }

    const deleteAttachmenfun = (v) => {
        // if (window.confirm('Are you sure?')) {
        axios.delete(`activity/delete-attachment/${selectedAttachment}`, authHeader).then((res) => {
            getAttachement()
            toast.success('Attachment deleted successfully')
            setdeleteAttachment(false)
        }).catch((err) => {
            toast.error('Opps something went wrong!')
        })
        // }
    }

    const handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        }).then((res) => {
            toast.success(`${filename} downloaded successfully`)
            fileDownload(res.data, filename)
        })
    }

    function showreminder() {
        setvisible(true)
        getReminder(id)
    }

    async function getReminder(id) {
        await axios.get(`reminder/get?type=${'Contact'}&type_id=${id}`, authHeader).then((res) => {
            setReminders(res.data.datas.reverse())
        })
    }

    return (
        <div>
            <Drawer title="Basic Drawer" placement="right" onClose={() => setvisible1(false)} visible={visible1} width="400px" closable={false} headerStyle={{ display: 'none' }} >
                <h4 style={{ fontSize: '20px', fontWeight: '800' }}>TimeLine <AiOutlineClockCircle size={22} style={{ margin: '0px 5px' }} /> </h4>
                <Timeline style={{ marginTop: '20px', width: '100%' }}>

                    {contact_tracker?.base?.filter((l => l.type === "Contact")).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.type_id}`)}>View Details</span> */}
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {contact_tracker?.sub?.filter((l => l.type === "Lead" && (l?.history?.includes('<p>Lead Created</p>')))).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '900', marginBottom: '0px', paddingBottom: '0px', lineHeight: '10px' }}>{t?.history?.replace(/(<([^>]+)>)/gi, "")}</h6>
                                {contact_tracker?.sub?.filter(l1 => l1.type === 'Lead' && l1.type_id === t.type_id && l1.history.includes("Converted from contact ")) !== undefined &&
                                    <>
                                        <span style={{ marginleft: '0px', fontSize: '11px', fontWeight: '700' }}>{contact_tracker?.sub?.find(l1 => l1.type === 'Lead' && l1.type_id === t.type_id && l1.history.includes("Converted from contact "))?.history?.split(':')[0]}</span>
                                        <span style={{ marginleft: '5px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '10px', paddingBottom: '2px' }} onClick={() => navigate(`/lead_detail/${t.type_id}`)}>View Details</span>
                                    </>
                                }
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {task_tracker.map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '900', marginBottom: '0px', paddingBottom: '2px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {meeting_tracker?.map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '900', marginBottom: '0px', paddingBottom: '2px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                </Timeline>
            </Drawer>
            
            {type === 'Contact' &&
            <MessageBox  type_id={id} comment_type={'Contact'}/> }

            <Toaster />

            <Modal visible={deleteAttachment} style={{ maxWidth: '280px' }} onCancel={() => setdeleteAttachment(false)} centered footer={null}>
                <div >
                    <h6 style={{ padding: '2%', fontWeight: '900', fontSize: '15px' }}>Delete Attachment</h6>
                    <p style={{ padding: '2%', fontSize: '12px' }}>Are you sure you want to delete the attachment?
                        If you delete the attachment, you cannot undo this action.</p>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', alignItems: 'flex-end', justifyItems: 'flex-end', justifySelf: 'flex-end', alignSelf: 'flex-end', alignContent: 'flex-end' }}>
                        <Button type="dashed" size="middle" style={{ maxWidth: '46%' }} block onClick={() => setdeleteAttachment(false)}>
                            Cancel
                        </Button>
                        <Button type="primary" size="middle" danger style={{ marginLeft: '10px', maxWidth: '46%' }} onClick={deleteAttachmenfun}>
                            Delete
                        </Button>
                    </div>


                </div>
            </Modal>
           
            <Reminder
                reminder={reminder}
                visible={visible}
                func={() => setvisible(false)}
                id={id}
                type={'contact'}
                getreminderfun={(v) => getReminder(v)} />

            {/* data section */}
            {section.map((s, i) => (
                <Card key={i} bordered={false} style={{ marginTop: '20px', position: 'relative' }}>
                    <h6 style={{ color: '#292961' }}>{section[i]}</h6>
                    
                    {/* {i === 0 && type === 'Contact' &&
                    <Tooltip title="Chats"><BiMessageDetail size={22} style={{
                            position: 'absolute',
                            right: '155px',
                            top: '10px',
                            cursor: 'pointer'
                        }} onClick={()=>setchatmodal(!chatmodal)} />
                        </Tooltip>} */}
                    {i === 0 && type === 'Contact' &&
                        <Tooltip title="Reminder"><GiAlarmClock size={24} style={{
                            position: 'absolute',
                            right: '60px',
                            top: '10px',
                            cursor: 'pointer'
                        }} onClick={showreminder} />
                            {/*setvisible(true)*/}
                        </Tooltip>}
                    {i === 0 && type === 'Contact' &&
                        <Tooltip title="Edit"><FiEdit size={20} style={{ position: 'absolute', right: '20px', top: '12px', cursor: 'pointer' }}
                            onClick={() => editContact()} />
                        </Tooltip>}
                    {i === 0 && type === 'Contact' && <Tooltip title="Timeline"><MdTimeline size={22} style={{ position: 'absolute', right: '110px', top: '12px', cursor: 'pointer' }} onClick={() => setvisible1(true)} /></Tooltip>}

                    <div className='row' style={{ marginLeft: '20px' }}>
                        {labels[i].map((l, j) => (
                            <div key={j} className={l === 'Description' ? 'col-md-12' : 'col-md-6'} style={{ padding: '5px 0px' }}>
                                {l !== 'Description' && <h6 style={{ color: 'grey', fontSize: '13px' }}>{l}</h6>}
                                <h6>{Object.values(values[i])[j]}</h6>
                            </div>
                        ))}


                    </div>
                </Card>
            ))}

            <div className='row' style={{ marginTop: '20px', marginBottom: '40px' }}>
                {/* attachement section */}
                <div className='col-md-6'>
                    <Card bordered={false}>
                        <Dragger onChange={handleUpload}
                            beforeUpload={() => false}
                            showUploadList={false}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to upload</p>
                        </Dragger>
                        <div style={{ maxHeight: '250px', overflowY: 'scroll' }}>
                            <h6 style={{ marginTop: '20px' }}>Attachements ({attachement.length})</h6>
                            {attachement.map((a) => (
                                <div key={a.id} style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    margin: '2px 0px'
                                }}>
                                    <p>{a.file.slice(14, 35)}.{a.file.split('.')[a.file.split('.').length - 1]} </p>
                                    {/* <p>{a.file.split('.')[a.file.split('.').length - 1]}</p> */}
                                    <div style={{ width: '20%' }}>
                                        <CloudDownloadOutlined size={20} style={{ marginRight: '10px' }}
                                            onClick={() => handleDownload(`${base_url}${a.file}`, a.file.split('/')[a.file.split('/').length - 1])} />
                                        <DeleteOutlined size={20} onClick={() => { setselectedAttachment(a.id); setdeleteAttachment(true) }} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Card>
                </div>

                {/* notes section */}
                <div className='col-md-6' >
                    <Card bordered={false} style={{ minHeight: '250px', maxHeight: '400px', overflowY: 'scroll' }}>
                        <h6 style={{ marginTop: '20px' }}>Notes ({contactNote.length})</h6>


                        <div style={{ height: '80px', marginBottom: '10px' }}>
                            <Input value={data.note_title} placeholder="Add Heading" style={{ width: '100%', border: 'none', borderBottom: '1px solid lightgrey', boxShadow: 'none', marginRight: '5px' }} onChange={(e) => setData({ ...data, note_title: e.target.value })}
                            />
                            <div style={{ position: 'relative' }}>
                                <Input value={data.summary} placeholder="Add Description" style={{ border: 'none', borderBottom: '1px solid lightgrey', boxShadow: 'none' }} onChange={(e) => setData({ ...data, summary: e.target.value })}
                                />
                                <IoSendSharp style={{ position: 'absolute', right: '-20px', bottom: '0px' }} size={20} onClick={addNotes} />
                            </div>
                        </div>

                        {contactNote?.map((note, i) => (
                            <div key={note?.id ? note?.id : note?._id} style={{ display: 'flex' }}>
                                <GrDocumentNotes />
                                <div style={{ marginLeft: '10px', width: '80%' }}>
                                    <h1 style={{ fontWeight: '900', marginBottom: '0px', fontSize: '13px' }}>{note?.note_title}</h1>
                                    {note_id !== note?.id && <h6 style={{ fontWeight: '400', marginBottom: '10px', fontSize: '12px', marginTop: '5px', wordBreak: 'break-word' }}  >{note?.summary.slice(0, 100)} {note?.summary?.length > 100 && <span onClick={() => setnote_id(note?.id)} style={{ color: '#5eb7ff', fontWeight: '800', cursor: 'pointer' }}> ...Read more</span>}</h6>}
                                    {note_id === note?.id && <h6 style={{ fontWeight: '400', marginBottom: '10px', wordBreak: 'break-word', fontSize: '12px', marginTop: '5px' }}>{note?.summary} <span onClick={() => setnote_id('')} style={{ color: '#5eb7ff', fontWeight: '800', cursor: 'pointer' }}> ...Read less</span></h6>}
                                </div>
                                <p style={{ fontSize: '9px', fontWeight: 'bolder', color: 'black', width: '20%' }}>{moment(note.updatedAt).format('LL')}</p>
                            </div>
                        ))}



                    </Card>
                </div>
            </div>
        </div>
    )
}

export default ContactComponent
