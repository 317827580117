import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import WebViewLead from './web/ViewLead'
import MobileViewLead from './mobile/ViewLead'

const index = () =>{
    if(isMobile){
        return(
            <div>
                <MobileViewLead />
            </div>
        )
    }else{
        return(
            <div>
                <WebViewLead />
            </div>
        )
    }
}

export default index