import TeamCLDWeb from './web/TeamCLD'
import TeamCLDMob from './android/TeamCLD'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'

const index = () => {
    if(isMobile){
        return <TeamCLDMob />
    }else{
        return <TeamCLDWeb />
    }
}

export default index