import React,{useState,useEffect} from 'react'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
import {BiEdit} from 'react-icons/bi'
import './DDR_Adding.css'
import InputField from '../../../components/inputcomponents/InputField'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import axios from 'axios'
import { Validators } from '../../../components/Validators/Validators'

function RolesList({roles_arr,single_role_sent,edit,editfunc,canceleditfun,fetch_all_roles}) {

    const [single_role, setsingle_role] = useState({role_name:single_role_sent?.role_name,display_name:single_role_sent?.display_name,id:single_role_sent?.id})
    const [role_err,setrole_err] = useState({})
    const [display_name_err,setdisplay_name_err] = useState({})

    useEffect(() => {
       if(edit){
           setrole_err({})
           setsingle_role({...single_role,role_name:single_role_sent.role_name,display_name:single_role_sent.display_name,id:single_role_sent.id})
       } else{
            setrole_err({})
            setsingle_role({...single_role,id:'',role_name:'',display_name:''})
       } 
    }, [edit])

    function addrole(){
        if(!single_role.role_name){
            setrole_err({error:true,message:'This field is required'})
        }
        else if(!single_role.display_name){
            setdisplay_name_err({error:true,message:'This field is required'}) 
        }
        else{
            let send_data = {
                'role_name':single_role.role_name,
                'display_name':single_role.display_name
            }
            axios.post('role/create',send_data,authHeader)
            .then((res)=>{
                setsingle_role({})
                fetch_all_roles()
            }).catch((err)=>{
                console.log('roles err',err.response.data)
                if(err.response.status === 422){
                    if(err.response.data.errors.role_name){
                        setrole_err({error:true,message:err.response.data.errors.role_name})
                    }else{
                        setdisplay_name_err({error:true,message:err.response.data.errors.display_name})
                    }
                }
            })
            }
    }

    function updaterole(){
        let send_data = {
            role_name:single_role.role_name,
            display_name:single_role.display_name,
            id:single_role.id
        }
        axios.put(`role/update/${single_role.id}`,send_data,authHeader)
        .then((res)=>{
            setsingle_role({})
            fetch_all_roles()
            canceleditfun()
            // console.log('edit status',res.data)
        }).catch((err)=>{
            console.log('err obtained',err)
        })
    }

    function handleChange({key,value}){
        if(key === 'role_name'){
            setrole_err({})
        }else if(key === 'display_name'){
            setdisplay_name_err({})
        }
        setsingle_role({...single_role,[key]:value})
    }


    return (
        <div style={{display:'flex',justifyContent:'space-between'}}> 
        <div style={{flex:0.4,flexShrink:3}}>
        <h6>{edit ? 'Edit' : 'Add' } Roles</h6>
        <InputField 
            placeholder={'Enter role name'}
            priority={'high'} 
            value={single_role.role_name}
            type="text"
            name="role_name"
            nameerr="role_err"
            error_obj={role_err}
            validators={[{check:Validators.required,message:'This field is required'},{check:Validators.minimum_length_required,message:'Role Name should be atleast 3 characters'}]}
            onChange={handleChange}
        />
        <div style={{marginTop:'10px'}}>
         <InputField 
            placeholder={'Enter role display name'}
            priority={'high'} 
            value={single_role.display_name}
            type="text"
            name="display_name"
            nameerr="display_name_err"
            error_obj={display_name_err}
            validators={[{check:Validators.required,message:'This field is required'},{check:Validators.minimum_length_required,message:'Role Name should be atleast 3 characters'}]}
            onChange={handleChange}
        />
        </div>
        {/* <Checkbox  style={{ margin:'15px 0px'}}>isActive</Checkbox> */}
        <div style={{display:'flex'}}>
                <div style={{width:'100px'}} onClick={()=>{edit ? updaterole() : addrole()}}>
                <PrimaryButton  btn_name = {`${edit ? 'Edit' : 'Add' } Roles`}  />
                </div>
               {edit && <div  onClick={()=>canceleditfun()}>
                <SecondaryButton btn_name = 'cancel' />
                </div>}
            </div>
        </div>
    {/* <div style={{display:'flex',flexDirection:'row',justifyItems:'flex-end',alignSelf:'flex-end',justifyContent:'flex-end',width:'100%',}}>
         <PrimaryButton btn_name = "Add Departement"  />
    </div> */}
    <div style={{flex: 0.55}}>
    <h6>All Roles</h6>
    <p style={{color:'#57606a'}}>Showing You all the roles added till now.</p>
    <div style={{boxShadow: '0 1px 1px 0 var(--awsui-color-shadow-medium,rgba(0,28,36,.3)),1px 1px 1px 0 var(--awsui-color-shadow-side,rgba(0,28,36,.15)),-1px 1px 1px 0 var(--awsui-color-shadow-side,rgba(0,28,36,.15))'}} className='table_ddr'> 
    <div style={{display:'flex',width:'100%',background:'#fafafa',padding:'10px'}}>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'6%',borderRight:'1px solid #dbdbdb'}}>ID</p>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'60%',borderRight:'1px solid #dbdbdb'}}>Role Name</p>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'26%',borderRight:'1px solid #dbdbdb'}}>Display Name</p>
        <p></p>
    </div>
   {roles_arr?.map((d,i)=>(
    <div key={d.id} style={{display:'flex',width:'100%',background:'#fafafa',padding:'5px 10px',borderTop:'1px solid #dbdbdb',backgroundColor:'white'}}>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'6%'}}>{i+1}</p>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'60%'}}>{d.role_name}</p>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'28%'}}><span style={{backgroundColor:'#edffe0',padding:'4px',borderRadius:'4px',textAlign:'center'}}>{d.display_name}</span> </p>
        <BiEdit  size={20} color="#292961" style={{cursor:'pointer'}} onClick={()=>editfunc(d)} />
    </div>
    ))}
    </div>
    </div>
    </div>
    )
}

export default RolesList
