import OnboardEmployeeAnd from './android/OnboardEmployee'
import OnboardEmployeeWeb from './web/OnboardEmployee'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'


const index = () => {
       if(isMobile){
           return (
               <OnboardEmployeeAnd />
           )
       }else{
           return (
               <OnboardEmployeeWeb />
           )
       }
}

export default index