import React, { useEffect, useState } from 'react'
import axios from "axios";
import { useParams } from "react-router-dom";
import DetailDesign2 from "../../../components/detail_design/DetailDesign2";
import { authHeader } from '../../../helpers/usertoken/UserToken';
import moment from 'moment'
import './ContactDetail.css'

function ContactDetail() {


    const [values, setvalues] = useState([])
    const [values1, setvalues1] = useState([])
    const [values2, setvalues2] = useState([])
    const [values3, setvalues3] = useState([])
    const [values4, setvalues4] = useState([])
    const [values5, setvalues5] = useState([])
    const [values6, setvalues6] = useState([])



    const [converted_status, setconverted_status] = useState(false)


    const [obj, setobj] = useState({})


    const { id } = useParams();
    const type = "Contact"

    const label = ['Contact Name', 'Company', 'Phone', 'Company Phone', 'Email', 'Company Email', 'Designation', 'Website', 'Secondary Contact Person', 'Contact Source', 'Secondary Contact Phone', 'Industry', 'Secondary Contact Designation', 'Contact Status', 'Contact Owner']
    const label1 = ['Street', 'City', 'State', 'Pincode', 'Country',]
    const label2 = ['Description']
    const label3 = ['Lead Name', 'Title', 'Lead Status', 'Modified Date', 'Contact Name']
    const label4 = ['Description']
    const label5 = ['Deal Name', 'Title', 'Deal Owner', 'Type', 'Stage', 'Closing Date']
    const label6 = ['Description']

    const section = ['Contact Information', 'Address Information', 'Description']
    const section1 = ['Lead Information', 'Description']
    const section2 = ['Deal Information', 'Description']

    const section_arr = [section, section1, section2]
    const labels_arr = [[label, label1, label2], [label3, label4], [label5, label6]]
    const values_arr = [[values, values1, values2], [values3, values4], [values5, values6]]

    let lead_id = ''




   

    useEffect(() => {


        axios.get(`contact/get/${id}`, authHeader)
            .then((res) => {
                setobj(res.data.datas[0])
                let item = res.data.datas[0]
                let contact_info =
                {
                    'contact_name': item?.contact_name,
                    'company_name': item?.company_name,

                    'phone': item?.phone,
                    'company_phone': item?.company_phone,

                    'email': item?.email,
                    'company_email': item?.company_email,

                    'designation': item?.designation,
                    'website': item?.website,

                    'secondary_contact_person': item?.secondary_contact_person,
                    'contact_source': item?.contact_source?.source_name,

                    'secondary_contact_phone': item?.secondary_contact_phone,
                    'industry': item?.industry?.industry_name,

                    'secondary_contact_designation': item?.secondary_contact_designation,
                    'status': item?.status,
                    'owner': item?.contact_owner?.name,
                }
                let address_info = {
                    'street': item?.street,
                    'city': item?.city,
                    'state': item?.state,
                    'pincode': item?.pin_code,
                    'country': item?.country
                }
                let description = {
                    'description': item?.description
                }

                setvalues(contact_info)
                setvalues1(address_info)
                setvalues2(description)
                setconverted_status(res?.data?.datas[0]?.isConverted)
                if (res?.data?.datas[0]?.isConverted) {
                    axios.get(`contact/get-lead-deal-by-contact/${id}`, authHeader)
                        .then((res) => {
                            // console.log('contact/get-lead-deal-by-contact/',res?.data?.datas[0])
                            let md = res?.data?.datas[0]?.lead
                            let mdd = res?.data?.datas[0]?.lead?.deal

                            // console.log('res data here are',md)   
                            let lead_info = {
                                'lead_name': md?.lead_name,
                                'lead_title': md?.lead_title,
                                'lead_status': md?.lead_status,
                                'modified_At': moment(md?.updatedAt).format('MMMM Do YYYY, h:mm:ss a'),
                                'contact_name': contact_info?.contact_name,
                            }

                            lead_id = md?._id

                            let deal_arr = []
                            let deal_desc_arr = []

                            mdd?.forEach((dd) => {
                                let obj = {
                                    'deal_name': dd.deal_name,
                                    'deal_title': dd.deal_title,
                                    'deal_owner': dd.deal_owner,
                                    'deal_type': dd.deal_type,
                                    'deal_stage': dd.deal_stage,
                                    'closing_date': dd.closing_date,
                                    'id': dd._id,
                                }
                                deal_arr.push(obj)
                            })
                            setvalues5(deal_arr)
                            setvalues6([])

                            let description1 = md?.description

                            setvalues3(lead_info)
                            setvalues4(description1)
                        })
                }

            })

    }, [id])





    return (
        <div style={{ width: '100%', margin: 'auto' }}>
            <DetailDesign2 activetab="1" id={id} lead_id={lead_id} section={section_arr} values={values_arr} labels={labels_arr} type={type} converted_status={converted_status} obj={obj} />
        </div>
    )
}

export default ContactDetail
