import React, { useEffect, useState } from 'react'
import ReactMaterialTable from '../../../components/table_design/ReactMaterialTable';
import { useNavigate, useParams } from "react-router-dom";
import { bd_active_tab } from "../../../redux/actionPayload/BdPayload";
import { useDispatch } from "react-redux";
import axios from "axios";
import { authHeader } from "../../../helpers/usertoken/UserToken";
import { Dropdown, Modal, Input, Menu, Row, Col, Checkbox } from 'antd'
import { HiOutlineFilter } from "react-icons/hi";
import { GrFormAdd } from "react-icons/gr";
import PrimaryButton, { PrimaryButtonFilter } from "../../../components/buttons/PrimaryButton";
import DateField from '../../../components/inputcomponents/DateField';
import { IoClose } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { data_not_found } from '../../../helpers/Constants';
import toast,{ Toaster } from 'react-hot-toast';

function ViewDeal() {

    const department = useSelector(state => state.Auth.department)
    const roles = useSelector(state => state.Auth.roles)

    const dealStage_proj =
        [
            {
                id: "Execution",
                value: "Execution"
            },
            {
                id: "Handing Over",
                value: "Handing Over"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Invoice raised",
                value: "Invoice raised"
            },
            {
                id: "Closed",
                value: "Closed"
            },
        ]
    const dealStage_trans =
        [
            {
                id: "Payment Received",
                value: "Payment Received"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Agreement/Registered",
                value: "Agreement/Registered"
            },
            {
                id: "Invoice Raised",
                value: "Invoice Raised"
            },
        ]
    const dealStage_hr =
        [
            {
                id: "Payment Received",
                value: "Payment Received"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Deal Closed",
                value: "Deal Closed"
            },
            {
                id: "Service stopped",
                value: "Service stopped"
            },
        ]
    const dealStage_fms =
        [
            {
                id: "Agreement Renewal",
                value: "Agreement Renewal"
            },
            {
                id: "Organic Growth",
                value: "Organic Growth"
            },
            {
                id: "Payment Received",
                value: "Payment Received"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Invoice Raised",
                value: "Invoice Raised"
            },
        ]
    const dealStage_gallery =
        [
            {
                id: "Payment Received",
                value: "Payment Received"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Invoise Raised",
                value: "Invoise Raised"
            },
            {
                id: "Work In Progess",
                value: "Work In Proges"
            },
        ]

    const { id } = useParams()
    const dispatch = useDispatch()
    const [datas, setDatas] = useState([])
    const [pagination, setpagination] = useState({})
    const [reminder, setReminders] = useState([])
    const [deal_status_filter, setdeal_status_filter] = useState([])

    useEffect(() => {
        if (department[0].department_name === 'Transaction Team') {
            setdeal_status_filter(dealStage_trans)
        } else if (department[0].department_name === 'Project Team') {
            setdeal_status_filter(dealStage_proj)
        } else if (department[0].department_name === 'Hr Team') {
            setdeal_status_filter(dealStage_hr)
        } else if (department[0].department_name === 'FMS Team') {
            setdeal_status_filter(dealStage_fms)
        } else if (department[0].department_name === 'Gallery Team') {
            setdeal_status_filter(dealStage_gallery)
        }
    }, [])

    const page = 1

    const navigate = useNavigate()
    const [datepickervisible, setdatepickervisible] = useState(false);
    const [search_deal, setsearch_deal] = useState({ search_text: '', deal_stage: '', from_date: '', to_date: '', filter_status: false })
    const [tempsearch_deal, settempsearch_deal] = useState({ search_text: '', deal_stage: '', from_date: '', to_date: '' })


    let keys_arr = ['Deal Name', 'Company', 'Stage', 'Closing Date', 'Phone', 'Email', 'Deal Owner']
    const label_arr = ['deal_name', 'company', 'stage', 'closing_date', 'phone', 'email', 'deal_owner']


    const menu = (
        <Menu style={{ width: '300px' }}>
            <h6 style={{ marginLeft: '14px', fontSize: '14px', fontWeight: '500' }}>Add Search Query</h6>
            <div style={{ display: 'flex', width: '90%' }}>
                <Input type={"text"} style={{ width: '95%', margin: 'auto', marginLeft: '5%' }} value={tempsearch_deal.search_text} onChange={e => settempsearch_deal({ ...tempsearch_deal, search_text: e.target.value })} />
                <PrimaryButton btn_name="+" padding='5px 10px' onClick={() => setsearch_deal({ ...search_deal, search_text: tempsearch_deal.search_text })} />
            </div>
        </Menu>
    );


    const menu1 = (
        <Menu style={{ width: '500px', marginTop: '10px' }}>
            <h6 style={{ padding: '2%', fontWeight: '500', fontSize: '13px' }}>Choose an option</h6>
            <Row style={{ width: '90%', margin: 'auto' }}>
                {deal_status_filter.map((s) => (
                    <Col key={s} span={12} style={{ background: 'white', padding: '0px 5px', display: 'flex' }} onClick={() => setsearch_deal({ ...search_deal, deal_stage: s.value })}>
                        <Checkbox style={{ marginRight: '5px' }} />
                        <p style={{ fontWeight: '600', fontSize: '13px' }}>{s.value}</p>
                    </Col>
                ))}
            </Row>
        </Menu>
    )





    useEffect(() => {
        getDeals()
    }, [])

    function getDeals() {
        axios.get(`deal/get/${page}`, authHeader).then((res) => {
            setDatas(res.data.datas)
            setpagination(res.data.pagination)
        })
    }

    function dealdetailfunc(v) {
        navigate(`/deal_detail/${v.id}`, { state: { datas: v } })
        dispatch(bd_active_tab('3'))
    }

    function editDeal(id) {
        navigate(`/edit_deal/${id}`)
    }

    const paginateddata = (v) => {
        axios.get(`deal/get/${v}`, authHeader)
            .then((res) => {
                setDatas(res.data.datas)
                setpagination(res.data.pagination)
            })
    }

    function setdatepickervisiblefun() {
        setdatepickervisible(false)
        setsearch_deal({ ...search_deal, from_date: '', to_date: '' })
    }

    function setselectedDateRange() {
        if (!tempsearch_deal.from_date || !tempsearch_deal.to_date) {
            toast.info('From date or To date might be missing')
        } else {
            settempsearch_deal({ ...tempsearch_deal, from_date: '', to_date: '' })
            setsearch_deal({ ...search_deal, from_date: tempsearch_deal.from_date, to_date: tempsearch_deal.to_date })
            setdatepickervisible(false)
        }
    }

    function applyfilter() {
        if (search_deal.search_text || search_deal.deal_stage || search_deal.from_date || search_deal.to_date) {
            axios.get(`deal/search?search_text=${search_deal.search_text}&deal_stage=${search_deal.deal_stage}&from_date=${search_deal.from_date}&to_date=${search_deal.to_date}&page=${page}`, authHeader)
                .then((res) => {
                    setsearch_deal({ ...search_deal, filter_status: true })
                    setpagination(res.data.pagination)
                    setDatas(res.data.datas)
                })
            settempsearch_deal({ search_text: '', deal_stage: '', from_date: '', to_date: '' })
        } else {
            toast.error('select any filter option before applying ')
        }
    }

    function handleChange({ key, value }) {
        settempsearch_deal({ ...tempsearch_deal, [key]: value })
    }

    function resetfilter() {
        setsearch_deal({ search_text: '', deal_stage: '', from_date: '', to_date: '', filter_status: false })
        settempsearch_deal({ search_text: '', deal_stage: '', from_date: '', to_date: '' })
        getDeals()
    }

    async function getReminder(id) {
        await axios.get(`reminder/get?type=${'Deal'}&type_id=${id}`, authHeader).then((res) => {
            setReminders(res.data.datas.reverse())
        })
    }


    return (
        <div style={{ width: '90%', margin: 'auto', display: 'flex', flexDirection: 'column', marginTop: '40px' }}>
            
            <Toaster />
            <Modal visible={datepickervisible} footer={false} onCancel={() => setdatepickervisiblefun()} style={{ top: '21%', width: '200px' }}>
                <div style={{ maxWidth: '200px', }}>
                    <h6 style={{ padding: '2%', fontWeight: '500', fontSize: '13px' }}>Select Date range option</h6>
                    <Row style={{ width: '300px', margin: 'auto', display: 'flex' }}>
                        <Col span={12} style={{ background: 'white', padding: '0px 5px', width: '100%' }}>
                            <h6 style={{ fontSize: '12px' }}>From Date</h6>
                            <DateField
                                onChangefun={handleChange}
                                name={'from_date'}
                                type="1"
                                date_value={tempsearch_deal.from_date}
                                style={{ width: '100%' }} />
                        </Col>

                        <Col span={12} style={{ background: 'white', padding: '0px 5px' }}>
                            <h6 style={{ fontSize: '12px' }}>To Date</h6>
                            <DateField
                                name={'to_date'}
                                type="1"
                                onChangefun={handleChange}
                                date_value={tempsearch_deal.to_date}
                                style={{ width: '100%' }} />
                        </Col>

                        <div style={{ marginLeft: '5px' }} onClick={setselectedDateRange}>
                            <PrimaryButton btn_name={'Apply'} />
                        </div>
                    </Row>
                </div>
            </Modal>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
                margin: '10px 0px',
                padding: '0px 0px',
                background: 'transparent',
                backgroundColor: 'white',
                position: 'sticky', top: '40px', zIndex: 10
            }}
            >
                <h6 style={{ marginBottom: '0px', fontWeight: 'bolder',fontSize:'15px' }}>Total Deals ({datas.length})</h6>


                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '0px', padding: '5px 10px' }}>
                        <HiOutlineFilter size={24} style={{ marginRight: '10px' }} />
                        <h6 style={{ marginBottom: '0px', marginRight: '15px', fontSize: '14px', fontWeight: 'bolder' }}>Filter Deal By</h6>
                        <div style={{ display: 'flex', alignItems: 'center' }}>


                            {search_deal.search_text === '' ?
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <button type='button' className='btn  btn-light btn-sm m-1'   >
                                        <GrFormAdd color="white" size={18} style={{ marginRight: '6px', color: 'white' }} />
                                        <span>Add Search filter</span>
                                    </button>
                                </Dropdown>
                                :
                                <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px' }}>
                                    <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_deal.search_text.slice(0, 20)}{search_deal.search_text.length > 20 && '...'}</h6>
                                    <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_deal({ ...search_deal, search_text: '' })} />
                                </div>

                            }

                            {!roles?.includes('admin') && !roles?.includes('db_head') && !roles?.includes('db_user') && !roles?.includes('db_sub_user') &&
                                <>
                                    {search_deal.deal_stage === '' ?
                                        <Dropdown overlay={menu1} trigger={['click']}>
                                            <button type='button' className='btn  btn-light btn-sm m-1'   >
                                                <GrFormAdd color="white" size={18} style={{ marginRight: '6px', color: 'white' }} />
                                                <span>Add Deal Stage</span>
                                            </button>
                                        </Dropdown>
                                        :
                                        <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', marginLeft: '5px' }}>
                                            <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_deal.deal_stage.slice(0, 20)}{search_deal.deal_stage.length > 20 && '...'}</h6>
                                            <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_deal({ ...search_deal, deal_stage: '' })} />
                                        </div>
                                    }
                                </>
                            }


                            {search_deal.from_date === '' || search_deal.to_date === '' ?
                                <div>
                                    <button type='button' className='btn  btn-light btn-sm m-1' onClick={() => setdatepickervisible(true)}   >
                                        <GrFormAdd color="white" size={18} style={{ marginRight: '6px', color: 'white' }} />
                                        <span>Date interval</span>
                                    </button>
                                </div>
                                :
                                <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '8px', borderRadius: '2px', margin: '0px 5px' }}>
                                    <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_deal.from_date + ':' + search_deal.to_date}</h6>
                                    <IoClose style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setsearch_deal({ ...search_deal, from_date: '', to_date: '' })} />
                                </div>
                            }


                            <div style={{ marginRight: '5px' }} onClick={applyfilter}>
                                <PrimaryButtonFilter btn_name="Apply Filter" padding={'2px 8px'} />
                            </div>
                            <div style={{ marginRight: '5px' }} onClick={resetfilter}>
                                <PrimaryButtonFilter btn_name="Reset Filter" padding={'2px 8px'} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {
                (datas.length ? <ReactMaterialTable
                    reminder={reminder}
                    id={id}
                    headers={keys_arr}
                    data={datas}
                    type="deal"
                    dataLabels={label_arr}
                    func={(v) => dealdetailfunc(v)}
                    editDeal={(v) => editDeal(v)}
                    setpagefuun={v => paginateddata(v)}
                    pagination={pagination}
                    getreminderfun={(v) => getReminder(v)}
                />
                    :
                    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                        <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
                        <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{search_deal.filter_status ? 'No data Found' : "You don't have any deal created yet."}</h4>
                        <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>{search_deal.filter_status ? 'No data found with provided search options please check other format filter options' : "Visit a Lead page and press on a convert icon. Then, you can find the deal on this page."}</h6>
                    </div>
                )
            }

        </div>
    )
}

export default ViewDeal
