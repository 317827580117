import React,{useState,useEffect} from 'react'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
import {BiEdit} from 'react-icons/bi'
import './DDR_Adding.css'
import {Checkbox,Modal} from 'antd'
import InputField from '../../../components/inputcomponents/InputField'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import axios from 'axios'
import { Validators } from '../../../components/Validators/Validators'

function DepartementsList({departement_arr,single_department_sent,edit,editfunc,canceleditfun,fetch_all_departement}) {

    const [single_department, setsingle_department] = useState({department_name:single_department_sent?.department_name,isActive:single_department_sent?.isActive,id:single_department_sent?.id})
    const [departement_name_err,setdepartement_name_err] = useState({})
    const [modalVisible,setmodalVisible] = useState(false)

    

    useEffect(() => {
       if(edit){
           setdepartement_name_err({})
           setsingle_department({...single_department,department_name:single_department_sent.department_name,isActive:single_department_sent.isActive,id:single_department_sent.id})
       } else{
            setdepartement_name_err({})
            setsingle_department({...single_department,id:'',department_name:'',isActive:''})
       } 
    }, [edit])

    function adddepartement(){
        if(!single_department.department_name){
            setdepartement_name_err({error:true,message:'This field is required'})
        }
        else{
            let send_data = {
                'department_name':single_department.department_name,
            }
            axios.post('department/create',send_data,authHeader)
            .then((res)=>{
                setsingle_department({})
                fetch_all_departement()
            }).catch((err)=>{
                if(err.response.status === 422){
                    setdepartement_name_err({error:true,message:err.response.data.errors.department_name})
                }
            })
            }
    }

    function updatedepartement(){
        let send_data = {
            department_name:single_department.department_name,
            isActive:single_department.isActive,
            id:single_department.id
        }
        axios.put(`department/update/${single_department.id}`,send_data,authHeader)
        .then((res)=>{
            setsingle_department({})
            fetch_all_departement()
            canceleditfun()
            setmodalVisible(false)
            // console.log('edit status',res.data)
        }).catch((err)=>{
            console.log('err obtained',err)
        })
    }

    function handleChange({key,value}){
        if(key === 'department_name'){
            setdepartement_name_err({})
        }
        setsingle_department({...single_department,[key]:value})
    }


    return (
        <div style={{display:'flex',justifyContent:'space-between'}}> 
         <Modal visible={modalVisible} 
            onCancel={()=>setmodalVisible(false)}
            footer={false}
            style={{width:'90%',margin:'30% auto',minHeight:'340px'}}
            >
            <div style={{height:'180px'}}>
            <h6 style={{fontWeight:'600',marginBottom:'15px'}}>{edit ? 'Edit' : 'Add' } Departement</h6>
            <InputField 
                placeholder={'Enter your department name'}
                value={single_department.department_name}
                priority={'high'}
                size={'large'}
                type="text"
                name="department_name"
                nameerr="departement_name_err"
                error_obj={departement_name_err}
                validators={[{check:Validators.required,message:'This field is required'},{check:Validators.minimum_length_required,message:'Departement Name should be atleast 3 characters'}]}
                onChange={handleChange}
                />
            <Checkbox  checked={single_department.isActive === true} onChange={()=>setsingle_department({...single_department,isActive:!single_department.isActive})} style={{ margin:'15px 0px'}}>isActive</Checkbox>
            <div style={{display:'flex'}}>
                <div style={{width:'180px'}} onClick={()=>{edit ? updatedepartement() : adddepartement()}}>
                <PrimaryButton  btn_name = {`${edit ? 'Update' : 'Add' } Departement`}  />
                </div>
               {edit && <div  style={{marginLeft:'-20px'}} onClick={()=>setmodalVisible(false)}>
                <SecondaryButton btn_name = 'cancel' />
                </div>}
            </div>

            </div>


        </Modal>
            
       
        <div style={{flex: 1}}>
        

        <div style={{display:'flex',width:'100%',alignItems:'flex-start',justifyContent:'space-between'}}>
            <div style={{flex:0.66}}>
            <h6>All Departement</h6>
            <p style={{color:'#57606a',width:'90%'}}>Showing You all the departments added till now.</p>
            </div>
            <div style={{flex:0.34}} onClick={()=>setmodalVisible(true)} >
            <PrimaryButton btn_name="Add Departement" />
            </div>
        </div>

        <div style={{border:'1px solid #dbdbdb'}} className='table_ddr'> 
        <div style={{display:'flex',width:'100%',background:'#fafafa',padding:'10px'}}>
            <p style={{marginBottom:'0px',padding:'0px 10px',width:'10%',borderRight:'1px solid #dbdbdb'}}>ID</p>
            <p style={{marginBottom:'0px',padding:'0px 10px',width:'50%',borderRight:'1px solid #dbdbdb'}}>Departement Name</p>
            <p style={{marginBottom:'0px',padding:'0px 10px',width:'30%',borderRight:'1px solid #dbdbdb'}}>Status</p>
            <p></p>
        </div>
       {departement_arr?.map((d,i)=>(
        <div style={{display:'flex',width:'100%',background:'#fafafa',padding:'10px',borderTop:'1px solid #dbdbdb',backgroundColor:'white'}}>
            <p style={{marginBottom:'0px',padding:'0px 10px',width:'10%',}}>{i+1}</p>
            <p style={{marginBottom:'0px',padding:'0px 10px',width:'50%',paddingLeft:'20px'}}>{d.department_name}</p>
            <p style={{marginBottom:'0px',padding:'0px 10px',width:'30%'}}>{d.isActive ? <span style={{backgroundColor:'#edffe0',padding:'4px',borderRadius:'4px',textAlign:'center'}}>Yes</span> : <span style={{backgroundColor:'#fae3d9',padding:'4px',borderRadius:'4px',textAlign:'center'}}>No</span>} </p>
            <BiEdit  size={20} color="#292961" style={{cursor:'pointer'}} onClick={()=>{editfunc(d);setmodalVisible(true)}}/>
        </div>
        ))}
        </div>
        </div>
        </div>
    )
}

export default DepartementsList
