import React, { useState } from 'react'
import './TableView1.css'
import { RiBuildingLine, RiPhoneLine } from 'react-icons/ri'
import { MdOutlinePersonOutline } from 'react-icons/md'
import { GoMail } from 'react-icons/go'
import { HiShieldCheck } from 'react-icons/hi'
import { FaSourcetree } from 'react-icons/fa'
import { SiStatuspal, SiHandshake } from 'react-icons/si'
import { MdPendingActions, MdPanoramaVerticalSelect, MdOutlineFollowTheSigns, MdPermContactCalendar, MdDriveFileRenameOutline } from 'react-icons/md'
import { GiAlarmClock } from 'react-icons/gi'
import { CgMoveRight } from 'react-icons/cg'
import { FaRegHandshake } from 'react-icons/fa'
import { Tag } from 'antd'
import { ImRadioUnchecked, ImRadioChecked2 } from 'react-icons/im'
import { FiEdit } from 'react-icons/fi'
import moment from 'moment'
import { TiTick } from 'react-icons/ti'
import { IoMdClose } from 'react-icons/io'
import { BiMessageDetail } from 'react-icons/bi'
import { BsCalendar2Week } from 'react-icons/bs'
import { IoWallet } from 'react-icons/io5'
import { VscGraphLine } from 'react-icons/vsc'
import { AiTwotoneCalendar } from 'react-icons/ai'
import { FaRegClock, FaListOl } from 'react-icons/fa'
import { MdEditLocationAlt } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import ReminderMobile from '../../../detail_design/ReminderMobile'
import MeetingModalMobile from '../../../detail_design/MeetingModalMobile'
import Avatar from '@mui/material/Avatar';
import { light_colors_arr } from '../../../../helpers/Constants'
import toast from 'react-hot-toast';

function TableView1({ data, type, selected_contact, setselected_contact_func, editdealfun, setselected_leadfun, selected_lead, setselected_assigned_lead, acceptassignedlead, gotoleadcreatefunc, getreminderfun, reminder, detailfun, remarkfunc, editmeeting, tracker }) {

  const navigate = useNavigate()


  const [isvisible, setisvisible] = useState(false)
  const [isvisible1, setisvisible1] = useState(false)
  const [typeId, setTypeId] = useState('')

  const onReminderClick = (id) => {
    getreminderfun(id)
    setTypeId(id)
    setisvisible(true)
  }

  const onMeetingClick = (id) => {
    console.log('clicked')
    // getreminderfun(id)
    setTypeId(id)
    setisvisible1(true)
  }

  function onAssignClick(v) {
    if (v.accept === 'Pending') {
      toast.error('Please accept before assign!')
    }
    else if (v.accept === 'Rejected') {
      toast.error('You rejected this lead!')
    }
    else if (v.accept === 'Accepted') {
      setselected_leadfun(v._id)
      setselected_assigned_lead(v)
    }
    else {
      toast.error('Unknown Error')
    }
  }

  function gotodeal(v) {
    navigate(`/create_deal`, { state: { datas: v } })
  }

  function assigned_lead_to_deal(d) {
    let obj = {
      _id: d?.assigned_lead_id?.lead_id?.id,
      department_id: d?.assigned_lead_id?.lead_id?.department_id
    }
    navigate(`/create_deal`, { state: { datas: obj } })
  }

  function texttruncater(v) {
    if (v?.length > 12) {
      return `${v?.slice(0, 12)} ...`
    } else {
      return `${v?.slice(0, 12)}`
    }
  }

  if (type === 'Contact') {
    return (
      <div style={{ width: '100%', height: '100%' }} className="hidescroll_bar">
        <MeetingModalMobile visible={isvisible1} func={() => setisvisible1(false)} data_id={typeId} type={type} />
        <ReminderMobile visible={isvisible} func={() => setisvisible(false)} id={typeId} type={type} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
        <div style={{ overflowX: 'auto', width: '100%', scrollbarWidth: 'none' }} className="hidescroll_bar">
          <table style={{ height: '100%' }}>
            <tr>
              <th style={{ minWidth: '40px', maxWidth: '40px', color: 'transparent' }} className='radio_section1'>a</th>
              <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiBuildingLine size={18} style={{ marginRight: '5px', color: 'black' }} />Company </th>
              <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px', color: 'black' }} />Contact Name</th>
              <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiPhoneLine size={18} style={{ marginRight: '5px', color: 'black' }} />Phone</th>
              <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><GoMail size={18} style={{ marginRight: '5px', color: 'black' }} />Email</th>
              <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><HiShieldCheck size={18} style={{ marginRight: '5px', color: 'black' }} />Designation</th>
              <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><FaSourcetree size={18} style={{ marginRight: '5px', color: 'black' }} />Source</th>
              <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><SiStatuspal size={18} style={{ marginRight: '5px', color: 'black' }} />Status</th>
              <th style={{ minWidth: '190px', color: 'black', fontWeight: '900' }}><MdPendingActions size={18} style={{ marginRight: '5px', color: 'black' }} />Actions</th>
            </tr>
            {data.map((d) => (
              <tr style={{ borderBottom: selected_contact !== d.id && '1px solid #eaeded' }} className={selected_contact === d.id && 'activetable'}>
                <th style={{ minWidth: '40px', maxWidth: '40px', background: 'transparent' }}>
                  {selected_contact !== d.id ?
                    <ImRadioUnchecked size={16} color="#e6e6e6" onClick={() => setselected_contact_func(d.id)} /> :
                    <ImRadioChecked2 size={16} style={{ marginTop: '0px', marginLeft: '0px', color: '#0073ba' }} onClick={() => setselected_contact_func('')} />
                  }
                </th>
                <td style={{ minWidth: '140px' }} onClick={() => detailfun(d.id)} className='active_link'>
                  {texttruncater(d?.company_name)}</td>
                <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_name)}</td>
                <td style={{ minWidth: '140px' }}>{d?.phone}</td>
                <td style={{ minWidth: '140px' }}>{texttruncater(d?.email)}</td>
                <td style={{ minWidth: '140px' }}>{d?.designation}</td>
                <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_source?.source_name)}</td>
                <td style={{ minWidth: '140px' }}>
                  {d?.status === 'Connected' && <Tag icon={<FaRegHandshake />} color="#87d068"> {d?.status} </Tag>}
                  {d?.status === 'Active' && <Tag icon={<FaRegHandshake />} color="#3defff"> {d?.status} </Tag>}
                  {d?.status === 'In Active' && <Tag icon={<FaRegHandshake />} color="#3defff"> {d?.status} </Tag>}
                  {d?.status === 'Not Connected' && <Tag icon={<FaRegHandshake />} color="#22148f"> {d?.status} </Tag>}
                  {d?.status === 'Old Client' && <Tag icon={<FaRegHandshake />} color="#4a83ff"> {d?.status} </Tag>}
                </td>
                <td style={{ minWidth: '190px', display: 'flex', alignItems: 'center' }}><CgMoveRight size={24} style={{ marginRight: '10px', marginLeft: '5px' }} onClick={() => gotoleadcreatefunc(d)} /> <GiAlarmClock size={22} onClick={() => onReminderClick(d.id)} /> <BsCalendar2Week size={16} onClick={() => onMeetingClick(d)} style={{ marginLeft: '10px' }} /> <Avatar sx={{ bgcolor: light_colors_arr[Math.floor(Math.random() * light_colors_arr?.length) + 1], width: 20, height: 20, fontSize: '10px', color: 'black', fontWeight: '800', marginLeft: '12px' }}>{d?.conversion_count}</Avatar> </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    )
  } else if (type === 'Shared To Me') {
    return (
      <div style={{ width: '100%', height: '100%', overflowX: 'auto', }} className="hidescroll_bar" >
        <MeetingModalMobile visible={isvisible1} func={() => setisvisible1(false)} data_id={typeId} type={type} />
        <ReminderMobile visible={isvisible} func={() => setisvisible(false)} id={typeId} type={type} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
        <table style={{ width: '100%', height: '100%', scrollbarWidth: 'none' }}>
          <tr>
            <th style={{ minWidth: '30px', maxWidth: '30px', color: 'transparent' }} className='radio_section1'>a</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiBuildingLine size={18} style={{ marginRight: '5px' }} />Company </th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Contact Name</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiPhoneLine size={18} style={{ marginRight: '5px' }} />Phone</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><GoMail size={18} style={{ marginRight: '5px' }} />Status</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }} ><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Cont-Owner</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><FaSourcetree size={18} style={{ marginRight: '5px' }} />Transf-By</th>
            <th style={{ minWidth: '160px', color: 'black', fontWeight: '900' }}><MdPendingActions size={18} style={{ marginRight: '5px' }} />Actions</th>
          </tr>
          {data.map((d) => (
            <tr style={{ borderBottom: selected_contact !== d._id && '1px solid #eaeded' }} className={selected_contact === d._id && 'activetable'}>
              <td style={{ minWidth: '30px', maxWidth: '30px', background: 'transparent' }}>
                {selected_contact !== d._id ?
                  <ImRadioUnchecked size={16} color="#e6e6e6" onClick={() => setselected_contact_func(d._id)} /> :
                  <ImRadioChecked2 size={16} style={{ marginTop: '0px', marginLeft: '0px', color: '#0073ba' }} onClick={() => setselected_contact_func('')} />
                }
              </td>
              <td style={{ minWidth: '140px', paddingLeft: '10px' }} className='active_link'>{texttruncater(d?.contact_id?.company_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_id?.contact_name)}</td>
              <td style={{ minWidth: '140px' }}>{d?.contact_id?.phone}</td>
              <td style={{ minWidth: '140px' }}>
                {d?.contact_id?.status === 'Connected' && <Tag icon={<FaRegHandshake />} color="#87d068"> {d?.contact_id?.status} </Tag>}
                {d?.contact_id?.status === 'Active' && <Tag icon={<FaRegHandshake />} color="#3defff"> {d?.contact_id?.status} </Tag>}
                {d?.contact_id?.status === 'In Active' && <Tag icon={<FaRegHandshake />} color="#3defff"> {d?.contact_id?.status} </Tag>}
                {d?.contact_id?.status === 'Not Connected' && <Tag icon={<FaRegHandshake />} color="#22148f"> {d?.contact_id?.status} </Tag>}
                {d?.contact_id?.status === 'Old Client' && <Tag icon={<FaRegHandshake />} color="#4a83ff"> {d?.contact_id?.status} </Tag>}
              </td>
              <td style={{ minWidth: '140px', paddingLeft: '10px' }} className='active_link'>{texttruncater(d?.contact_id?.contact_owner?.name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.referred_by?.name)}</td>
              <td style={{ minWidth: '160px', display: 'flex', alignItems: 'center' }}><CgMoveRight size={24} style={{ marginRight: '10px', marginLeft: '5px' }} /> <GiAlarmClock size={24} onClick={() => onReminderClick(d?.contact_id?.id)} />  <BsCalendar2Week size={16} onClick={() => onMeetingClick(d)} style={{ marginLeft: '10px' }} /> <Avatar sx={{ bgcolor: light_colors_arr[Math.floor(Math.random() * light_colors_arr?.length) + 1], width: 20, height: 20, fontSize: '10px', color: 'black', fontWeight: '800', marginLeft: '12px' }}>{d?.contact_id?.conversion_count}</Avatar> </td>
            </tr>
          ))}
        </table>
      </div>
    )
  } else if (type === 'Shared By Me') {
    return (
      <div style={{ overflowX: 'auto', width: '100%' }} className="hidescroll_bar">
        <table style={{ height: '100%' }}>
          <tr>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiBuildingLine size={18} style={{ marginRight: '5px' }} />Company </th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Contact Name</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiPhoneLine size={18} style={{ marginRight: '5px' }} />Phone</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><GoMail size={18} style={{ marginRight: '5px' }} />Email</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><SiStatuspal size={18} style={{ marginRight: '5px' }} />Status</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Cont-Owner</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Reffered-To</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdPanoramaVerticalSelect size={18} style={{ marginRight: '5px' }} />Referr-Depart</th>
            <th></th>
          </tr>
          {data.map((d) => (
            <tr style={{ borderBottom: '1px solid #eaeded' }}>
              <td style={{ minWidth: '140px', paddingLeft: '10px' }} className='active_link' onClick={() => detailfun(d?.contact_id?.id)}>{texttruncater(d?.contact_id?.company_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_id?.contact_name)}</td>
              <td style={{ minWidth: '140px' }}>{d?.contact_id?.phone}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_id?.email)}</td>
              <td style={{ minWidth: '140px' }}>
                {d?.contact_id?.status === 'Connected' && <Tag icon={<FaRegHandshake />} color="#87d068"> {d?.contact_id?.status} </Tag>}
                {d?.contact_id?.status === 'Active' && <Tag icon={<FaRegHandshake />} color="#3defff"> {d?.contact_id?.status} </Tag>}
                {d?.contact_id?.status === 'In Active' && <Tag icon={<FaRegHandshake />} color="#3defff"> {d?.contact_id?.status} </Tag>}
                {d?.contact_id?.status === 'Not Connected' && <Tag icon={<FaRegHandshake />} color="#22148f"> {d?.contact_id?.status} </Tag>}
                {d?.contact_id?.status === 'Old Client' && <Tag icon={<FaRegHandshake />} color="#4a83ff"> {d?.contact_id?.status} </Tag>}
              </td>
              <td style={{ minWidth: '140px', paddingLeft: '10px' }} className='active_link'>{texttruncater(d?.contact_id?.contact_owner?.name)}</td>
              <td style={{ minWidth: '140px', paddingLeft: '10px' }} className='active_link'>{texttruncater(d?.referred_to?.name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.department_id?.department_name)}</td>
              <td> <Avatar sx={{ bgcolor: light_colors_arr[Math.floor(Math.random() * light_colors_arr?.length) + 1], width: 20, height: 20, fontSize: '10px', color: 'black', fontWeight: '800', marginLeft: '12px' }}>{d?.contact_id?.conversion_count}</Avatar> </td>
            </tr>
          ))}
        </table>
      </div>
    )
  } else if (type === 'Deal') {
    return (
      <div style={{ overflowX: 'auto', width: '100%' }} className="hidescroll_bar">
        <MeetingModalMobile visible={isvisible1} func={() => setisvisible1(false)} data_id={typeId} type={type} />
        <ReminderMobile visible={isvisible} func={() => setisvisible(false)} id={typeId} type={type} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
        <table>
          <tr>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdDriveFileRenameOutline size={18} style={{ marginRight: '5px' }} />Deal Name </th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiBuildingLine size={18} style={{ marginRight: '5px' }} />Company</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><VscGraphLine size={18} style={{ marginRight: '5px' }} />Stage</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><AiTwotoneCalendar size={18} style={{ marginRight: '5px' }} />Closing Date</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiPhoneLine size={18} style={{ marginRight: '5px' }} />Phone</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><GoMail size={18} style={{ marginRight: '5px' }} />Email</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Deal Owner</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdPendingActions size={18} style={{ marginRight: '5px' }} />Actions</th>
          </tr>
          {data.map((d) => (
            <tr style={{ borderBottom: '1px solid #eaeded' }}>
              <td style={{ minWidth: '140px' }} className='active_link' onClick={() => detailfun(d)}>{texttruncater(d?.deal_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.lead_id?.contact_id?.contact_name)}</td>
              <td style={{ minWidth: '140px' }} className='active_link'>{texttruncater(d?.deal_stage)}</td>
              <td style={{ minWidth: '140px' }}>{moment(d?.closing_date).format('LL')}</td>
              <td style={{ minWidth: '140px' }}>{d?.lead_id?.contact_id?.phone}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.lead_id?.contact_id?.email)}</td>
              <td style={{ minWidth: '140px' }}> {texttruncater(d?.deal_owner?.name)}</td>
              <td style={{ minWidth: '140px' }}><FiEdit size={18} style={{ marginRight: '10px', marginLeft: '10px' }} onClick={() => editdealfun(d?.id)} /> <GiAlarmClock size={22} onClick={() => onReminderClick(d?.id || d?._id)} /> <BsCalendar2Week size={16} onClick={() => onMeetingClick(d)} style={{ marginLeft: '10px' }} /> </td>
            </tr>
          ))}
        </table>
      </div>
    )
  } else if (type === 'My Leads') {
    return (
      <div style={{ overflowX: 'auto', width: '100%' }} className="hidescroll_bar">
        <MeetingModalMobile visible={isvisible1} func={() => setisvisible1(false)} data_id={typeId} type={type} />
        <ReminderMobile visible={isvisible} func={() => setisvisible(false)} id={typeId} type={'Lead'} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
        <table>
          <tr>
            <th style={{ minWidth: '40px', maxWidth: '40px', color: 'transparent' }} className='radio_section1'>a</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdDriveFileRenameOutline size={18} style={{ marginRight: '5px' }} />Lead Name </th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiBuildingLine size={18} style={{ marginRight: '5px' }} />Company</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><GoMail size={18} style={{ marginRight: '5px' }} />Email</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiPhoneLine size={18} style={{ marginRight: '5px' }} />Phone</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><VscGraphLine size={18} style={{ marginRight: '5px' }} />Lead Status</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><FaSourcetree size={18} style={{ marginRight: '5px' }} />Lead Source</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Owner</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdPendingActions size={18} style={{ marginRight: '5px' }} />Actions</th>
          </tr>
          {data.map((d) => (
            <tr key={d._id} style={{ borderBottom: selected_lead !== d._id && '1px solid #eaeded' }} className={selected_lead === d._id && 'activetable'}>
              <th style={{ minWidth: '40px', maxWidth: '40px', background: 'transparent' }}>
                {selected_lead !== d._id ?
                  <ImRadioUnchecked size={16} color="#e6e6e6" onClick={() => setselected_leadfun(d._id)} /> :
                  <ImRadioChecked2 size={16} style={{ marginTop: '0px', marginLeft: '0px', color: '#0073ba' }} onClick={() => setselected_leadfun('')} />
                }
              </th>
              <td style={{ minWidth: '140px' }} className='active_link' onClick={() => detailfun(d)} >{texttruncater(d?.lead_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_id?.company_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_id?.email)}</td>
              <td style={{ minWidth: '140px' }}>{d?.contact_id?.phone}</td>
              <td style={{ minWidth: '140px' }} className='active_link'>{d?.lead_status?.split(' ')[0]}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_id?.contact_source?.source_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.lead_owner?.name)}</td>
              <td style={{ minWidth: '140px' }}><CgMoveRight size={24} style={{ marginRight: '10px', marginLeft: '5px', ...d.isConverted && { color: '#d4d4d4' } }} onClick={() => !d.isConverted && gotodeal(d)} /> <GiAlarmClock size={24} onClick={() => !d.isConverted && onReminderClick(d._id)} style={{ ...d.isConverted && { color: '#d4d4d4' } }} /> <BsCalendar2Week size={16} onClick={() => onMeetingClick(d)} style={{ marginLeft: '10px' }} /> </td>
            </tr>
          ))}
        </table>
      </div>
    )
  } else if (type === 'Leads Assigned To Me') {
    return (
      <div style={{ overflowX: 'auto', width: '100%' }} className="hidescroll_bar">
        <MeetingModalMobile visible={isvisible1} func={() => setisvisible1(false)} data_id={typeId} type={type} />
        <ReminderMobile visible={isvisible} func={() => setisvisible(false)} id={typeId} type={'Lead'} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
        <table>
          <tr>
            <th style={{ minWidth: '40px', maxWidth: '40px', color: 'transparent' }} className='radio_section1'>a</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdDriveFileRenameOutline size={18} style={{ marginRight: '5px' }} />Lead Name </th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Company</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><GoMail size={18} style={{ marginRight: '5px' }} />Email</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiPhoneLine size={18} style={{ marginRight: '5px' }} />Phone</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Assigned By</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><AiTwotoneCalendar size={18} style={{ marginRight: '5px' }} />Assigned Date</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><FaSourcetree size={18} style={{ marginRight: '5px' }} />Accept Status</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><BiMessageDetail size={18} style={{ marginRight: '5px' }} />Remarks</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdPendingActions size={18} style={{ marginRight: '5px' }} />Actions</th>
          </tr>
          {data.map((d) => (
            <tr key={d._id} style={{ borderBottom: selected_lead !== d._id && '1px solid #eaeded' }} className={selected_lead === d._id && 'activetable'}>
              <th style={{ minWidth: '40px', maxWidth: '40px', background: 'transparent' }}>
                {selected_lead !== d._id ?
                  <ImRadioUnchecked size={16} color="#e6e6e6" onClick={() => onAssignClick(d)} /> :
                  <ImRadioChecked2 size={16} style={{ marginTop: '0px', marginLeft: '0px', color: '#0073ba' }} onClick={() => setselected_leadfun('')} />
                }
              </th>
              <td style={{ minWidth: '140px', color: d.accept !== 'Accepted' && 'black', fontWeight: d.accept !== 'Accepted' && '800' }} className={d.accept === 'Accepted' && 'active_link'} onClick={() => d.accept === 'Accepted' && detailfun(d?.assigned_lead_id?.lead_id?.id)}>{texttruncater(d?.assigned_lead_id?.lead_id?.lead_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.assigned_lead_id?.lead_id?.contact_id?.company_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.assigned_lead_id?.lead_id?.contact_id?.email)}</td>
              <td style={{ minWidth: '140px' }}>{d?.assigned_lead_id?.lead_id?.contact_id?.phone}</td>
              <td style={{ minWidth: '140px' }}>{d?.assigned_by?.name}</td>
              <td style={{ minWidth: '140px' }}>{moment(d?.createdAt).format('LL')}</td>
              <td style={{ minWidth: '140px' }}><Tag color={(d.accept === 'Pending' && '#108ee9') || (d.accept === 'Accepted' && '#87d068') || (d.accept === 'Rejected' && '#f50')}>{d.accept}</Tag></td>
              <td style={{ minWidth: '140px', alignItems: 'center', display: 'grid', textAlign: 'center', }} onClick={() => remarkfunc(d?.remarks)}><BiMessageDetail size={24} /></td>
              <td style={{ minWidth: '140px', marginLeft: '15px' }}>
                {
                  (d.accept === 'Pending') &&
                  <>
                    <TiTick size={22} style={{ color: 'black' }} onClick={() => acceptassignedlead(d, 1)} />
                    <IoMdClose size={20} style={{ marginLeft: '20px', color: 'black' }} onClick={() => acceptassignedlead(d, 2)} />
                  </>
                }
                {
                  (d.accept === 'Accepted') &&
                  <>
                    <CgMoveRight size={28} style={{ color: d?.assigned_lead_id?.lead_id?.isConverted ? '#ebebeb' : 'black' }} onClick={() => !d?.assigned_lead_id?.lead_id?.isConverted && assigned_lead_to_deal(d)} />
                    <GiAlarmClock size={20} style={{ marginLeft: '10px', marginRight: '10px', color: d?.assigned_lead_id?.lead_id?.isConverted ? '#ebebeb' : 'black' }} onClick={() => !d?.assigned_lead_id?.lead_id?.isConverted && onReminderClick(d?.assigned_lead_id?.lead_id?.id)} />
                    <BsCalendar2Week size={16} onClick={() => !d?.assigned_lead_id?.lead_id?.isConverted && onMeetingClick(d)}  style={{ color: d?.assigned_lead_id?.lead_id?.isConverted ? '#ebebeb' : 'black' }} />
                  </>
                }
              </td>
            </tr>
          ))}
        </table>
      </div>
    )
  } else if (type === 'Leads Assigned By Me') {
    return (
      <div style={{ overflowX: 'auto', width: '100%' }} className="hidescroll_bar">
        <table>
          <tr>
            <th style={{ minWidth: '30px', maxWidth: '30px', color: 'transparent' }} className='radio_section1'>a</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdDriveFileRenameOutline size={18} style={{ marginRight: '5px' }} />Lead Name </th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiBuildingLine size={18} style={{ marginRight: '5px' }} />Company</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><GoMail size={18} style={{ marginRight: '5px' }} />Email</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiPhoneLine size={18} style={{ marginRight: '5px' }} />Phone</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Assigned To</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><AiTwotoneCalendar size={18} style={{ marginRight: '5px' }} />Assigned Date</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><SiStatuspal size={18} style={{ marginRight: '5px' }} />Accept Status</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdPendingActions size={18} style={{ marginRight: '5px' }} />Track Status</th>
          </tr>
          {data.map((d) => (
            <tr key={d._id} style={{ borderBottom: selected_lead !== d._id && '1px solid #eaeded' }} className={selected_lead === d._id && 'activetable'} >
              <th style={{ minWidth: '30px', maxWidth: '30px', background: 'transparent' }}>
                {/* {selected_lead !== d._id ? 
              <ImRadioUnchecked size={16} color="#b0b0b0" onClick={()=>setselected_leadfun(d._id)}/> :
              <ImRadioChecked2 size={16} style={{marginTop:'0px',marginLeft:'0px',color:'#0073ba'}} onClick={()=>setselected_leadfun('')}/> 
              } */}
              </th>
              <td style={{ minWidth: '140px' }} onClick={() => detailfun(d?.assigned_lead_id?.lead_id?.id)} className='active_link'>{texttruncater(d?.assigned_lead_id?.lead_id?.lead_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.assigned_lead_id?.lead_id?.contact_id?.company_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.assigned_lead_id?.lead_id?.contact_id?.email)}</td>
              <td style={{ minWidth: '140px' }} >{d?.assigned_lead_id?.lead_id?.contact_id?.phone}</td>
              <td style={{ minWidth: '140px' }} className='active_link'>{texttruncater(d?.user_id?.name)}</td>
              <td style={{ minWidth: '140px' }}>{moment(d?.createdAt).format('LL')}</td>
              <td style={{ minWidth: '140px' }}><Tag color={d?.accept === 'Accepted' ? '#ec7311' : '#0080ff'}>{d?.accept}</Tag></td>
              <td style={{ minWidth: '140px' }} onClick={() => tracker(d)}><MdOutlineFollowTheSigns size={24} style={{ marginRight: '10px', marginLeft: '10px' }} />Track</td>
            </tr>
          ))}
        </table>
      </div>
    )
  } else if (type === 'Meeting') {
    return (
      <div style={{ overflowX: 'auto', width: '100%' }} className="hidescroll_bar">
        <ReminderMobile visible={isvisible} func={() => setisvisible(false)} id={typeId} type={type} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
        <table>
          <tr>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdDriveFileRenameOutline size={18} style={{ marginRight: '5px' }} />Title </th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><AiTwotoneCalendar size={18} style={{ marginRight: '5px' }} />Date</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><FaRegClock size={18} style={{ marginRight: '5px' }} />Time</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><FaListOl size={18} style={{ marginRight: '5px' }} />Related To</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Contact Name</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdEditLocationAlt size={18} style={{ marginRight: '5px' }} />Location</th>
            <th style={{ minWidth: '100px', color: 'black', fontWeight: '900' }}><MdPendingActions size={18} style={{ marginRight: '5px' }} />Action</th>
          </tr>
          {data.map((d) => (
            <tr key={d.id} style={{ borderBottom: '1px solid #eaeded' }}>
              <td style={{ minWidth: '140px' }} className='active_link'>{texttruncater(d.title)}</td>
              <td style={{ minWidth: '140px' }}>{moment(d.date_time).format('LL')}</td>
              <td style={{ minWidth: '140px' }}>{moment(d.date_time).format('LT')}</td>
              <td style={{ minWidth: '140px' }}>{d.related_to}</td>
              <td style={{ minWidth: '140px' }}>{d.related_to === 'Lead' && <SiHandshake size={16} style={{ marginRight: '10px', color: '#ff9a3b' }} />} {d.related_to === 'Lead' && texttruncater(d?.contact_name?.lead_name)} {d.related_to === 'Deal' && <IoWallet size={18} style={{ marginRight: '10px', color: '#31d45f' }} />} {d.related_to === 'Deal' && texttruncater(d?.contact_name?.deal_name)} {d.related_to === 'Contact' && <MdPermContactCalendar size={18} style={{ marginRight: '5px', color: '#3badff' }} />} {d.related_to === 'Contact' && texttruncater(d?.contact_name?.contact_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d.location)}</td>
              <td style={{ minWidth: '100px' }}><GiAlarmClock size={20} style={{ marginRight: '15px', color: 'black', marginLeft: '5px' }} onClick={() => onReminderClick(d.id)} /><FiEdit size={18} style={{ color: 'black' }} onClick={() => editmeeting(d)} /></td>
            </tr>
          ))}
        </table>
      </div>
    )
  } else if (type === 'Contact1') {
    return (
      <div >
        <div className="hidescroll_bar" style={{ overflowX: 'auto', width: '100%' }}>
          <table style={{ height: '100%' }}>
            <tr>
              <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiBuildingLine size={18} style={{ marginRight: '5px', color: 'black' }} />Company </th>
              <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px', color: 'black' }} />Contact Name</th>
              <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiPhoneLine size={18} style={{ marginRight: '5px', color: 'black' }} />Phone</th>
              <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><GoMail size={18} style={{ marginRight: '5px', color: 'black' }} />Email</th>
              <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><HiShieldCheck size={18} style={{ marginRight: '5px', color: 'black' }} />Designation</th>
              <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><FaSourcetree size={18} style={{ marginRight: '5px', color: 'black' }} />Source</th>
              <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><SiStatuspal size={18} style={{ marginRight: '5px', color: 'black' }} />Status</th>
              <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px', color: 'black' }} />Owner</th>
            </tr>
            {data.map((d) => (
              <tr style={{ borderBottom: '1px solid #eaeded' }} >

                <td style={{ minWidth: '140px' }} className='active_link' onClick={() => detailfun(d)}>{texttruncater(d?.company_name)}</td>
                <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_name)}</td>
                <td style={{ minWidth: '140px' }}>{d?.phone}</td>
                <td style={{ minWidth: '140px' }}>{texttruncater(d?.email)}</td>
                <td style={{ minWidth: '140px' }}>{texttruncater(d?.designation)}</td>
                <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_source?.source_name)}</td>
                <td style={{ minWidth: '140px' }}>
                  {d?.status === 'Connected' && <Tag icon={<FaRegHandshake />} color="#2ea1ff"> {d?.status} </Tag>}
                  {d?.status === 'Active' && <Tag icon={<FaRegHandshake />} color="#ff773d"> {d?.status} </Tag>}
                  {d?.status === 'In Active' && <Tag icon={<FaRegHandshake />} color="#ffc4ab"> {d?.status} </Tag>}
                  {d?.status === 'Not Connected' && <Tag icon={<FaRegHandshake />} color="#b8dfff"> {d?.status} </Tag>}
                  {d?.status === 'Old Client' && <Tag icon={<FaRegHandshake />} color="#3bffeb"> {d?.status} </Tag>}
                </td>
                <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_owner?.name)}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>

    )
  } else if (type === 'Leads1') {
    return (
      <div style={{ overflowX: 'auto', width: '100%' }} className="hidescroll_bar">
        <table style={{ height: '100%' }}>
          <tr>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdDriveFileRenameOutline size={18} style={{ marginRight: '5px' }} />Lead Name </th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiBuildingLine size={18} style={{ marginRight: '5px' }} />Company</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><GoMail size={18} style={{ marginRight: '5px' }} />Email</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiPhoneLine size={18} style={{ marginRight: '5px' }} />Phone</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><VscGraphLine size={18} style={{ marginRight: '5px' }} />Lead Status</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><FaSourcetree size={18} style={{ marginRight: '5px' }} /> Source</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Owner</th>
          </tr>
          {data.map((d) => (
            <tr key={d._id} style={{ borderBottom: '1px solid #eaeded' }}>
              <td style={{ minWidth: '140px' }} className='active_link' onClick={() => detailfun(d)}>{texttruncater(d?.lead_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_id?.company_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_id?.email)}</td>
              <td style={{ minWidth: '140px' }}>{d?.contact_id?.phone}</td>
              <td style={{ minWidth: '140px' }} className='active_link'>{d?.lead_status?.split(' ')[0]}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_id?.contact_source?.source_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_id?.contact_owner?.name)}</td>
            </tr>
          ))}
        </table>
      </div>
    )
  } else if (type === 'Deals1') {
    return (
      <div style={{ overflowX: 'auto', width: '100%' }} className="hidescroll_bar">
        <table style={{ height: '100%' }}>
          <tr>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdDriveFileRenameOutline size={18} style={{ marginRight: '5px' }} />Deal Name </th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiBuildingLine size={18} style={{ marginRight: '5px' }} />Company</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><VscGraphLine size={18} style={{ marginRight: '5px' }} />Stage</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><GoMail size={18} style={{ marginRight: '5px' }} />Closing Date</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiPhoneLine size={18} style={{ marginRight: '5px' }} />Phone</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><GoMail size={18} style={{ marginRight: '5px' }} />Email</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Deal Owner</th>
          </tr>
          {data.map((d) => (
            <tr style={{ borderBottom: '1px solid #eaeded' }}>
              <td style={{ minWidth: '140px' }} className='active_link' onClick={() => detailfun(d)}>{texttruncater(d?.deal_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.lead_id?.contact_id?.contact_name)}</td>
              <td style={{ minWidth: '140px' }} className='active_link'>{texttruncater(d?.deal_stage)}</td>
              <td style={{ minWidth: '140px' }}>{moment(d?.closing_date).format('LL')}</td>
              <td style={{ minWidth: '140px' }}>{d?.lead_id?.contact_id?.phone}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.lead_id?.contact_id?.email)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.deal_owner?.name)}</td>
            </tr>
          ))}
        </table>
      </div>
    )
  } else if (type === 'assignleads') {
    return (
      <div style={{ overflowX: 'auto', width: '100%' }} className="hidescroll_bar">
        <ReminderMobile visible={isvisible} func={() => setisvisible(false)} id={typeId} type={'Lead'} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
        <table>
          <tr>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdDriveFileRenameOutline size={18} style={{ marginRight: '5px' }} />Lead Name </th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Company</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><GoMail size={18} style={{ marginRight: '5px' }} />Email</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiPhoneLine size={18} style={{ marginRight: '5px' }} />Phone</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Assigned By</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Assigned To</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><BiMessageDetail size={18} style={{ marginRight: '5px' }} />Remarks</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdPendingActions size={18} style={{ marginRight: '5px' }} />Actions</th>
          </tr>
          {data.map((d) => (
            <tr key={d._id} style={{ borderBottom: selected_lead !== d._id && '1px solid #eaeded' }} className={selected_lead === d._id && 'activetable'}>
              <td style={{ minWidth: '140px', color: d.accept !== 'Accepted' && 'black', fontWeight: d.accept !== 'Accepted' && '800' }} className={d.accept === 'Accepted' && 'active_link'} onClick={() => d.accept === 'Accepted' && detailfun(d?.lead_id?.id)}>{texttruncater(d?.lead_id?.lead_name)}</td>
              <td style={{ minWidth: '140px' }}>{d?.lead_id?.contat_id !== undefined && d?.lead_id?.contat_id[0]?.company_name}</td>
              <td style={{ minWidth: '140px' }}>{d?.lead_id?.contat_id !== undefined && d?.lead_id?.contat_id[0]?.email}</td>
              <td style={{ minWidth: '140px' }}>{d?.lead_id?.contat_id !== undefined && d?.lead_id?.contat_id[0]?.phone}</td>
              <td style={{ minWidth: '140px' }}>{d?.assigned_by?.name}</td>
              <td style={{ minWidth: '140px' }}>{d?.user_id?.name}</td>
              <td style={{ minWidth: '140px', alignItems: 'center', display: 'grid', textAlign: 'center', }} onClick={() => remarkfunc(d?.remarks)}><BiMessageDetail size={24} /></td>
              <td style={{ minWidth: '140px' }} onClick={() => tracker(d?.lead_id?._id)}><MdOutlineFollowTheSigns size={24} style={{ marginRight: '10px', marginLeft: '10px' }} onClick={() => tracker(d?.lead_id?._id)} />Track</td>
            </tr>
          ))}
        </table>
      </div>
    )
  } else if (type === 'Leads2') {
    return (
      <div style={{ overflowX: 'auto', width: '100%' }} className="hidescroll_bar">
        <table style={{ height: '100%' }}>
          <tr>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdDriveFileRenameOutline size={18} style={{ marginRight: '5px' }} />Lead Name </th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiBuildingLine size={18} style={{ marginRight: '5px' }} />Company</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><GoMail size={18} style={{ marginRight: '5px' }} />Email</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiPhoneLine size={18} style={{ marginRight: '5px' }} />Phone</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><VscGraphLine size={18} style={{ marginRight: '5px' }} />Lead Status</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} /> Cont Person</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Lead Owner</th>
          </tr>
          {data?.map((d) => (
            <tr key={d._id} style={{ borderBottom: '1px solid #eaeded' }}>
              <td style={{ minWidth: '140px' }} className='active_link' onClick={() => detailfun(d)}>{texttruncater(d?.lead_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_id?.company_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_id?.email)}</td>
              <td style={{ minWidth: '140px' }}>{d?.contact_id?.phone}</td>
              <td style={{ minWidth: '140px' }} className='active_link'>{d?.lead_status?.split(' ')[0]}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.contact_id?.contact_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.lead_owner?.name)}</td>
            </tr>
          ))}
        </table>
      </div>
    )
  } else if (type === 'assignleads1') {
    return (
      <div style={{ overflowX: 'auto', width: '100%' }} className="hidescroll_bar">
        <ReminderMobile visible={isvisible} func={() => setisvisible(false)} id={typeId} type={'Lead'} reminder={reminder} getreminderfun={(v) => getreminderfun(v)} />
        <table>
          <tr>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdDriveFileRenameOutline size={18} style={{ marginRight: '5px' }} />Lead Name </th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Company</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><GoMail size={18} style={{ marginRight: '5px' }} />Email</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><RiPhoneLine size={18} style={{ marginRight: '5px' }} />Phone</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Assigned By</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdOutlinePersonOutline size={18} style={{ marginRight: '5px' }} />Assigned To</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><BiMessageDetail size={18} style={{ marginRight: '5px' }} />Remarks</th>
            <th style={{ minWidth: '140px', color: 'black', fontWeight: '900' }}><MdPendingActions size={18} style={{ marginRight: '5px' }} />Actions</th>
          </tr>
          {data.map((d) => (
            <tr key={d._id} style={{ borderBottom: selected_lead !== d._id && '1px solid #eaeded' }} className={selected_lead === d._id && 'activetable'}>
              <td style={{ minWidth: '140px', color: d.accept !== 'Accepted' && 'black', fontWeight: d.accept !== 'Accepted' && '800' }} className={d.accept === 'Accepted' && 'active_link'} onClick={() => d.accept === 'Accepted' && detailfun(d?.lead_id?.id)}>{texttruncater(d?.lead_id?.contact_id?.company_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.lead_id?.contact_id?.company_name)}</td>
              <td style={{ minWidth: '140px' }}>{texttruncater(d?.lead_id?.contact_id?.email)}</td>
              <td style={{ minWidth: '140px' }}>{d?.lead_id?.contact_id?.phone}</td>
              <td style={{ minWidth: '140px' }}>{d?.assigned_by?.name}</td>
              <td style={{ minWidth: '140px' }}>{d?.user_id?.name}</td>
              <td style={{ minWidth: '140px', alignItems: 'center', display: 'grid', textAlign: 'center', }} onClick={() => remarkfunc(d?.remarks)}><BiMessageDetail size={24} /></td>
              <td style={{ minWidth: '140px' }} onClick={() => tracker(d?.lead_id?._id)}><MdOutlineFollowTheSigns size={24} style={{ marginRight: '10px', marginLeft: '10px' }} onClick={() => tracker(d?.lead_id?._id)} />Track</td>
            </tr>
          ))}
        </table>
      </div>
    )
  }
}

export default TableView1

