import React, { useState, useEffect } from 'react'
import DeveloperMain from '../DeveloperMain'
import './KrishnaDashboardstyle.css'
import { Checkbox, Select, DatePicker, Row, Col } from 'antd';

import {  MdPermContactCalendar } from 'react-icons/md'
import { BsArrowDownShort } from 'react-icons/bs'
import { HiOutlineFilter } from 'react-icons/hi'
import { IoReload } from 'react-icons/io5'
import { FaHandshake, FaCompress, FaExpand } from 'react-icons/fa'
import { RiMoneyDollarBoxFill } from 'react-icons/ri'
import { authHeader } from '../../../helpers/usertoken/UserToken';
import DateField from '../../../components/inputcomponents/DateField';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast'



import moment from 'moment'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import Texttruncate from '../../../components/TextTruncater/Texttruncate';

function Reportgeneration() {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [departement_arr, setdepartement_arr] = useState([])
  const [employee, setemployee] = useState([])


  const [today_report, settoday_report] = useState()
  const [report_data, setreport_data] = useState()


  const [selected_team, setselected_team] = useState({})


  const [filter, setfilter] = useState({ type: '', department_id: '', employee_id: '', from_date: '', to_date: '', status: false })

  const [options, setoptions] = useState({ contact: '', lead: '', deal: '' })


  const [sheets_list, setsheets_list] = useState()
  const [sheets_list1, setsheets_list1] = useState()
  const [datas, setdatas] = useState()
  const [datas1, setdatas1] = useState()

  // const [report_data, setreport_data] = useState({ sheets_list: [], datas: [] })

  // const [print, setprint] = useState(false)

  const [contact, setcontact] = useState('')
  const [lead, setlead] = useState('')
  const [deal, setdeal] = useState('')




  const [download_type, setdownload_type] = useState({ count: true, data: false, both: false })

  // let sheets_list ,datas

  useEffect(() => {
    getDepartements()
    todaycld()
    getcontacts()
    getleads()
    getdeals()
  }, [])


  function getcontacts() {
    axios.get(`contact/getallcontacts/${1}`, authHeader)
      .then((res) => {
        setcontact(res?.data?.pagination?.total)
        // setcontacts(res.data.datas)
        // setpagination(res.data.pagination)
      })
  }

  function getleads() {
    axios.get(`lead/getallleads/${1}`, authHeader)
      .then((res) => {
        // setleads(res.data.datas)
        setlead(res?.data?.pagination?.total)
        // setpagination(res.data.pagination)
      })
  }

  function getdeals() {
    axios.get(`deal/getalldeals/${1}`, authHeader)
      .then((res) => {
        setdeal(res?.data?.pagination?.total)

        // setdeals(res.data.datas)
        // setpagination(res.data.pagination)
      })
  }



  function getDepartements() {
    axios.get('department/get', authHeader)
      .then((res) => {
        const departement_arr_created = []
        res.data.datas.forEach(d => {
          let data = {}
          data['label'] = d.department_name
          data['value'] = d.department_name
          data['id'] = d.id
          departement_arr_created.push(data)
        })
        setdepartement_arr(departement_arr_created)

      })
  }

  function getusersbydepartmentId(v) {
    axios.get(`user/get-users-by-departmentId/${v}`, authHeader)
      .then((res) => {
        let temp_Arr = []
        res.data.datas.map((d) => {
          let obj = {}
          obj['label'] = d.name
          obj['value'] = d.name
          obj['id'] = d._id
          let des = ''
          d.user_roles.forEach((u) => {
            des = des + u.roles[0].display_name + ','
          })
          obj['label'] = obj['label'] + `(${des})`
          obj['value'] = obj['value']
          temp_Arr.push(obj)
        })
        setemployee(temp_Arr)
      }).catch((err) => {
      })
  }

  function todaycld() {
    axios.get(`crm_report/today_report`, authHeader)
      .then((res) => {
        settoday_report(res?.data?.data)
        // console.log('res.data', res.data.data)
      }).catch(err => {
      })
  }


  function applyfilterdata() {
    let str = ''
    if (options.contact) {
      str = 'contact'
    }
    if (options.lead) {
      str = str.length > 0 ? str + ',lead' : 'lead'
    }
    if (options.deal) {
      str = str.length > 0 ? str + ',deal' : 'deal'
    }

    if (str.length === 0) {
      toast.error('Select contact lead deal before you apply filter')
    } else {
      setfilter({ ...filter, status: true })
      // console.log('str here na', str)
      // console.log('filter', filter)
      axios.get(`crm_report/filter_report?type=${str}&department_id=${filter.department_id}&employee_id=${filter.employee_id}&from_date=${filter.from_date}&to_date=${filter.to_date}`, authHeader)
        .then((res) => {
          setreport_data(res.data.data)
          // console.log('res.data here', res.data)
        }).catch((err) => {
          // console.log('err.data here', err.response.data)
        })
    }
  }

  function resetfilterdata() {
    setfilter({ type: '', department_id: '', employee_id: '', from_date: '', to_date: '', status: false })
    setreport_data()
  }


  async function downloadreport(v) {
    const sheets_list = []
    const datas_wb = []

    const sheets_list_1 = ['Contacts', 'Leads', 'Deals']
    const datas_wb_1 = []


    if (v !== '1') {
      return new Promise(async (resolve, reject) => {
        for (let i = 0; i < departement_arr.length; i++) {
          let d = departement_arr[i]
          await axios.get(`user/get-users-by-departmentId/${d.id}`, authHeader)
            .then((res) => {
              if (download_type?.count) {
                sheets_list.push(`${d.value}`)
                let csvData = []

                for (let i = 0; i < res.data.datas.length; i++) {
                  let e = res.data.datas[i]
                  let obj = {
                    'Name': e?.name,
                    'Contact': report_data?.contacts?.filter(d => d?.contact_owner?._id === e._id)?.length,
                    'Lead': report_data?.leads?.filter(d => d?.lead_owner?._id === e?._id)?.length,
                    'Deal': report_data?.deals?.filter(d => d?.deal_owner?._id === e?._id)?.length
                  }
                  csvData.push(obj)
                }
                datas_wb.push(csvData)
              }
            })
        }
        setsheets_list(sheets_list)
        setdatas(datas_wb)


        console.log("report_data?.leads?.length ",report_data?.leads?.length)
       console.log("report_data",report_data)
        if (download_type?.data) {
          let contact_data_list = []
          let lead_data_list = []
          let deal_data_list = []
          // console.log('iam in loop for contacts')
          if (report_data?.contacts?.length === 0) {
            let obj = {
              contact_owner: null,
              company_name: null,
              contact_name: null,
              phone: null,
              email: null,
              designation: null,
              contact_source: null,
              website: null,
              industry: null,
              company_phone: null,
              company_email: null,
              secondary_contact_person: null,
              secondary_contact_phone: null,
              secondary_contact_designation: null,
              status: null,
              street: null,
              city: null,
              state: null,
              country: null,
              pin_code: null,
              description: null,
              isConverted: null,
              _id: null,
            }
            contact_data_list.push(obj)
            datas_wb_1.push(contact_data_list)
          } else {
            for (let i = 0; i < report_data?.contacts?.length; i++) {
              // console.log('iam here in', i)
              let d = report_data.contacts[i]
              let obj = {
                contact_owner: d?.contact_owner?.name,
                company_name: d?.company_name,
                contact_name: d?.contact_name,
                phone: d?.phone,
                email: d?.email,
                designation: d?.designation,
                contact_source: d?.contact_source,
                website: d?.website,
                industry: d?.industry,
                company_phone: d?.company_phone,
                company_email: d?.company_email,
                secondary_contact_person: d?.secondary_contact_person,
                secondary_contact_phone: d?.secondary_contact_phone,
                secondary_contact_designation: d?.secondary_contact_designation,
                status: d?.status,
                street: d?.street,
                city: d?.city,
                state: d?.state,
                country: d?.country,
                pin_code: d?.pin_code,
                description: d?.description,
                isConverted: d?.isConverted,
                _id: d?._id,
              }
              contact_data_list.push(obj)
            }
            datas_wb_1.push(contact_data_list)
          }
          if (report_data?.leads?.length === 0) {
            let obj = {
              lead_owner: null,
              lead_title: null,
              lead_name: null,
              measurement: null,
              requirement: null,
              zone: null,
              description: null,
              remark: null,
              contact_id: null,
              isConverted: null,
              _id: null,
            }
            lead_data_list.push(obj)
            datas_wb_1.push(lead_data_list)


          } else {
            for (let i = 0; i < report_data?.leads?.length; i++) {
              let d = report_data.leads[i]
              let obj = {
                lead_owner: d?.lead_owner?.name,
                lead_title: d?.lead_title,
                lead_name: d?.lead_name,
                measurement: d?.measurement,
                requirement: d?.requirement,
                zone: d?.zone,
                description: d?.description,
                remark: d?.remark,
                contact_id: d?.contact_id,
                isConverted: d?.isConverted,
                _id: d?._id,
              }
              lead_data_list.push(obj)
              // datas_wb_1.push(deal_data_list)
            }
            datas_wb_1.push(lead_data_list)
          }
          if (report_data?.deals?.length === 0) {
            let obj = {
              deal_owner: null,
              deal_name: null,
              deal_title: null,
              closing_date: null,
              deal_stage: null,
              deal_type: null,
              description: null,
              lead_id: null,
              _id: null
            }
            deal_data_list.push(obj)
            datas_wb_1.push(deal_data_list)
          } else {
            for (let i = 0; i < report_data?.deals?.length; i++) {
              let d = report_data.deals[i]
              let obj = {
                deal_owner: d?.deal_owner?._id,
                deal_name: d?.deal_name,
                deal_title: d?.deal_title,
                closing_date: d?.closing_date,
                deal_stage: d?.deal_stage,
                deal_type: d?.deal_type,
                description: d?.description,
                lead_id: d?.lead_id?._id,
                _id: d?._id
              }
              deal_data_list.push(obj)
            }
            datas_wb_1.push(deal_data_list)
          }
        }



        setsheets_list1(sheets_list_1)
        setdatas1(datas_wb_1)
        // if(sheets_list !)
        resolve({ sheets_list, datas_wb, sheets_list_1, datas_wb_1 })

      })
    } else {
      return new Promise(async (resolve, reject) => {
        for (let i = 0; i < departement_arr.length; i++) {
          let d = departement_arr[i]
          await axios.get(`user/get-users-by-departmentId/${d.id}`, authHeader)
            .then((res) => {
              if (download_type?.count) {
                sheets_list.push(`${d.value}`)
                let csvData = []

                for (let i = 0; i < res.data.datas.length; i++) {
                  let e = res.data.datas[i]
                  let obj = {
                    'Name': e?.name,
                    'Contact': today_report?.contacts?.filter(d => d?.contact_owner?._id === e._id)?.length,
                    'Lead': today_report?.leads?.filter(d => d?.lead_owner?._id === e?._id)?.length,
                    'Deal': today_report?.deals?.filter(d => d?.deal_owner?._id === e?._id)?.length
                  }
                  csvData.push(obj)
                }
                datas_wb.push(csvData)
              }
            })
        }
        setsheets_list(sheets_list)
        setdatas(datas_wb)


        if (download_type?.data) {
          let contact_data_list = []
          let lead_data_list = []
          let deal_data_list = []
          // console.log('iam in loop for contacts')
          if (today_report?.contacts?.length === 0) {
            let obj = {
              contact_owner: null,
              company_name: null,
              contact_name: null,
              phone: null,
              email: null,
              designation: null,
              contact_source: null,
              website: null,
              industry: null,
              company_phone: null,
              company_email: null,
              secondary_contact_person: null,
              secondary_contact_phone: null,
              secondary_contact_designation: null,
              status: null,
              street: null,
              city: null,
              state: null,
              country: null,
              pin_code: null,
              description: null,
              isConverted: null,
              _id: null,
            }
            contact_data_list.push(obj)
            datas_wb_1.push(contact_data_list)
          } else {
            for (let i = 0; i < today_report?.contacts?.length; i++) {
              console.log('iam here in', i)
              let d = today_report.contacts[i]
              let obj = {
                contact_owner: d?.contact_owner?._id,
                company_name: d?.company_name,
                contact_name: d?.contact_name,
                phone: d?.phone,
                email: d?.email,
                designation: d?.designation,
                contact_source: d?.contact_source,
                website: d?.website,
                industry: d?.industry,
                company_phone: d?.company_phone,
                company_email: d?.company_email,
                secondary_contact_person: d?.secondary_contact_person,
                secondary_contact_phone: d?.secondary_contact_phone,
                secondary_contact_designation: d?.secondary_contact_designation,
                status: d?.status,
                street: d?.street,
                city: d?.city,
                state: d?.state,
                country: d?.country,
                pin_code: d?.pin_code,
                description: d?.description,
                isConverted: d?.isConverted,
                _id: d?._id,
              }
              contact_data_list.push(obj)
            }
            console.log('iam here in', contact_data_list)
            datas_wb_1.push(contact_data_list)
          }
          if (today_report?.leads?.length === 0) {
            let obj = {
              lead_owner: null,
              lead_title: null,
              lead_name: null,
              measurement: null,
              requirement: null,
              zone: null,
              description: null,
              remark: null,
              contact_id: null,
              isConverted: null,
              _id: null,
            }
            lead_data_list.push(obj)
            datas_wb_1.push(lead_data_list)

          } else {
            for (let i = 0; i < today_report?.leads?.length; i++) {
              let d = today_report.leads[i]
              let obj = {
                lead_owner: d?.lead_owner?._id,
                lead_title: d?.lead_title,
                lead_name: d?.lead_name,
                measurement: d?.measurement,
                requirement: d?.requirement,
                zone: d?.zone,
                description: d?.description,
                remark: d?.remark,
                contact_id: d?.contact_id,
                isConverted: d?.isConverted,
                _id: d?._id,
              }
              lead_data_list.push(obj)
              datas_wb_1.push(deal_data_list)
            }
            datas_wb_1.push(lead_data_list)
          }
          if (today_report?.deals?.length === 0) {
            let obj = {
              deal_owner: null,
              deal_name: null,
              deal_title: null,
              closing_date: null,
              deal_stage: null,
              deal_type: null,
              description: null,
              lead_id: null,
              _id: null
            }
            deal_data_list.push(obj)
            datas_wb_1.push(deal_data_list)

          } else {
            for (let i = 0; i < today_report?.deals?.length; i++) {
              let d = today_report.deals[i]
              let obj = {
                deal_owner: d?.deal_owner?._id,
                deal_name: d?.deal_name,
                deal_title: d?.deal_title,
                closing_date: d?.closing_date,
                deal_stage: d?.deal_stage,
                deal_type: d?.deal_type,
                description: d?.description,
                lead_id: d?.lead_id?._id,
                _id: d?._id
              }
              deal_data_list.push(obj)
              datas_wb_1.push(deal_data_list)
            }
            datas_wb_1.push(deal_data_list)
          }
        }





        setsheets_list1(sheets_list_1)
        setdatas1(datas_wb_1)
        // if(sheets_list !)
        resolve({ sheets_list, datas_wb, sheets_list_1, datas_wb_1 })

      })
    }



  }

  async function print_report(v) {
    // console.log('sheets_list 11', sheets_list1, 'datas1', datas1, 'sheets_list', sheets_list, 'datas', datas)

    await downloadreport(v)
      .then((res) => {
        if (download_type.count) {
          const wb = XLSX.utils.book_new();
          for (let i = 0; i < res?.sheets_list?.length; i++) {
            let t = XLSX.utils.json_to_sheet(res?.datas_wb[i])
            XLSX.utils.book_append_sheet(wb, t, res?.sheets_list[i]);
          }

          const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, `CRM REPORT (${moment(new Date()).format('lll')})`);
        }

        console.log("res?.sheets_list_1",res?.sheets_list_1)
        console.log("res?.datas_wb_1",res?.datas_wb_1)

        if (download_type.data) {
          const wb1 = XLSX.utils.book_new();
          for (let i = 0; i < res?.sheets_list_1?.length; i++) {
            let t = XLSX.utils.json_to_sheet(res?.datas_wb_1[i])
            XLSX.utils.book_append_sheet(wb1, t, res?.sheets_list_1[i]);
          }

          const excelBuffer1 = XLSX.write(wb1, { bookType: 'xlsx', type: 'array' });
          const data1 = new Blob([excelBuffer1], { type: fileType });
          FileSaver.saveAs(data1, `CRM REPORT Data (${moment(new Date()).format('lll')})`);
        }

        // console.log('res', res)
        // console.log('sheets_list1', sheets_list1, 'datas1', datas1, 'sheets_list', sheets_list, 'datas', datas)
      })






  }

  async function handleChange({ key, value }) {
    setfilter({ ...filter, [key]: value })
    // console.log('handleChange', key, value)
  }





  return (
    <DeveloperMain>
      <Toaster/>
      <div style={{ width: '94%', margin: 'auto', marginBottom: '100px' }}>

        <div>
          <div style={{marginTop:'30px'}}>
            
            <h4 className="crm_report_heading">CRM REPORT GENERATOR</h4>
          </div>
        </div>


        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', marginTop: '20px', borderTop: '1px solid #f2f2f2', paddingTop: '10px' }}>
          <div>
            <h6 style={{ fontSize: '13px', fontWeight: 'bolder', textTransform: 'uppercase' }}>Company CLD</h6>
            <div style={{ display: 'flex', borderTop: '1px solid #f7f7f7', borderBottom: '1px solid #f7f7f7' }}>
              <div style={{ padding: '10px 0px', minWidth: '140px', display: 'flex', alignItems: 'center', color: '#2be36b' }}>
                <MdPermContactCalendar size={20} />
                <h6 className='count_rg' >  {contact}</h6>
              </div>
              <div style={{ padding: '10px 0px', minWidth: '140px', display: 'flex', alignItems: 'center', color: '#4273bd' }}>
                <FaHandshake size={20} />
                <h6 className='count_rg' >{lead}</h6>
              </div>
              <div style={{ padding: '10px 0px', minWidth: '140px', display: 'flex', alignItems: 'center', color: '#d16241' }}>
                <RiMoneyDollarBoxFill size={20} />
                <h6 className='count_rg'>{deal}</h6>
              </div>
            </div>
          </div>

          <div>
            <h6 style={{ fontSize: '13px', fontWeight: 'bolder', textTransform: 'uppercase' }}>Today CLD</h6>
            <div style={{ display: 'flex', borderTop: '1px solid #f7f7f7', borderBottom: '1px solid #f7f7f7' }}>
              <div style={{ padding: '10px 0px', minWidth: '140px', display: 'flex', alignItems: 'center', color: '#2be36b' }}>
                <MdPermContactCalendar size={20} />
                <h6 className='count_rg' >{today_report?.count?.contact_count}</h6>
              </div>
              <div style={{ padding: '10px 0px', minWidth: '140px', display: 'flex', alignItems: 'center', color: '#4273bd' }}>
                <FaHandshake size={20} />
                <h6 className='count_rg' >{today_report?.count?.lead_count}</h6>
              </div>
              <div style={{ padding: '10px 0px', minWidth: '140px', display: 'flex', alignItems: 'center', color: '#d16241' }}>
                <RiMoneyDollarBoxFill size={20} />
                <h6 className='count_rg'>{today_report?.count?.deal_count}</h6>
              </div>
            </div>
          </div>
        </div>


        <div style={{ display: 'flex',cursor:'pointer' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '0px',width:'6%' }}>
            <h6 style={{ margin: '0px 5px',minWidth:'80px', fontWeight: '900', fontSize: '14px', marginRight: '15px' }}> + Filter</h6>
          </div>
          <div style={{width:'28%',marginTop:'4px'}}>
            {/* <Checkbox defaultChecked={options.contact && options.lead && options.deal}>All</Checkbox> */}
            <Checkbox onChange={() => setoptions({ ...options, contact: !options.contact })}>Contacts</Checkbox>
            <Checkbox onChange={() => setoptions({ ...options, lead: !options.lead })}>Leads</Checkbox>
            <Checkbox onChange={() => setoptions({ ...options, deal: !options.deal })}>Deals</Checkbox>
          </div>

          <div style={{ margin: '0px 0px' }}>
            <Select
              placeholder="Select Department"
              size='medium'
              onChange={(v) => { setfilter({ ...filter, department_id: v }); getusersbydepartmentId(v) }}
              value={filter.department_id ? departement_arr.find(d => d.id === filter.department_id).value : null}
              style={{ maxWidth: '180px', minWidth: '180px' }}
            >
              {
                departement_arr.map((d) => (
                  <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                ))
              }
            </Select>

          </div>

          <div style={{ margin: '0px 5px' }}>
            <Select
              placeholder="Select Employee"
              size='medium'
              onChange={(v) => { setfilter({ ...filter, employee_id: v }) }}
              value={filter.employee_id ? employee.find(d => d.id === filter.employee_id).value : null}
              style={{ maxWidth: '180px', minWidth: '180px' }}
            >
              {
                employee.map((d) => (
                  <Option key={d.id} value={d.id}><div>{d.value}</div></Option>
                ))
              }
            </Select>

          </div>

          <div style={{ margin: '0px 5px' }}>
            <DateField
              onChangefun={handleChange}
              disableprev='true'
              name={'from_date'}
              date_value={filter.from_date}
              style={{ width: '100%' }}
            />
          </div>

          <div style={{ margin: '0px 5px' }}>
            <DateField
              disableprev='true'
              onChangefun={handleChange}
              name={'to_date'}
              date_value={filter.to_date}
              style={{ width: '100%' }}
            />
          </div>

          <div style={{ background: '#0072b1', display: 'flex', alignItems: 'center', padding: '6px', borderRadius: '2px', height: '96%', cursor: 'pointer' }} onClick={applyfilterdata}>
            <HiOutlineFilter size={18} style={{ color: '#fff' }} />
            {/* <h6 style={{ fontSize: '10px', marginBottom: '0px', fontWeight: '800', color: '#fff', paddingRight: '5px' }}>Apply Filter</h6> */}
          </div>
          <div style={{ background: '#0072b1', display: 'flex', alignItems: 'center', padding: '6px', borderRadius: '2px', margin: '0px 5px', height: '96%', cursor: 'pointer' }} onClick={resetfilterdata}>
            <IoReload size={18} style={{ color: '#fff' }} />
            {/* <h6 style={{ fontSize: '10px', marginBottom: '0px', fontWeight: '800', color: '#fff', paddingRight: '5px' }}>Reset Filter</h6> */}
          </div>
          <div style={{ display: 'flex', background: '#0072b1', alignItems: 'center',  borderRadius: '2px',  cursor: 'pointer',minWidth:'100px' }} onClick={() => print_report('1')}>
            <BsArrowDownShort size={24} color="#fff" />
            <h6 style={{ fontSize: '10px', marginBottom: '0px', fontWeight: '800', color: '#fff', paddingRight: '5px' }}>Today Report</h6>
          </div>

        </div>


        {report_data ?
          <div >
            <div style={{ marginTop: '30px', borderTop: '1px solid #f2f2f2' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', padding: '15px 0px', alignItems: 'center' }}>
                  <h6 style={{ fontSize: '13px', fontWeight: 'bolder', textTransform: 'uppercase', marginBottom: '0px', marginRight: '15px' }}>Report CLD</h6>
                  <Checkbox checked={download_type?.count} onChange={() => setdownload_type({ ...download_type, count: !download_type.count, })} >Count</Checkbox>
                  <Checkbox checked={download_type?.data} onChange={() => setdownload_type({ ...download_type, data: !download_type.data })}>Data</Checkbox>
                  {/* <Checkbox checked={download_type?.both} onChange={() => setdownload_type({ ...download_type, count: true, data: true, both: !download_type.both })}>Both</Checkbox> */}
                </div>

                <span style={{ display: 'flex', background: '#0072b1', alignItems: 'center', margin: '5px', borderRadius: '2px', height: '100%', cursor: 'pointer' }} onClick={() => print_report()}>
                  <BsArrowDownShort size={24} color="#fff" />
                  <h6 style={{ fontSize: '10px', marginBottom: '0px', fontWeight: '800', color: '#fff', paddingRight: '5px' }}>Download Report</h6>
                </span>
              </div>

              <div style={{ display: 'flex', borderTop: '1px solid #f7f7f7', borderBottom: '1px solid #f7f7f7' }}>
                <div style={{ padding: '10px 0px', minWidth: '140px', display: 'flex', alignItems: 'center', color: '#2be36b' }}>
                  <MdPermContactCalendar size={20} />
                  <h6 className='count_rg' >  {report_data?.count?.contact_count}</h6>
                </div>
                <div style={{ padding: '10px 0px', minWidth: '140px', display: 'flex', alignItems: 'center', color: '#4273bd' }}>
                  <FaHandshake size={20} />
                  <h6 className='count_rg' >{report_data?.count?.lead_count}</h6>
                </div>
                <div style={{ padding: '10px 0px', minWidth: '140px', display: 'flex', alignItems: 'center', color: '#d16241' }}>
                  <RiMoneyDollarBoxFill size={20} />
                  <h6 className='count_rg'>{report_data?.count?.deal_count}</h6>
                </div>
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ flex: 0.4, width: '100%' }}>
                <h6 style={{ fontSize: '13px', fontWeight: 'bolder', textTransform: 'uppercase', marginBottom: '0px', margin: '10px 0px' }}>Fidelitus Vertical</h6>

                <div style={{ display: 'flex', flexDirection: 'row', background: '#fafafa', padding: '6px 5px', borderTop: '1px solid #eaeded', borderBottom: '1px solid #eaeded' }}>
                  <h6 style={{ fontSize: '13px', fontWeight: 'bolder', color: '#000', marginBottom: '0px', marginRight: '15px', width: '40%' }}>Vertical Name</h6>
                  <h6 style={{ fontSize: '13px', fontWeight: 'bolder', color: '#000', marginBottom: '0px', marginRight: '15px', width: '15%', textAlign: 'center' }}>Contacts</h6>
                  <h6 style={{ fontSize: '13px', fontWeight: 'bolder', color: '#000', marginBottom: '0px', marginRight: '15px', width: '15%', textAlign: 'center' }}>Leads</h6>
                  <h6 style={{ fontSize: '13px', fontWeight: 'bolder', color: '#000', marginBottom: '0px', marginRight: '15px', width: '15%', textAlign: 'center' }}>Deals</h6>
                  <h6 style={{ fontSize: '13px', fontWeight: 'bolder', color: '#000', marginBottom: '0px', marginRight: '15px', width: '15%', textAlign: 'center' }}>Actions</h6>
                </div>

                {departement_arr.map(d1 => (
                  <div style={{ display: 'flex', flexDirection: 'row', padding: '5px 5px', borderBottom: '1px solid #f2f2f2' }}>
                    <h6 style={{ fontSize: '13px', fontWeight: '600', marginBottom: '0px', marginRight: '15px', width: '40%' }}>{d1.value}</h6>
                    <h6 style={{ fontSize: '13px', fontWeight: '600', marginBottom: '0px', marginRight: '15px', width: '15%', textAlign: 'center' }}>{report_data?.contacts?.filter(d => d?.contact_owner?.department_id[0]._id === d1.id)?.length}</h6>
                    <h6 style={{ fontSize: '13px', fontWeight: '600', marginBottom: '0px', marginRight: '15px', width: '15%', textAlign: 'center' }}>{report_data?.leads?.filter(d => d?.lead_owner?.department_id[0]._id === d1.id)?.length}</h6>
                    <h6 style={{ fontSize: '13px', fontWeight: '600', marginBottom: '0px', marginRight: '15px', width: '15%', textAlign: 'center' }}>{report_data?.deals?.filter(d => d?.deal_owner?.department_id[0]._id === d1.id)?.length}</h6>
                    {selected_team?.id === d1?.id ?
                      <h6 onClick={() => setselected_team()} style={{ fontSize: '13px', fontWeight: 'bolder', color: '#12a7ff', marginBottom: '0px', marginRight: '15px', width: '15%', textAlign: 'center' }}><span style={{ background: '#f5fbff', }}> <FaExpand size={18} /></span></h6>
                      :
                      <h6 onClick={() => { setselected_team(d1); getusersbydepartmentId(d1?.id) }} style={{ fontSize: '13px', fontWeight: 'bolder', color: '#12a7ff', marginBottom: '0px', marginRight: '15px', width: '15%', textAlign: 'center' }}><span style={{ background: '#f5fbff', }}> <FaCompress size={18} /></span></h6>}
                  </div>
                ))}


              </div>
              <div style={{ flex: 0.6, width: '96%', marginLeft: '2%', }}>

                <h6 style={{ fontSize: '13px', fontWeight: 'bolder', textTransform: 'uppercase', marginBottom: '0px', margin: '10px 0px' }}>{departement_arr?.find(d => d?.id === selected_team?.id)?.value} Result</h6>

                {download_type.count &&
                  <Row>
                    <Col span={12} style={{ margin: '2px' }} >
                      <div style={{ display: 'flex', flexDirection: 'row', background: '#fafafa', padding: '6px 5px', borderTop: '1px solid #eaeded', borderBottom: '1px solid #eaeded' }}>
                        <h6 style={{ fontSize: '13px', fontWeight: 'bolder', color: '#000', marginBottom: '0px', marginRight: '15px', width: '45%' }}>Employee Name</h6>
                        <h6 style={{ fontSize: '13px', fontWeight: 'bolder', color: '#000', marginBottom: '0px', marginRight: '15px', width: '15%', textAlign: 'center' }}>Contacts</h6>
                        <h6 style={{ fontSize: '13px', fontWeight: 'bolder', color: '#000', marginBottom: '0px', marginRight: '15px', width: '15%', textAlign: 'center' }}>Leads</h6>
                        <h6 style={{ fontSize: '13px', fontWeight: 'bolder', color: '#000', marginBottom: '0px', marginRight: '15px', width: '15%', textAlign: 'center' }}>Deals</h6>
                      </div>
                    </Col>
                    {employee?.map((e) => (
                      <>
                        <Col span={12} style={{ margin: '2px' }} >
                          <div style={{ display: 'flex', flexDirection: 'row', padding: '5px 5px', borderBottom: '1px solid #f2f2f2' }}>
                            <h6 style={{ fontSize: '13px', fontWeight: '600', marginBottom: '0px', marginRight: '15px', width: '50%' }}>{e?.value}</h6>
                            <h6 style={{ fontSize: '13px', fontWeight: '600', marginBottom: '0px', marginRight: '15px', width: '13%', textAlign: 'center' }}>{report_data?.contacts?.filter(d => d?.contact_owner?._id === e?.id)?.length}</h6>
                            <h6 style={{ fontSize: '13px', fontWeight: '600', marginBottom: '0px', marginRight: '15px', width: '13%', textAlign: 'center' }}>{report_data?.leads?.filter(d => d?.lead_owner?._id === e?.id)?.length}</h6>
                            <h6 style={{ fontSize: '13px', fontWeight: '600', marginBottom: '0px', marginRight: '15px', width: '13%', textAlign: 'center' }}>{report_data?.deals?.filter(d => d?.deal_owner?._id === e?.id)?.length}</h6>
                          </div>
                        </Col>
                      </>
                    ))}
                  </Row>}






              </div>
            </div>


            {download_type.data &&
              <div style={{ flex: 1, width: '100%', marginLeft: '0%', marginTop: 20 }}>
                <h6 style={{ marginBottom: '10px', marginTop: '10px', fontWeight: 'bolder', fontSize: '14px' }}>Total Contacts ({'0'})</h6>
                <Row>
                  <Col span={24}>
                    <div className='mateial_table_header'>
                      <p style={{ width: '1%' }}></p>
                      <p style={{ width: '10%', color: 'black' }}>Company </p>
                      <p style={{ width: '12%', paddingLeft: '0.5%' }}>Contact Name</p>
                      <p style={{ width: '12%' }}>Phone</p>
                      <p style={{ width: '20%' }}>Email</p>
                      <p style={{ width: '12%' }}>Designation</p>
                      <p style={{ width: '12%' }}>Contact Source</p>
                      <p style={{ width: '10%' }}>Contact Status</p>
                      <p style={{ width: '12%' }}>Owner Name</p>
                    </div>
                  </Col>
                  <Col span={24}>
                    {report_data?.contacts?.map((d) => (
                      <div key={d.id} className={'mateial_table_body'} >
                        <p style={{ width: '1%' }}></p>
                        <p style={{ width: '10%', fontWeight: '700', color: "#0594f2", wordBreak: 'break-word' }}>{d?.company_name?.slice(0, 12)}{d?.company_name?.length > 12 && '...'}</p>
                        <p style={{ width: '12%', wordBreak: 'break-word', paddingLeft: '0.5%' }}>{<Texttruncate text={d?.contact_name} />}</p>
                        <p style={{ width: '12%', wordBreak: 'break-word' }}>{d?.phone}</p>
                        <p style={{ width: '20%', wordBreak: 'break-word' }}>{d?.email?.length > 16 ? d?.email?.slice(0, 16) + '...' : d.email}</p>
                        <p style={{ width: '12%', wordBreak: 'break-word' }}>{d?.designation?.length > 16 ? d?.designation?.slice(0, 16) + '...' : d.designation}</p>
                        <p style={{ width: '12%', wordBreak: 'break-word' }}>{d?.contact_source?.source_name?.length > 16 ? d?.contact_source?.source_name?.slice(0, 16) + '...' : d?.contact_source?.source_name} </p>
                        <p style={{ width: '10%', wordBreak: 'break-word' }}>{d?.status?.length > 14 ? d?.status?.slice(0, 14) + '...' : d?.status?.slice(0, 14)} </p>
                      </div>
                    ))}
                  </Col>
                </Row>

                <Row style={{ marginTop: 20 }}>
                  <Col span={11}>
                    <h6 style={{ marginBottom: '10px', marginTop: '10px', fontWeight: 'bolder', fontSize: '14px' }}>Total Leads ({'0'})</h6>
                    <div className='mateial_table_header'>
                      <p style={{ width: '1%' }}></p>
                      <p style={{ width: '30%', color: 'black' }}>Lead Name </p>
                      <p style={{ width: '24%' }}>Status</p>
                      <p style={{ width: '24%' }}>Source</p>
                      <p style={{ width: '20%' }}>Owner Name</p>
                    </div>
                    {report_data?.leads?.map((d) => (
                      <div key={d._id} className={'mateial_table_body'}>
                        <p style={{ width: '1%' }}></p>
                        <p style={{ width: '30%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700' }} >{<Texttruncate text={d?.lead_name} />}</p>
                        <p style={{ width: '24%', wordBreak: 'break-word', marginRight: '1%' }}>{<Texttruncate text={d?.lead_status?.split(' ')[0]} />}</p>
                        <p style={{ width: '24%', wordBreak: 'break-word', marginRight: '1%' }}>{<Texttruncate text={d?.contact_id?.contact_source?.source_name} />}</p>
                        <p style={{ width: '20%', wordBreak: 'break-word' }}>{<Texttruncate text={d?.lead_owner?.name} />}</p>
                      </div>
                    ))}
                  </Col>

                  <Col span={2}>
                  </Col>


                  <Col span={11}>
                    <h6 style={{ marginBottom: '10px', marginTop: '10px', fontWeight: 'bolder', fontSize: '14px' }}>Total Deals ({'0'})</h6>
                    <div className='mateial_table_header'>
                      <p style={{ width: '1%' }}></p>
                      <p style={{ width: '30%', color: 'black' }}>Deal Name </p>
                      <p style={{ width: '24%' }}>Stage</p>
                      <p style={{ width: '24%' }}>Closing Date</p>
                      <p style={{ width: '20%' }}>Owner Name</p>
                    </div>

                    {report_data?.deals?.map((d) => (
                      <div key={d.id} className='mateial_table_body'>
                        <p style={{ width: '1%' }}></p>
                        <p style={{ width: '30%', color: "#0594f2", cursor: 'pointer', fontWeight: '700', wordBreak: 'break-word' }}>{<Texttruncate text={d?.deal_name} />}</p>
                        <p style={{ width: '24%', wordBreak: 'break-word' }}>{<Texttruncate text={d?.deal_stage} />}</p>
                        <p style={{ width: '24%', wordBreak: 'break-word' }}>{moment(d?.closing_date).format('LL')}</p>
                        <p style={{ width: '20%', wordBreak: 'break-word' }}>{<Texttruncate text={d?.deal_owner?.name} />}</p>
                      </div>
                    ))}
                  </Col>
                </Row>

              </div>}
          </div>
          :
          <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
            {/* <img src={"https://fidecrms3bucket.s3.amazonaws.com/report_generator-removebg.png"} alt="nodatafouund" style={{ margin: 'auto', width: '500px', objectFit: 'contain' }} /> */}
            {/* <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{"Add filter to get a data for report"}</h4>
            <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>{'No data found with provided search options please check other format filter options'}</h6> */}
          </div>
        }


      </div>
    </DeveloperMain>
  )
}

export default Reportgeneration