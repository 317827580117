import React from 'react'
import OnboardMobile from './android'
import OnboardWeb from './web'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'

const index = () =>{
    if(isMobile){
        return(
            <div>
                <OnboardMobile />
            </div>
        )
    }else{
        return(
            <div>
                <OnboardWeb />
            </div>
        )
    }
}

export default index
