import React from 'react'
import { PrimaryButtonAdd } from '../buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom';

function DetailDesignHeader1({ type }) {

  const navigate = useNavigate()


  function navigatemefunc() {
    if (type === 'Task') {
      navigate('/create_tasks')
    }
  }

  return (
    <div style={{ width: '100%', marginTop: '60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <h5 style={{ marginBottom: '0px', fontWeight: 'bolder',fontSize:'16px' }}>Total Tasks</h5>

      <div onClick={() => navigatemefunc()} >
        <PrimaryButtonAdd btn_name="Add Task" />
      </div>
    </div>
  )
}

export default DetailDesignHeader1
