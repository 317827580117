import React from 'react'
import DetailDesignBody2 from './DetailDesignBody2'

function DetailDesign2({section, id, values, labels, type, activetab,converted_status,obj,lead_id}) {

    return (
        <div style={{marginTop: '45px'}}>
            <DetailDesignBody2 
                activetab={activetab} 
                section={section} 
                id={id} 
                lead_id = {lead_id}
                values={values} 
                labels={labels}
                type={type}
                converted_status={converted_status}
                obj={obj}/>
        </div>
    )
}

export default DetailDesign2
