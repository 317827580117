import React from 'react'
import './Loader.css'

function Loader() {
  return (
    <div className='loader-outer'>
      <div className='loader'>
      </div>

      <div className="center_loading">
        {/* <img src="https://fidelituscorpmanagebase.com/images/icon.png" className="com_logo" /> */}

        <h1 className='msg-loading'>LOADING</h1>
      </div>
    </div>
  )
}

export default Loader