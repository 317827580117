import React, { useState, useEffect } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { main_bg } from '../../constants/Constants1'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { Drawer, Input, Row, Col, Button, message, Modal } from 'antd'
import { BsFillPlusCircleFill } from 'react-icons/bs'
import { IoClose } from 'react-icons/io5'
import { data_not_found } from '../../../helpers/Constants'

function Notesui() {



    const { type, id } = useParams()
    const { TextArea } = Input;

    const navigate = useNavigate()

    const [data, setdata] = useState([])
    const [single_data, setsingle_data] = useState({})
    const [visible, setvisible] = useState(false)
    const [visible1, setvisible1] = useState(false)

    const [note, setnote] = useState({ note_title: '', summary: '' })

    console.log('type', type, 'id', id)


    useEffect(() => {
        getdata()
    }, [])


    function getdata() {
        if (type === 'Contact') {
            axios.get(`activity/get-note?type=${type}&type_id=${id}`, authHeader).then((res) => {
                setdata(res.data.datas)
            })
        }
    }

    function adddata() {
        if (type === 'Contact') {
            authHeader['Content-Type'] = 'multiplart/form-data'
            let fd = new FormData()
            fd.append('note_type', type)
            fd.append('type_id', id)
            fd.append('note_title', note?.note_title)
            fd.append('summary', note?.summary)
            axios.post('activity/create-note', fd, authHeader).then((res) => {
                getdata()
                setvisible(false)
                message.success('Note added successfully')
                setnote({ note_title: '', summary: '' })
            }).catch((err) => {
                message.error('Oops something went wrong!')
            })
        }
    }


    console.log('datas', data)

    return (
        <div>

            <Modal visible={visible1} footer={false} onCancel={() => setvisible1(false)} style={{ maxWidth: '320px' }} centered>
                <div style={{ maxWidth: '100%', }}>
                    <h6 style={{ fontWeight: '900', fontSize: '15px' }}>Notes Detail</h6>
                    <h6 style={{ fontWeight: '900', fontSize: '15px' }}>{single_data?.note_title}</h6>
                    <h6 style={{ fontSize: '13px', width: '100%', wordBreak: 'break-word', borderRadius: '4px' }}>{single_data.summary}</h6>
                </div>
            </Modal>

            <Drawer placement={'bottom'} visible={visible} height={'50%'} width={'85%'} closable={false} maskClosable={false} bodyStyle={{ background: '#fff', margin: '5%', padding: '0px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', overflow: 'hidden' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <h6 style={{width:'80px',height:'6px',borderRadius:'20px',opacity:0.6,background:'#d9d9d9',display:'flex',justifyContent:'center',justifySelf:'center',alignSelf:'center'}}></h6> */}
                </div>
                <div style={{ alignItems: 'center', margin: '3px 40px', marginBottom: '20px' }}>
                    <h5 style={{ fontWeight: '800', textAlign: 'left' }}>Add Notes</h5>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', background: '#f0f0f0', borderRadius: 4, padding: 5, position: 'absolute', left: '20px', top: '25px' }} onClick={() => setvisible(false)}>
                    <IoClose size={12} color='black' />
                </div>




                <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px' }}>
                    <div>
                        <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Title</h6>
                        <Input type={"text"} placeholder={'Add Title ...'} style={{ width: '100%', margin: 'auto', marginLeft: '0%', padding: '10px', border: 'none', background: '#fafafa', boxShadow: 'none' }} value={note.note_title} onChange={e => setnote({ ...note, note_title: e.target.value })} />
                    </div>
                </div>

                <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px' }}>
                    <div>
                        <h6 style={{ fontWeight: '600', fontSize: '14px', }}>Description</h6>
                        <TextArea rows={3} type={"text"} placeholder={'Add Description ...'} style={{ width: '100%', margin: 'auto', marginLeft: '0%', padding: '5px', border: 'none', background: '#fafafa', boxShadow: 'none' }} value={note.summary} onChange={e => setnote({ ...note, summary: e.target.value })} />
                    </div>
                </div>







                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', width: '100%' }} onClick={adddata}>
                        <Button size='large' style={{ background: `${main_bg}`, border: 'none', borderRadius: '5px', color: 'white', fontWeight: '500', display: 'flex', justifyContent: 'center', justifyItems: 'center', width: '100%' }}>Add Notes</Button>
                    </div>
                </div>
            </Drawer>

            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: `${main_bg}`, padding: '10px', height: '50px', position: 'sticky', top: '0px',zIndex:100 }}>
                <IoIosArrowBack size={24} color="#fff" onClick={() => navigate(-1)} />
                <h6 style={{ fontSize: '18px', fontWeight: '500', color: '#fff', marginBottom: '0px', marginLeft: '20px' }}>Notes</h6>
            </div>

            {data?.length > 0 ?
                <div style={{ width: '94%', margin: 'auto' }}>
                    <h6 style={{ padding: '10px', fontWeight: '800', fontSize: '13px' }}> ({data.length}) Notes Added</h6>
                    <Row>
                        {data.map((d) => (
                            <Col span={12} key={d?.id} onClick={() => { setsingle_data(d); setvisible1(true) }}>
                                <div style={{ width: '92%', margin: '1% 4%', backgroundColor: 'white', border: '1px solid #ededed', boxSizing: 'border-box', padding: '10px', borderRadius: '5px', minHeight: '120px', maxHeight: '120px' }}>
                                    <h6 style={{ fontWeight: '900',wordBreak:'break-word' }}>{d?.note_title.slice(0, 15)}{d?.note_title?.length > 15 && '...'}</h6>
                                    <h6 style={{ fontSize: '14px',wordBreak:'break-word' }}>{d?.summary.slice(0, 70)}{d?.summary?.length > 70 && '...'}</h6>
                                </div>
                            </Col>
                        ))}

                    </Row>
                </div>
                :
                <div style={{ display: 'grid', alignItems: 'center', justifyItems: 'center', justifyContent: 'center', marginTop: '30%' }}>
                    <img src={data_not_found} alt="1" style={{ width: '190px', height: '190px',marginLeft:'10px', objectFit: 'contain' }} />
                    <h5 style={{ fontSize: '15px', marginTop: '-10px', fontWeight: '800' }}>No Notes added yet</h5>
                    <h5 style={{ fontSize: '13px',textAlign:'center',width:'85%'}}>Please click on the create icon and add data to see notes here </h5>
                </div>
            }

            <div style={{ position: 'fixed', bottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <BsFillPlusCircleFill size={44} onClick={() => setvisible(true)} />
            </div>
        </div>
    )
}

export default Notesui