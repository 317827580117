import React from 'react'
import { TimePicker } from 'antd';
import Inputerror from '../errors/Inputerror';


function TimeField({priority,name,onChangefun,error_obj,value}) {
    
    const format = 'HH:mm';

    function onChange(value){
        onChangefun({key:name,value})

    //    console.log('value',value)
    }
    return (
        <div>
            <TimePicker value={value} style={{width:'100%',borderLeft:priority === 'high' &&  '4px solid #292961',borderRadius:'5px'}} onChange={onChange} format={format}  />
            <Inputerror message={error_obj?.message} />
        </div>
    )
}

export default TimeField
