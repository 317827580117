import React,{useState,useEffect} from 'react'
// import BdDetail from '../../../components/bdcomponenttable/mobileComponents/BdDetail'
import { useNavigate, useParams} from 'react-router-dom'
// import BdDetail from '../../../components/bdcomponenttable/mobileComponents/BdDetail'
import axios from "axios";
import {authHeader} from '../../../helpers/usertoken/UserToken'
import {IoIosArrowBack} from 'react-icons/io'
import {BiMessageAltDetail} from 'react-icons/bi'
import {GoFileSubmodule} from 'react-icons/go'
import { main_bg } from '../../../components/constants/Constants1';
import moment from 'moment';
import {IoMdCall,IoMdMail} from 'react-icons/io'
import {FaUserShield,FaIndustry} from 'react-icons/fa'
import {AiOutlineEdit} from 'react-icons/ai'
import {GiAlarmClock} from 'react-icons/gi'
import ReminderMobile from '../../../components/detail_design/ReminderMobile';
import {Drawer,Tooltip} from 'antd'

import {MdTimeline} from 'react-icons/md'

import { Timeline } from '@mui/lab';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import {AiOutlineClockCircle} from 'react-icons/ai'

import {IoCreateOutline} from 'react-icons/io5'
import {BiTransfer} from 'react-icons/bi'

function DealDetail() {

    const navigate = useNavigate()
    const {id} = useParams()

    const [reminder,setReminders] = useState([])
    const [visible,setvisible] = useState(false)
    const [visible1,setvisible1] = useState(false)

    const [data,setdata] = useState({})

    const [deal_tracker,setdeal_tracker] = useState([])
    const [task_tracker,settask_tracker] = useState([])
    const [meeting_tracker,setmeeting_tracker] = useState([])


    useEffect(()=>{
        getdata()
    },[])

    function getdata(){
        axios.get(`deal/get/${id}`, authHeader)
        .then((res) => {
            setdata(res.data.datas[0])
            // console.log('res data here',res.data)
        })
        .catch((err) => {
            // console.log('err me na')
        })    
    }

    useEffect(()=>{
        tracker()
    },[])
     

    function tracker(){
        axios.get(`activity/get-timeline/Deal/${id}`,authHeader)
        .then((res)=>{
            setdeal_tracker(res.data.datas)
            console.log('res data here na',res.data)
            let tracker_arrange = []
            let tracker_arrange1 = []
                res.data.datas.sub.filter(t=>t.type === 'Task' && t.history === '<p>Task created</p>' && t.event === 'create').forEach(e1=>{
                    tracker_arrange.push(e1)
                    // console.log('tracker_arrange.push(t)1',tracker_arrange)
                    if(res.data.datas.sub.filter(t1=>t1.type === 'Task' && t1.event === 'update' && t1.type_id === e1.type_id).length > 0){
                        res.data.datas.sub.filter(t1=>t1.type === 'Task' && t1.event === 'update' && t1.type_id === e1.type_id).forEach(e=>{
                            tracker_arrange.push(e)
                        })
                    // console.log('tracker_arrange.push(t)2',tracker_arrange)

                    }
                }) 
                settask_tracker(tracker_arrange)

                res.data.datas.sub.filter(t=>t.type === "Meeting" && t.history === '<p>Meeting created</p>' && t.event === 'create').forEach(e1=>{
                    tracker_arrange1.push(e1)
                    // console.log('tracker_arrange.push(t)1',tracker_arrange)
                    if(res.data.datas.sub.filter(t1=>t1.type === 'Meeting' && t1.event === 'update' && t1.type_id === e1.type_id).length > 0){
                        res.data.datas.sub.filter(t1=>t1.type === 'Meeting' && t1.event === 'update' && t1.type_id === e1.type_id).forEach(e=>{
                            tracker_arrange1.push(e)
                        })
                    // console.log('tracker_arrange.push(t)2',tracker_arrange)

                    }
                }) 
                setmeeting_tracker(tracker_arrange1)
        }).catch((err)=>{
            console.log('err data here na',err.response)
        })
    }


    async function getReminder(id){
        await axios.get(`reminder/get?type=${'Deal'}&type_id=${id}`, authHeader).then((res) => {
             setReminders(res.data.datas.reverse())
         })
    }

    function editDeal(){
        navigate('/edit_deal/' + id)
    }
    

    const goback = () => {
        navigate(-1)
    }
   
    return (
        <div> 
            <Drawer title="Basic Drawer" placement="bottom" onClose={()=>setvisible1(false)} visible={visible1} height={window.innerHeight/1.3} width={window.innerWidth} closable={false} headerStyle={{display:'none'}}  >
            <h4 style={{fontSize:'20px',fontWeight:'800'}}>TimeLine <AiOutlineClockCircle size={22} style={{margin:'0px 5px'}} /> </h4>
            <Timeline style={{marginTop:'30px'}} >


            <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                       <h6 style={{fontSize:'12px',color:'#0ca7fa',fontWeight:'bold'}}> {moment(data?.lead_id?.contact_id?.createdAt).format('lll')}</h6>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <IoCreateOutline size={20}  color="#171636"/>
                        {/* {t.event === 'convert' && <BiTransfer size={20}  color="#171636"/>} */}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <h6 style={{fontSize:'13px',fontWeight:'900',marginBottom:'0px',paddingBottom:'0px'}}>{'Contact Created'}</h6>
                        <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${data?.lead_id?.contact_id?.id}`)}>View Details</span>
                    </TimelineContent>
            </TimelineItem>

            {deal_tracker?.base?.filter((l=>l.type === "Deal" &&  l.event === 'convert')).map(t=>(
            <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                       <h6 style={{fontSize:'12px',color:'#0ca7fa',fontWeight:'bold'}}> {moment(data?.lead_id?.createdAt).format('lll')}</h6>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        {<BiTransfer size={20}  color="#171636"/>}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <h6 style={{fontSize:'13px',fontWeight:'900',marginBottom:'0px',paddingBottom:'0px'}}>{`Converted from Contact Company name:${data?.lead_id?.contact_id?.company_name}`}</h6>
                        <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${data?.lead_id?.contact_id?.id}`)}>View Details</span>
                    </TimelineContent>
            </TimelineItem>
            ))}    

      

            {deal_tracker?.base?.filter((l=>l.type === "Deal" &&  l.event === 'convert')).map(t=>(
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                       <h6 style={{fontSize:'12px',color:'#0ca7fa',fontWeight:'bold'}}> {moment(data?.lead_id?.createdAt).format('lll')}</h6>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <IoCreateOutline size={20}  color="#171636"/>
                        {/* {t.event === 'convert' && <BiTransfer size={20}  color="#171636"/>} */}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <h6 style={{fontSize:'13px',fontWeight:'900',marginBottom:'0px',paddingBottom:'0px'}}>{'Lead Created'}</h6>
                        <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/lead_detail/${t?.history.split(":")[t?.history.split(":").length - 1]}`)}>View Details</span>
                        {/* <h6 style={{fontSize:'11px',fontWeigt:'600',color:'#858585',marginBottom:'0px',paddingBottom:'0px'}}>{t?.user_id?.name}</h6> */}
                    </TimelineContent>
                </TimelineItem>
              ))}    

             {deal_tracker?.base?.filter((l=>l.type === "Deal" &&  l.event === 'convert')).map(t=>(
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                       <h6 style={{fontSize:'12px',color:'#0ca7fa',fontWeight:'bold'}}> {moment(t?.createdAt).format('lll')}</h6>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        {t.event === 'create' && <IoCreateOutline size={20}  color="#171636"/>}
                        {t.event === 'convert' && <BiTransfer size={20}  color="#171636"/>}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <h6 style={{fontSize:'13px',fontWeight:'900',marginBottom:'0px',paddingBottom:'0px'}}>{t?.history.split(":")[0].replace(/(<([^>]+)>)/gi, "")}</h6>
                        <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/lead_detail/${t?.history.split(":")[t?.history.split(":").length - 1]}`)}>View Details</span>
                        <h6 style={{fontSize:'11px',fontWeigt:'600',color:'#858585',marginBottom:'0px',paddingBottom:'0px'}}>{t?.user_id?.name}</h6>
                    </TimelineContent>
                </TimelineItem>
              ))}     

             {deal_tracker?.base?.filter(l=>(l.type === "Deal" &&  l.type === 'create')).map(t=>(
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                       <h6 style={{fontSize:'12px',color:'#0ca7fa',fontWeight:'bold'}}> {moment(t?.createdAt).format('lll')}</h6>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        {t.event === 'create' && <IoCreateOutline size={20}  color="#171636"/>}
                        {t.event === 'convert' && <BiTransfer size={20}  color="#171636"/>}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <h6 style={{fontSize:'13px',fontWeight:'900',marginBottom:'0px',paddingBottom:'0px'}}>{t?.history.split(":")[0].replace(/(<([^>]+)>)/gi, "")}</h6>
                        {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/lead_detail/${t?.history.split(":")[t?.history.split(":").length - 1]}`)}>View Details</span> */}
                        <h6 style={{fontSize:'11px',fontWeigt:'600',color:'#858585',marginBottom:'0px',paddingBottom:'0px'}}>{t?.user_id?.name}</h6>
                    </TimelineContent>
                </TimelineItem>
              ))}  
              


              {deal_tracker?.base?.filter( (l=>l.type === "Deal") ).map(t=>(
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                       <h6 style={{fontSize:'12px',color:'#0ca7fa',fontWeight:'bold'}}> {moment(t?.createdAt).format('lll')}</h6>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        {t.event === 'create' && <IoCreateOutline size={20}  color="#171636"/>}
                        {t.event === 'convert' && <BiTransfer size={20}  color="#171636"/>}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <h6 style={{fontSize:'13px',fontWeight:'900',marginBottom:'0px',paddingBottom:'0px'}}>{t?.history.split(":")[0].replace(/(<([^>]+)>)/gi, "")}</h6>
                        <h6 style={{fontSize:'11px',fontWeigt:'600',color:'#858585',marginBottom:'0px',paddingBottom:'0px'}}>{t?.user_id?.name}</h6>
                    </TimelineContent>
                </TimelineItem>
              ))}


             {task_tracker.map(t=>(
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                       <h6 style={{fontSize:'12px',color:'#0ca7fa',fontWeight:'bold'}}> {moment(t?.createdAt).format('lll')}</h6>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        {t.event === 'create' && <IoCreateOutline size={20}  color="#171636"/>}
                        {t.event === 'update' && <AiOutlineEdit size={20}  color="#171636"/>}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent style={{maxWidth:'155px',backgroundColor:'#fff'}}>
                        <h6 style={{fontSize:'12px',fontWeight:'900',marginBottom:'0px',paddingBottom:'2px'}} dangerouslySetInnerHTML={{ __html:t?.history}}></h6>
                        <h6 style={{fontSize:'11px',fontWeigt:'600',color:'#858585',marginBottom:'0px',paddingBottom:'0px'}}>{t?.user_id?.name}</h6>
                    </TimelineContent>
                </TimelineItem>
              ))}

             {meeting_tracker?.map(t=>(
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                       <h6 style={{fontSize:'12px',color:'#0ca7fa',fontWeight:'bold'}}> {moment(t?.createdAt).format('lll')}</h6>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        {t.event === 'create' && <IoCreateOutline size={20}  color="#171636"/>}
                        {t.event === 'update' && <AiOutlineEdit size={20}  color="#171636"/>}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent style={{maxWidth:'155px',backgroundColor:'#fff'}}>
                        <h6 style={{fontSize:'12px',fontWeight:'900',marginBottom:'0px',paddingBottom:'2px'}} dangerouslySetInnerHTML={{ __html:t?.history}}></h6>
                        <h6 style={{fontSize:'11px',fontWeigt:'600',color:'#858585',marginBottom:'0px',paddingBottom:'0px'}}>{t?.user_id?.name}</h6>
                    </TimelineContent>
                </TimelineItem>
              ))}


            </Timeline>    
            </Drawer>

            <ReminderMobile  visible={visible} func={() => setvisible(false)} id={id} type={'Deal'} reminder={reminder} getreminderfun ={(v)=>getReminder(v)}/>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'100%',padding:'3%',alignItems:'center',backgroundColor:`${main_bg}`,position:'sticky',top:'0px',zIndex:100}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <IoIosArrowBack size={24} color="#fff"  onClick={goback} style={{marginLeft:'-5px'}} />
                <h6 style={{marginBottom:'0px',color:'#fff',marginLeft:'25px',fontWeight:'600'}}>Deal Detail</h6>
                </div> 
                <div>
                <GoFileSubmodule size={24} style={{marginRight:'20px',color:'white'}} onClick={()=>navigate(`/attachments_ui/Deal/${id}`)} /> 
                <BiMessageAltDetail size={24} style={{marginRight:'15px',color:'white'}} onClick={()=>navigate(`/notes_ui/Deal/${id}`)} />
                </div> 
            </div>    

                <div style={{position:'relative'}}>
                <MdTimeline size={20} style={{position:'absolute',right:'115px',top:'20px'}} onClick={()=>setvisible1(true)} /> 
                <GiAlarmClock size={20} style={{position:'absolute',right:'70px',top:'20px'}} onClick={()=>setvisible(true)} /> 
                <AiOutlineEdit size={20} style={{position:'absolute',right:'30px',top:'20px'}} onClick={editDeal} />  
             <div style={{display:'grid',alignItems:'center',justifyContent:'center',paddingTop:'50px'}}>
                <div style={{width:'120px',height:'120px',backgroundColor:'#f5fcff',display:'grid',alignItems:'center',justifyContent:'center',borderRadius:'50%'}}>
                    <h6 style={{color:'black',fontSize:'50px',color:`${main_bg}`,textTransform:'capitalize',fontWeight:'900',opacity:0.5}}>{data?.lead_id?.contact_id?.company_name?.slice(0,1)}</h6>
                </div>
             </div>
             <h6 style={{color:'black',fontSize:'17px',textTransform:'capitalize',fontWeight:'900',marginTop:'5px',textAlign:'center'}}>{data?.lead_id?.contact_id?.contact_name}</h6>
             <h6 style={{marginTop:'5px',textAlign:'center',fontSize:'13px',color:'#adadad  '}}>Created at : {moment(data?.lead_id?.contact_id?.createdAt).format('LLL')} </h6>
              
             <div style={{display:'flex',width:'50%',margin:'auto',justifyContent:'space-between'}}>
               <Tooltip placement="topLeft" title={data?.lead_id?.contact_id?.phone}>
               <div style={{backgroundColor:`${main_bg}`,width:'30px',height:'30px',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%'}}>
                    <IoMdCall size={16} color="#fff" />
               </div>
               </Tooltip>
               <Tooltip placement="topLeft" title={data?.lead_id?.contact_id?.email}>
               <div style={{backgroundColor:`${main_bg}`,width:'30px',height:'30px',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%'}}>
                    <IoMdMail size={16} color="#fff" />
               </div>
               </Tooltip>
               <Tooltip placement="topLeft" title={data?.lead_id?.contact_id?.designation}>
               <div style={{backgroundColor:`${main_bg}`,width:'30px',height:'30px',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%'}}>
                    <FaUserShield size={16} color="#fff" />
               </div>
               </Tooltip>
               <Tooltip placement="topLeft" title={data?.lead_id?.contact_id?.industry?.industry_name}>
               <div style={{backgroundColor:`${main_bg}`,width:'30px',height:'30px',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%'}}>
                    <FaIndustry size={16} color="#fff" />
               </div>
               </Tooltip>
             </div> 


             <div style={{width:'70%',margin:'auto',marginTop:'30px'}}>
                <h6 style={{fontWeight:'800',margin:'10px 0px',fontSize:'15px'}}>Company Information</h6>
                {data?.lead_id?.contact_id?.company_name && <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <h4 style={{flex:0.4,color:'#adadad',fontSize:'14px',width:'100%',padding:'3px',textAlign:'right'}}>Company</h4>
                    <h4 style={{flex:0.6,color:'black',fontWeight:'600',fontSize:'14px',width:'100%',padding:'3px',textAlign:'left',marginLeft:'10px'}}>{data?.lead_id?.contact_id?.company_name}</h4>
                </div>}
                {data?.lead_id?.contact_id?.company_phone && <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <h4 style={{flex:0.4,color:'#adadad',fontSize:'14px',width:'100%',padding:'3px',textAlign:'right'}}>Company Phone</h4>
                    <h4 style={{flex:0.6,color:'black',fontWeight:'600',fontSize:'14px',width:'100%',padding:'3px',textAlign:'left',marginLeft:'10px'}}>{data?.lead_id?.contact_id?.company_phone}</h4>
                </div>}
             </div>


             <div style={{width:'70%',margin:'auto',marginTop:'30px'}}>
                <h6 style={{fontWeight:'800',margin:'10px 0px',fontSize:'15px'}}>Lead Information</h6>
                {data?.lead_id?.lead_name && <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <h4 style={{flex:0.4,color:'#adadad',fontSize:'14px',width:'100%',padding:'3px',textAlign:'right'}}>Lead Name</h4>
                    <h4 style={{flex:0.6,color:'black',fontWeight:'600',fontSize:'14px',width:'100%',padding:'3px',textAlign:'left',marginLeft:'10px'}}>{data?.lead_id?.lead_name}</h4>
                </div>}
                {data?.lead_id?.lead_title && <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <h4 style={{flex:0.4,color:'#adadad',fontSize:'14px',width:'100%',padding:'3px',textAlign:'right'}}>Lead Title</h4>
                    <h4 style={{flex:0.6,color:'black',fontWeight:'600',fontSize:'14px',width:'100%',padding:'3px',textAlign:'left',marginLeft:'10px'}}>{data?.lead_id?.lead_title}</h4>
                </div>}
                {data?.lead_id?.requirement && <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <h4 style={{flex:0.4,color:'#adadad',fontSize:'14px',width:'100%',padding:'3px',textAlign:'right'}}>Requirement</h4>
                    <h4 style={{flex:0.6,color:'black',fontWeight:'600',fontSize:'14px',width:'100%',padding:'3px',textAlign:'left',marginLeft:'10px'}}>{data?.lead_id?.requirement}</h4>
                </div>}
                {data?.lead_id?.measurement && <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <h4 style={{flex:0.4,color:'#adadad',fontSize:'14px',width:'100%',padding:'3px',textAlign:'right'}}>Measurment</h4>
                    <h4 style={{flex:0.6,color:'black',fontWeight:'600',fontSize:'14px',width:'100%',padding:'3px',textAlign:'left',marginLeft:'10px'}}>{data?.lead_id?.measurement}</h4>
                </div>}
                {data?.lead_id?.zone && <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <h4 style={{flex:0.4,color:'#adadad',fontSize:'14px',width:'100%',padding:'3px',textAlign:'right'}}>Zone</h4>
                    <h4 style={{flex:0.6,color:'black',fontWeight:'600',fontSize:'14px',width:'100%',padding:'3px',textAlign:'left',marginLeft:'10px'}}>{data?.lead_id?.zone}</h4>
                </div>}
                {data?.lead_id?.description && <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <h4 style={{flex:0.4,color:'#adadad',fontSize:'14px',width:'100%',padding:'3px',textAlign:'right'}}>Description</h4>
                    <h4 style={{flex:0.6,color:'black',fontWeight:'600',fontSize:'14px',width:'100%',padding:'3px',textAlign:'left',marginLeft:'10px'}}>{data?.lead_id?.description}</h4>
                </div>}
             </div>  

             <div style={{width:'70%',margin:'auto',marginTop:'30px'}}>
                <h6 style={{fontWeight:'800',margin:'10px 0px',fontSize:'15px'}}>Deal Information</h6>
                {data?.deal_name && <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <h4 style={{flex:0.4,color:'#adadad',fontSize:'14px',width:'100%',padding:'3px',textAlign:'right'}}>Deal Name</h4>
                    <h4 style={{flex:0.6,color:'black',fontWeight:'600',fontSize:'14px',width:'100%',padding:'3px',textAlign:'left',marginLeft:'10px'}}>{data?.deal_name}</h4>
                </div>}
                {data?.deal_title && <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <h4 style={{flex:0.4,color:'#adadad',fontSize:'14px',width:'100%',padding:'3px',textAlign:'right'}}>Deal Title</h4>
                    <h4 style={{flex:0.6,color:'black',fontWeight:'600',fontSize:'14px',width:'100%',padding:'3px',textAlign:'left',marginLeft:'10px'}}>{data?.deal_title}</h4>
                </div>}
                {data?.deal_type && <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <h4 style={{flex:0.4,color:'#adadad',fontSize:'14px',width:'100%',padding:'3px',textAlign:'right'}}>Deal Type</h4>
                    <h4 style={{flex:0.6,color:'black',fontWeight:'600',fontSize:'14px',width:'100%',padding:'3px',textAlign:'left',marginLeft:'10px'}}>{data?.deal_type}</h4>
                </div>}
                {data?.deal_stage && <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <h4 style={{flex:0.4,color:'#adadad',fontSize:'14px',width:'100%',padding:'3px',textAlign:'right'}}>Deal Stage</h4>
                    <h4 style={{flex:0.6,color:'black',fontWeight:'600',fontSize:'14px',width:'100%',padding:'3px',textAlign:'left',marginLeft:'10px'}}>{data?.deal_stage}</h4>
                </div>}
                {data?.deal_owner && <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <h4 style={{flex:0.4,color:'#adadad',fontSize:'14px',width:'100%',padding:'3px',textAlign:'right'}}>Deal Owner</h4>
                    <h4 style={{flex:0.6,color:'black',fontWeight:'600',fontSize:'14px',width:'100%',padding:'3px',textAlign:'left',marginLeft:'10px'}}>{data?.deal_owner?.name}</h4>
                </div>}
                {data?.description && <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <h4 style={{flex:0.4,color:'#adadad',fontSize:'14px',width:'100%',padding:'3px',textAlign:'right'}}>Description</h4>
                    <h4 style={{flex:0.6,color:'black',fontWeight:'600',fontSize:'14px',width:'100%',padding:'3px',textAlign:'left',marginLeft:'10px'}}>{data?.description}</h4>
                </div>} 

             </div>   

            </div>
            {/* <BdDetail section={section} values={[values,values1,values2]} labels = {[label,label1,label2]} type={type} goback={()=>goback()} /> */}
        </div>
    )
}

export default DealDetail
