import React from 'react'
import { isMobile } from '../../../helpers/mobileresize/Mobileresize'
import WebbbDashboard from './web/index'
import MobilebdDashboard from './mobile/index'

const index = () =>{
    if(isMobile){
        return(
            <div>
                <MobilebdDashboard />
            </div>
        )
    }else{
        return(
            <div>
                <WebbbDashboard />
            </div>
        )
    }
}

export default index