import React,{useState,useEffect} from 'react'
import axios from 'axios'
import {authHeader} from '../../../helpers/usertoken/UserToken'
import { Tabs } from 'antd';

import {RiPagesFill,RiUser2Fill} from 'react-icons/ri'
import {ImUsers} from 'react-icons/im'

import DepartementsList from './DepartementsList';
import DesignationList from './DesignationList';
import RolesList from './RolesList';
import {useLocation} from 'react-router-dom'

function AddingControllerfunc() {

    const { TabPane } = Tabs;

    const {pathname} = useLocation()


    const [departement_arr, setdepartement_arr] = useState([])
    const [designation_arr, setdesignation_arr] = useState([])
    const [roles_arr, setroles_arr] = useState([])


    const [single_department, setsingle_department] = useState({})
    const [single_designation, setsingle_designation] = useState({})
    const [single_roles, setsingle_roles] = useState({})

    const [edit, setedit] = useState(false)

    const [active_tab,setactive_tab] =  useState('1')



    useEffect(() => {
      if(pathname === '/departments'){
        setactive_tab('1')
        fetch_all_departement()
      }else if(pathname === '/designation'){
        setactive_tab('2')
        fetch_all_designation()
      }else if(pathname === '/roles'){
        setactive_tab('3')
        fetch_all_roles()
      }  
    }, [pathname])

    function fetch_all_departement(){
      axios.get('department/get',authHeader)
      .then((res)=>{
        setdepartement_arr(res.data.datas)
      })
    }

    function fetch_all_designation(){
      axios.get('designation/get',authHeader)
      .then((res)=>{
        setdesignation_arr(res.data.datas)
     })
    }

    function fetch_all_roles(){
      axios.get('role/get',authHeader)
      .then((res)=>{
       setroles_arr(res.data.datas)
      })
    }



    function editfunc(v){
      setedit(true)
      if(active_tab === '1'){
        setsingle_department(v)
      }else if(active_tab === '2'){
        setsingle_designation(v)
      }else if(active_tab === '3'){
        setsingle_roles(v)
      }
    }

    function canceleditfun(){
      setedit(false)
      if(active_tab === '1'){
        setsingle_department({})
      }else if(active_tab === '2'){
        setsingle_designation({})
      }else if(active_tab === '3'){
        setsingle_roles({})
      }
    }

    function setactive_tabfunc(v){
      setactive_tab(v)
      if(v === '1'){
        fetch_all_departement()
      }else if(v === '2'){
        fetch_all_designation()
      }else if(v === '3'){
        fetch_all_roles()
      }
    }


    // console.log('active_tab',active_tab)
    


    
    
    return (
      <div style={{width:'96%',margin:'auto',paddingTop:'50px'}}>
      <Tabs activeKey={active_tab} style={{marginTop:'30px'}} 
      onTabClick={(v)=>{
        setactive_tabfunc(v)
      }}
      >
      <TabPane
        tab={
          <span style={{display:'flex',alignItems:'center'}}>
            <RiPagesFill size={18} />
            <p style={{marginBottom:'0px',marginLeft:'10px'}}>Departments</p>
          </span>
        }
        
        key="1"
      >
     
      <DepartementsList 
          departement_arr={departement_arr}
          single_department_sent={single_department} 
          edit={edit}  
          editfunc = {(v)=>editfunc(v)}
          canceleditfun={()=>canceleditfun()}
          fetch_all_departement={fetch_all_departement}
      />
      </TabPane>
      <TabPane
        tab={
          <span style={{display:'flex',alignItems:'center'}}>
            <RiUser2Fill  size={18} />
            <p style={{marginBottom:'0px',marginLeft:'10px'}}>Designations</p>
          </span>
        }
        key="2"
      >
        <DesignationList 
          designation_arr={designation_arr}
          single_designation_sent={single_designation} 
          edit={edit}  
          editfunc = {(v)=>editfunc(v)}
          canceleditfun={()=>canceleditfun()}
          fetch_all_designation={fetch_all_designation}
      />
      </TabPane>

      <TabPane
        tab={
          <span style={{display:'flex',alignItems:'center'}}>
            <ImUsers  size={18} />
            <p style={{marginBottom:'0px',marginLeft:'10px'}}>Roles</p>
          </span>
        }
        key="3"
      >
        <RolesList 
        roles_arr={roles_arr} 
        single_role_sent={single_roles}
        edit={edit}  
        editfunc = {(v)=>editfunc(v)}
        canceleditfun={()=>canceleditfun()}
        fetch_all_roles={fetch_all_roles}
        />
      </TabPane>
    </Tabs>
    </div>
    )
}

export default AddingControllerfunc
