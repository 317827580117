import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CountUp from 'react-countup';
import BDtable from '../../../../components/bdcomponenttable/BDtable';
import Main from '../../../base/Main';
import axios from "axios";
import { authHeader } from "../../../../helpers/usertoken/UserToken";
import moment from "moment";
import { MdOutlineContacts, MdLeaderboard, MdOutlineTransform } from 'react-icons/md'
import { AiFillMoneyCollect } from 'react-icons/ai'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'

function BD_Dashboard() {

    const navigate = useNavigate()
    const name = useSelector(state => state.Auth.name)


    const task_labels_arr = ['subject', 'due_date', 'contact', 'status', 'priority', 'related_to', 'task_owner']
    const task_labels = ['Subject', 'Due Date', 'Contact Name', 'Status', 'Priority', 'Related to', 'Task Owner']
    const [tasks, setTask] = useState([])

    const labels_arr = ['lead_name', 'company_name', 'email', 'phone', 'contact_source', 'lead_status', 'contact_owner']
    const labels = ['Lead Name', 'Company', 'Email', 'Phone', 'Lead Source', 'Lead Status', 'Lead Owner']
    const [leads, setLead] = useState([])

    const deal_labels_arr = ['deal_name', 'company_name', 'email', 'phone', 'closing_date', 'deal_stage', 'contact_owner']
    const deal_labels = ['Deal Name', 'Company', 'Email', 'Phone', 'Closing Date', 'Stage', 'Deal Owner']
    const [deals, setDeal] = useState([])

    const assigned_labels_arr = ['lead_name', 'company_name', 'email', 'phone', 'assigned_by', 'assigned_to', 'assigned_date', 'status']
    const assigned_labels = ['Lead Name', 'Company', 'Email', 'Phone', 'Assigned By', 'Assigned To', 'Assigned Date', 'Status']
    const [assigned_leads, setassigned_leads] = useState([])

    const current = new Date().toISOString().slice(0, 10);

    const [counts, setcounts] = useState({})



    useEffect(() => {
        getAssignedtomeLeads()
        getHomeCounts()
        gettodaystld()
    }, [])


    function gettodaystld() {
        axios.get(`activity/homepage-today-tld`, authHeader)
            .then((res) => {
                const deal_arr = []
                const dealData = res?.data?.datas?.deals
                dealData.forEach(deal => {
                    let deal_data = {
                        'deal_name': deal?.deal_name,
                        'company_name': deal?.lead_id?.contact_id?.company_name,
                        'email': deal?.lead_id?.contact_id?.email,
                        'phone': deal?.lead_id?.contact_id?.phone,
                        'closing_date': moment(deal?.closing_date).format("DD-M-YYYY"),
                        'deal_stage': deal?.deal_stage,
                        'contact_owner': deal?.deal_owner?.name
                    }
                    deal_arr.push(deal_data)
                })
                setDeal(deal_arr)



                const lead_arr = []
                const leadData = res?.data?.datas?.leads
                leadData.forEach(lead => {
                    if (current === lead.createdAt.slice(0, 10)) {
                        let lead_data = {
                            'lead_name': lead.lead_name,
                            'company_name': lead?.contact_id?.company_name,
                            'email': lead?.contact_id?.email,
                            'phone': lead?.contact_id?.phone,
                            'contact_source': lead?.contact_id?.contact_source?.source_name,
                            'lead_status': lead.lead_status,
                            'contact_owner': lead?.contact_id?.contact_owner?.name
                        }
                        lead_arr.push(lead_data)
                    }
                })
                setLead(lead_arr)


                const task_arr = []
                const taskData = res?.data?.datas?.tasks
                taskData.forEach(task => {
                    if (current == task?.createdAt?.slice(0, 10)) {
                        let task_data = {
                            'subject': task.subject,
                            'due_date': moment(task.due_date).format("DD-M-YYYY"),
                            'contact': task.related_to === 'Lead' ? task?.contact_name?.lead_name : task.related_to === 'Deal' ? task?.contact_name?.deal_name : task?.contact_name?.contact_name,
                            'status': task.status,
                            'priority': task.priority,
                            'related_to': task.related_to,
                            'task_owner': task?.task_owner?.name
                        }
                        task_arr.push(task_data)
                    }
                })
                setTask(task_arr)
            })
    }


    const getHomeCounts = () => {
        axios.get(`activity/homepage-counts?pipeline=Attempted to Contact,Contact in future,Pre Qualified,Not Contacted,Not Qualified`, authHeader)
            .then((res) => {
                setcounts(res.data.datas)
            })
    }


    const getAssignedtomeLeads = () => {
        axios.get(`assign/assigned-to-me`, authHeader).then((res) => {
            let arr = []
            res.data.datas.forEach((d) => {
                let obj = {
                    lead_name: d?.assigned_lead_id?.lead_id?.lead_name,
                    company_name: d?.assigned_lead_id?.lead_id?.contact_id?.company_name,
                    email: d?.assigned_lead_id?.lead_id?.contact_id?.email,
                    phone: d?.assigned_lead_id?.lead_id?.contact_id?.phone,
                    assigned_by: d?.assigned_by?.name,
                    assigned_to: name,
                    assigned_date: moment(d?.createdAt).format('LL'),
                    status: d?.accept
                }
                arr.push(obj)
            })
            setassigned_leads(arr.reverse())
        })
    }




    return (
        <Main>
            <div style={{ background: '#fff' }}>
                <div style={{ width: '90%', margin: 'auto', paddingTop: '55px', paddingBottom: '40px' }}>
                    <div style={{ alignItems: 'center', margin: '10px 0px', marginLeft: '10px', }}>
                        <h5 style={{ fontWeight: '800' }}>
                            {/* Welcome {name} */}
                            BD Dashboard
                        </h5>
                    </div>

                    <div style={{ width: '99.2%', display: 'flex', justifyContent: 'space-between' }}>
                        <Box onClick={() => navigate('/view_contacts')} style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '10px ',
                            width: '100%',
                            borderColor: '#283873',
                            background: '#f5f9ff'
                        }}>
                            <CardContent>
                                <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyItems: 'center', justifySelf: 'center', justifyContent: 'center', marginLeft: '-25%' }}>
                                    <MdOutlineContacts size={54} style={{ color: '#3080ff', padding: '16px' }} />
                                    <Typography component="div" variant="h6"
                                        style={{ fontSize: '18px', paddingLeft: '10px', fontWeight: '900' }}>
                                        Contacts
                                    </Typography>
                                </div>
                                <Typography variant="h5" color="text.primary" component="div" style={{ textAlign: 'center', dispaly: 'flex', alignItems: 'center' }}>
                                    <CountUp start={1.6} end={counts?.contact} style={{ fontWeight: 'bolder' }}
                                        easing="linear" duration={2} />
                                </Typography>
                            </CardContent>
                        </Box>
                        <Box onClick={() => navigate('/view_leads')} style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '10px ',
                            width: '100%',
                            borderColor: '#283873',
                            background: '#f4fff0'
                        }}>
                            <CardContent>
                                <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyItems: 'center', justifySelf: 'center', justifyContent: 'center', marginLeft: '-24%' }}>
                                    <MdLeaderboard size={54} style={{ color: '#38ff26', padding: '16px' }} />
                                    <Typography component="div" variant="h6"
                                        style={{ fontSize: '18px', paddingLeft: '10px', fontWeight: '900' }}>
                                        Leads
                                    </Typography>
                                </div>
                                <Typography variant="h5" color="text.primary" component="div" style={{ textAlign: 'center', dispaly: 'flex', alignItems: 'center' }}>
                                    <CountUp end={counts?.lead} style={{ fontWeight: 'bolder' }} easing="linear"
                                        duration={1.6} />
                                </Typography>
                            </CardContent>
                        </Box>
                        <Box onClick={() => navigate('/view_deals')} style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '10px ',
                            width: '100%',
                            borderColor: '#283873',
                            background: '#fff8eb'
                        }}>
                            <CardContent>
                                <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyItems: 'center', justifySelf: 'center', justifyContent: 'center', marginLeft: '-24%' }}>
                                    <AiFillMoneyCollect size={54} style={{ color: '#f7d425', padding: '16px' }} />
                                    <Typography component="div" variant="h6"
                                        style={{ fontSize: '18px', paddingLeft: '10px', fontWeight: '900' }}>
                                        Deals
                                    </Typography>
                                </div>
                                <Typography variant="h5" color="text.primary" component="div" style={{ textAlign: 'center', dispaly: 'flex', alignItems: 'center' }}>
                                    <CountUp start={0} end={counts?.deal} style={{ fontWeight: 'bolder' }} easing="linear"
                                        duration={1.6} />
                                </Typography>
                            </CardContent>
                        </Box>
                        <Box onClick={() => navigate('/view_leads')} style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '10px ',
                            width: '100%',
                            borderColor: '#283873',
                            background: '#fff2fc'
                        }}>
                            <CardContent>
                                <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyItems: 'center', justifySelf: 'center', justifyContent: 'center', marginLeft: '-24%' }}>
                                    <MdOutlineTransform size={54} style={{ color: '#f424ff', padding: '16px' }} />
                                    <Typography component="div" variant="h6"
                                        style={{ fontSize: '18px', paddingLeft: '10px', fontWeight: '900' }}>
                                        Pipeline Leads
                                    </Typography>
                                </div>
                                <Typography variant="h5" color="text.primary" component="div" style={{ textAlign: 'center', dispaly: 'flex', alignItems: 'center' }}>
                                    <CountUp start={0} end={counts.pipeline_leads} style={{ fontWeight: 'bolder' }} easing="linear" duration={1.6} />
                                </Typography>
                            </CardContent>
                        </Box>
                    </div>

                    <div style={{ minWidth: '50%', maxWidth: '50%', display: 'flex' }}>
                        <div className="row" style={{ minWidth: '200%' }}>
                            <div className="col-md-6">
                                <BDtable reload={gettodaystld} labels={task_labels} labels_arr={task_labels_arr} datas={tasks}
                                    msg="No task's added for today" heading={'Open Tasks'} active1='0' active2='2' />
                            </div>
                            <div className="col-md-6">
                                <BDtable reload={gettodaystld} labels={labels} labels_arr={labels_arr} datas={leads} heading={'Leads'}
                                    msg="No Lead's created for today" active1='0' active2='2' />
                            </div>
                            <div className="col-md-6">
                                <BDtable reload={gettodaystld} labels={deal_labels} labels_arr={deal_labels_arr} datas={deals}
                                    msg="No Deal's created for today" heading={'Deals'} active1='0' active2='2' />
                            </div>
                            <div className="col-md-6">
                                <BDtable
                                    reload={getAssignedtomeLeads}
                                    labels={assigned_labels}
                                    labels_arr={assigned_labels_arr}
                                    datas={assigned_leads}
                                    heading={'Assigned Leads'}
                                    active1='0'
                                    active2='2'
                                    msg="No Lead's assigned for today" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Main>
    )
}

export default BD_Dashboard
