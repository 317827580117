import React, { useEffect, useState } from 'react'
import DetailDesign2 from '../../../components/detail_design/DetailDesign2'
import axios from "axios";
import { authHeader } from "../../../helpers/usertoken/UserToken";
import { useParams } from "react-router-dom";
import moment from 'moment'

function DealDetail() {

    const { id } = useParams();
    const type = "Deal"

    const label = ['Company', 'Contact Name', 'Phone', 'Email', 'Designation', 'Contact Source', 'Website', 'Industry', 'Company Phone', 'Company Email', 'Secondary Contact Person', 'Secondary Contact Phone', 'Secondary Contact Designation', 'Status']
    const label1 = ['Street', 'City', 'State', 'Pincode', 'Country',]
    const label2 = ['Description']

    const label3 = ['Lead Name', 'Title', 'Lead Status', 'Modified Date', 'Contact Name']
    const label4 = ['Description']


    const label5 = ['Deal Name', 'Title', 'Deal Owner', 'Type', 'Stage', 'Closing Date']
    const label6 = ['Description']

    const section = ['Contact Information', 'Address Information', 'Description']
    const section1 = ['Lead Information', 'Description']
    const section2 = ['Deal Information', 'Description']


    const [values, setvalues] = useState([])
    const [values1, setvalues1] = useState([])
    const [values2, setvalues2] = useState([])
    const [values3, setvalues3] = useState([])
    const [values4, setvalues4] = useState([])
    const [values5, setvalues5] = useState([])
    const [values6, setvalues6] = useState([])


    const section_arr = [section, section1, section2]
    const labels_arr = [[label, label1, label2], [label3, label4], [label5, label6]]
    const values_arr = [[values, values1, values2], [values3, values4], [values5, values6]]
    useEffect(() => {
        axios.get(`deal/get/${id}`, authHeader).then((res) => {

            let item = res.data.datas[0]
            let contact_info =
            {
                'company_name': item?.lead_id?.contact_id?.company_name,
                'contact_name': item?.lead_id?.contact_id?.contact_name,
                'phone': item?.lead_id?.contact_id?.phone,
                'email': item?.lead_id?.contact_id?.email,
                'designation': item?.lead_id?.contact_id?.designation,
                'contact_source': item?.lead_id?.contact_id?.contact_source?.source_name,
                'website': item?.lead_id?.contact_id?.website,
                'industry': item?.lead_id?.contact_id?.industry?.industry_name,
                'company_phone': item?.lead_id?.contact_id?.company_phone,
                'company_email': item?.lead_id?.contact_id?.company_email,
                'secondary_contact_person': item?.lead_id?.contact_id?.secondary_contact_person,
                'secondary_contact_phone': item?.lead_id?.contact_id?.secondary_contact_phone,
                'secondary_contact_designation': item?.lead_id?.contact_id?.secondary_contact_designation,
                'status': item?.lead_id?.contact_id?.status,
            }

            let contact_address_info = {
                'street': item?.lead_id?.contact_id?.street,
                'city': item?.lead_id?.contact_id?.city,
                'state': item?.lead_id?.contact_id?.state,
                'pin_code': item?.lead_id?.contact_id?.pin_code,
                'country': item?.lead_id?.contact_id?.country
            }
            let contact_description = {
                'description': item?.lead_id?.contact_id?.description
            }
            let lead_info = [
                item?.lead_id?.lead_name,
                item?.lead_id?.lead_title,
                item?.lead_id?.lead_status,
                moment(item?.lead_id?.contact_id?.updatedAt).format('MMMM Do YYYY, h:mm:ss a'),
                item?.lead_id?.contact_id?.contact_name
            ]
            let lead_description = [
                item?.lead_id?.description
            ]
            let deal_info = [
                item?.deal_name,
                item?.deal_title,
                item?.deal_owner?.name,
                item?.deal_type,
                item?.deal_stage,
                moment(item?.closing_date).format('MMMM Do YYYY, h:mm:ss a'),
            ]
            let deal_description = [
                item?.description
            ]
            return [setvalues(contact_info),
            setvalues1(contact_address_info),
            setvalues2(contact_description),
            setvalues3(lead_info),
            setvalues4(lead_description),
            setvalues5(deal_info),
            setvalues6(deal_description)]
        })
    }, [])


    return (
        <div style={{ width: '100%', margin: 'auto' }}>
            <DetailDesign2 activetab="3" id={id} section={section_arr} values={values_arr} labels={labels_arr} type={type} converted_status={true} />
        </div>
    )
}

export default DealDetail
