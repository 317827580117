import React from 'react';
import { Input } from 'antd';
import { useSelector } from 'react-redux';


function TextInput({textval,val,onchange}){

    const theme_color = useSelector(state=>state.Theme.theme_color);
    return(
        <div>
        <Input style={{borderLeft:`3px solid ${theme_color}`,borderRadius:'4px'}} value={val} onChange={onchange} size={'medium'}/>
        </div>
    )
}


export default TextInput

