import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import WebTaskDetail from './web/TaskDetail'
import MobileTaskDetail from './android/TaskDetail'

const index2 = () =>{
    if(isMobile){
        return(
            <div>
                <MobileTaskDetail />
            </div>
        )
    }else{
        return(
            <div>
                <WebTaskDetail />
            </div>
        )
    }
}

export default index2