import React,{useState,useEffect} from 'react'

import {MdAddCall} from 'react-icons/md'

import {FaTasks} from 'react-icons/fa'

import {MdInput} from 'react-icons/md'
import {RiProjectorFill} from 'react-icons/ri'

import {useSelector} from 'react-redux'
import {authHeader} from "../../../../helpers/usertoken/UserToken";
import axios from 'axios'

function BD_dashboard() {
    const name = useSelector(state=>state.Auth.name)
    const [counts,setcounts]  = useState()
    
    useEffect(()=>{
        getHomeCounts()
    },[])
    
    const getHomeCounts = () => {
        axios.get(`activity/homepage-counts?pipeline=Attempted to Contact,Contact in future,Pre Qualified,Not Contacted,Not Qualified`,authHeader)
        .then((res)=>{
            console.log('setcounts',res.data.datas)
            setcounts(res.data.datas)
        })
    }


    return (
        <div style={{width:'100%',margin:'auto',paddingTop:'60px',paddingBottom:'40px',background:'#fff',height:'100vh',overflowY:'scroll'}}>

              <h3 style={{padding:'20px 0px 10px 10px',fontWeight:'900',textTransform:'Capitalize',fontSize:'18px'}}>Dashboard</h3>
              <h5 style={{fontWeight:'900',fontSize:'13px',paddingLeft:'10px',opacity:0.6,marginTop:'-15px',textTransform:'capitalize'}}>Welcome {name}</h5>
              <div style={{width:'100%',display:'flex',flexDirection:'row',overflow:'scroll',margin:'auto',marginLeft:'3px'}} className="hidescroll_bar">
                
                <div style={{width:'125px',height:'155px',borderRadius:'6px',minWidth:'145px',margin:'10px 5px',display:'grid',alignItems:'center',alignContent:'center',justifyItems:'center',justifyContent:'center',boxShadow:'0px 0 3px #e3e3e3'}}>
                    <div style={{margin:'5px 0px',padding:'10px 10px',alignItems:'center',background:'#fff',width:'100%',borderRadius:'6px',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <div style={{backgroundColor:'#f2fbff',padding:'10px',width:'60px',height:'60px',borderRadius:'4px',display:'grid',justifyContent:'center',alignItems:'center'}}>
                           <MdAddCall size={16} color="#99b5ff"/>
                        </div>
                        <div style={{marginTop:'10px'}}>
                        <h6 style={{fontSize:'18px',color:'black',fontWeight:'900',textAlign:'center'}}>{counts?.contact}</h6>
                        <h6 style={{fontSize:'12px',color:'black',fontWeight:'600',textAlign:'center',opacity:0.5,marginTop:'-5px'}}>Contacts</h6>
                        </div>
                   </div> 
                </div>

                <div style={{width:'125px',height:'155px',borderRadius:'6px',minWidth:'145px',margin:'10px 5px',display:'grid',alignItems:'center',alignContent:'center',justifyItems:'center',justifyContent:'center',boxShadow:'0px 0 3px #e3e3e3'}}>
                    <div style={{margin:'5px 0px',padding:'10px 10px',alignItems:'center',background:'#fff',width:'100%',borderRadius:'6px',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <div style={{backgroundColor:'#fff2db',padding:'10px',width:'60px',height:'60px',borderRadius:'4px',display:'grid',justifyContent:'center',alignItems:'center'}}>
                           <MdInput size={16} color="#ffc236"/>
                        </div>
                        <div style={{marginTop:'10px'}}>
                        <h6 style={{fontSize:'18px',color:'black',fontWeight:'900',textAlign:'center'}}>{counts?.lead}</h6>
                        <h6 style={{fontSize:'12px',color:'black',fontWeight:'600',textAlign:'center',opacity:0.5,marginTop:'-5px'}}>Leads</h6>
                        </div>
                   </div> 
                </div>

                <div style={{width:'125px',height:'155px',borderRadius:'6px',minWidth:'145px',margin:'10px 5px',display:'grid',alignItems:'center',alignContent:'center',justifyItems:'center',justifyContent:'center',boxShadow:'0px 0 3px #e3e3e3'}}>
                    <div style={{margin:'5px 0px',padding:'10px 10px',alignItems:'center',background:'#fff',width:'100%',borderRadius:'6px',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                       <div style={{backgroundColor:'#fff5fb',padding:'10px',width:'60px',height:'60px',borderRadius:'4px',display:'grid',justifyContent:'center',alignItems:'center'}}>
                           <RiProjectorFill size={16} color="#f06eff"/>
                        </div>
                        <div style={{marginTop:'10px'}}>
                        <h6 style={{fontSize:'18px',color:'black',fontWeight:'900',textAlign:'center'}}>{counts?.deal}</h6>
                        <h6 style={{fontSize:'12px',color:'black',fontWeight:'600',textAlign:'center',opacity:0.5,marginTop:'-5px'}}>Deals</h6>
                        </div>
                   </div> 
                </div>

                <div style={{width:'125px',height:'155px',borderRadius:'6px',minWidth:'145px',margin:'10px 5px',display:'grid',alignItems:'center',alignContent:'center',justifyItems:'center',justifyContent:'center',boxShadow:'0px 0 3px #e3e3e3'}}>
                    <div style={{margin:'5px 0px',padding:'10px 10px',alignItems:'center',background:'#fff',width:'100%',borderRadius:'6px',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                       <div style={{backgroundColor:'#fff3f0',padding:'10px',width:'60px',height:'60px',borderRadius:'4px',display:'grid',justifyContent:'center',alignItems:'center'}}>
                           <FaTasks size={16} color="#ff6338"/>
                        </div>
                        <div style={{marginTop:'10px'}}>
                        <h6 style={{fontSize:'18px',color:'black',fontWeight:'900',textAlign:'center'}}>{counts?.pipeline_leads}</h6>
                        <h6 style={{fontSize:'12px',color:'black',fontWeight:'600',textAlign:'center',opacity:0.5,marginTop:'-5px'}}>Pipeline Lead</h6>
                        </div>
                   </div> 
                </div>
                
              </div>



            {/* <div style={{display:'flex',alignItems:'center',margin:'10px 0px',marginLeft:'10px',}}>
                <BsBuilding size={50} color="grey" style={{border:'2px solid grey',padding:'10px',borderRadius:'10px'}} />
            <h5 style={{fontWeight:'bolder',fontFamily:'Arial',margin:'0px 0px 0px 12px'}}>Welcome <span style={{marginLeft:'5px',fontWeight:'400',fontSize:'16px'}}>Krishna</span></h5>

            </div>

            <div style={{ display: 'flex',justifyContent:'space-between'}}>
                <Box style={{ display: 'flex', flexDirection: 'column',margin:'10px ',width:'100%',borderColor:'#283873',border:'1px solid lightgrey',borderRadius:'4px' }}>
                    <divContent>
                    <Typography component="div" variant="h6" style={{fontSize:'14px'}}>
                        Lead's Created
                    </Typography>
                    <Typography variant="h5" color="text.primary" component="div">
                      <CountUp start={0} end={2000} style={{fontWeight:'bolder'}} duration={20} />
                    </Typography>
                    </CardContent>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column',margin:'10px ',width:'100%',borderColor:'#283873',border:'1px solid lightgrey',borderRadius:'4px'}}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h6" style={{fontSize:'14px'}}>
                        Contact's Created
                    </Typography>
                    <Typography variant="h5" color="text.primary" component="div">
                      <CountUp start={0} end={1210} style={{fontWeight:'bolder'}} duration={20} />
                    </Typography>
                    </CardContent>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column',margin:'10px ',width:'100%',borderColor:'#283873',border:'1px solid lightgrey',borderRadius:'4px'}}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h6" style={{fontSize:'14px'}}>
                        Lead's Created
                    </Typography>
                    <Typography variant="h5" color="text.primary" component="div">
                     <CountUp start={0} end={121} style={{fontWeight:'bolder'}} duration={20} />
                    </Typography>
                    </CardContent>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column',margin:'10px ',width:'100%',borderColor:'#283873',border:'1px solid lightgrey',borderRadius:'4px'}}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h6" style={{fontSize:'14px'}}>
                        Lead's Created
                    </Typography>
                    <Typography variant="h5" color="text.primary" component="div">
                     <CountUp start={0} end={1} style={{fontWeight:'bolder'}} duration={20} />
                    </Typography>
                    </CardContent>
                </Box>
            </div>
            <div style={{minWidth:'50%',maxWidth:'50%',display:'flex'}}>
                <div className="row" style={{minWidth:'200%'}} >
                <div className="col-md-6">
                    <BDtable labels = {labels} labels_arr = {labels_arr} datas ={data} heading={'Open Tasks'} />
                    </div>
                    <div className="col-md-6">
                    <BDtable labels = {[]} labels_arr = {[]} datas ={[]} heading={'Deals'} />
                    </div>
                    <div className="col-md-6">
                    <BDtable labels = {[]} labels_arr = {[]} datas ={[]} heading={'Leads'} />
                    </div>
                    <div className="col-md-6">
                    <BDtable labels = {[]} labels_arr = {[]} datas ={[]} heading={'Referred Leads'} />
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default BD_dashboard
