import React, { useState, useEffect } from 'react'
import { Input, Modal, message, DatePicker } from 'antd';
import InputField from '../../../components/inputcomponents/InputField';
import { Validators } from '../../../components/Validators/Validators'
import DropDown from '../../../components/inputcomponents/DropDown';

import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { useSelector } from 'react-redux'
import ReactMaterialTable from '../../../components/table_design/ReactMaterialTable';
import PrimaryButton, { PrimaryButtonAdd, SecondaryButton } from '../../../components/buttons/PrimaryButton';
import Inputerror from '../../../components/errors/Inputerror';
import moment from 'moment'
import { data_not_found } from '../../../helpers/Constants';
import toast,{Toaster} from 'react-hot-toast';

function ViewMettings({visible}) {

    const [modal, setmodal] = useState(visible)
    const { TextArea } = Input;

    const user_id = useSelector(state => state.Auth.id)
    const [reminder, setReminders] = useState([])
    const page = '1'
    const show_remindar = false


    const [data, setdata] = useState({ title: '', related_to: '', contact: '', contact_id: '', date_time: '', location: '', description: '', id: '', edit: false })
    const [dataerr, setdataerr] = useState({ titleerr: {}, related_to_err: '', contacterr: {}, date_time_err: {}, location_err: {}, description_err: {} })

    const [meeting_data, setmeeting_data] = useState([])

    const [related_to_data, setrelated_to_data] = useState([])
    const [pagination, setpagination] = useState({})

    const [remindar_data, setremindar_data] = useState({ on_date: '', on_time: '', repeat_type: '', notify: '' })
    const [remindar_data_err, setremindar_data_err] = useState({ on_date_err: {}, on_time_err: {}, repeat_type_err: {}, notify_err: {} })



    useEffect(() => {
        getallmeetings()
    }, [])

    function getallmeetings() {
        axios.get(`meeting/get/${page}`, authHeader)
            .then((res) => {
                setmeeting_data(res.data.datas)
                setpagination(res.data.pagination)
            })
    }
    function addmetting(v) {
        setdata({ ...data, edit: false })
        setmodal(true)

    }
    const related_to = [
        {
            value: 'Lead',
            id: 'Lead'
        },
        {
            value: 'Deal',
            id: 'Deal'
        },
        {
            value: 'Contact',
            id: 'Contact'
        },
    ]
    function handleChange({ key, value }) {
        if (key === 'title') {
            setdataerr({ ...dataerr, titleerr: {} })
        } else if (key === 'from_date') {
            setdataerr({ ...dataerr, from_date_err: {} })
        } else if (key === 'to_date') {
            setdataerr({ ...dataerr, to_date_err: {} })
        } else if (key === 'location') {
            setdataerr({ ...dataerr, location_err: {} })
        } else if (key === 'on_date') {
            setremindar_data_err({ ...remindar_data_err, on_date_err: {} })
        } else if (key === 'on_time') {
            setremindar_data_err({ ...remindar_data_err, on_time_err: {} })
        } else if (key === 'repeat_type') {
            setremindar_data_err({ ...remindar_data_err, repeat_type_err: {} })
        } else if (key === 'notify') {
            setremindar_data_err({ ...remindar_data_err, notify_err: {} })
        }
        if (key !== 'on_date' && key !== 'on_time' && key !== 'repeat_type' && key !== 'notify') {
            setdata({ ...data, [key]: value })
        } else {
            setremindar_data({ ...remindar_data, [key]: value })
        }
    }
    function meetingdetailfunc() {
        window.location.href = '/meeting_detail'
    }
    function addMetting() {
        if (!data.title) {
            setdataerr({ ...dataerr, titleerr: { error: true, message: 'This field is required' } })
        } else if (!data.related_to) {
            setdataerr({ ...dataerr, related_to_err: { error: true, message: 'This field is required' } })
        } else if (!data.contact) {
            setdataerr({ ...dataerr, contacterr: { error: true, message: 'This field is required' } })
        } else if (!data.date_time) {
            setdataerr({ ...dataerr, date_time_err: { error: true, message: 'This field is required' } })
        } else if (!data.location) {
            setdataerr({ ...dataerr, location_err: { error: true, message: 'This field is required' } })
        } else {
            if (show_remindar) {
                if (!remindar_data.on_date) {
                    setremindar_data_err({ ...remindar_data_err, on_date_err: { error: true, message: 'This field is required' } })
                } else if (!remindar_data.on_time) {
                    setremindar_data_err({ ...remindar_data_err, on_time_err: { error: true, message: 'This field is required' } })
                } else if (!remindar_data.repeat_type) {
                    setremindar_data_err({ ...remindar_data_err, repeat_type_err: { error: true, message: 'This field is required' } })
                } else if (!remindar_data.notify) {
                    setremindar_data_err({ ...remindar_data_err, notify_err: { error: true, message: 'This field is required' } })
                }
            } else {

                const send_data = {}
                send_data['title'] = data.title
                send_data['related_to'] = data.related_to
                send_data['contact_name'] = data.contact_id
                send_data['date_time'] = data.date_time
                send_data['location'] = data.location
                send_data['description'] = data.description
                send_data['host'] = user_id
                if (!data.edit) {
                    axios.post('meeting/create', send_data, authHeader)
                        .then((res) => {
                            resetform()
                            setmodal(false)
                            toast.success('Meeting Created Successfully')
                            getallmeetings()
                        }).catch((err)=>{
                            setdataerr({ ...dataerr, date_time_err: { error: true, message:err?.response?.data?.errors?.date_time } })
                        })
                } else if (data.edit) {
                    axios.put(`meeting/update/${data.id}`, send_data, authHeader)
                        .then((res) => {
                            resetform()
                            setmodal(false)
                            toast.success('Meeting Updated Successfully')
                            getallmeetings()
                        }).catch((err)=>{
                            setdataerr({ ...dataerr, date_time_err: { error: true, message:err?.response?.data?.errors?.date_time } })
                        })
                }


            }
        }
    }
    function seteditmeeting(v) {
        if (v?.related_to === 'Deal') {
            setdata({
                ...data,
                title: v?.title,
                related_to: v?.related_to,
                contact_name: v?.contact_name?.deal_name,
                contact_id: v?.contact_name?.id,
                date_time: v?.date_time,
                location: v?.location,
                description: v?.description,
                id: v?.id,
                edit: true,
                contact: v?.contact_name?.deal_name,
            })
            setmodal(true)

            getDeals()
        } else if (v?.related_to === 'Lead') {
            setdata({
                ...data,
                title: v?.title,
                related_to: v?.related_to,
                contact_name: v?.contact_name?.deal_name,
                contact_id: v?.contact_name?.id,
                date_time: v?.date_time,
                location: v?.location,
                description: v?.description,
                id: v?.id,
                edit: true,
                contact: v?.contact_name?.lead_name,
            })
            setmodal(true)

            getLeads()
        } else if (v?.related_to === 'Contact') {
            setdata({
                ...data,
                title: v?.title,
                related_to: v?.related_to,
                contact_name: v?.contact_name?.deal_name,
                contact_id: v?.contact_name?.id,
                date_time: v?.date_time,
                location: v?.location,
                description: v?.description,
                id: v?.id,
                edit: true,
                contact: v?.contact_name?.contact_name
            })
            setmodal(true)

        }
    }
    function getLeads() {
        axios.get('lead/get', authHeader)
            .then((res) => {


                const temp_arr = []
                res.data.datas.forEach(e => {
                    const data = {}
                    data['id'] = e._id
                    data['value'] = e.lead_name
                    temp_arr.push(data)
                })

                setrelated_to_data(temp_arr)
            }).catch((err) => {
            })
    }
    function getDeals() {
        axios.get('deal/get', authHeader)
            .then((res) => {
                const temp_arr = []
                res.data.datas.forEach(e => {
                    const data = {}
                    data['id'] = e.id
                    data['value'] = e.deal_name
                    temp_arr.push(data)
                })

                setrelated_to_data(temp_arr)
            }).catch((err) => {
            })
    }
    function getContacts() {
        axios.get('contact/get', authHeader)
            .then((res) => {
                const temp_arr = []
                res.data.datas.forEach(e => {
                    const data = {}
                    data['id'] = e.id
                    data['value'] = e.contact_name
                    temp_arr.push(data)
                })

                setrelated_to_data(temp_arr)
            }).catch((err) => {
                // console.log('err',err)
            })
    }
    function selectedvalue(v) {
        if (data.edit && v?.id !== data.related_to) {
            setdata({ ...data, contact: '', contact_id: '' })
        }
        if (v?.id && !data.edit) {

            setdataerr({ ...dataerr, [v.nameerr]: {} })
            setdata({ ...data, [v.name]: v?.id, [v.id_name]: v?.id })


            if (v?.id === 'Deal') {
                getDeals()
            } else if (v?.id === 'Lead') {
                getLeads()
            } else if (v?.id === 'Contact') {
                getContacts()
            }
        } if (v?.id !== data.related_to && data.edit) {
            if (v?.id !== data.related_to && v?.name === 'related_to') {
                setdata({ ...data, contact_id: '', contact: '', [v.name]: v?.id, [v.id_name]: v?.id })
                if (v?.id === 'Deal') {
                    getDeals()
                } else if (v?.id === 'Lead') {
                    getLeads()
                } else if (v?.id === 'Contact') {
                    getContacts()
                }
            } else {
                setdataerr({ ...dataerr, [v.nameerr]: {} })
                setdata({ ...data, [v.name]: v?.id, [v.id_name]: v?.id })

                if (v?.id === 'Deal') {
                    getDeals()
                } else if (v?.id === 'Lead') {
                    getLeads()
                } else if (v?.id === 'Contact') {
                    getContacts()
                }
            }
        }
    }
    function resetform() {
        setmodal(false)
        setdata({ ...data, title: '', related_to: '', contact: '', from_date: '', from_time: '', location: '', description: '' })
        setdataerr({ ...dataerr, titleerr: {}, related_to_err: '', contacterr: {}, from_date_err: {}, from_time_err: {}, location_err: {}, description_err: {} })
    }
    function cancelmettingcreatefunc() {
        setmodal(false)
        resetform()
    }
    function handledatechange(v, v1) {
        setdata({ ...data, date_time: v1 })
        setdataerr({...dataerr,date_time_err:{status:false,message:''}})
    }
    function handleok(v, v1) {
        console.log('handleok', v, v1)
    }
    function paginateddata(v) {
        axios.get(`meeting/get/${v}`, authHeader)
            .then((res) => {
                setmeeting_data(res.data.datas)
                setpagination(res.data.pagination)
            })
    }
    async function getReminder(id) {
        await axios.get(`reminder/get?type=${'Meeting'}&type_id=${id}`, authHeader).then((res) => {
            setReminders(res.data.datas.reverse())
        })


    }
    function disabledDate(current) {
        return current && current < moment().startOf('day');

    }



    return (
        <div style={{ width: '100%', minHeight: '100vh', background: 'white', margin: 'auto', display: 'flex', flexDirection: 'column', }}>
            <div style={{ width: '90%', background: 'white', margin: 'auto', display: 'flex', flexDirection: 'column', marginTop: '40px' }}>
                <Toaster
                  />
                <Modal
                    title="Meeting Information"
                    style={{ top: 0 }}
                    visible={modal}
                    onOk={() => addMetting()}
                    onCancel={() => cancelmettingcreatefunc()}
                    footer={null}
                >
                    <div className="row" >
                        <div className="col-md-12">
                            <div style={{ margin: '10px 0px' }}>
                                <p style={{ marginBottom: '8px', fontSize: '14px' }}>Title</p>
                                <InputField
                                    type="text"
                                    priority="high"
                                    onChange={handleChange}
                                    name={'title'}
                                    error_obj={dataerr.titleerr}
                                    value={data.title}
                                    validators={[{ check: Validators.required, message: 'This field is required' }, { check: Validators.minimum_length_required, message: 'Title should be atleast 3 characters' }]}

                                />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div style={{ margin: '10px 0px' }}>
                                <p style={{ marginBottom: '8px', fontSize: '14px' }}>Related To</p>
                                <DropDown
                                    onChange={(v) => selectedvalue(v)}
                                    priority={'high'}
                                    name='related_to'
                                    id_name='related_to'
                                    value={data.related_to}
                                    nameerr='related_to_err'
                                    options={related_to}
                                    error_obj={dataerr.related_to_err}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div style={{ margin: '10px 0px' }}>
                                <p style={{ marginBottom: '8px', fontSize: '14px' }}>{data.related_to} Name</p>
                                <DropDown
                                    value={data.contact}
                                    onChange={(v) => selectedvalue(v)}
                                    priority={'high'}
                                    name='contact'
                                    id_name='contact_id'
                                    nameerr='contacterr'
                                    options={related_to_data}
                                    error_obj={dataerr.contacterr}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div style={{ margin: '10px 0px', width: '100%', alignItems: 'center' }}>
                                <p style={{ fontSize: '14px', marginRight: '10px', marginBottom: '5px' }}>Date</p>
                                <DatePicker
                                    style={{ marginTop: '3px', width: '100%', borderLeft: '4px solid #292961', borderRadius: '4px' }}
                                    showTime
                                    disabledDate={disabledDate}
                                    onChange={handledatechange} onOk={handleok}
                                    format="YYYY-MM-DD HH:mm"
                                    value={data.date_time !== '' ? moment(data.date_time) : null}
                                />
                                <Inputerror message={dataerr.date_time_err.message} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div style={{ margin: '10px 0px' }}>
                                <p style={{ marginBottom: '8px', fontSize: '14px' }}>Location</p>
                                <InputField
                                    type="text"
                                    priority="high"
                                    onChange={handleChange}
                                    name={'location'}
                                    error_obj={dataerr.location_err}
                                    value={data.location}
                                    validators={[{ check: Validators.required, message: 'This field is required' }, { check: Validators.minimum_length_required, message: 'Location field should be required' }]}

                                />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div style={{ margin: '10px 0px' }}>
                                <p style={{ marginBottom: '8px', fontSize: '14px' }}>Description</p>
                                <TextArea value={data.description} onChange={(e) => setdata({ ...data, description: e.target.value })} size="medium" style={{ width: '100%', outline: 'none', boxShadow: 'none' }} />
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <div onClick={resetform}>
                                <SecondaryButton btn_name="Cancel" />
                            </div>
                            <div onClick={addMetting}>
                                <PrimaryButton btn_name={data.edit ? "Update" : "Save"} />
                            </div>
                        </div>

                    </div>
                </Modal>


                <div style={{ height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <h6 style={{fontWeight:'700'}}>Total Meetings ({meeting_data.length}) </h6>
                    <div style={{ marginTop: '0px' }} onClick={() => addmetting()}>
                        <PrimaryButtonAdd btn_name="Create Meeting" />
                    </div>
                </div>


                <div style={{ display: 'flex' }}>

                    <div style={{ flex: 1, }}>
                        {meeting_data.length > 0 ?
                            <ReactMaterialTable
                                reminder={reminder}
                                editmeeting={(v) => seteditmeeting(v)}
                                selected={data?.id}
                                data={meeting_data}
                                type="meeting"
                                setpagefuun={v => paginateddata(v)}
                                pagination={pagination}
                                func={() => meetingdetailfunc()}
                                getreminderfun={(v) => getReminder(v)} />
                            :
                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '200px', objectFit: 'contain' }} />
                                <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{"You don't have any Meeting created yet."}</h4>
                                <h6 style={{ color: '#747474', fontSize: '13px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>{"Click on the create meeting."}</h6>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewMettings
