import React, { useEffect, useState } from 'react'
import './Navbar.css'
import { AiFillCopy } from 'react-icons/ai'
import { Dropdown, Menu, Drawer, Modal, Input, Row, Col } from 'antd';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { FaBell } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Profile from '../../profile/web/index'
import { useDispatch, useSelector } from 'react-redux'
import { VscTriangleDown } from 'react-icons/vsc'
import { logout } from '../../../redux/actionPayload/AuthPayload'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import InputField from '../../../components/inputcomponents/InputField';
import moment from 'moment';
import {fcpl_white_logo} from '../../../helpers/Constants'


function Navbar() {


  const { pathname } = useLocation()


  const theme_color = useSelector(state=>state.Theme.theme_color)



  useEffect(() => {
    getnotifications()
  }, [])



  let p = pathname

  const { TextArea } = Input
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [notifications, setnotifications] = useState([])

  const name = useSelector(state => state.Auth.name)
  const login = useSelector(state => state.Auth.login)
  const email = useSelector(state => state.Auth.email)
  const roles = useSelector(state => state.Auth.roles)
  const r = useSelector(state => state.Auth.roles)
  const fcpl_id = useSelector(state => state.Auth.fcpl_id)



  // console.log('roles kp',roles)


  const [mobile, setmobile] = useState('')

  const [visible, setvisible] = useState(false)
  const [notification_visible, setnotification_visible] = useState(false)

  const [drawer, setdrawer] = useState(false)

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  const menu = (
    <Menu style={{ width: '350px', padding: '10px', }}>
      <div style={{ borderBottom: '0.5px solid #f0f0f0', paddingBottom: '10px', display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
        <h6 style={{ fontWeight: '500', marginBottom: '0px' }}>Notifications </h6>
        {/* <p style={{ marginBottom: '0px', fontSize: '12px', color: '#1db6f2', fontWeight: '500', cursor: 'pointer', }}>Mark all as Read</p> */}
      </div>

      {notifications.length > 0 ?
        <>
          {notifications.map((n) => (
            // <Menu.Item>
            <div style={{ display: 'flex', padding: '10px 0px', cursor: 'pointer' }} onClick={() => detailcreation(n)}>
              {/* {!n.isRead && <p style={{ width: '5px', height: '5px', backgroundColor: '#292961', color: 'transparent', borderRadius: '10px', overflow: 'hidden', position: 'absolute', right: '10px' }}>k</p>} */}
              <StyledBadge
                style={{ width: '10%' }}
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
              >
                <Avatar sx={{ bgcolor: deepOrange[500], width: 24, height: 24, fontSize: 14 }} alt={`${n?.type_id?.contact_owner?.name?.slice(0, 1)}`} src="/static/images/avatar/1.jpg" variant="dot" />
              </StyledBadge>
              <div style={{ marginLeft: '10px', width: '90%' }}>
                <h6 style={{ fontSize: '14px', marginBottom: '0px' }}>{n?.type_id?.contact_owner?.name}</h6>
                <p style={{ marginBottom: '0px', fontSize: '10px' }}>{n?.notification}</p>
              </div>
              <p style={{ fontSize: '10px', marginBottom: '0px', right: '0px', width: '40%', position: 'absolute' }}>{moment(n?.type_id?.createdAt).format('lll')},</p>
            </div>
            // </Menu.Item>
          ))}
        </>
        :
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src="https://fidecrms3bucket.s3.amazonaws.com/post.png" style={{ width: '180px', resize: 'contain', display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
        </div>
      }


    </Menu>
  );

  function logoutfun() {
    localStorage.setItem("access_token", "")
    dispatch(logout())
    navigate('/login')
  }

  const profile_drawer = () => {
    setdrawer(!drawer)
  }

  function detailcreation(v) {
    setnotification_visible(false)
    axios.put(`activity/update-notifications/${v?.id}`, {}, authHeader)
      .then((res) => {
        getnotifications()
        // console.log('res.data update', res.data)
      })
    if (v?.type === 'Contact') {
      navigate(`/contact_detail/${v?.type_id?.id}`)
    }
  }

  function getnotifications() {
    axios.get(`activity/get-notifications`, authHeader)
      .then((res) => {
        setnotifications(res.data.datas.reverse())
        // console.log('notification', res.data.datas)
      })

  }

  // const id = useSelector(state => state.Auth.id)

  function gotodashboard() {
    if (login) {
      if (r.includes('admin') || r.includes('hod') || r.includes('Manager') || r.includes('db_head') || r.includes('dbuser') || r.includes('bd_user')) {
        navigate('/bd_dashboard')
      } else if (r.includes('controller') && !r.includes('admin') && !r.includes('hod') && !r.includes('Manager') && !r.includes('db_head') && !r.includes('db_user')) {
        navigate('/controller_dashboard')
      }
    } else {
      navigate('/login')
    }

  }

  //  console.log('roles',roles)
  // 
  return (
    <div style={{ width: '100%', maxHeight: '40px', minHeight: '40px', backgroundColor: theme_color, display: 'flex', alignItems: 'center', position: 'fixed', top: 0, left: 0, zIndex: '30', marginBottom: '100px' }}>

      <Modal visible={visible} onClose={() => setvisible(false)}>
        <Row>
          <Col span={12}>
            <h6 style={{ fontWeight: 'bold' }}>Add Industry/Contact Source</h6>

            <div>
              <h6>Industry Name</h6>

              <InputField
                priority={'high'}
              />
            </div>

            <div>
              <h6>Description</h6>
              <TextArea rows={4} />
            </div>

          </Col>

          <Col span={12}>

          </Col>
        </Row>


      </Modal>


      <Drawer
        placement={'right'}
        closable={false}
        onClose={() => profile_drawer()}
        visible={drawer}
        maskStyle={{ backgroundColor: '#000', opacity: 0.7 }}
        key={'left'}
        size='large'
        width='400px'
        bodyStyle={{ background: '#fff' }}
      >
        {/* <div style={{ width: '114.4%', margin: '-7% -7.2%' }}> */}
        <Profile mobile={mobile} func={() => profile_drawer()} logoutfun={logoutfun} />
        {/* </div> */}
      </Drawer>
      
      <div style={{display:'flex',alignItems:'center',margin: '0px 15px', }}>
      <img src={fcpl_white_logo} style={{width:'24px',height:'24px'}} />
      <h6 onClick={gotodashboard} style={{ marginBottom: 0, color: 'white', padding: '0px 5px', fontSize: '14px', fontWeight: '700', cursor: 'pointer' }}>FCPL CRM</h6>
      </div>



      {(roles?.includes('db_head') ||
        roles?.includes('db_user') ||
        roles?.includes('db_sub_user') ||
        roles?.includes('hod') ||
        roles?.includes('manager') ||
        roles?.includes('admin') ||
        roles?.includes('bd_user') ||
        roles?.includes('fms_user') ||
        roles?.includes('fms_hr') 
        ) && login &&
        <div className="menu-item">
          <ul style={{ display: 'flex', alignItems: 'center', alignSelf: 'center', paddingTop: '15px', listStyle: 'none', color: 'white', height: '100%' }}>

            {
        (roles?.includes('db_head') ||
        roles?.includes('db_user') ||
        roles?.includes('db_sub_user') ||
        roles?.includes('hod') ||
        roles?.includes('manager') ||
        roles?.includes('admin') ||
        roles?.includes('bd_user'))
         &&
         <>
            <li className='menu-nav-active' ><Link to="/bd_dashboard" className={p !== '/bd_dashboard' ? "link-nav" : "link-nav-active"}>Home</Link></li>
            <li className='menu-nav'><Link to="/view_contacts" className={p !== '/view_contacts' ? "link-nav" : "link-nav-active"}>Contacts</Link></li>
            <li className='menu-nav'><Link to="/view_leads" className={p !== '/view_leads' ? "link-nav" : "link-nav-active"}>Leads</Link></li>
            <li className='menu-nav'><Link to="/view_deals" className={p !== '/view_deals' ? "link-nav" : "link-nav-active"}>Deals</Link></li>
            <li className='menu-nav'><Link to="/view_tasks" className={p !== '/view_tasks' ? "link-nav" : "link-nav-active"}>Tasks</Link></li>
            <li className='menu-nav'><Link to="/view_meetings" className={p !== '/view_meetings' ? "link-nav" : "link-nav-active"}>Meetings</Link></li>
            <li className='menu-nav'><Link to="/view_analytics" className={p !== '/view_analytics' ? "link-nav" : "link-nav-active"}>Analytics</Link></li>
         </>}    
            {(roles?.includes('db_head') ||
              roles?.includes('db_user') ||
              roles?.includes('hod') ||
              roles?.includes('admin')) &&
              <li className='menu-nav'><Link to="/TeamCLD" className={p !== '/TeamCLD' ? "link-nav" : "link-nav-active"}>{roles.includes('hod') ? 'Department' : 'Company'} CLD</Link></li>}
            {(roles?.includes('admin') ||
              roles?.includes('controller')) &&
              <li className='menu-nav'><Link to="/controller_dashboard" className={p !== '/controller_dashboard' ? "link-nav" : "link-nav-active"}>Controller Dashboard</Link></li>}
            {(fcpl_id === 'fcpl_240' ||
              fcpl_id === 'fcpl_239') &&
              <li className='menu-nav'><Link to="/rk/krishna_dashboard" className={p !== '/rk/krishna_dashboard' ? "link-nav" : "link-nav-active"}>RK Dashboard</Link></li>}
            {(fcpl_id === 'fcpl_240' ||
              fcpl_id === 'fcpl_239' ||
              roles?.includes('fms_user') ||
              roles?.includes('fms_hr') ) &&
              <li className='menu-nav'><Link to="/fms/fms_dashboard" className={p !== '/fms/fms_dashboard' ? "link-nav" : "link-nav-active"}>FMS Dashboard</Link></li>} 
              {(fcpl_id === 'fcpl_240' ||
              fcpl_id === 'fcpl_239'
             ) &&
              <li className='menu-nav'><Link to="/it/it_dashboard" className={p !== '/it/it_dashboard' ? "link-nav" : "link-nav-active"}>It Dashboard</Link></li>}  
            {/* {(fcpl_id === 'fcpl_240' ||
              fcpl_id === 'fcpl_239') &&
              <li className='menu-nav'><Link to="/krishna_dashboard" className={p !== '/krishna_dashboard' ? "link-nav" : "link-nav-active"}>Mail</Link></li>} */}
          </ul>
        </div>
      }


      {(!roles?.includes('db_head') &&
        !roles?.includes('db_user') &&
        !roles?.includes('db_sub_user') &&
        !roles?.includes('hod') &&
        !roles?.includes('manager') &&
        !roles?.includes('admin') &&
        !roles?.includes('bd_user') &&
        roles.includes('controller')) &&
        login &&
        <div className="menu-item">
          <ul style={{ display: 'flex', alignItems: 'center', alignSelf: 'center', paddingTop: '15px', listStyle: 'none', color: 'white', height: '100%' }}>
            <li className='menu-nav'><Link to="/controller_dashboard" className={p !== '/controller_dashboard' ? "link-nav" : "link-nav-active"}>Home</Link></li>
            <li className='menu-nav-active'><Link to="/on_board" className={p !== '/on_board' ? "link-nav" : "link-nav-active"}>Onboard</Link></li>
            <li className='menu-nav'><Link to="/departments" className={p !== '/departments' ? "link-nav" : "link-nav-active"}>Departments</Link></li>
            <li className='menu-nav'><Link to="/designation" className={p !== '/designation' ? "link-nav" : "link-nav-active"}>Designation</Link></li>
            <li className='menu-nav'><Link to="/roles" className={p !== '/roles' ? "link-nav" : "link-nav-active"}>Roles</Link></li>
            <li className='menu-nav'><Link to="/onboarded_employee" className={p !== '/onboarded_employee' ? "link-nav" : "link-nav-active"}>Assign</Link></li>
          </ul>
        </div>
      }


      {login &&
        <div className="righticon" style={{ display: 'flex', position: 'absolute', alignItems: 'center', right: '20px', top: '9px', alignSelf: 'flex-end' }}>

          <div className='nav-section' style={{ borderLeft: '2px solid #414750' }}>
            <AiFillCopy size={26} color="white" style={{ marginLeft: '0px', color: 'white' }} className='nav-section' />
          </div>



          <Dropdown overlay={menu} visible={notification_visible} onClick={() => setnotification_visible(!notification_visible)} placement="bottomLeft" arrow trigger={['click']} >
            <div className='nav-section' style={{ borderLeft: '2px solid #414750' }}>
              <FaBell size={21} color="white" className='nav-section' />
            </div>
          </Dropdown>

          <div className='nav-section' style={{ borderLeft: '2px solid #414750', cursor: 'pointer' }} onClick={() => setdrawer(true)}>
            <h6 style={{ marginBottom: '0px', marginRight: '2px', color: 'white', fontSize: '12px', fontWeight: '700', textTransform: 'Capitalize' }}>{name?.split(' ')[0]}</h6>
            <VscTriangleDown color="white" size={14} style={{ paddingLeft: '5px', marginTop: '0px' }} />
          </div>

        </div>}
    </div>
  )
}

export default Navbar
