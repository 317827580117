import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { RiOpenSourceFill,RiBuildingFill } from 'react-icons/ri'
import { MdSpaceDashboard, MdResetTv } from 'react-icons/md'
import { IoSettingsSharp, IoLogOut, IoSettingsOutline } from 'react-icons/io5'
import { SiRelianceindustrieslimited } from 'react-icons/si'
import {VscGraphLeft} from 'react-icons/vsc'
import {fcpl_white_logo} from '../../helpers/Constants'

import './KrishnaDashboard.css'
import { ItSmallText, ItSmallText1 } from '../../helpers/constants/ConstantsText'
import {useSelector} from 'react-redux'
import { HiLogout, HiOutlineColorSwatch } from 'react-icons/hi'
import {GiProgression} from 'react-icons/gi'
import {RiAncientPavilionLine,RiContactsBookUploadLine} from 'react-icons/ri'

function KrishnaDashboard() {

    const navigate = useNavigate()
    const location = useLocation()

    const { pathname } = location
    const theme_color = useSelector(state=>state.Theme.theme_color)



    function navigatetopage(v) {
        navigate(v)
    }


    return (
        <>
        


<div style={{ background: theme_color, height: '100vh', position: 'fixed', left: '0px', maxHeight: '100vh', top: '0px', alignItems: 'center',minWidth:'200px',maxWidth:'200px' }}>
<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', justifyItems: 'center', width: '100%',padding:'20px' }}>
    <div style={{ display: 'flex', width: '100%', flexDirection: 'column',alignContent:'center',justifyContent:'center' }}>

        <div style={{display:'flex',alignItems:'center',borderBottom:'1px solid #e2e0e0',paddingBottom:'10px' }}>
            <img className="inactive" src={fcpl_white_logo} style={{ width: '25px', height: '25px',marginTop:'0px',marginBottom:'0px',opacity:1 }} alt="#" />
            <ItSmallText col="#fff" val={'RK DASHBOARD'} marginfun={'0px 0px 0px 10px'} /> 

        </div> 
        
        <ItSmallText val={'MENU'}  col="#e2e0e0" marginfun={'25px 0px 5px 10px'}/>
        <div className={pathname !== '/rk/krishna_dashboard' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/rk/krishna_dashboard')}>
            <MdSpaceDashboard size={15} className={pathname !== '/rk/krishna_dashboard' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Dashboard'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/rk/crm_report' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/rk/crm_report')}>
            <GiProgression size={15} className={pathname !== '/rk/crm_report' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'CRM Report'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/rk/add_industry' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/rk/add_industry')}>
            <RiBuildingFill size={15} className={pathname !== '/rk/add_industry' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={"Industry's"} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/rk/contact_source' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/rk/contact_source')}>
            <RiAncientPavilionLine size={15} className={pathname !== '/rk/contact_source' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Contact Source'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/rk/userxl_upload' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/rk/userxl_upload')}>
            <RiContactsBookUploadLine size={15} className={pathname !== '/rk/userxl_upload' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Upload XL'} marginfun={'0px 0px 0px 5px'} /> 
        </div>


    <ItSmallText val={'OPTIONS'}  col="#e2e0e0" marginfun={'25px 0px 5px 10px'}/>
       <div className={pathname !== '/rk/crm_users' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/rk/crm_users')}>
            <MdResetTv size={15} className={pathname !== '/rk/crm_users' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Update Password'} marginfun={'0px 0px 0px 5px'} /> 
        </div>



    </div>

    <ItSmallText val={'CUSTOMIZATION'}  col="#e2e0e0" marginfun={'25px 0px 5px 10px'}/>


    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

        <div className={pathname !== '/rk/settings' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/rk/settings')}>
            <HiOutlineColorSwatch size={16} className={pathname !== '/rk/settings' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Theme'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/rk/settings' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/rk/settings')}>
            <IoSettingsOutline size={16} className={pathname !== '/rk/settings' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Setting'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/rk/log_out' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/bd_dashboard')}>
            <HiLogout size={16} className={pathname !== '/rk/log_out' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Logout'} marginfun={'0px 0px 0px 5px'} /> 
        </div>
    </div>
</div>
</div>
</>
    )
}


export default KrishnaDashboard