import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import AddIndustryWeb from "./web/AddIndustry";
import AddIndustryMob from "./mobile/AddIndustry";

const index1 =()=>{
    if(isMobile){
        return <AddIndustryMob/>
    }else {
        return <AddIndustryWeb />
    }
}

export default index1