import React from 'react'
import { data_not_found } from '../../helpers/Constants';


function BDtable({ heading, labels, datas, labels_arr, active1, active2, msg, reload }) {


    function navigatemetopage() {
        if (heading === 'Leads' || heading === 'Referred Leads') {
            window.location.href = "/view_leads"
        } else if (heading === 'Deals') {
            window.location.href = "/view_deals"
        } else if (heading === 'Open Tasks') {
            window.location.href = '/view_tasks'
        }
    }


    return (
        <>
            {heading !== 'Assigned Leads' ?
                <div style={{ width: '100%', height: '400px', border: '1px solid #f2f2f2', borderRadius: '1px', margin: '1%', marginTop: '20px  ', background: 'white', }}>
                    <div style={{ padding: '0px 0px', }}>
                        <div style={{ display: 'flex', alignItems: 'center', background: '#fafafa', borderBottom: '1px solid #f2f2f2',padding:'10px 0px' }}>
                            <h6 style={{ marginBottom: '0px', fontWeight: '800', marginLeft: '10px',fontSize:'13px' }}>{heading} ({datas.length}) </h6>
                        </div>


                        <div style={{ width: '100%', height: '350px', overflow: 'scroll', background: 'white', }}>
                            {datas?.length > 0
                                ?
                                <>
                                    <div style={{ width: 'fit-content', backgroundColor: '#fafafa', borderBottom: '1px solid #f2f2f2', display: 'flex', justifyContent: 'space-between', position: 'sticky', top: '0px', }}>
                                        {labels?.map((l, i) => (
                                            <p key={i} style={{ minWidth: '100px', marginBottom: '0px', display: 'inline-block', color: i === 0 ? 'black' : '#535b64', fontWeight: '600', fontSize: '12px', paddingLeft: '10px', margin: '5px', borderRight: '1px solid #eaeded' }}>{l}</p>
                                        ))}
                                    </div>

                                    <div style={{ width: '100%', background: 'white' }}>
                                        {datas?.map((d, i) => (
                                            <div key={i} style={{ minWidth: '100px', width: 'fit-content', borderBottom: '1px solid #e0e8ff', display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => navigatemetopage()}>

                                                {labels_arr?.map((l, ind) => (
                                                    <p key={ind} style={{ minWidth: '100px', maxWidth: '100px', marginBottom: '0px', color: (active1 === JSON.stringify(ind) || active2 === JSON.stringify(ind)) ? '#4da1ff' : 'black',  paddingLeft: '10px', margin: '5px', fontWeight: '500',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }} >{d[l]}</p>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </>
                                :
                                <div style={{ width: '42vw', marginTop: '40px', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={data_not_found} alt="1" style={{ width: '140px', height: '140px', objectFit: 'contain', display: 'flex', margin: 'auto' }} />
                                    <h6 style={{ fontSize: '13px',fontWeight:'800' }}>{msg}</h6>
                                </div>
                            }
                        </div>

                    </div>
                </div>

                :

                <div style={{ width: '100%', height: '400px', border: '1px solid #f2f2f2', borderRadius: '1px', margin: '1%', marginTop: '20px  ', background: 'white', }}>
                    <div style={{ padding: '0px 0px', }}>
                        <div style={{ display: 'flex', alignItems: 'center', background: '#fafafa', borderBottom: '1px solid #f2f2f2',padding:'10px 0px' }}>
                            <h6 style={{ marginBottom: '0px', fontWeight: '700', marginLeft: '10px',fontSize:'13px' }}>{heading} ({datas.length}) </h6>
                        </div>


                        <div style={{ width: '100%', height: '350px', overflow: 'scroll', background: 'white', }}>
                            {datas?.length > 0
                                ?
                                <>
                                    <div style={{ width: 'fit-content', backgroundColor: '#ebebeb', borderBottom: '1px solid #eaeded', display: 'flex', justifyContent: 'space-between', position: 'sticky', top: '0px', }}>
                                        {labels?.map((l, i) => (
                                            <p key={i} style={{ minWidth: '100px', marginBottom: '0px', display: 'inline-block', color: i === 0 ? 'black' : 'black', fontSize: '12px', paddingLeft: '10px', margin: '5px', borderRight: '1px solid #eaeded',fontWeight:'600' }}>{l}</p>
                                        ))}
                                    </div>

                                    <div style={{ width: '100%', background: 'white' }}>
                                        {datas?.map((d, i) => (
                                            <div key={i} style={{ minWidth: '100px', width: 'fit-content', borderBottom: '1px solid #e0e8ff', display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => navigatemetopage()}>

                                                {labels_arr?.map((l, ind) => (
                                                    <p key={ind} style={{ minWidth: '100px', maxWidth: '100px', marginBottom: '0px', color: (active1 === JSON.stringify(ind) || active2 === JSON.stringify(ind)) ? '#4da1ff' : 'black', fontSize: '12px', paddingLeft: '10px', margin: '5px', fontWeight: '500', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{d[l]}</p>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </>
                                :
                                <div style={{ width: '42vw', marginTop: '40px', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={data_not_found} alt="1" style={{ width: '140px', height: '140px', objectFit: 'contain', display: 'flex', margin: 'auto' }} />
                                    <h6 style={{ fontSize: '13px',fontWeight:'800' }}>{msg}</h6>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            }
        </>
    )
}

export default BDtable
