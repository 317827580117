import React from 'react'
// import Sidebar from '../../Sidebar'

function Main(props) {
  return (
    <div id="main" style={{ display: 'flex' }}>
      <div style={{ width: '100%' }}>
        {props.children}
      </div>

    </div>
  )
}

export default Main
