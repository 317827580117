import React from 'react'

function TaskDetail() {
    return (
        <div>
          <h3>TaskDetail page</h3>
        </div>
    )
}

export default TaskDetail
