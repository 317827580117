import React,{useState,useEffect} from 'react';

import TextInput from '../../components/ItComponents/TextInput';
import {  Itheadings, ItSmallText, ItSmallText1 } from '../../helpers/constants/ConstantsText';
import ITMain from './ITMain';
import { gray_col1, gray_col_light1, gray_dark_1 } from '../../helpers/constants/ConstantColor';
import PrimaryButton, { PrimaryButtonAdd } from '../../components/buttons/PrimaryButton';
import {CgExtensionRemove} from 'react-icons/cg'

import {IoClose} from 'react-icons/io5'

import { Modal} from 'antd';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import toast, { Toaster } from 'react-hot-toast'


import {
    createMuiTheme,
  } from "@material-ui/core/styles";
import axios from 'axios';
import { MdOutlineDelete } from 'react-icons/md';
import { BiEditAlt } from 'react-icons/bi';
import './Maincss.css';



function DeviceStage(){

    const theme = createMuiTheme({
        overrides: {
            MuiTooltip: {
            tooltip: {
                // fontSize: "2em",
                color: "black",
                backgroundColor: {gray_dark_1}
            }
            }
        }
    });


    const [modal,setmodal] = useState(false)
    const [device_stage,setdevice_stage] = useState({_id:null,stage:''})
    const [message,setmessage] = useState('')

    const [device_stage_arr,setdevice_stage_arr] = useState([])

    useEffect(()=>{
        getdata()
    },[])  
    
    function getdata(){
        axios.get('it/devicestage/get',)
        .then((res)=>{
            setdevice_stage_arr(res.data.data)
        }).catch((err)=>{
            // console.log('err',err)
        })
    }

    function add_devicestage(){
        axios.post('it/devicestage/create',{stage:device_stage.stage})
        .then((res)=>{
            toast.success(res.data.message)
            setmodal(false)
            getdata()
        }).catch((err)=>{
        })
    }

    function update_devicestage(){
        axios.put(`it/devicestage/update/${device_stage._id}`,{stage:device_stage.stage,_id:device_stage._id,method:'_PUT'})
        .then((res)=>{
            toast.success(res.data.message)
            setmodal(false)
            getdata()
        }).catch((err)=>{
        })
    }

    function delete_devicestage(v){
        axios.delete(`it/devicestage/delete/${v._id}`)
        .then((res)=>{
            toast.success(res.data.message)
            getdata()
        }).catch((err)=>{
            // console.log('err here',err.response.data)
        })
    }



    return(
        <ITMain>
            <div style={{width:'95%'}}>
                <Toaster />

                <Modal visible={modal} footer={false} closable={false} width='260px'>
                    <div style={{width:'220px',position:'relative',height:'90px'}}>

                    <Tooltip title="Close" >
                            <IconButton style={{padding:'2px',marginTop:'-5px',position:'absolute',right:-10,top:-10}}>
                            <IoClose  onClick={()=>setmodal(!modal)} style={{ cursor: 'pointer',color:gray_dark_1, }} size={16} />
                            </IconButton>
                        </Tooltip>

                        <ItSmallText val={'Device Stage'} />
                        <TextInput val={device_stage.stage} onchange={(e)=>setdevice_stage({...device_stage,stage:e.target.value})} />

                        <PrimaryButton btn_name={device_stage._id === null ? 'ADD STAGE' : "UPDATE STAGE"} onClick={device_stage._id === null ? add_devicestage : update_devicestage} />
                    </div>
                </Modal>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'-10px',borderBottom:`1px solid ${gray_col1}`}}>
                <Itheadings val={'Device Stage'} />
                  <div onClick={()=>(setdevice_stage({_id:null,stage:''}),setmodal(true))}>
                 <PrimaryButtonAdd btn_name={'Add STAGE'}  />
                 </div>
                </div>
            <div className='row'>

            {device_stage_arr.map((p)=>(
              <div className="col-md-3">  
              <div className='card_processor'  key={p._id} style={{display:'flex',alignItems:'center',margin:'15px 0px',cursor:'pointer',borderLeft:'4px solid #344a6e',paddingLeft:'10px',width:'100%',position:'relative'}}>
                {/* <MdOutlineCable style={{backgroundColor:'#FFA156',color:'#000',padding:'2px',borderRadius:'2px'}} size={20} /> */}
                <CgExtensionRemove color='#fff'  style={{backgroundColor:'#344a6e',color:'#fff',padding:'2px',borderRadius:'2px'}} size={20} />
                <ItSmallText1 val={p.stage} marginfun={'0px 0px 0px 10px'} textTransform={'Uppercase'}  />
                 
                <div style={{paddingLeft:'60px',display:'flex',position:'absolute',right:10}} className="delete_visible">
                <Tooltip title="Delete" >
                    <IconButton style={{marginTop:'-5px',color:'#000'}} onClick={()=>delete_devicestage(p)}>
                <MdOutlineDelete  size={16} style={{color:'black'}} />
                </IconButton>
                </Tooltip>
                <Tooltip title="Edit" >
                    <IconButton style={{marginTop:'-5px',color:'#000'}} onClick={()=>(setdevice_stage({_id:device_stage._id,stage:device_stage.stage}),setmodal(true))}>
                <BiEditAlt  size={16} style={{color:'black'}} />
                </IconButton>
                </Tooltip>
                </div>
              </div>
              </div>
            ))}
            </div>


            </div>
        </ITMain>
    )
}


export default DeviceStage