import React from 'react'
import {IoNotificationsOutline} from 'react-icons/io5'
import { IconButton } from '@material-ui/core';

import { Row, Col } from 'antd';

import {MdMeetingRoom} from 'react-icons/md';
import {FaTasks} from 'react-icons/fa'

function MoreOption() {

    // console.log('iam edited')
    return (
        <div style={{background:'#f2f2f2',minHeight:'100vh',height:'100%',width:'100%'}}>
            <div style={{display:'flex',alignItems:'center',backgroundColor:'#292961',height:'6vh',justifyContent:'space-between'}}>
                <h6 style={{color:'white',fontSize:'18px',paddingLeft:'20px'}}>More Features</h6>
                <div className="righticon" style={{display:'flex',alignItems:'center',marginRight:'15px'}}>     
                <IconButton style={{marginRight:'10px'}} onClick ={()=>window.location.href = '/notifications'}  ><IoNotificationsOutline size={20} color="white" /></IconButton>
                <span onClick={()=>window.location.href = "/profile"} style={{display:'flex'}} ><h6 style={{backgroundColor:'#ff952b',color:'black',fontWeight:'bold',height:'26px',width:'26px',textAlign:'center',alignSelf:'center',justifyContent:'center',display:'grid',alignItems:'center',marginBottom:'0px',borderRadius:'20px',cursor:'pointer',fontSize:'12px'}} className="nav_user_letter">K</h6></span>
                </div>  
            </div>

                <div style={{width:'96%',margin:'2%',background:'white',padding:'10px',}}>
                   <h4 style={{fontWeight:'bold',fontSize:'20px'}}>BD Features</h4>
                   <Row style={{marginTop:'20px'}}>
                       <Col span={8}>
                        <div style={{dispaly:'flex',alignItems:'center',justifyContent:'center',width:'96%',margin:'2%'}} onClick={()=>window.location.href= "/view_mettings"}>
                            <MdMeetingRoom size={26} style={{width:'100%'}}/>
                            <h1 style={{fontSize:'16px',marginTop:'6px',textAlign:'center'}}>Meetings</h1>
                        </div>
                       </Col>
                       <Col span={8}>
                        <div style={{dispaly:'flex',alignItems:'center',justifyContent:'center',width:'96%',margin:'2%'}} onClick={()=>window.location.href= "/view_tasks"}>
                            <FaTasks size={26} style={{width:'100%'}}/>
                            <h1 style={{fontSize:'16px',marginTop:'6px',textAlign:'center'}}>Tasks</h1>
                        </div>
                       </Col>
                   </Row>
                </div>

        </div>
    )
}

export default MoreOption
