import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from "axios";
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { IoIosArrowBack } from 'react-icons/io'
import { BiMessageAltDetail } from 'react-icons/bi'
import { GoFileSubmodule } from 'react-icons/go'
import { main_bg } from '../../../components/constants/Constants1';
import moment from 'moment';
import { IoMdCall, IoMdMail } from 'react-icons/io'
import { FaUserShield, FaIndustry } from 'react-icons/fa'
import { AiOutlineEdit } from 'react-icons/ai'
import { CgCalendarNext } from 'react-icons/cg'
import { GiAlarmClock } from 'react-icons/gi'
import ReminderMobile from '../../../components/detail_design/ReminderMobile';
import { MdTimeline } from 'react-icons/md'

import { Timeline } from '@mui/lab';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Drawer, Tooltip } from 'antd';

import { IoCreateOutline } from 'react-icons/io5'
import { AiOutlineClockCircle } from 'react-icons/ai'

function ContactDetail() {

    const navigate = useNavigate()
    const { id } = useParams()
    const [values, setvalues] = useState({})
    const [reminder, setReminders] = useState([])



    const [visible, setvisible] = useState(false)
    const [visible1, setvisible1] = useState(false)

    const [contact_tracker, setcontact_tracker] = useState([])
    const [task_tracker, settask_tracker] = useState([])
    const [meeting_tracker, setmeeting_tracker] = useState([])

    const [data, setdata] = useState({})


    const goback = () => {
        navigate(-1)
    }


    useEffect(() => {
        axios.get(`contact/get/${id}`, authHeader).then((res) => {
            let item = res.data.datas[0]
            setdata(res.data.datas[0])
            let contact_info =
            {
                'company_name': item?.company_name,
                'contact_name': item?.contact_name,
                'phone': item?.phone,
                'email': item?.email,
                'designation': item?.designation,
                'contact_source': item?.contact_source?.source_name,
                'website': item?.website,
                'industry': item?.industry?.industry_name,
                'company_phone': item?.company_phone,
                'company_email': item?.company_email,
                'secondary_contact_person': item?.secondary_contact_person,
                'secondary_contact_phone': item?.secondary_contact_phone,
                'secondary_contact_designation': item?.secondary_contact_designation,
                'status': item?.status,
            }

            let address_info = {
                'street': item?.street,
                'city': item?.city,
                'state': item?.state,
                'pincode': item?.pincode,
                'country': item?.country
            }
            let description = {
                'description': item?.description
            }
            setvalues(contact_info)
        })
        // })
        getReminder(id)
    }, [id])

    async function getReminder(id) {
        await axios.get(`reminder/get?type=${'Contact'}&type_id=${id}`, authHeader).then((res) => {
            setReminders(res.data.datas.reverse())
        })
    }

    function editdetailfunc() {
        navigate(`/edit_contact/${id}`)
    }

    function gotoleadcreatefunc() {
        navigate(`/create_lead`, { state: { datas: data } })
    }

    useEffect(() => {
        tracker()
    }, [])

    function tracker() {
        axios.get(`activity/get-timeline/Contact/${id}`, authHeader)
            .then((res) => {
                setcontact_tracker(res.data.datas)
                let tracker_arrange = []
                let tracker_arrange1 = []
                res.data.datas.sub.filter(t => t.type === 'Task' && t.history === '<p>Task created</p>' && t.event === 'create').forEach(e1 => {
                    tracker_arrange.push(e1)
                    if (res.data.datas.sub.filter(t1 => t1.type === 'Task' && t1.event === 'update' && t1.type_id === e1.type_id).length > 0) {
                        res.data.datas.sub.filter(t1 => t1.type === 'Task' && t1.event === 'update' && t1.type_id === e1.type_id).forEach(e => {
                            tracker_arrange.push(e)
                        })
                    }
                })
                settask_tracker(tracker_arrange)

                res.data.datas.sub.filter(t => t.type === "Meeting" && t.history === '<p>Meeting created</p>' && t.event === 'create').forEach(e1 => {
                    tracker_arrange1.push(e1)
                    if (res.data.datas.sub.filter(t1 => t1.type === 'Meeting' && t1.event === 'update' && t1.type_id === e1.type_id).length > 0) {
                        res.data.datas.sub.filter(t1 => t1.type === 'Meeting' && t1.event === 'update' && t1.type_id === e1.type_id).forEach(e => {
                            tracker_arrange1.push(e)
                        })
                    }
                })
                setmeeting_tracker(tracker_arrange1)
            }).catch((err) => {
            })
    }





    return (
        <div>

            <Drawer title="Basic Drawer" placement="bottom" onClose={() => setvisible1(false)} visible={visible1} height={window.innerHeight / 1.3} width={window.innerWidth} closable={false} headerStyle={{ display: 'none' }} >
                <h4 style={{ fontSize: '20px', fontWeight: '800' }}>TimeLine <AiOutlineClockCircle size={22} style={{ margin: '0px 5px' }} /> </h4>
                <Timeline style={{ marginTop: '20px', width: '100%' }}>

                    {contact_tracker?.base?.filter((l => l.type === "Contact")).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.type_id}`)}>View Details</span> */}
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {contact_tracker?.sub?.filter((l => l.type === "Lead" && (l?.history?.includes('<p>Lead Created</p>')))).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '900', marginBottom: '0px', paddingBottom: '0px', lineHeight: '5px' }}>{t?.history?.replace(/(<([^>]+)>)/gi, "")}</h6>
                                {contact_tracker?.sub?.filter(l1 => l1.type === 'Lead' && l1.type_id === t.type_id && l1.history.includes("Converted from contact ")) !== undefined &&
                                    <>
                                        <span style={{ marginleft: '0px', fontSize: '11px', fontWeight: '700' }}>{contact_tracker?.sub?.find(l1 => l1.type === 'Lead' && l1.type_id === t.type_id && l1.history.includes("Converted from contact "))?.history?.split(':')[0]}</span>
                                        <span style={{ marginleft: '5px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '10px', paddingBottom: '2px' }} onClick={() => navigate(`/lead_detail/${t.type_id}`)}>View Details</span>
                                    </>
                                }
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {task_tracker.map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '900', marginBottom: '0px', paddingBottom: '2px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {meeting_tracker?.map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '900', marginBottom: '0px', paddingBottom: '2px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                </Timeline>
            </Drawer>

            <ReminderMobile visible={visible} func={() => setvisible(false)} id={id} type={'Contact'} reminder={reminder} getreminderfun={(v) => getReminder(v)} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', padding: '3%', alignItems: 'center', backgroundColor: `${main_bg}`, position: 'sticky', top: '0px',zIndex:100,maxHeight:'45px',minHeight:'45px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <IoIosArrowBack size={24} color="#fff" onClick={goback} style={{ marginLeft: '-5px' }} />
                    <h6 style={{ marginBottom: '0px', color: '#fff', marginLeft: '25px', fontWeight: '600' }}>Contact Detail</h6>
                </div>
                <div>
                    <GoFileSubmodule size={24} style={{ marginRight: '20px', color: 'white' }} onClick={() => navigate(`/attachments_ui/Contact/${data.id}`)} />
                    <BiMessageAltDetail size={24} style={{ marginRight: '15px', color: 'white' }} onClick={() => navigate(`/notes_ui/Contact/${data.id}`)} />
                </div>
            </div>


            <div style={{ position: 'relative' }}>
                <MdTimeline size={20} style={{ position: 'absolute', right: '165px', top: '20px' }} onClick={() => setvisible1(true)} />
                <GiAlarmClock size={20} style={{ position: 'absolute', right: '120px', top: '20px' }} onClick={() => setvisible(true)} />
                <CgCalendarNext size={20} style={{ position: 'absolute', right: '75px', top: '20px' }} onClick={gotoleadcreatefunc} />
                <AiOutlineEdit size={20} style={{ position: 'absolute', right: '30px', top: '20px' }} onClick={editdetailfunc} />
                <div style={{ display: 'grid', alignItems: 'center', justifyContent: 'center', paddingTop: '50px' }}>
                    <div style={{ width: '120px', height: '120px', backgroundColor: '#f5fcff', display: 'grid', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }}>
                        <h6 style={{ color: 'black', fontSize: '50px', color: `${main_bg}`, textTransform: 'capitalize', fontWeight: '900', opacity: 0.5 }}>{values?.company_name?.slice(0, 1)}</h6>
                    </div>
                </div>
                <h6 style={{ color: 'black', fontSize: '17px', textTransform: 'capitalize', fontWeight: '900', marginTop: '5px', textAlign: 'center' }}>{values?.contact_name}</h6>
                <h6 style={{ marginTop: '5px', textAlign: 'center', fontSize: '13px', color: '#adadad  ' }}>Created at : {moment(data?.createdAt).format('LLL')} </h6>

                <div style={{ display: 'flex', width: '50%', margin: 'auto', justifyContent: 'space-between' }}>
                    <Tooltip placement="topLeft" title={data.phone}>
                        <div style={{ backgroundColor: `${main_bg}`, width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }}>
                            <IoMdCall size={16} color="#fff" />
                        </div>
                    </Tooltip>
                    <Tooltip placement="topLeft" title={data.email}>
                        <div style={{ backgroundColor: `${main_bg}`, width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }}>
                            <IoMdMail size={16} color="#fff" />
                        </div>
                    </Tooltip>
                    <Tooltip placement="topLeft" title={data.designation}>
                        <div style={{ backgroundColor: `${main_bg}`, width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }}>
                            <FaUserShield size={16} color="#fff" />
                        </div>
                    </Tooltip>
                    <Tooltip placement="topLeft" title={data?.industry?.industry_name}>
                        <div style={{ backgroundColor: `${main_bg}`, width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }}>
                            <FaIndustry size={16} color="#fff" />
                        </div>
                    </Tooltip>
                </div>


                <div style={{ width: '70%', margin: 'auto', marginTop: '30px' }}>
                    {data?.company_name && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Company</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.company_name}</h4>
                    </div>}
                    {data?.company_phone && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Company Phone</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.company_phone}</h4>
                    </div>}
                    {data?.company_email && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Company Email</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.company_email}</h4>
                    </div>}
                    {data?.website && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Website</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.website}</h4>
                    </div>}
                    {data?.contact_source?.source_name && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Contact Source</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.contact_source?.source_name}</h4>
                    </div>}
                    {data?.industry?.industry_name && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Industry</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.industry?.industry_name}</h4>
                    </div>}

                    {data?.status && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Contact Status</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.status}</h4>
                    </div>}

                    {data?.company_name && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Contact Owner</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.company_name}</h4>
                    </div>}

                    {data?.secondary_contact_person && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>2nd Contact Person</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.secondary_contact_person}</h4>
                    </div>}

                    {data?.secondary_contact_phone && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>2nd Contact Phone</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.secondary_contact_phone}</h4>
                    </div>}

                    {data?.secondary_contact_designation && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>2nd Contact Designation</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.secondary_contact_designation}</h4>
                    </div>}

                </div>


                <div style={{ width: '70%', margin: 'auto', marginTop: '30px' }}>
                    <h6 style={{ fontWeight: '800', margin: '10px 0px', fontSize: '15px' }}>Address Information</h6>
                    {data?.street && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Street</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.street}</h4>
                    </div>}
                    {data?.city && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>City</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.city}</h4>
                    </div>}
                    {data?.state && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>State</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.state}</h4>
                    </div>}
                    {data?.pincode && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Pincode</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.pincode}</h4>
                    </div>}
                    {data?.country && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Country</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.country}</h4>
                    </div>}
                    {data?.description && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Description</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.description}</h4>
                    </div>}
                </div>

                <div>

                </div>


            </div>

        </div>
    )
}

export default ContactDetail
