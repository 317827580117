import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import WebCreateTasks from './web/CreateTasks'
import MobileCreateTasks from './android/CreateTasks'

const index = () =>{
    if(isMobile){
        return(
            <div>
                <MobileCreateTasks />
            </div>
        )
    }else{
        return(
            <div>
                <WebCreateTasks />
            </div>
        )
    }
}

export default index