import React from 'react'
import KrishnaDashboard from '../dashboard/KrishnaDashboard'


function DeveloperMain(props){
    return(
          <div style={{display:'flex',flexDirection:'row',marginTop:'0px',paddingTop:'0px'}}>
            <div style={{flex:0.14,boxSizing:'border-box',width:'100%'}}>
              <KrishnaDashboard />
            </div> 
            <div style={{flex:0.86,boxSizing:'border-box',width:'100%',maxHeight:'100vh',overflowY:'scroll'}}>
                {props.children}
            </div>

          </div>
    )
}


export default DeveloperMain