import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { main_bg } from '../../components/constants/Constants1'


function NavbarMobile1({ func }) {

    const navigate = useNavigate()

    const goback = () => {
        func()
        navigate(-1)
    }

    return (
        <div style={{ background: `${main_bg}`, marginBottom: '40px', minHeight: '50px', position: 'sticky', top: '0px', zIndex: 100, display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center', paddingLeft: '10px' }} onClick={() => goback()}>
                <IoIosArrowBack size={24} color="white" />
                <h6 style={{ marginBottom: '0px', paddingLeft: '20px', color: 'white' }}> Go back</h6>
            </div>
        </div>
    )
}

export default NavbarMobile1
