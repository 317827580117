import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import AddingDDRWeb from './web'
import AddingDDRMob from './android'

const index = ()=>{
    if(isMobile){
        return(
            <div>
                <AddingDDRMob />
            </div>
        )
    }else{
        return(
            <div>
                <AddingDDRWeb />
            </div>
        )
    }
}

export default index