import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from "axios";
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { IoIosArrowBack } from 'react-icons/io'
import { BiMessageAltDetail } from 'react-icons/bi'
import { GoFileSubmodule } from 'react-icons/go'
import { main_bg } from '../../../components/constants/Constants1';
import moment from 'moment';
import { IoMdCall, IoMdMail } from 'react-icons/io'
import { FaUserShield, FaIndustry } from 'react-icons/fa'
import { CgCalendarNext } from 'react-icons/cg'
import { GiAlarmClock } from 'react-icons/gi'
import ReminderMobile from '../../../components/detail_design/ReminderMobile';
import { MdTimeline } from 'react-icons/md'

import { Drawer, Tooltip } from 'antd';


import { Timeline } from '@mui/lab';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import { IoCreateOutline } from 'react-icons/io5'
import { AiOutlineClockCircle, AiOutlineEdit } from 'react-icons/ai'
import { BiTransfer } from 'react-icons/bi'


function LeadDetail() {

    const navigate = useNavigate()
    const { id } = useParams()

    const [reminder, setReminders] = useState([])
    const [visible, setvisible] = useState(false)
    const [visible1, setvisible1] = useState(false)
    const [data, setdata] = useState({})

    const [lead_tracker, setlead_tracker] = useState([])
    const [task_tracker, settask_tracker] = useState([])
    const [meeting_tracker, setmeeting_tracker] = useState([])
    const [departement_arr, setdepartement_arr] = useState([])


    const goback = () => {
        navigate(-1)
    }


    useEffect(() => {
        getDepartements()
        axios.get(`lead/get/${id}`, authHeader).then((res) => {
            setdata(res.data.datas[0])
        })
        getReminder(id)
        tracker()
    }, [id])

    async function getReminder(id) {
        await axios.get(`reminder/get?type=${'Lead'}&type_id=${id}`, authHeader).then((res) => {
            setReminders(res.data.datas.reverse())
        })
    }

    function editLead() {
        navigate('/edit_lead/' + id)
    }

    function gotodealcreatefunc() {
        navigate(`/create_deal`, { state: { datas: data } })
    }

   

    async function getDepartements() {
        await axios.get('department/get', authHeader)
            .then((res) => {
                const departement_arr_created = []
                res.data.datas.filter(d => d?.department_name !== 'Database Team' && d?.department_name !== 'Media Team' && d?.department_name !== 'MD Office').forEach(d => {
                    let data = {}
                    data['label'] = d.department_name
                    data['value'] = d.department_name
                    data['id'] = d.id
                    departement_arr_created.push(data)
                })
                setdepartement_arr(departement_arr_created)

            })
    }


    function tracker() {
        axios.get(`activity/get-timeline/Lead/${id}`, authHeader)
            .then((res) => {
                setlead_tracker(res.data.datas)
                let tracker_arrange = []
                let tracker_arrange1 = []
                res.data.datas.sub.filter(t => t.type === 'Task' && t.history === '<p>Task created</p>' && t.event === 'create').forEach(e1 => {
                    tracker_arrange.push(e1)
                    if (res.data.datas.sub.filter(t1 => t1.type === 'Task' && t1.event === 'update' && t1.type_id === e1.type_id).length > 0) {
                        res.data.datas.sub.filter(t1 => t1.type === 'Task' && t1.event === 'update' && t1.type_id === e1.type_id).forEach(e => {
                            tracker_arrange.push(e)
                        })
                    }
                })
                settask_tracker(tracker_arrange)

                res.data.datas.sub.filter(t => t.type === "Meeting" && t.history === '<p>Meeting created</p>' && t.event === 'create').forEach(e1 => {
                    tracker_arrange1.push(e1)
                    if (res.data.datas.sub.filter(t1 => t1.type === 'Meeting' && t1.event === 'update' && t1.type_id === e1.type_id).length > 0) {
                        res.data.datas.sub.filter(t1 => t1.type === 'Meeting' && t1.event === 'update' && t1.type_id === e1.type_id).forEach(e => {
                            tracker_arrange1.push(e)
                        })
                    }
                })
                setmeeting_tracker(tracker_arrange1)
            }).catch((err) => {
            })
    }

    return (
        <div>
            <Drawer title="Basic Drawer" placement="bottom" onClose={() => setvisible1(false)} visible={visible1} height={window.innerHeight / 1.3} width={window.innerWidth} closable={false} headerStyle={{ display: 'none' }}  >
                <h4 style={{ fontSize: '20px', fontWeight: '800' }}>TimeLine <AiOutlineClockCircle size={22} style={{ margin: '0px 5px' }} /> </h4>
                <Timeline style={{ marginTop: '30px', width: '100%' }} >

                    {lead_tracker?.base?.filter((l => l.type === 'Lead' && (l?.history?.includes('Converted from contact ')))).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(data?.contact_id?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'convert' && <IoCreateOutline size={20} color="#171636" />}
                                {/* {t.event === 'update' && <AiOutlineEdit size={20} color="#171636"/>} */}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }}>Contact Created</h6>
                                <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span>
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {lead_tracker?.base?.filter((l => l.type === 'Lead' && (l?.history?.includes('Converted from contact ')))).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'convert' && <BiTransfer size={20} color="#171636" />}
                                {/* {t.event === 'update' && <AiOutlineEdit size={20} color="#171636"/>} */}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} >{t?.history.split(':')[0]}</h6>
                                <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span>
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {lead_tracker?.base?.filter((l => l.type === 'Lead' && (l?.history?.includes('<p>Lead Created</p>')))).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span> */}
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {lead_tracker?.base?.filter((l => l.event === "assign_lead")).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'assign_lead' && <IoCreateOutline size={20} color="#171636" />}
                                {/* {t.event === 'update' && <AiOutlineEdit size={20} color="#171636"/>} */}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span> */}
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {lead_tracker?.base?.filter((l => l.type === 'Lead' && l.event === 'update')).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                {/* <span style={{marginleft:'0px',fontSize:'11px',color:'#0087f5',fontWeight:'700',cursor:'pointer',paddingLeft:'0px',marginTop:'0px',paddingBottom:'2px'}} onClick={()=>navigate(`/contact_detail/${t?.history.split(':')[1]}`)}>View Details</span> */}
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {lead_tracker?.sub?.filter((l => l.type === 'Deal')).map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'convert' && <BiTransfer size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '600', marginBottom: '0px', paddingBottom: '0px' }} >{t?.history?.replace(/(<([^>]+)>)/gi, "").split(':')[0]}</h6>
                                {t.event === 'create' ?
                                    <>
                                        {lead_tracker?.sub?.find((l => l.type === 'Deal' && l.history.includes('Converted from lead'))) &&
                                            <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/deal_detail/${lead_tracker?.sub?.find((l => l.type === 'Deal' && l.history.includes('Converted from lead')))?.type_id}`)}>View Details</span>
                                        }
                                    </>
                                    :
                                    <span style={{ marginleft: '0px', fontSize: '11px', color: '#0087f5', fontWeight: '700', cursor: 'pointer', paddingLeft: '0px', marginTop: '0px', paddingBottom: '2px' }} onClick={() => navigate(`/deal_detail/${t?.type_id}`)}>View Details</span>
                                }
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {task_tracker.map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '900', marginBottom: '0px', paddingBottom: '2px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                    {meeting_tracker?.map(t => (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <h6 style={{ fontSize: '12px', color: '#0ca7fa', fontWeight: 'bold' }}> {moment(t?.createdAt).format('lll')}</h6>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {t.event === 'create' && <IoCreateOutline size={20} color="#171636" />}
                                {t.event === 'update' && <AiOutlineEdit size={20} color="#171636" />}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{ maxWidth: '155px', backgroundColor: '#fff' }}>
                                <h6 style={{ fontSize: '12px', fontWeight: '900', marginBottom: '0px', paddingBottom: '2px' }} dangerouslySetInnerHTML={{ __html: t?.history }}></h6>
                                <h6 style={{ fontSize: '11px', fontWeigt: '600', color: '#858585', marginBottom: '0px', paddingBottom: '0px' }}>{t?.user_id?.name}</h6>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </Drawer>

            <ReminderMobile visible={visible} func={() => setvisible(false)} id={id} type={'Lead'} reminder={reminder} getreminderfun={(v) => getReminder(v)} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', padding: '3%', alignItems: 'center', backgroundColor: `${main_bg}`, position: 'sticky', top: '0px',zIndex:100 }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <IoIosArrowBack size={24} color="#fff" onClick={goback} style={{ marginLeft: '-5px' }} />
                    <h6 style={{ marginBottom: '0px', color: '#fff', marginLeft: '25px', fontWeight: '600' }}>Lead Detail</h6>
                </div>
                <div>
                    <GoFileSubmodule size={24} style={{ marginRight: '20px', color: 'white' }} onClick={() => navigate(`/attachments_ui/Lead/${id}`)} />
                    <BiMessageAltDetail size={24} style={{ marginRight: '15px', color: 'white' }} onClick={() => navigate(`/notes_ui/Lead/${id}`)} />
                </div>
            </div>


            <div style={{ position: 'relative' }}>
                <MdTimeline size={20} style={{ position: 'absolute', right: '165px', top: '20px' }} onClick={() => setvisible1(true)} />
                <GiAlarmClock size={20} style={{ position: 'absolute', right: '120px', top: '20px' }} onClick={() => setvisible(true)} />
                <CgCalendarNext size={20} style={{ position: 'absolute', right: '75px', top: '20px' }} onClick={gotodealcreatefunc} />
                <AiOutlineEdit size={20} style={{ position: 'absolute', right: '30px', top: '20px' }} onClick={editLead} />
                <div style={{ display: 'grid', alignItems: 'center', justifyContent: 'center', paddingTop: '50px' }}>
                    <div style={{ width: '120px', height: '120px', backgroundColor: '#f5fcff', display: 'grid', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }}>
                        <h6 style={{ color: 'black', fontSize: '50px', color: `${main_bg}`, textTransform: 'capitalize', fontWeight: '900', opacity: 0.5 }}>{data?.contact_id?.company_name?.slice(0, 1)}</h6>
                    </div>
                </div>
                <h6 style={{ color: 'black', fontSize: '17px', textTransform: 'capitalize', fontWeight: '900', marginTop: '5px', textAlign: 'center' }}>{data?.contact_id?.contact_name}</h6>
                <h6 style={{ marginTop: '5px', textAlign: 'center', fontSize: '13px', color: '#adadad  ' }}>Created at : {moment(data?.contact_id?.createdAt).format('LLL')} </h6>

                <div style={{ display: 'flex', width: '50%', margin: 'auto', justifyContent: 'space-between' }}>
                    <Tooltip placement="topLeft" title={data?.contact_id?.phone}>
                        <div style={{ backgroundColor: `${main_bg}`, width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }}>
                            <IoMdCall size={16} color="#fff" />
                        </div>
                    </Tooltip>
                    <Tooltip placement="topLeft" title={data?.contact_id?.email}>
                        <div style={{ backgroundColor: `${main_bg}`, width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }}>
                            <IoMdMail size={16} color="#fff" />
                        </div>
                    </Tooltip>
                    <Tooltip placement="topLeft" title={data?.contact_id?.designation}>
                        <div style={{ backgroundColor: `${main_bg}`, width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }}>
                            <FaUserShield size={16} color="#fff" />
                        </div>
                    </Tooltip>
                    <Tooltip placement="topLeft" title={data?.contact_id?.industry?.industry_name}>
                        <div style={{ backgroundColor: `${main_bg}`, width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }}>
                            <FaIndustry size={16} color="#fff" />
                        </div>
                    </Tooltip>
                </div>


                <div style={{ width: '70%', margin: 'auto', marginTop: '30px' }}>
                    {data?.contact_id?.company_name && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Company</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.contact_id?.company_name}</h4>
                    </div>}
                    {data?.contact_id?.company_phone && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Company Phone</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.contact_id?.company_phone}</h4>
                    </div>}
                    {data?.contact_id?.company_email && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Company Email</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.contact_id?.company_email}</h4>
                    </div>}
                    {data?.contact_id?.website && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Website</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.contact_id?.website}</h4>
                    </div>}
                    {data?.contact_id?.contact_source?.source_name && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Contact Source</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.contact_id?.contact_source?.source_name}</h4>
                    </div>}
                    {data?.contact_id?.industry?.industry_name && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Industry</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.contact_id?.industry?.industry_name}</h4>
                    </div>}

                    {data?.contact_id?.status && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Contact Status</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.contact_id?.status}</h4>
                    </div>}

                    {data?.contact_id?.company_name && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Contact Owner</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.contact_id?.company_name}</h4>
                    </div>}

                    {data?.contact_id?.secondary_contact_person && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>2nd Contact Person</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.contact_id?.secondary_contact_person}</h4>
                    </div>}

                    {data?.contact_id?.secondary_contact_phone && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>2nd Contact Phone</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.contact_id?.secondary_contact_phone}</h4>
                    </div>}

                    {data?.contact_id?.secondary_contact_designation && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>2nd Contact Designation</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.contact_id?.secondary_contact_designation}</h4>
                    </div>}

                </div>


                <div style={{ width: '70%', margin: 'auto', marginTop: '30px' }}>
                    <h6 style={{ fontWeight: '800', margin: '10px 0px', fontSize: '15px' }}>Lead Information</h6>
                    {data?.lead_name && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Lead Name</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.lead_name}</h4>
                    </div>}
                    {data?.lead_title && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>{departement_arr?.find(d=>d.id === data.department_id)?.value === 'Project Team' ? 'Project Scope' : 'Lead Title'}</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.lead_title}</h4>
                    </div>}
                    {data?.lead_status && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Lead Status</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.lead_status}</h4>
                    </div>}
                    {data?.remarks && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Remark</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.remarks}</h4>
                    </div>}
                    {data?.requirement && departement_arr?.find(d=>d.id === data.department_id)?.value === 'Transaction Team' && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Requirement</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.requirement}</h4>
                    </div>}
                    {data?.measurement && departement_arr?.find(d=>d.id === data.department_id)?.value === 'Transaction Team' && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Measurment</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.measurement}</h4>
                    </div>}
                    {data?.zone && departement_arr?.find(d=>d.id === data.department_id)?.value === 'Transaction Team' && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Zone</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.zone}</h4>
                    </div>}
                    {data?.lead_operators && departement_arr?.find(d=>d.id === data.department_id)?.value === 'Transaction Team' && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Lead Operators</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.lead_operators}</h4>
                    </div>}
                    {data?.description && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h4 style={{ flex: 0.4, color: '#adadad', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'right' }}>Description</h4>
                        <h4 style={{ flex: 0.6, color: 'black', fontWeight: '600', fontSize: '14px', width: '100%', padding: '3px', textAlign: 'left', marginLeft: '10px' }}>{data?.description}</h4>
                    </div>}
                </div>

                <div>

                </div>

                {/* <h6>{JSON.stringify(data)}</h6> */}


            </div>
            {/* <h6>Contact Detail func</h6> */}
            {/* <BdDetail section={section} values={[values,values1,values2]} company_name={values?.company_name} company_phone={values?.company_phone} labels = {[label,label1,label2]} type={type} goback={()=>goback()} /> */}
        </div>
    )
}

export default LeadDetail
