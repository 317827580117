import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { RiPagesLine } from 'react-icons/ri'
import { MdOutlineFeaturedPlayList, MdOutlineKeyboard, MdOutlineCable } from 'react-icons/md'
import { BiRectangle } from 'react-icons/bi'
import { SiBrandfolder } from 'react-icons/si'
import { ItSmallText, ItSmallText1 } from '../../../../helpers/constants/ConstantsText'
import { FaLaptop, FaPager } from 'react-icons/fa'
import { HiLogout, HiOutlineChip, } from 'react-icons/hi'
import {AiOutlineMobile,AiOutlineDesktop} from 'react-icons/ai'
import { BiMouse } from 'react-icons/bi'
import { FiHardDrive } from 'react-icons/fi'
import {IoMdOptions} from 'react-icons/io'
import { fcpl_white_logo } from '../../../../helpers/Constants';
import { useSelector } from 'react-redux'
import './It_dashboard.css'




function ItDashboard() {

    const navigate = useNavigate()
    const location = useLocation()

    const { pathname } = location

    const theme_color = useSelector(state=>state.Theme.theme_color)

    function navigatetopage(v) {
        navigate(v)
    }


    return (
        <div style={{ background: theme_color, height: '100vh', position: 'fixed', left: '0px', maxHeight: '100vh', top: '0px', alignItems: 'center',minWidth:'200px',maxWidth:'200px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', justifyItems: 'center', width: '100%',padding:'20px' }}>
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column',alignContent:'center',justifyContent:'center' }}>

                    <div style={{display:'flex',alignItems:'center',borderBottom:'1px solid #e2e0e0',paddingBottom:'10px' }}>
                        <img className="inactive" src={fcpl_white_logo} style={{ width: '25px', height: '25px',marginTop:'0px',marginBottom:'0px',opacity:1 }} alt="#" />
                        <ItSmallText col="#fff" val={'FCPL IT ASSETS'} marginfun={'0px 0px 0px 15px'} /> 

                    </div> 
                    
                    <ItSmallText val={'ANALYTICS'}  col="#e2e0e0" marginfun={'20px 0px 0px 10px'}/>
                    <div className={pathname !== '/krishna_dashboard' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/krishna_dashboard')}>
                        <RiPagesLine size={15} className={pathname !== '/it/krishna_dashboard' ? 'icon_kp' : 'icon_kp_active'} />
                        <ItSmallText1 col="#fff" val={'Dashboard'} marginfun={'0px 0px 0px 5px'} /> 
                    </div>

                    <div className={pathname !== '/it/assets_assign' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/it/assets_assign')}>
                        <FaPager size={15} className={pathname !== '/it/assets_assign' ? 'icon_kp' : 'icon_kp_active'} />
                        <ItSmallText1 col="#fff" val={'Employee'} marginfun={'0px 0px 0px 5px'} /> 

                    </div>


                    <ItSmallText val={'CONTENT'}  col="#e2e0e0" marginfun={'20px 0px 0px 10px'}/>

                    <div className={pathname !== '/it/processor' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/it/processor')}>
                        <HiOutlineChip size={18} className={pathname !== '/it/processor' ? 'icon_kp' : 'icon_kp_active'} style={{ marginLeft: '2px' }} />
                        <ItSmallText1 col="#fff" val={'Processor'} marginfun={'0px 0px 0px 3px'} /> 
                    </div>
                    <div className={pathname !== '/it/brand' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/it/brand')}>
                        <SiBrandfolder size={15} className={pathname !== '/it/brand' ? 'icon_kp' : 'icon_kp_active'} style={{ marginLeft: '2px' }} />
                        <ItSmallText1 col="#fff" val={'Brand'} marginfun={'0px 0px 0px 5px'} /> 
                    </div>
                    <div className={pathname !== '/it/types_available' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/it/types_available')}>
                        <MdOutlineFeaturedPlayList size={15} className={pathname !== '/it/types_available' ? 'icon_kp' : 'icon_kp_active'} style={{ marginLeft: '2px' }} />
                        <ItSmallText1 col="#fff" val={'TypeOption'} marginfun={'0px 0px 0px 5px'} /> 
                    </div>
                    <div className={pathname !== '/it/purchase_type' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/it/purchase_type')}>
                        <MdOutlineFeaturedPlayList size={15} className={pathname !== '/it/purchase_type' ? 'icon_kp' : 'icon_kp_active'} style={{ marginLeft: '2px' }} />
                        <ItSmallText1 col="#fff" val={'Purchase Type'} marginfun={'0px 0px 0px 5px'} /> 
                    </div>
                    <div className={pathname !== '/it/laptop' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/it/laptop')}>
                        <FaLaptop size={15} className={pathname !== '/it/laptop' ? 'icon_kp' : 'icon_kp_active'} style={{ marginLeft: '2px' }} />
                        <ItSmallText1 col="#fff" val={'Laptop'} marginfun={'0px 0px 0px 5px'} /> 
                    </div>
                    <div className={pathname !== '/it/desktop' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/it/desktop')}>
                        <AiOutlineDesktop size={15} className={pathname !== '/it/desktop' ? 'icon_kp' : 'icon_kp_active'} style={{ marginLeft: '2px' }} />
                        <ItSmallText1 col="#fff" val={'Desktop'} marginfun={'0px 0px 0px 5px'} /> 
                    </div>
                    <div className={pathname !== '/it/cpu' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/it/cpu')}>
                        <BiRectangle size={15} className={pathname !== '/it/cpu' ? 'icon_kp' : 'icon_kp_active'} style={{ marginLeft: '2px' }} />
                        <ItSmallText1 col="#fff" val={'CPU'} marginfun={'0px 0px 0px 5px'} /> 
                    </div>
                    <div className={pathname !== '/it/mouse' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/it/mouse')}>
                        <BiMouse size={15} className={pathname !== '/it/mouse' ? 'icon_kp' : 'icon_kp_active'} style={{ marginLeft: '2px' }} />
                        <ItSmallText1 col="#fff" val={'Mouse'} marginfun={'0px 0px 0px 5px'} /> 
                    </div>
                    <div className={pathname !== '/it/keyboard' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/it/keyboard')}>
                        <MdOutlineKeyboard size={15} className={pathname !== '/it/keyboard' ? 'icon_kp' : 'icon_kp_active'} style={{ marginLeft: '2px' }} />
                        <ItSmallText1 col="#fff" val={'KeyBoard'} marginfun={'0px 0px 0px 5px'} /> 
                    </div>
                    <div className={pathname !== '/it/disk' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/it/disk')}>
                        <FiHardDrive size={15} className={pathname !== '/it/disk' ? 'icon_kp' : 'icon_kp_active'} style={{ marginLeft: '2px' }} />
                        <ItSmallText1 col="#fff" val={'HardDisk'} marginfun={'0px 0px 0px 5px'} /> 
                    </div>
                    <div className={pathname !== '/it/mobile' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/it/mobile')}>
                        <AiOutlineMobile size={15} className={pathname !== '/it/mobile' ? 'icon_kp' : 'icon_kp_active'} style={{ marginLeft: '2px' }} />
                        <ItSmallText1 col="#fff" val={'Mobile'} marginfun={'0px 0px 0px 5px'} /> 
                    </div>
                    <div className={pathname !== '/it/adapters' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/it/adapters')}>
                        <MdOutlineCable size={15} className={pathname !== '/it/adapters' ? 'icon_kp' : 'icon_kp_active'} style={{ marginLeft: '2px' }} />
                        <ItSmallText1 col="#fff" val={'Connectors'} marginfun={'0px 0px 0px 5px'} /> 
                    </div>
                    <div className={pathname !== '/it/device_stage' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/it/device_stage')}>
                        <IoMdOptions size={15} className={pathname !== '/it/device_stage' ? 'icon_kp' : 'icon_kp_active'} style={{ marginLeft: '2px' }} />
                        <ItSmallText1 col="#fff" val={'Device Stage'} marginfun={'0px 0px 0px 5px'} /> 
                    </div>

                </div>

                <ItSmallText val={'CUSTOMIZATION'}  col="#e2e0e0" marginfun={'20px 0px 0px 10px'}/>


                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div className={pathname !== '/log_out' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/bd_dashboard')}>
                        <HiLogout size={16} className={pathname !== '/log_out' ? 'icon_kp' : 'icon_kp_active'} />
                        <ItSmallText1 col="#fff" val={'Logout'} marginfun={'0px 0px 0px 5px'} /> 
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ItDashboard