import React, { useState, useEffect } from 'react'
import { ImCalendar } from 'react-icons/im'
import { IoAddOutline } from 'react-icons/io5'
import { FaWpforms } from 'react-icons/fa'
import { SiPrivateinternetaccess } from 'react-icons/si'
import { FiUserPlus, FiUserMinus } from 'react-icons/fi'
import { RiPagesFill, RiUser2Fill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { ImUsers } from 'react-icons/im'
import { useSelector } from 'react-redux'
import { MdGroups } from 'react-icons/md'
import { authHeader } from '../../../../helpers/usertoken/UserToken'
import axios from 'axios'

function Controller_Dashboard() {

    const name = useSelector(state => state.Auth.name)
    const id = useSelector(state => state.Auth.id)

    const [employee, setemployee] = useState([])
    const [inactive_employee, setinactive_employee] = useState([])

    const [departement_arr, setdepartement_arr] = useState([])
    const [designation_arr, setdesignation_arr] = useState([])
    const [roles_arr, setroles_arr] = useState([])



    const navigate = useNavigate()

    useEffect(() => {
        getOnboardedEmployee()
        fetch_all_designation()
        fetch_all_departement()
        fetch_all_roles()
    }, [])

    function getOnboardedEmployee() {
        axios.get(`user/get/1/true`, authHeader)
            .then((res) => {
                //   setpagination(res.data.pagination)
                setemployee(new Array(res?.data?.pagination?.total))
                // setemployee(res.data.datas.filter(d => d.isActive === true))
            })
            axios.get(`user/get/1/false`, authHeader)
            .then((res) => {
                //   setpagination(res.data.pagination)
                setinactive_employee(new Array(res?.data?.pagination?.total))
            })    
    }

    function fetch_all_departement() {
        axios.get('department/get', authHeader)
            .then((res) => {
                setdepartement_arr(res.data.datas)
            })
    }

    function fetch_all_designation() {
        axios.get('designation/get', authHeader)
            .then((res) => {
                setdesignation_arr(res.data.datas)
            })
    }

    function fetch_all_roles() {
        axios.get('role/get', authHeader)
            .then((res) => {
                setroles_arr(res.data.datas)
            })
    }


    return (
        <div style={{ width: '90%', margin: 'auto', marginTop: '80px' }}>
            {/* <h6>This is the controller dashboard </h6> */}

            <div style={{ display: 'flex', marginBottom: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginRight: '20px' }} onClick={() => navigate('/on_board')}>
                    <div style={{ background: '#f1e0ff', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '24px', cursor: 'pointer' }}>
                        <FaWpforms size={24} />
                    </div>
                    <h6 style={{ fontSize: '12px', marginTop: '5px', color: 'grey' }}>Onboard</h6>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginRight: '15px' }} onClick={() => navigate('/departments')}>
                    <div style={{ background: '#dfffba', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '24px', cursor: 'pointer' }}>
                        <RiPagesFill size={24} />
                    </div>
                    <h6 style={{ fontSize: '12px', marginTop: '5px', color: 'grey' }}>Department</h6>
                </div>


                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginRight: '20px' }} onClick={() => navigate('/designation')}>
                    <div style={{ background: '#ffd1e0', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '24px', cursor: 'pointer' }}>
                        <RiUser2Fill size={24} />
                    </div>
                    <h6 style={{ fontSize: '12px', marginTop: '5px', color: 'grey' }}>Designation</h6>
                </div>


                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginRight: '28px' }} onClick={() => navigate('/roles')}>
                    <div style={{ background: '#d2fcf8', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '24px', cursor: 'pointer' }}>
                        <ImUsers size={24} />
                    </div>
                    <h6 style={{ fontSize: '12px', marginTop: '5px', color: 'grey' }}>Roles</h6>
                </div>



                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginRight: '28px' }} onClick={() => navigate('/onboarded_employee')}>
                    <div style={{ background: '#ffe6bf', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '24px', cursor: 'pointer' }}>
                        <SiPrivateinternetaccess size={24} />
                    </div>
                    <h6 style={{ fontSize: '12px', marginTop: '5px', color: 'grey' }}>Assign</h6>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginRight: '28px' }}>
                    <div style={{ background: '#f3ffb5', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '24px', cursor: 'pointer' }}>
                        <ImCalendar size={24} />
                    </div>
                    <h6 style={{ fontSize: '12px', marginTop: '5px', color: 'grey' }}>Tasks</h6>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginRight: '20px' }}>
                    <div style={{ background: '#d1e8ff', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '24px', cursor: 'pointer' }}>
                        <IoAddOutline size={24} />
                    </div>
                    <h6 style={{ fontSize: '12px', marginTop: '5px', color: 'grey' }}>More</h6>
                </div>
            </div>

            {/* borderTop:'1px solid #f0f0f0',borderBottom:'1px solid #f0f0f0' */}

            <div style={{display:'flex',justifyContent:'space-between',width:'90%'}}>
            <div style={{margin:'20px 0px',padding:'40px 15px',width:'24%',borderRadius:'10px',display:'flex',alignItems:'center',backgroundImage:'linear-gradient(to right, #0e029c 15%, #4d42c9 ,#7a51f5)'}}>
                <MdGroups size={38} color='white' style={{color:'white !important'}}/>
                <div style={{marginLeft:'20px'}}>
                <h6 style={{fontSize:'14px',color:'white'}}>Onboarded Employee</h6>
                <h6 style={{fontSize:'18px',color:'white',fontWeight:'bolder'}}>{employee.length + inactive_employee.length}</h6>
                </div>
            </div> 
            <div style={{margin:'20px 0px',padding:'40px 15px',width:'24%',borderRadius:'10px',display:'flex',alignItems:'center',backgroundImage:'linear-gradient(to right, #ffe436 25%,#fc9c0d ,#fa8e00)'}}>
                <FiUserPlus size={34} color='white' />
                <div style={{marginLeft:'20px'}}>
                <h6 style={{ffontSize:'14px',color:'white'}}>Employee Active</h6>
                <h6 style={{fontSize:'18px',color:'white',fontWeight:'bolder'}}>{employee.length}</h6>
                </div>
            </div> 
            <div style={{margin:'20px 0px',padding:'40px 15px',width:'24%',borderRadius:'10px',display:'flex',alignItems:'center',backgroundImage:'linear-gradient(to bottom, #4c166e 8% ,#e3529a,#d678a7)'}}>
                <FiUserMinus size={34} color='white' />
                <div style={{marginLeft:'20px'}}>
                <h6 style={{ffontSize:'14px',color:'white'}}>Employee In-Active</h6>
                <h6 style={{fontSize:'18px',color:'white',fontWeight:'bolder'}}>{inactive_employee.length}</h6>
                </div>
            </div> 
            <div style={{margin:'20px 0px',padding:'40px 15px',width:'24%',borderRadius:'10px',display:'flex',alignItems:'center',backgroundImage:'linear-gradient(to bottom, #4a48c7 ,#373699 ,#2e2d6e)'}}>
                <SiPrivateinternetaccess size={34} color='white' />
                <div style={{marginLeft:'20px'}}>
                <h6 style={{ffontSize:'14px',color:'white'}}>Employee Roles</h6>
                <h6 style={{fontSize:'18px',color:'white',fontWeight:'bolder'}}>{roles_arr.length}</h6>
                </div>
            </div> 
            </div>

            {/* <div style={{display:'flex',marginBottom:'10px'}}>
                <h6 style={{marginTop:'40px',flex:0.7}}>Onboarded Employee</h6>
                <h6 style={{marginTop:'40px',flex:0.3,marginLeft:'30px'}}>Employee Roles</h6>
            </div>
           
            <div style={{width:'100%',display:'flex'}}>
                <div style={{flex:0.7,marginRight:'10px',boxShadow: '0 1px 1px 0 var(--awsui-color-shadow-medium,rgba(0,28,36,.3)),1px 1px 1px 0 var(--awsui-color-shadow-side,rgba(0,28,36,.15)),-1px 1px 1px 0 var(--awsui-color-shadow-side,rgba(0,28,36,.15))'}}>
                    <div style={{width:'100%',background:'#fafafa',display:'flex',padding:'6px',fontSize:'14px',margin:'0px 20px 0px 0px'}}>
                        <p style={{marginBottom:'0px',width:'20%',fontWeight:'600'}}>Name</p>
                        <p style={{marginBottom:'0px',width:'20%',fontWeight:'600'}}>Email</p>
                        <p style={{marginBottom:'0px',width:'20%',fontWeight:'600'}}>Fcpl_Id</p>
                        <p style={{marginBottom:'0px',width:'20%',fontWeight:'600'}}>Team</p>
                        <p style={{marginBottom:'0px',width:'20%',fontWeight:'600'}}>On Boarder on</p>
                    </div>

                    <div style={{width:'100%',background:'#fff',display:'flex',padding:'6px',fontSize:'14px',margin:'0px 20px 0px 0px',borderBottom:'1px solid #fafafa'}}>
                        <p style={{marginBottom:'0px',width:'20%'}}>Krishna prasad</p>
                        <p style={{marginBottom:'0px',width:'150px',marginRight:'30px',overflow:'auto'}}>krishna.prasad@fidelituscorp.com</p>
                        <p style={{marginBottom:'0px',width:'20%'}}>Fcpl_240</p>
                        <p style={{marginBottom:'0px',width:'20%'}}>IT Team</p>
                        <p style={{marginBottom:'0px',width:'20%'}}>12 Jul 2021</p>
                    </div>

                </div>

                
                <div  style={{flex:0.3,boxShadow: '0 1px 1px 0 var(--awsui-color-shadow-medium,rgba(0,28,36,.3)),1px 1px 1px 0 var(--awsui-color-shadow-side,rgba(0,28,36,.15)),-1px 1px 1px 0 var(--awsui-color-shadow-side,rgba(0,28,36,.15))'}}>
                    <div style={{background:'#fafafa',display:'flex',justifyContent:'space-between',padding:'6px',fontSize:'14px',}}>
                        <p style={{marginBottom:'0px',width:'100%',fontWeight:'600'}}>Roles Name</p>
                        <p style={{marginBottom:'0px',width:'100%',fontWeight:'600'}}>Is Active</p>
                    </div>
                    <div style={{background:'#fff',display:'flex',justifyContent:'space-between',padding:'6px',fontSize:'14px',borderBottom:'1px solid #fafafa'}}>
                        <p style={{marginBottom:'0px',width:'100%'}}>Developer </p>
                        <p style={{marginBottom:'0px',width:'100%',marginLeft:'20px'}}><BsFillCheckSquareFill color="#4fb3ff" /> </p>   
                    </div>
                </div>

            </div> */}
        </div>
    )
}

export default Controller_Dashboard
