import axios from 'axios';
import { authHeader } from '../../../helpers/usertoken/UserToken';


export const getContacts = async()=>{
    const res = await axios.get('contact/get', authHeader)
    return res.data.datas
}

export const getLeads = async(v='')=>{
    const res = await axios.get(`lead/get${v && '/'+v}`, authHeader)
    return res.data
}


export const nextpagefun = async(v)=>{
    console.log('iam here okna',v)
    const res = await axios.get(`lead/get/${v}`, authHeader)
    return res.data.datas
}

export const prevpagefun = async(v)=>{
    const res = await axios.get(`lead/get/${v}`, authHeader)
    return res.data.datas
}

export const getLead = async(id)=>{
    const res = await axios.get(`lead/get/${id}`, authHeader)
    return res.data.datas
}

export const getSources = async()=>{
    const res = await axios.get('source/get', authHeader)
    return res.data.datas
}

export const getIndustries = async()=>{
    const res = await axios.get('industry/get', authHeader)
    return res.data.datas
}
