import React from 'react'
import DetailDesignBody1 from './DetailDesignBody1'
import DetailDesignHeader1 from './DetailDesignHeader1'

function DetailDesign1(props) {

    const { type, func, data, getupdatedstate } = props
    return (
        <div style={{ width: '90%', margin: 'auto' }}>
            <DetailDesignHeader1 type={type} func={func} />
            <DetailDesignBody1 data={data} getupdatedstate={getupdatedstate} />
        </div>
    )
}

export default DetailDesign1
