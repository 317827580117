import React,{useState,useEffect} from 'react';

import TextInput from '../../components/ItComponents/TextInput';
import {  Itheadings, ItSmallText, ItSmallText1 } from '../../helpers/constants/ConstantsText';
import ITMain from './ITMain';
import { gray_col1, gray_col_light1, gray_dark_1 } from '../../helpers/constants/ConstantColor';
import PrimaryButton, { PrimaryButtonAdd } from '../../components/buttons/PrimaryButton';


import {IoClose} from 'react-icons/io5'
import {MdOutlineCable} from 'react-icons/md'

import { Modal} from 'antd';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import toast, { Toaster } from 'react-hot-toast'


import {
    createMuiTheme,
  } from "@material-ui/core/styles";
import axios from 'axios';
import { MdOutlineDelete } from 'react-icons/md';
import { BiEditAlt } from 'react-icons/bi';
import './Maincss.css';
import { light_colors_arr } from '../../helpers/Constants';



function Wire(){

    const theme = createMuiTheme({
        overrides: {
            MuiTooltip: {
            tooltip: {
                // fontSize: "2em",
                color: "black",
                backgroundColor: {gray_dark_1}
            }
            }
        }
    });


    const [modal,setmodal] = useState(false)
    const [wire,setwire] = useState({_id:null,type:''})
    const [message,setmessage] = useState('')

    const [wire_arr,setwire_arr] = useState([])

    useEffect(()=>{
        getdata()
    },[])  
    
    function getdata(){
        axios.get('it/wireschema/get',)
        .then((res)=>{
            setwire_arr(res.data.data)
        }).catch((err)=>{
            // console.log('err',err)
        })
    }

    function add_wireschema(){
        axios.post('it/wireschema/create',{type:wire.type})
        .then((res)=>{
            toast.success(res.data.message)
            setmodal(false)
            getdata()
        }).catch((err)=>{
        })
    }

    function update_wireschema(){
        axios.put(`it/wireschema/update/${wire._id}`,{type:wire.type,_id:wire._id,method:'_PUT'})
        .then((res)=>{
            toast.success(res.data.message)
            setmodal(false)
            getdata()
        }).catch((err)=>{
        })
    }

    function delete_wireschema(v){
        axios.delete(`it/wireschema/delete/${v._id}`)
        .then((res)=>{
            toast.success(res.data.message)
            getdata()
        }).catch((err)=>{
            // console.log('err here',err.response.data)
        })
    }



    return(
        <ITMain>
            <div style={{width:'95%'}}>
                <Toaster />

                <Modal visible={modal} footer={false} closable={false} width='260px'>
                    <div style={{width:'220px',position:'relative',height:'90px'}}>

                    <Tooltip title="Close" >
                            <IconButton style={{padding:'2px',marginTop:'-5px',position:'absolute',right:-10,top:-10}}>
                            <IoClose  onClick={()=>setmodal(!modal)} style={{ cursor: 'pointer',color:gray_dark_1, }} size={16} />
                            </IconButton>
                        </Tooltip>

                        <ItSmallText val={'Type Name'} />
                        <TextInput val={wire.type} onchange={(e)=>setwire({...wire,type:e.target.value})} />

                        <PrimaryButton btn_name={wire._id === null ? 'ADD DATA' : "UPDATE DATA"} onClick={wire._id === null ? add_wireschema : update_wireschema} />
                    </div>
                </Modal>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'-10px',borderBottom:`1px solid ${gray_col1}`}}>
                <Itheadings val={'Wire/Wireless Option Tab'} />
                  <div onClick={()=>(setwire({_id:null,wire:''}),setmodal(true))}>
                 <PrimaryButtonAdd btn_name={'Add Data'}  />
                 </div>
                </div>
            <div className='row'>

            {wire_arr.map((p)=>(
              <div className="col-md-3">  
              <div className='card_processor'  key={p._id} style={{display:'flex',alignItems:'center',margin:'15px 0px',cursor:'pointer',borderLeft:'4px solid #FFCAA1 ',paddingLeft:'10px',width:'100%'}}>
                <MdOutlineCable style={{backgroundColor:'#FFA156',color:'#000',padding:'2px',borderRadius:'2px'}} size={20} />
                <ItSmallText1 val={p.type} marginfun={'0px 0px 0px 10px'} />
                 
                <div style={{paddingLeft:'60px',display:'flex'}} className="delete_visible">
                <Tooltip title="Delete" >
                    <IconButton style={{marginTop:'-5px',color:'#000'}} onClick={()=>delete_wireschema(p)}>
                <MdOutlineDelete  size={16} style={{color:'#000'}} />
                </IconButton>
                </Tooltip>
                <Tooltip title="Edit" >
                    <IconButton style={{marginTop:'-5px',color:'#000'}} onClick={()=>(setwire(p),setmodal(true))}>
                <BiEditAlt  size={16} style={{color:'#000'}} />
                </IconButton>
                </Tooltip>
                </div>
              </div>
              </div>
            ))}
            </div>


            </div>
        </ITMain>
    )
}


export default Wire