import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import WebMettingDetail from './web/MettingDetail'
import MobileMettingDetail from './android/MettingDetail'

const index = () =>{
    if(isMobile){
        return(
            <div>
                <MobileMettingDetail />
            </div>
        )
    }else{
        return(
            <div>
                <WebMettingDetail />
            </div>
        )
    }
}

export default index