import React,{useEffect, useState} from 'react'
import FmsDashboardMainRoot from '../../FmsDashboardMainRoot'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Tabs,Select,Row, Col,  } from 'antd';
import { Pagination,Tag,DatePicker } from 'antd'
import { authHeader } from '../../../../helpers/usertoken/UserToken'
import axios from 'axios';
import { BiReset } from "react-icons/bi";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineFilter } from "react-icons/ai";
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import { BoldText1 } from '../../../../helpers/constants/ConstantsText';
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';
import { RiBillLine } from "react-icons/ri";


function WorkAssignedList() {


    const optionsArr = [{label:'Pending',value:'Pending'},{label:'Progress',value:'Progress'},{label:'Done',value:'Done'},{label:'Completed',value:'Completed'},{label:'Not Completed',value:'Not Completed'}]

    const userInfo = useSelector(state=>state.Auth)
    const roles = useSelector(state=>state.Auth.roles)
    const [search,setsearch] = useState({from_date:'',to_date:'',from_date1:'',to_date1:'',user:'',status:''})
    const [page,setpage] = useState(1)   
    const navigate = useNavigate()   

    const [pagination,setpagination] = useState({})
    const [fmsEmployee,setfmsEmployee] = useState([])
    const [data,setdata] = useState([])

    console.log("userinfo",userInfo)

    useEffect(()=>{
        getData()
    },[page])

    function getData(){
        axios.get(`fms/work_assigned/get?page=${page}&from_date=${search?.from_date}&to_date=${search?.to_date}&user=${search?.user}&status=${search?.status}`,authHeader)
        .then((res)=>{
            setdata(res?.data?.datas)
            setpagination(res?.data?.pagination)
        })
    }

   
    

    async function searchuser(v){ 
        await axios.get(`fms/fms_in_house_employee/search?search=${v}`,authHeader)
            .then((res)=>{
                let arr = []
                res?.data?.datas?.forEach(d => {
                    arr.push({label:`${d?.name}[Employee Id : ${d?.employee_id}]`,value:d?._id})
                });
                setfmsEmployee(arr)
                return arr
            }).catch((err)=>{
            })
    }        

    
  function applyfilter() {
    setpage(1)
    axios.get(`fms/work_assigned/get?page=${1}&from_date=${search?.from_date}&to_date=${search?.to_date}&user=${search?.user}&status=${search?.status}`,authHeader)
    .then((res)=>{
        setdata(res?.data?.datas)
        setpagination(res?.data?.pagination)
    })
  }  
  
  function resetfilter(){
    setpage(1)
    setsearch({from_date:'',to_date:'',from_date1:'',to_date1:'',user:'',status:''})
    axios.get(`fms/work_assigned/get?page=${1}&from_date=${''}&to_date=${''}&user=${''}&status=${''}`,authHeader)
    .then((res)=>{
        setdata(res?.data?.datas)
        setpagination(res?.data?.pagination)
    })
  }  
  
  function deleteData(id){
    axios.delete(`fms/work_assigned/delete/${id}`,authHeader)
    .then((res)=>{
        toast.success('Deleted Successfully')
        getData()
    })
  }

  return (
    <FmsDashboardMainRoot>
        <Toaster />
        <div style={{width:'98%'}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',borderBottom:'1px solid #eee'}}>
                <BoldText1 val={`Job Card (${pagination?.total})`} />
              

                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                
                {/* <div style={{cursor:'pointer',marginRight:10,border:'1px solid #ddd',borderRadius:5}}>
                <CiViewTable size={30} onClick={()=>settype(1)}  style={{background:type == 1 && theme_color,color:type == 1 && 'white',padding:5,borderRadius:5}} />    
                <CiGrid41 size={30} onClick={()=>settype(2)} style={{background:type == 2 && theme_color,color:type == 2 && 'white',padding:5,borderRadius:5}}  />    
                </div> */}
                <DatePicker style={{marginRight:'5px'}} value={search?.from_date} onChange={(e,e1)=>setsearch({...search,from_date:e,from_date1:e1})} placeholder='From Date' />
                <DatePicker style={{marginRight:'5px'}} value={search?.to_date} onChange={(e,e1)=>setsearch({...search,to_date:e,to_date1:e1})} placeholder='To Date' />
                
                <Select 
                   
                    value={search?.status === '' ? null : search?.status}
                    onChange={(v)=>setsearch({...search,status:v})}
                    options={optionsArr}
                    placeholder="Select Stage"
                    style={{ width: '120px',marginRight:'5px' }}
                  />
                
                {(roles?.includes('admin') || roles?.includes('fms_crm_head') || roles?.includes('fms_user') || roles?.includes('fms_hr') || roles?.includes('fms_ticket')) &&
                <Select 
                    showSearch
                    cacheOptions
                    filterOption={false}
                    allowClear={true}
                    value={search?.user == '' ? null : search?.user}
                    onSearch={searchuser}
                    placeholder="FMS Employee " 
                    closeMenuOnSelect={true}
                    onChange={(v)=>setsearch({...search,user:v})}
                    options={fmsEmployee}
                    style={{ width: '120px',marginRight:'5px' }}
                  />}
                
                <BiReset onClick={resetfilter} size={30} style={{background:'#e3e3e3',color:'#000',padding:7,marginRight:5,borderRadius:5}} />
                <AiOutlineFilter onClick={applyfilter} size={30} style={{background:'#0e68bf',color:'#fff',padding:7,marginRight:5,borderRadius:5}} />
                {!roles?.includes('fms_data_view')  && (roles?.includes('admin') || roles?.includes('fms_crm_head') || roles?.includes('fms_hr')) &&
                <PrimaryButton btn_name={'Create Work'} onClick={()=>navigate('/fms/work_assigned/create')} />}
                </div>


            </div>

            <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>

                <div className='mateial_table_header' style={{borderLeft:'1px solid #eee',borderRight:'1px solid #eee'}}>
                    <p className='checked_here' style={{opacity:0}} ></p> 
                    <p style={{fontSize:'11px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eee', color: 'black',marginLeft:'-10px',marginRight:'10px',opacity:'1' }}>SL No</p>
                    <p style={{fontSize:'12px',minWidth: '15%',maxWidth: '15%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Title</p>
                    <p style={{fontSize:'12px',minWidth: '15%',maxWidth: '15%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Description</p>
                    <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Working Employee</p>
                    <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Created</p>
                    <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Deadline</p>
                    <p style={{fontSize:'11px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Status</p>
                    <p style={{fontSize:'11px',minWidth: '15%',maxWidth: '15%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>Remarks</p>
                    <p style={{fontSize:'12px',minWidth: '8%',maxWidth: '8%' }}>Actions</p>
                </div>
                {data?.map((d,i)=>(
                <div key={d?._id} style={{borderLeft:'1px solid #eee',padding:'0px 0px !important',display:'flex',borderRight:'1px solid #eee',borderBottom:'1px solid #eee',height:'100%'}}>
                    <p className='checked_here' style={{opacity:0}} ></p> 
                    <p style={{fontSize:'11px',paddingLeft:'10px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eee', color: 'black',marginRight:'10px',opacity:'1' }}>{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
                    <p style={{fontSize:'12px',minWidth: '14.6%',maxWidth: '14.6%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>{d?.title}</p>
                    <p style={{fontSize:'12px',minWidth: '14.8%',maxWidth: '14.8%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>{d?.description}</p>
                    <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>{d?.assigned_employee?.map((a,icons)=>(
                        <span key={i}><span>{a?.name}</span><br></br></span> 
                    ))}</p>
                    <p style={{fontSize:'12px',minWidth: '9.6%',maxWidth: '9.6%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>{moment(d?.createdAt)?.format('ll')}</p>
                    <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>{moment(d?.deadline)?.format('ll')}</p>
                    <p style={{fontSize:'11px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>{d?.status}</p>
                    <p style={{fontSize:'11px',minWidth: '14.5%',maxWidth: '14.5%',borderRight:'1px solid #eee', marginRight: '0.5%' }}>{d?.remarks}</p>
                    <p style={{fontSize:'12px',minWidth: '8%',maxWidth: '8%' }}>
                    <p style={{fontSize:'12px',display:'inline',minWidth: '8%',maxWidth: '8%', marginRight: '0.5%' }}>
                            <RiBillLine style={{marginRight:'10px'}} onClick={()=>navigate('detail',{state:d})} />
                            {(d?.created_by?._id == userInfo?.id) &&
                            <AiOutlineEdit style={{marginRight:'10px'}} onClick={()=>navigate('edit',{state:d})} />}
                            {(roles?.includes('admin') || roles?.includes('fms_crm_head') || roles?.includes('fms_hr')) &&
                            <AiOutlineDelete onClick={()=>deleteData(d?._id)} />}
                    </p>
                    </p>
                </div>))}
            </div>

            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                {pagination.total > pagination?.limit &&
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpage(v)}  pageSize={pagination?.limit} />
                }
            </div>


        </div>
    </FmsDashboardMainRoot>
  )
}

export default WorkAssignedList