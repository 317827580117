import React, { useState, useEffect } from 'react'
import { Input, Row, Col, message } from 'antd';
import InputField from '../../../components/inputcomponents/InputField'
import { Validators } from '../../../components/Validators/Validators'
import DateField from '../../../components/inputcomponents/DateField';
import DropDown from '../../../components/inputcomponents/DropDown';
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import NavbarMobile1 from '../../mobileNavbar/NavbarMobile1';
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import { useSelector } from 'react-redux'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { name_reg_string, name_reg_validation } from '../../../helpers/constants/ConstantReg';
import toast, { Toaster } from 'react-hot-toast'

function CreateDeal() {


    const { deal_id } = useParams()
    const navigate = useNavigate()
    const { state, pathname } = useLocation()






    const rd = state?.datas



    let url_start_name = pathname.split('/')[1]

    const dealStage_proj =
        [
            {
                id: "Execution",
                value: "Execution"
            },
            {
                id: "Handing Over",
                value: "Handing Over"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Invoice raised",
                value: "Invoice raised"
            },
            {
                id: "Closed",
                value: "Closed"
            },
        ]
    const dealStage_trans =
        [
            {
                id: "Payment Received",
                value: "Payment Received"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Agreement/Registered",
                value: "Agreement/Registered"
            },
            {
                id: "Invoice Raised",
                value: "Invoice Raised"
            },
        ]
    const dealStage_hr =
        [
            {
                id: "Payment Received",
                value: "Payment Received"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Deal Closed",
                value: "Deal Closed"
            },
            {
                id: "Service stopped",
                value: "Service stopped"
            },
        ]
    const dealStage_fms =
        [
            {
                id: "Agreement Renewal",
                value: "Agreement Renewal"
            },
            {
                id: "Organic Growth",
                value: "Organic Growth"
            },
            {
                id: "Payment Received",
                value: "Payment Received"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Invoice Raised",
                value: "Invoice Raised"
            },
        ]
    const dealStage_gallery =
        [
            {
                id: "Payment Received",
                value: "Payment Received"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Invoise Raised",
                value: "Invoise Raised"
            },
            {
                id: "Work In Progess",
                value: "Work In Proges"
            },
        ]
    const dealType = [{
        id: "New Business",
        value: "New Business"
    },
    {
        id: "Existing Business",
        value: "Existing Business"
    }]

    const [edit, setedit] = useState(false)
    const [dealStage, setdealStage] = useState([])



    useEffect(async () => {

        if (deal_id && pathname.split('/')[1] === 'edit_deal') {
            setedit(true)
            axios.get(`deal/get/${deal_id}`, authHeader).then(async (res) => {
                const singleData = res.data.datas[0];
                const departement_arr_created = []
                await axios.get('department/get', authHeader)
                    .then((res) => {
                        res.data.datas.forEach(d => {
                            let data = {}
                            data['label'] = d.department_name
                            data['value'] = d.department_name
                            data['id'] = d.id
                            departement_arr_created.push(data)
                        })
                    })

                let lead_department = departement_arr_created?.find(d => d.id === singleData?.lead_id?.department_id)

                if (lead_department?.value === 'Transaction Team') {
                    setdealStage(dealStage_trans)
                } else if (lead_department?.value === 'Project Team') {
                    setdealStage(dealStage_proj)
                } else if (lead_department?.value === 'Hr Team') {
                    setdealStage(dealStage_hr)
                } else if (lead_department?.value === 'FMS Team') {
                    setdealStage(dealStage_fms)
                } else if (lead_department?.value === 'Gallery Team') {
                    setdealStage(dealStage_gallery)
                }

                setdata({
                    deal_name: singleData?.deal_name,
                    closing_date: singleData?.closing_date,
                    deal_title: singleData?.deal_title,
                    deal_stage: singleData?.deal_stage,
                    deal_owner: singleData?.deal_owner?.name,
                    deal_type: singleData?.deal_type,
                    description: singleData?.description,
                    contact_id: singleData?.lead_id?.contact_id,
                });
            })
        } else {
            const departement_arr_created = []
            await axios.get('department/get', authHeader)
                .then((res) => {
                    res.data.datas.forEach(d => {
                        let data = {}
                        data['label'] = d.department_name
                        data['value'] = d.department_name
                        data['id'] = d.id
                        departement_arr_created.push(data)
                    })
                })

            let lead_department = departement_arr_created?.find(d => d.id === rd?.department_id)

            if (lead_department?.value === 'Transaction Team') {
                setdealStage(dealStage_trans)
            } else if (lead_department?.value === 'Project Team') {
                setdealStage(dealStage_proj)
            } else if (lead_department?.value === 'Hr Team') {
                setdealStage(dealStage_hr)
            }
            setedit(false)
        }
    }, [])




    function selectedvalue(v) {
        if (v?.id) {
            setdataerr({ ...dataerr, [v.nameerr]: {} })
            setdata({ ...data, [v.name]: v.id });
        }
    }



    const name = useSelector(state => state.Auth.name)
    const id = useSelector(state => state.Auth.id)

    const [data, setdata] = useState({ ...rd, deal_owner: name, deal_name: '', deal_title: '', deal_Stage: '', deal_type: '', closing_date: '', description: '', id: '' })
    const [dataerr, setdataerr] = useState({
        deal_owner_err: {},
        deal_name_err: {},
        title_err: {},
        closing_date_err: {},
        stage_err: {},
        type_err: {},
        description_err: {}
    })

    function handleChange({ key, value }) {
        if (key === 'deal_name') {
            setdataerr({ ...dataerr, deal_name_err: {} })
        } else if (key === 'title') {
            setdataerr({ ...dataerr, title_err: {} })
        } else if (key === 'phone') {
            setdataerr({ ...dataerr, phoneerr: {} })
        } else if (key === 'email') {
            setdataerr({ ...dataerr, email_err: {} })
        } else if (key === 'company') {
            setdataerr({ ...dataerr, company_err: {} })
        } else if (key === 'lead_Status') {
            setdataerr({ ...dataerr, lead_Status_err: {} })
        } else if (key === 'country') {
            setdataerr({ ...dataerr, country_err: {} })
        } else if (key === 'state') {
            setdataerr({ ...dataerr, state_err: {} })
        } else if (key === 'city') {
            setdataerr({ ...dataerr, city_err: {} })
        }
        else if (key === 'city') {
            setdataerr({ ...dataerr, city_err: {} })
        }
        setdata({ ...data, [key]: value })

    }

    function adddeal() {
        if (!data.deal_owner) {
            setdataerr({ ...dataerr, deal_owner_err: { error: true, message: 'This field is required' } })
        } else if (!data.deal_name) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: { error: true, message: 'This field is required' } })
        } else if (!name_reg_validation(data.deal_name)) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: { error: true, message: `Deal name should contains only ${name_reg_string}` } })
        } else if (!data.closing_date) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: { error: true, message: 'This field is required' } })
        } else if (!data.deal_title) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: {}, title_err: { error: true, message: 'This field is required' } })
        } else if (!name_reg_validation(data.deal_title)) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: {}, title_err: { error: true, message: `Title should contains only ${name_reg_string}` } })
        } else if (!data.deal_stage) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: {}, title_err: {}, stage_err: { error: true, message: 'This field is required' } })
        } else if (!data.deal_type) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: {}, title_err: {}, stage_err: {}, type_err: { error: true, message: 'This field is required' } })
        } else {
            let set_Data = {
                deal_owner: id,
                deal_name: data.deal_name,
                deal_title: data.deal_title,
                closing_date: data.closing_date,
                deal_stage: data.deal_stage,
                deal_type: data.deal_type,
                description: data.description,
                lead_id: rd?._id
            }

            axios.post(`deal/create`, set_Data, authHeader).then((res) => {
                toast.success('Added Successfully')
                resetform()
                navigate('/view_deals')
            }).catch((err) => {
                if (err?.response?.status === 422) {
                    toast.error(err?.response?.data?.errors?.lead_id)
                }
            })
        }

    }

    function updatedeal() {
        if (!data.deal_owner) {
            setdataerr({ ...dataerr, deal_owner_err: { error: true, message: 'This field is required' } })
        } else if (!data.deal_name) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: { error: true, message: 'This field is required' } })
        } else if (!name_reg_validation(data.deal_name)) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: { error: true, message: `Deal name should contains only ${name_reg_string}` } })
        } else if (!data.closing_date) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: { error: true, message: 'This field is required' } })
        } else if (!data.deal_title) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: {}, title_err: { error: true, message: 'This field is required' } })
        } else if (!name_reg_validation(data.deal_title)) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: {}, title_err: { error: true, message: `Title should contains only ${name_reg_string}` } })
        } else if (!data.deal_stage) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: {}, title_err: {}, stage_err: { error: true, message: 'This field is required' } })
        } else if (!data.deal_type) {
            setdataerr({ ...dataerr, deal_owner_err: {}, deal_name_err: {}, closing_date_err: {}, title_err: {}, stage_err: {}, type_err: { error: true, message: 'This field is required' } })
        } else {

            let set_Data = {
                id: deal_id,
                deal_owner: id,
                deal_name: data.deal_name,
                deal_title: data.deal_title,
                closing_date: data.closing_date,
                deal_stage: data.deal_stage,
                deal_type: data.deal_type,
                description: data.description,
                lead_id: state?.datas?.id
            }
            axios.put(`deal/update/${deal_id}`, set_Data, authHeader)
                .then((res) => {
                    toast.success('Updated Successfully')
                    navigate('/view_deals')
                })
                .catch((err) => {
                    // console.log('err response here',err.response)
                })
        }
    }

    const { TextArea } = Input;

    const goback = () => {
        navigate(-1)
    }

    function resetform() {
        setdata({})
        setdataerr({})
    }

    return (
        <div>

            <NavbarMobile1 func={resetform} />
            <Toaster />
            <div style={{ width: '94%', margin: 'auto' }}>
                <h4 style={{ fontWeight: '600', marginBottom: '2px' }}>{url_start_name === 'create_deal' ? 'Create' : 'Edit'} Deal </h4>
                <h6 style={{ fontSize: '12px', color: 'grey' }}>Let's {url_start_name === 'create_deal' ? 'create' : 'update'}  a {url_start_name === 'create_deal' ? 'new' : 'old'} deal</h6>
            </div>



            <div style={{ width: '94%', margin: '10px 3%' }}>
                <div className="row mt-3">
                    <h6>Deal Information</h6>

                    <div className="row">
                        <div className="col-6" >
                            <div style={{ width: '100%', margin: '10px 0px' }}>
                                <p style={{ marginBottom: '8px', fontSize: '14px' }}>Deal Name</p>
                                <InputField
                                    size='large'
                                    type="text"
                                    priority="high"
                                    onChange={handleChange}
                                    name={'deal_name'}
                                    error_obj={dataerr.deal_name_err}
                                    value={data.deal_name}
                                    validators={[{
                                        check: Validators.required,
                                        message: 'This field is required'
                                    }, {
                                        check: Validators.minimum_length_required,
                                        message: 'Deal Name should be atleast 3 characters'
                                    }]}
                                />
                            </div>
                        </div>

                        <div className="col-6" >
                            <div style={{ width: '100%', margin: '10px 0px' }}>
                                <p style={{ marginBottom: '8px', fontSize: '14px' }}>Closing Date </p>
                                <DateField
                                    size='large'
                                    priority={'high'}
                                    name={'closing_date'}
                                    onChangefun={handleChange}
                                    error_obj={dataerr.closing_date_err}
                                    date_value={data.closing_date}
                                    validators={[{
                                        check: Validators.required,
                                        message: 'This field is required'
                                    }, {
                                        check: Validators.minimum_length_required,
                                        message: 'Title should be atleast 3 characters'
                                    }]}
                                />
                            </div>
                        </div>

                        <div className="col-6" >
                            <div style={{ width: '100%', margin: '10px 0px' }}>
                                <p style={{ marginBottom: '8px', fontSize: '14px' }}>Title</p>
                                <InputField
                                    type="text"
                                    priority="high"
                                    onChange={handleChange}
                                    name={'deal_title'}
                                    error_obj={dataerr.title_err}
                                    value={data.deal_title}
                                    validators={[{
                                        check: Validators.required,
                                        message: 'This field is required'
                                    }, {
                                        check: Validators.minimum_length_required,
                                        message: 'Title should be atleast 3 characters'
                                    }]}
                                    size={'large'}
                                />
                            </div>
                        </div>

                        <div className="col-6" >
                            <div style={{ width: '100%', margin: '10px 0px' }}>
                                <p style={{ marginBottom: '8px', fontSize: '14px' }}>Stage </p>
                                <DropDown
                                    onChange={(v) => selectedvalue(v)}
                                    priority={'high'}
                                    name='deal_stage'
                                    nameerr='stage_err'
                                    error_obj={dataerr.stage_err}
                                    dropdownValue={data.deal_stage}
                                    value={data.deal_stage}
                                    options={dealStage}
                                    size={'large'}
                                />
                            </div>
                        </div>
                        <div className="col-6" >
                            <div style={{ width: '100%', margin: '10px 0px' }}>
                                <p style={{ marginBottom: '8px', fontSize: '14px' }}>Deal Owner</p>
                                <InputField
                                    type="email"
                                    priority="high"
                                    onChange={handleChange}
                                    name={'deal_owner'}
                                    error_obj={dataerr.deal_owner_err}
                                    value={data.deal_owner}
                                    validators={[
                                        {
                                            check: Validators.required,
                                            message: 'This field is required'
                                        },
                                        {
                                            check: Validators.minimum_length_required,
                                            message: 'Deal owner is required'
                                        }]}
                                    size={'large'}
                                />
                            </div>
                        </div>
                        <div className="col-6" >
                            <div style={{ width: '100%', margin: '10px 0px' }}>
                                <p style={{ marginBottom: '8px', fontSize: '14px' }}>Type </p>
                                <DropDown
                                    onChange={(v) => selectedvalue(v)}
                                    priority={'high'}
                                    name='deal_type'
                                    nameerr='type_err'
                                    error_obj={dataerr.type_err}
                                    dropdownValue={data.deal_type}
                                    value={data.deal_type}
                                    options={dealType}
                                    size={'large'}
                                />
                            </div>
                        </div>

                        <div className="col-12">
                            <h6>Description Information</h6>
                            <TextArea style={{ height: 60, width: '100%', margin: 'auto' }}
                                onChange={(e) => setdata({ ...data, description: e.target.value })}
                                name={'description'}
                                error_obj={dataerr.description_err}
                                value={data.description}
                            />
                        </div>
                    </div>

                </div>


            </div>

            <div style={{ display: 'flex', width: '96%', marginTop: '20px', justifyContent: 'flex-end', paddingBottom: '40px', }}>
                {url_start_name === 'create_lead' &&
                    <div style={{ marginRight: '0px' }} onClick={() => goback()}>
                        <SecondaryButton
                            btn_name='Cancel'
                            padding={'7px 20px'}
                        />
                    </div>
                }
                <div style={{ marginRight: url_start_name !== 'create_lead' && '18px' }} onClick={(edit) ? updatedeal : adddeal} >
                    <PrimaryButton
                        padding={'7px 20px'}
                        btn_name={url_start_name === 'create_deal' ? "Save" : "Update"}
                    />
                </div>
            </div>
        </div>
    )
}

export default CreateDeal
