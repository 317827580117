import React, { useState } from 'react'
import { Card, Menu, Dropdown, Checkbox, Modal } from 'antd';
import { AiOutlineCalendar } from 'react-icons/ai'
import { GrCompliance } from 'react-icons/gr'
import { authHeader } from '../../helpers/usertoken/UserToken'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { FiEdit } from 'react-icons/fi'
import './DetailDesignBody1.css'
import { data_not_found } from '../../helpers/Constants';
function DetailDesignBody1({ data, getupdatedstate }) {

    const [singlestatus, setsinglestatus] = useState('')
    const [singletask, setsingletask] = useState({})
    const [modalVisible, setmodalVisible] = useState(false)


    const user_id = useSelector(state => state.Auth.id)
    const department = useSelector(state => state.Auth.department)


    const navigate = useNavigate()


    const menu = (
        <Menu style={{ background: 'black', marginBottom:'3px', color: 'white', width: '200px', marginTop: '5px' }}>
            <h6 style={{ color: 'white', fontSize: '13px',marginLeft:'18px',marginTop:'10px' }}>Update Task Status</h6>
            <div style={{ marginLeft: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'Not Started'} onChange={() => updatestatus('Not Started')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>Not Started</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'Deferred'} onChange={() => updatestatus('Deferred')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>Deferred</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'Waiting For Input'} onChange={() => updatestatus('Waiting For Input')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>Waiting For Input</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'In Progress'} onChange={() => updatestatus('In Progress')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>In Progress</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'Completed'} onChange={() => updatestatus('Completed')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>Completed</h6>
                </div>

            </div>
        </Menu>
    );

    const menu1 = (
        <Menu style={{ background: 'black', marginBottom:'3px', color: 'white', width: '250px', marginTop: '5px' }}>
            <h6 style={{ color: 'white', fontSize: '13px',marginLeft:'18px',marginTop:'10px'}}>Update Task Status</h6>
            <div style={{ marginLeft: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'Agreement'} onChange={() => updatestatus('Agreement')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>Agreement</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'Advance Collection'} onChange={() => updatestatus('Advance Collection')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>Advance Collection</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'Registration'} onChange={() => updatestatus('Registration')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>Registration</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'calls/follow up'} onChange={() => updatestatus('calls/follow up')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>calls/follow up</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'In Progress'} onChange={() => updatestatus('In Progress')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>In Progress</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'Inspection'} onChange={() => updatestatus('Inspection')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>Inspection</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'Invoice Raised'} onChange={() => updatestatus('Invoice Raised')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>Invoice Raised</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'LOI'} onChange={() => updatestatus('LOI')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>LOId</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'Meeting/Discussion'} onChange={() => updatestatus('Meeting/Discussion')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>Meeting/Discussion</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'Negotiation'} onChange={() => updatestatus('Negotiation')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>Negotiation</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'Options sent/provided'} onChange={() => updatestatus('Options sent/provided')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>Options sent/provided</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'Payment Pending follow up'} onChange={() => updatestatus('Payment Pending follow up')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>Payment Pending follow up</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'Payment Collection'} onChange={() => updatestatus('Payment Collection')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>Payment Collection</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'RFQ'} onChange={() => updatestatus('RFQ')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>RFQ</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={singlestatus === 'Waiting for Input'} onChange={() => updatestatus('Waiting for Input')} />
                    <h6 style={{ color: 'white', fontSize: '11px', paddingLeft: '10px', marginBottom: '0px' }}>Waiting for Input</h6>
                </div>
            </div>
        </Menu>
    );

    function updatestatusfun(v) {
        setsinglestatus(v.status)
        setsingletask(v)
    }

    function updatestatus(v) {
        setsinglestatus(v)
        const send_data = {
            'task_owner': user_id,
            'subject': singletask.subject,
            'due_date': singletask.due_date,
            'related_to': singletask.related_to,
            'contact_name': singletask.contact_name.id,
            'status': v,
            'priority': singletask.priority,
            'description': singletask.description
        }
        axios.put(`task/update/${singletask.id}`, send_data, authHeader)
            .then((res) => {
                getupdatedstate()
            })
    }

    function edittaskfunc(v) {
        navigate(`/edit_task/${v.id}`)
    }

    function expandmodal(v) {
        setsingletask(v)
        setmodalVisible(true)
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Modal visible={modalVisible} footer={false} onCancel={() => setmodalVisible(false)} centered>
                <h6 style={{ fontWeight: '700', marginBottom: '0px', width: '75%', }}>{singletask.subject}</h6>
                <p style={{ marginBottom: '10px' }}>{singletask?.task_owner?.name}</p>
                <p style={{ marginBottom: '0px', fontSize: '14px' }}>Due date: {moment(singletask?.due_date).format('LL')}</p>
                <p style={{ marginBottom: '0px', fontSize: '14px' }}>Created date: {moment(singletask?.created_date).format('LL')}</p>
                <h6 style={{ position: 'absolute', top: '50px', right: '50px', fontSize: '12px' }}>Priority:<span style={{ background: singletask.priority === 'High' ? 'red' : singletask.priority === 'Low' ? '#0fa336' : '#ffa230', padding: '4px', fontSize: '10px', borderRadius: '2px', fontWeight: 'bold', marginLeft: '5px', color: 'white' }}>{singletask.priority}</span></h6>

                {singletask.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', display: 'block', color: 'black', left: '0px', margin: '5px 0px', position: 'relative', paddingLeft: '4px' }}> LEAD NAME : <strong>{singletask?.contact_name?.lead_name}</strong></p>)}
                {singletask.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', display: 'block', color: 'black', left: '0px', margin: '5px 0px', position: 'relative', paddingLeft: '4px' }}> CONTACT NAME : <strong>{singletask?.contact_name?.contact_name}</strong></p>)}
                {singletask.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', display: 'block', color: 'black', left: '0px', margin: '5px 0px', position: 'relative', paddingLeft: '4px' }}> DEAL NAME : <strong>{singletask?.contact_name?.deal_name}</strong></p>}
                <p style={{ color: 'black' }}>{singletask.description}</p>

            </Modal>

            {data?.datas?.length === 0 ?

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',alignContent:'center', alignItems: 'center', width: '100%', marginTop: '50px' }}>
                    <img src={data_not_found} alt="img" style={{ width: '200px', height: '200px', objectFit: 'contain', display: 'flex', justifyContent: 'center' }} />
                    <h6 style={{ color: 'black', fontWeight: '600' }}>No task found for the day</h6>
                    <h6 style={{ color: 'black',fontSize:'13px',opacity:0.8, fontWeight: '400', }}>We hope you have not created any task till now its time to create some and work on it </h6>
                </div>

                :

                <>
                    {department[0].department_name !== 'Transaction Team' ?
                        <div style={{ display: 'flex', minWidth: '100%', maxWidth: '100%', overflow: 'scroll',justifyContent:'space-between' }}>
                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>Not Started</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'Not Started').length}</span>) Task Found</p>
                                {data?.datas?.filter(d => d.status === 'Not Started').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                        <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                        <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                            
                                            <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>Deferred</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'Deferred').length}</span>) Task Found</p>

                                {/* <h5 style={{padding:'10px',fontSize:'15px'}}>Deferred ({data?.datas?.filter(d=>d.status === 'In Progress').length})</h5> */}
                                {data?.datas?.filter(d => d.status === 'Deferred').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                        <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                        <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           
                                            <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>
                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>Waiting For Input</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'Waiting For Input').length}</span>) Task Found</p>

                                {data?.datas?.filter(d => d.status === 'Waiting For Input').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                        <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                        <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                            
                                            <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>In Progress</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'In Progress').length}</span>) Task Found</p>
                                                                   
                                {data?.datas?.filter(d => d.status === 'In Progress').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}
                                        <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                        <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           
                                            <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>Completed</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'Completed').length}</span>) Task Found</p>
                                 
                                {data?.datas?.filter(d => d.status === 'Completed').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                        <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                            <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                            <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>
                        </div>
                        :
                        <div style={{ display: 'flex', minWidth: '100%', maxWidth: '100%', overflow: 'scroll' }}>
                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>Agreement</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'Agreement').length}</span>) Task Found</p>
                                
                                {data?.datas?.filter(d => d.status === 'Agreement').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                       <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                             <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu1} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>

                                <h5 style={{marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>Advance Collection </h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'Advance Collection').length}</span>) Task Found</p>

                                {/* <h5 style={{padding:'10px',fontSize:'15px'}}>Deferred ({data?.datas?.filter(d=>d.status === 'In Progress').length})</h5> */}
                                {data?.datas?.filter(d => d.status === 'Advance Collection').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                        <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                        <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu1} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>calls/follow up</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'calls/follow up').length}</span>) Task Found</p>
                            
                                {data?.datas?.filter(d => d.status === 'calls/follow up').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                       <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                             <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu1} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>In Progress</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'In Progress').length}</span>) Task Found</p>
                            
                                {data?.datas?.filter(d => d.status === 'In Progress').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                       <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                             <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu1} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>Inspection</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'Inspection').length}</span>) Task Found</p>
                                
                                {data?.datas?.filter(d => d.status === 'Inspection').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                       <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                             <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu1} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>Invoice Raised</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'Invoice Raised').length}</span>) Task Found</p>
                                
                                {data?.datas?.filter(d => d.status === 'Invoice Raised').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                       <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                             <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu1} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>LOI</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'LOI').length}</span>) Task Found</p>
                                
                                {data?.datas?.filter(d => d.status === 'LOI').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                       <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                             <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu1} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>Meeting/Discussion</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'Meeting/Discussion').length}</span>) Task Found</p>
                                
                                {data?.datas?.filter(d => d.status === 'Meeting/Discussion').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                       <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                             <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu1} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>Negotiation</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'Negotiation').length}</span>) Task Found</p>
                                
                                {data?.datas?.filter(d => d.status === 'Negotiation').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                       <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                             <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu1} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>Options sent/provided</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'Options sent/provided').length}</span>) Task Found</p>
                                
                                {data?.datas?.filter(d => d.status === 'Options sent/provided').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                       <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                             <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu1} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>Payment Pending follow up</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'Payment Pending follow up').length}</span>) Task Found</p>
                                
                                {data?.datas?.filter(d => d.status === 'Payment Pending follow up').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                       <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                             <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu1} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>Payment Collection </h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'Payment Collection').length}</span>) Task Found</p>
                                
                                {data?.datas?.filter(d => d.status === 'Payment Collection').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                       <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                             <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu1} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>RFQ</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'RFQ').length}</span>) Task Found</p>
                                
                                {data?.datas?.filter(d => d.status === 'RFQ').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                       <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                             <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu1} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>
                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>Registration </h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'Registration').length}</span>) Task Found</p>
                                
                                {data?.datas?.filter(d => d.status === 'Registration').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                       <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                             <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu1} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>

                            <div className='task_web_section'>

                                <h5 style={{ marginBottom:'3px', fontSize: '15px',fontWeight:'700' }}>Waiting for Input</h5>
                                <p className='task_web_sub_head'>  (<span style={{color:'black'}}>{data?.datas?.filter(d => d.status === 'Waiting for Input').length}</span>) Task Found</p>
                                
                                {data?.datas?.filter(d => d.status === 'Waiting for Input').map((t, i) => (
                                    <Card key={i} style={{ width: '100%', margin: '2%', borderRadius: '3px', cursor: 'pointer' }}>

                                        {t.related_to === 'Lead' && (<p style={{ borderLeft: '4px solid #ffe83b', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Contact' && (<p style={{ borderLeft: '4px solid red', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>)}
                                        {t.related_to === 'Deal' && <p style={{ borderLeft: '4px solid #3895ff', height: '25px', display: 'block', color: 'white', left: '-1px', position: 'absolute' }}></p>}

                                       <h6 style={{ fontWeight: '700', marginBottom: '0px',fontSize:'12px' }} onClick={() => expandmodal(t)}>{t.subject.slice(0, 19)}{t.subject.length > 20 && '...'} </h6>
                                        <p style={{ marginBottom: '20px',fontSize:'10px'  }} onClick={() => expandmodal(t)}>{t.task_owner.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-15px' }}>
                                             <div style={{ background: '#fafafa', width: '100%', padding: '2px', borderRadius: '5px' }}>
                                                <AiOutlineCalendar size={16} color="#616161" />
                                                <span style={{ fontSize: '9px', fontWeight: '500', color: "#616161", paddingLeft: '5px' }}>{moment(t?.createdAt).format('lll')}</span>
                                            </div>

                                           <div style={{ display: 'flex',justifyContent: 'space-between',position:'absolute',top:'10px',right:'10px' }}>
                                                <FiEdit color="#616161" style={{marginRight:'10px'}} size={14} onClick={() => edittaskfunc(t)} />
                                                <Dropdown overlay={menu} trigger={['click']} style={{ background: '#616161',marginLeft:'20px' }} placement={'bottomCenter'}>
                                                    <GrCompliance color="#616161" size={14} onClick={() => updatestatusfun(t)} />
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </Card>
                                ))}
                            </div>
                        </div>
                    }
                </>
            } 

        </div>
    )
}

export default DetailDesignBody1
