import React, { useState } from 'react'
import xlsxixon from '../../../images/xls.png'
import { GiShare } from 'react-icons/gi'
import { MdAssignmentInd } from 'react-icons/md'
import { RiFileTransferLine } from 'react-icons/ri'
import { IoMenu } from 'react-icons/io5'
import { main_bg } from '../../constants/Constants1';
import { AiFillPlusCircle } from 'react-icons/ai'
import RootNavbar from '../../../screens/mobileNavbar/RootNavbar';

function MobileTableHeader({ type, addfun, setvisiblefun1, selected_contact, setshareContactVisiblefun, selected_lead, setvisiblefun2, setvisiblefun21, roles, assigned_lead, setIsModalVisible, }) {

    const [visible, setvisible] = useState(false)


    return (
        <div>
            <RootNavbar visible={visible} func={() => setvisible(false)} />
            <div style={{ marginTop: '0vh',maxHeight:'45px',minHeight:'45px', display: 'flex', justifyContent: 'space-between', width: '100vw', alignItems: 'center', position: 'sticky', top: 0, background: `${main_bg}`,zIndex:100 }}>
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => setvisible(true)}>
                    <IoMenu size={30} style={{ marginRight: 5,marginLeft:5, color: 'white', padding: 5, borderRadius: 4 }} />
                    <h6 style={{ fontSize: '15px', fontWeight: '600', marginBottom: '0px', color: '#fff', }}>{type}'s</h6>
                </div>


                {type !== 'Deal' &&
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                        {type === 'Contact' &&
                            <>
                                <img src={xlsxixon} alt="" style={{ width: '25px', height: '25px', resize: 'contain', marginRight: '10px' }} onClick={setvisiblefun1} />
                                {selected_contact && <GiShare color="#fff" size={24} style={{ marginRight: '15px' }} onClick={() => setshareContactVisiblefun()} />}
                            </>}

                        {type === 'Lead' && (selected_lead || assigned_lead) &&
                            <>
                                {(roles.find(r => r === 'admin') || roles.find(r => r === 'controller') || roles.find(r => r === 'hod') || roles.find(r => r === 'db_head') || roles.find(r => r === 'db_user') || roles.find(r => r === 'Manager')) &&
                                    <MdAssignmentInd size={26} color="#fff" style={{ marginRight: '15px', color: '#fff' }} onClick={assigned_lead ? setvisiblefun21 : setvisiblefun2} />}
                                <RiFileTransferLine size={24} style={{ marginRight: '15px', color: '#fff' }} />
                            </>}

                        <AiFillPlusCircle size={24} color="#fff" onClick={type === 'Lead' ? () => setIsModalVisible() : addfun} />
                    </div>}
            </div>
        </div>
    )
}

export default MobileTableHeader
