import React from 'react'
import BreadCrumbs from '../../../components/breadcrumbs/BreadCrumbs'
import DetailBoby from '../../../components/detail_design/DetailBoby'
import DetailHeader from '../../../components/detail_design/DetailHeader'


function MettingDetail() {
    
    const type= "Meeting"
    const headerValues = ['state.datas.company_name', 'state.datas.website', 'formatDate, state.datas.lead_status', 'state.datas.lead_name', 'state.datas.lead_owner']
    const label = ['Title','Contact Name','Date','Time','Location','Related To']
    const label1 = ['Description',]
    const section = ['Meeting Information','Description']
    const values = ['Krishna xyz asas','xyz company','12th jan 2021','12:20pm','pending','krishna']
    const values1 = ['sandkjah jfabhskjdnf jkdsbngjkd fjdshgjkhak dghkjfhdjks ghjkdhgk dhkj']

    return (
        <div style={{width:'70%',margin:'auto'}}>
            <BreadCrumbs />
            <DetailHeader type={type} headerValues={headerValues} />
            <div style={{width:'100%',}}>
            <DetailBoby section={section} values={[values,values1]} labels = {[label,label1]} type={type} />
            </div>
        </div>
    )
}

export default MettingDetail
