import React, { useState } from 'react'
import { Input, Modal, DatePicker } from 'antd';
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Validators } from '../Validators/Validators';
import PrimaryButton, { SecondaryButton } from '../buttons/PrimaryButton';
import InputField from '../inputcomponents/InputField';
import moment from 'moment'
import toast from 'react-hot-toast'
import Inputerror from '../errors/Inputerror';
import { authHeader } from '../../helpers/usertoken/UserToken';
import { IoIosClose } from 'react-icons/io';



function MeetingModal({ visible, func, data_id, type }) {



    const user_id = useSelector(state => state.Auth.id)
    // const [modal, setmodal] = useState(visible)
    const { TextArea } = Input;

    const [data, setdata] = useState({ title: '', related_to: type, contact: '', contact_id: '', date_time: '', location: '', description: '', id: '', edit: false })
    const [dataerr, setdataerr] = useState({ titleerr: {}, related_to_err: '', contacterr: {}, date_time_err: {}, location_err: {}, description_err: {} })

    const [remindar_data, setremindar_data] = useState({ on_date: '', on_time: '', repeat_type: '', notify: '' })
    const [remindar_data_err, setremindar_data_err] = useState({ on_date_err: {}, on_time_err: {}, repeat_type_err: {}, notify_err: {} })



    function handleChange({ key, value }) {
        if (key === 'title') {
            setdataerr({ ...dataerr, titleerr: {} })
        } else if (key === 'from_date') {
            setdataerr({ ...dataerr, from_date_err: {} })
        } else if (key === 'to_date') {
            setdataerr({ ...dataerr, to_date_err: {} })
        } else if (key === 'location') {
            setdataerr({ ...dataerr, location_err: {} })
        } else if (key === 'on_date') {
            setremindar_data_err({ ...remindar_data_err, on_date_err: {} })
        } else if (key === 'on_time') {
            setremindar_data_err({ ...remindar_data_err, on_time_err: {} })
        } else if (key === 'repeat_type') {
            setremindar_data_err({ ...remindar_data_err, repeat_type_err: {} })
        } else if (key === 'notify') {
            setremindar_data_err({ ...remindar_data_err, notify_err: {} })
        }
        if (key !== 'on_date' && key !== 'on_time' && key !== 'repeat_type' && key !== 'notify') {
            setdata({ ...data, [key]: value })
        } else {
            setremindar_data({ ...remindar_data, [key]: value })
        }
    }

    function addMetting() {

        const send_data = {}
        send_data['title'] = data.title
        if (type === 'contact') {
            send_data['related_to'] = 'Contact'
            send_data['contact_name'] = data_id?.id ? data_id?.id : data_id?._id
        } else if (type === 'Referred To me') {
            send_data['related_to'] = 'Contact'
            send_data['contact_name'] = data_id?.contact_id?.id ? data_id?.contact_id?.id : data_id?.contact_id?._id
        } else if (type === 'assign_lead') {
            send_data['related_to'] = 'Lead'
            send_data['contact_name'] = data_id?.id ? data_id?.id : data_id?._id
        } else if (type === 'assign_lead1') {
            send_data['related_to'] = 'Lead'
            send_data['contact_name'] = data_id?.assigned_lead_id?.lead_id?.id ? data_id?.assigned_lead_id?.lead_id?.id : data_id?.assigned_lead_id?.lead_id?._id
        } else if (type === 'deal') {
            send_data['related_to'] = 'Deal'
            send_data['contact_name'] = data_id?.id ? data_id?.id : data_id?._id
        }
        send_data['date_time'] = data.date_time
        send_data['location'] = data.location
        send_data['description'] = data.description
        send_data['host'] = user_id
        axios.post('meeting/create', send_data, authHeader)
            .then((res) => {
                resetform()
                toast.success('Meeting created successfully')
            }).catch((err) => {
                setdataerr({ ...dataerr, date_time_err: { error: true, message: err?.response?.data?.errors?.date_time } })
            })

    }

    function resetform() {
        func()
        setdata({ ...data, title: '', related_to: '', contact: '', from_date: '', from_time: '', location: '', description: '' })
        setdataerr({ ...dataerr, titleerr: {}, related_to_err: '', contacterr: {}, from_date_err: {}, from_time_err: {}, location_err: {}, description_err: {} })
    }

   

    function disabledDate(current) {
        return current && current < moment().startOf('day');
    }

    function handledatechange(v, v1) {
        setdata({ ...data, date_time: v1 })
        setdataerr({ ...dataerr, date_time_err: { status: false, message: '' } })
    }

    function handleok(v, v1) {
        console.log('handleok', v, v1)
    }

    return (
        <div>
            <Modal
                closable={false}
                // title="Meeting Information"
                style={{maxWidth:'400px' }}
                centered
                visible={visible}
                onOk={() => addMetting()}
                onCancel={() => func()}
                footer={null}
            >
                <IoIosClose size={20} onClick={()=>func()}  style={{position:'absolute',cursor:'pointer',right:'20px',top:'20px'}}/>
                <h6 style={{fontWeight:'700',fontSize:'14px'}}>Meeting Information</h6>
                <div className="row" >
                    <div className="col-md-12">
                        <div style={{ margin: '10px 0px' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Title</p>
                            <InputField
                                type="text"
                                priority="high"
                                onChange={handleChange}
                                name={'title'}
                                error_obj={dataerr.titleerr}
                                value={data.title}
                                validators={[{ check: Validators.required, message: 'This field is required' }, { check: Validators.minimum_length_required, message: 'Title should be atleast 3 characters' }]}

                            />
                        </div>
                    </div>

                    {/* <div className="col-md-6">
                        <div style={{ margin: '10px 0px' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Related To</p>
                            <InputField
                                type="text"
                                priority="high"
                                name={'Related To'}
                                error_obj={dataerr.related_to}
                                value={type === 'contact' && 'Contact', type === 'Referred To me' && 'Contact', (type === 'assign_lead1' || type === 'assign_lead') && 'Lead'}
                            />

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div style={{ margin: '10px 0px' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px', textTransform: 'capitalize' }}>{type === 'Referred To me' ? 'Contact' : (type === 'assign_lead1' || type === 'assign_lead') ? 'Lead' : type} Name</p>

                            <InputField
                                type="text"
                                priority="high"
                                name={'Related To Name'}
                                value={(type === 'contact' && data_id?.company_name), (type === 'Referred To me' && data_id?.contact_id?.company_name), (type === 'assign_lead' && data_id?.lead_name !== undefined ? data_id?.lead_name : ''), (type === 'assign_lead1' && data_id?.assigned_lead_id?.lead_id?.lead_name)}
                            />


                        </div>
                    </div> */}

                    <div className="col-md-6">
                        <div style={{ margin: '10px 0px', width: '100%', alignItems: 'center' }}>
                            <p style={{ fontSize: '14px', marginRight: '10px', marginBottom: '5px' }}>Date</p>
                            <DatePicker
                                style={{ marginTop: '3px', width: '100%', borderLeft: '4px solid #292961', borderRadius: '4px' }}
                                showTime
                                disabledDate={disabledDate}
                                onChange={handledatechange} onOk={handleok}
                                format="YYYY-MM-DD HH:mm"
                                value={data.date_time !== '' ? moment(data.date_time) : null}
                            />
                            <Inputerror message={dataerr.date_time_err.message} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div style={{ margin: '10px 0px' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Location</p>
                            <InputField
                                type="text"
                                priority="high"
                                onChange={handleChange}
                                name={'location'}
                                error_obj={dataerr.location_err}
                                value={data.location}
                                validators={[{ check: Validators.required, message: 'This field is required' }, { check: Validators.minimum_length_required, message: 'Location field should be required' }]}

                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div style={{ margin: '10px 0px' }}>
                            <p style={{ marginBottom: '8px', fontSize: '14px' }}>Description</p>
                            <TextArea value={data.description} onChange={(e) => setdata({ ...data, description: e.target.value })} size="medium" style={{ width: '100%', outline: 'none', boxShadow: 'none' }} />
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <div onClick={resetform}>
                            <SecondaryButton btn_name="Cancel" />
                        </div>
                        <div onClick={addMetting}>
                            <PrimaryButton btn_name={data.edit ? "Update" : "Save"} />
                        </div>
                    </div>

                </div>
            </Modal>
        </div>
    )
}

export default MeetingModal