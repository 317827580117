import React,{useState,useEffect} from 'react'
import axios from 'axios'
import { Validators } from '../../../components/Validators/Validators'
import InputField from '../../../components/inputcomponents/InputField'

import {Row,Col, message} from 'antd'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import {authHeader} from '../../../helpers/usertoken/UserToken'
import DeveloperMain from '../DeveloperMain'
import {Modal} from 'antd'
import moment from 'moment'
import {FiEdit} from 'react-icons/fi'
import {FcFullTrash} from 'react-icons/fc'
import {data_not_found} from '../../../helpers/Constants'

function AddIndustry() {

    const [data,setdata] = useState({industry_name:'',industry_id:'',description:'',edit:false})

    const [industry_data,setindustry_data] = useState([])
 
    const [visible,setvisible] = useState(false)
    const [visible1,setvisible1] = useState(false)
     
    useEffect(()=>{
        getIndustry()
    },[])
    const handleChange = ({key, value}) =>{ 
        setdata({...data, [key]: value})
    }

    function addIndustry(){

        const send_data = {}
        send_data['industry_name'] = data.industry_name
        send_data['description'] = data.description
            if(!data.edit){
                axios.post(`industry/create`,send_data,authHeader)
                .then((res)=>{
                    getIndustry()
                    message.success('Industry Created Successfully ')
                    setdata({...data,industry_name:'',industry_id:'',description:'',edit:false})
                    setvisible(false)
                })
                .catch((err)=>{
                    if(err?.response?.status === 422){
                    message.error(err?.response?.data.errors?.industry_name)
                    }
                })
            }else if(data.edit){
                axios.put(`industry/update/${data.industry_id}`,send_data,authHeader)
            .then((res)=>{
                getIndustry()
                message.success('Industry Updated Successfully ')
                setdata({...data,industry_name:'',industry_id:'',description:'',edit:false})
                setvisible(false)
            })
            .catch((err)=>{
                if(err?.response?.status === 422){
                message.error(err?.response?.data.errors?.industry_name)
                }
            })
        }
        
    }

    function addIndustryFun(){
       setdata({...data,industry_name:'',industry_id:'',description:'',edit:false})
       setvisible(true)
    }

    function getIndustry(){
        axios.get(`industry/get`,authHeader)
        .then((res)=>{
            setindustry_data(res.data.datas)
        })
    }

    function seteditfun(d){
        setdata({...data,industry_name:d.industry_name,description:d.description,industry_id:d.id,edit:true})
        setvisible(true)
    }

    function deleteindustry(d){
        setdata({...data,industry_name:d.industry_name,description:d.description,industry_id:d.id,edit:true})
        setvisible1(true)
    }

    function deleteselected(){
        axios.delete(`industry/delete/${data.industry_id}`,authHeader)
        .then((res)=>{
            message.success('Indusry deleted Successfully')
            getIndustry()
            setvisible1(false)
        })
    }



  return (
    <DeveloperMain>

        <Modal visible={visible} onCancel={()=>setvisible(false)} style={{maxWidth:'280px'}}  centered footer={false}>
       <Row >
                  <Col span={24} > 
                     <h4 style={{fontSize:'18px',fontWeight:'700'}}>Add Industry</h4>
                     <div style={{width:'100%'}}>
                         <p style={{marginBottom: '8px', fontSize: '14px'}}>Industry Name</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'industry_name'}
                            value={data.industry_name}
                            validators={[{
                                check: Validators.required,
                                message: 'This field is required'
                            }, {
                                check: Validators.minimum_length_required,
                                message: 'Industry Name should be atleast 3 characters'
                            }]}
                        />
                        </div>

                        <div style={{width:'100%'}}>
                        <p style={{marginBottom: '8px', fontSize: '14px'}}>Description</p>
                        <InputField
                            type="text"
                            priority="high"
                            onChange={handleChange}
                            name={'description'}
                            value={data.description}
                            validators={[{
                                check: Validators.required,
                                message: 'This field is required'
                            }, {
                                check: Validators.minimum_length_required,
                                message: 'Description field should be required'
                            }]}
                        />
                        </div>
                        
                        <div onClick={addIndustry}>
                        <PrimaryButton btn_name ="Add Industry" />
                        </div>
                 </Col>



        </Row> 
        </Modal>

        <Modal visible={visible1} onCancel={()=>setvisible1(false)} style={{maxWidth:'300px'}}  centered footer={false}>
              <div>
                  <h6 style={{fontWeight:'bolder'}}>Confirmation message</h6>
                  <p style={{fontSize:'11px',marginBottom:'4px'}}>Do you want to delete a industry with a name <b>{data.industry_name}</b> to be deleted</p>
                  <div style={{ borderLeftWidth: '4px', borderLeftColor: '#2bfc39', background: '#e8ffea', padding: '6px' }}>
                        <h3 style={{ fontSize: '10px',fontWeight:'bolder' }}>Be sure before confirmation this action cannot be reverted back </h3>
                    </div>
                  <div style={{width:'100%'}} onClick={deleteselected}>
                      <PrimaryButton btn_name={"I'm Sure"} />
                  </div>
              </div>
        </Modal>
         

         <div style={{width:'50%',margin:'50px 40px'}}>
            
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'10px'}}>
            <h6 style={{fontWeight:'800',fontSize:'15px'}}>Industry Added</h6> 
            <div onClick={addIndustryFun}>
                <PrimaryButton btn_name="Add Industry" />
            </div>
            </div>

             {industry_data.length > 0 ? 
            <div style={{ border:'0px solid lightgray',overflowY:'scroll'}}>
                <div className='mateial_table_header'>
                    <p style={{width: '10%',color:'black'}}> SL no</p>
                    <p style={{width: '25%',marginRight:'2%'}}>Name</p>
                    <p style={{width: '25%',marginRight:'2%'}}>Description</p>
                    <p style={{width: '18%'}}>Created At</p>
                    <p style={{width: '10%'}}>Options</p>
                </div>

                {industry_data.map((d,i) => (
                    <div key={d.id} className={'mateial_table_body'}>
                      
                        <p style={{width: '10%', color: "black",wordBreak:'break-word',fontWeight:'700'}}>{i+1}</p>
                        <p style={{width: '25%',wordBreak:'break-word',marginRight:'2%'}}>{d?.industry_name}</p>
                        <p style={{width: '25%',wordBreak:'break-word',marginRight:'2%'}}>{d?.description}</p>
                        <p style={{width: '18%',wordBreak:'break-word'}}>{moment(d?.createdAt).format('l')}</p>
                        <p style={{width: '10%',marginLeft:'-6px',display:'flex'}}>
                            <FiEdit size={18} style={{marginRight:'10px'}} onClick={()=>seteditfun(d)} /> 
                            <FcFullTrash size={18} onClick={()=>deleteindustry(d)}/>
                        </p>
  
                    </div>
                ))}



               
            </div>
            :
            <div style={{display:'grid',justifyContent:'center',alignItems:'center',marginTop:'100px'}}>
            <img src={data_not_found} alt="nodatafouund" style={{margin:'auto',width:'200px',objectFit:'contain'}} />
            <h4 style={{fontSize:'18px',fontWeight:'700',marginTop:'10px',textAlign:'center'}}>No Industry Found</h4>     
            <h6 style={{color:'#747474',fontSize:'13px',maxWidth:'85%',textAlign:'center',margin:'auto'}}>{"Click on a add industry Button. Then, you can find the Indusry on this page."}</h6>
            </div> 
            }
         </div>



    </DeveloperMain>
  )
}

export default AddIndustry