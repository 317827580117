import React from 'react'
import { isMobile } from '../../helpers/mobileresize/Mobileresize'
import WebCreateContact from './web/CreateContact'
import MobileCreateContact from './mobile/CreateContact'

const index = () =>{
    if(isMobile){
        return(
            <div>
                <MobileCreateContact />
            </div>
        )
    }else{
        return(
            <div>
                <WebCreateContact />
            </div>
        )
    }
}

export default index