import React,{useState,useEffect} from 'react'
import PrimaryButton,{SecondaryButton} from '../../../components/buttons/PrimaryButton'
import {BiEdit} from 'react-icons/bi'
import './DDR_Adding.css'
import {Checkbox,Modal} from 'antd'
import InputField from '../../../components/inputcomponents/InputField'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { Validators } from '../../../components/Validators/Validators'

function DesignationList({designation_arr,single_designation_sent,edit,editfunc,canceleditfun,fetch_all_designation}) {

    const [single_designation, setsingle_designation] = useState({designation_name:single_designation_sent?.designation_name,isActive:single_designation_sent?.isActive,id:single_designation_sent?.id})
    const [designation_name_err,setdesignation_name_err] = useState({})
    const [modalVisible,setmodalVisible] = useState(false)

    useEffect(() => {
        if(edit){
            setdesignation_name_err({})
            setsingle_designation({...single_designation,designation_name:single_designation_sent.designation_name,isActive:single_designation_sent.isActive,id:single_designation_sent.id})
        } else{
             setdesignation_name_err({})
             setsingle_designation({...single_designation,id:'',designation_name:'',isActive:''})
        } 
     }, [edit])
 
 
 
     function adddesignation(){
         if(!single_designation.designation_name){
             setdesignation_name_err({error:true,message:'This field is required'})
         }
         else{
             let send_data = {
                 'designation_name':single_designation.designation_name,
             }
             axios.post('designation/create',send_data,authHeader)
             .then((res)=>{
                 setsingle_designation({})
                 fetch_all_designation()
                 setmodalVisible(false)
             }).catch((err)=>{
                 if(err.response.status === 422){
                     setdesignation_name_err({error:true,message:err.response.data.errors.designation_name})
                 }
             })
             }
     }
 
     function updatedesignation(){
         let send_data = {
             designation_name:single_designation.designation_name,
             isActive:single_designation.isActive,
             id:single_designation.id
         }
         axios.put(`designation/update/${single_designation.id}`,send_data,authHeader)
         .then((res)=>{
             setsingle_designation({})
             fetch_all_designation()
             canceleditfun()
             setmodalVisible(false)
             // console.log('edit status',res.data)
         }).catch((err)=>{
             console.log('err obtained',err)
         })
     }
 
     function handleChange({key,value}){
         if(key === 'designation_name'){
             setdesignation_name_err({})
         }
         setsingle_designation({...single_designation,[key]:value})
     }

    return (
        <div style={{display:'flex',justifyContent:'space-between'}}> 

        <Modal visible={modalVisible} 
        onCancel={()=>setmodalVisible(false)}
        footer={false}
        style={{width:'90%',margin:'30% auto',minHeight:'300px'}}
        
        >
        <div style={{height:'160px'}}>
            <h6>{edit ? 'Edit' : 'Add' } Designation</h6>
            <InputField 
            placeholder={'Enter your designation name'}
            value={single_designation.designation_name}
            priority={'high'}
            size="large"
            type="text"
            name="designation_name"
            nameerr="departement_name_err"
            error_obj={designation_name_err}
            validators={[{check:Validators.required,message:'This field is required'},{check:Validators.minimum_length_required,message:'Designation Name should be atleast 3 characters'}]}
            onChange={handleChange}
            />
            <Checkbox checked={single_designation.isActive === true}  onChange={()=>setsingle_designation({...single_designation,isActive:!single_designation.isActive})}  style={{ margin:'15px 0px'}}>isActive</Checkbox>
            <div style={{display:'flex'}}>
                    <div style={{width:'150px'}} onClick={()=>{edit ? updatedesignation() : adddesignation()}}>
                    <PrimaryButton  btn_name = {`${edit ? 'Update' : 'Add' } Designation`}  />
                    </div>
                {edit && <div onClick={()=>setmodalVisible(false)}>
                    <SecondaryButton btn_name = 'cancel' />
                    </div>}
                </div>
        </div>

        </Modal>
    
    <div style={{flex: 1}}>
    
        <div style={{display:'flex',width:'100%',alignItems:'flex-start',justifyContent:'space-between'}}>
            <div style={{flex:0.66}}>
            <h6>All Designation</h6>
            <p style={{color:'#57606a',width:'90%'}}>Showing You all the designation added till now.</p>
            </div>
            <div style={{flex:0.34}} onClick={()=>setmodalVisible(true)} >
            <PrimaryButton btn_name="Add Designation" />
            </div>
        </div>

    <div style={{border:'1px solid #dbdbdb'}} className='table_ddr'> 
    <div style={{display:'flex',width:'100%',background:'#fafafa',padding:'10px'}}>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'10%',borderRight:'1px solid #dbdbdb'}}>ID</p>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'50%',borderRight:'1px solid #dbdbdb'}}>Designation Name</p>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'30%',borderRight:'1px solid #dbdbdb'}}>Status</p>
        <p></p>
    </div>
   {designation_arr?.map((d,i)=>(
    <div style={{display:'flex',width:'100%',background:'#fafafa',padding:'10px',borderTop:'1px solid #dbdbdb',backgroundColor:'white'}}>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'10%'}}>{i+1}</p>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'50%'}}>{d.designation_name}</p>
        <p style={{marginBottom:'0px',padding:'0px 10px',width:'30%'}}>{d.isActive ? <span style={{backgroundColor:'#edffe0',padding:'4px',borderRadius:'4px',textAlign:'center'}}>Yes</span> : <span style={{backgroundColor:'#fae3d9',padding:'4px',borderRadius:'4px',textAlign:'center'}}>No</span>} </p>
        <BiEdit  size={20} color="#292961" style={{cursor:'pointer'}} onClick={()=>{editfunc(d);setmodalVisible(true)}}/>
    </div>
    ))}
    </div>
    </div>
    </div>
    )
}

export default DesignationList
