import React,{useState,useEffect} from 'react'
import {AiOutlineUserDelete,AiOutlineUserAdd,AiFillFileAdd} from 'react-icons/ai'
import {BsPersonBoundingBox} from 'react-icons/bs'
import {MdRealEstateAgent} from 'react-icons/md'
import { useSelector } from 'react-redux'
import {useNavigate} from 'react-router-dom'

import './ControllerDashboard.css'
import axios from 'axios'
import {authHeader} from '../../../../helpers/usertoken/UserToken'

import {FiEdit} from 'react-icons/fi'
import {Drawer,Menu,Checkbox, message} from 'antd'
import { Tag, Modal,Select,Button } from 'antd';
import { Tabs,Input } from 'antd';
import {IoClose,IoMailOutline,IoMenu} from 'react-icons/io5'
import {Pagination} from 'antd'
import {FaUserShield} from 'react-icons/fa'
import {BiPlus,BiMinus} from 'react-icons/bi'

import {FaIdCardAlt} from 'react-icons/fa'
import {FiUser} from 'react-icons/fi'
import {FcDepartment} from 'react-icons/fc'
import {BiHighlight} from 'react-icons/bi'
import {VscFolderActive} from 'react-icons/vsc'
import {MdPendingActions} from 'react-icons/md'

function Controller_Dashboard() {

    const navigate = useNavigate()
    const name = useSelector(state=>state.Auth.name)

    const [employee,setemployee] = useState([])
    const [inactive_employee,setinactive_employee] = useState([])

    const [departement_arr, setdepartement_arr] = useState([])


    
    useEffect(()=>{
        getOnboardedEmployee()
        fetch_all_departement()
    },[])

    function getOnboardedEmployee(){
        axios.get(`user/get`,authHeader)
        .then((res)=>{
        //   setpagination(res.data.pagination)
          setemployee(res.data.datas.filter(d=>d.isActive === true))
          setinactive_employee(res.data.datas.filter(d=>d.isActive === false))
        })
    }

    function fetch_all_departement(){
        axios.get('department/get',authHeader)
        .then((res)=>{
          setdepartement_arr(res.data.datas)
        })
      }
  

    return (
        <div style={{marginTop:'50px',}}>

              <h3 style={{padding:'20px 0px 10px 10px',fontWeight:'900',textTransform:'Capitalize',fontSize:'18px'}}>Dashboard</h3>
              <h5 style={{fontWeight:'900',fontSize:'13px',paddingLeft:'10px',opacity:0.6,marginTop:'-15px',textTransform:'capitalize'}}>Welcome {name}</h5>
              <div style={{width:'100%',display:'flex',flexDirection:'row',overflow:'scroll',margin:'auto',marginLeft:'3px'}} className="hidescroll_bar">
                
                <div style={{width:'125px',height:'155px',borderRadius:'6px',minWidth:'145px',margin:'10px 5px',display:'grid',alignItems:'center',alignContent:'center',justifyItems:'center',justifyContent:'center',boxShadow:'0px 0 3px #e3e3e3'}}>
                    <div style={{margin:'5px 0px',padding:'10px 10px',alignItems:'center',background:'#fff',width:'100%',borderRadius:'6px',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <div style={{backgroundColor:'#f2fbff',padding:'10px',width:'60px',height:'60px',borderRadius:'4px',display:'grid',justifyContent:'center',alignItems:'center'}}>
                           <BsPersonBoundingBox size={16} color="#99b5ff"/>
                        </div>
                        <div style={{marginTop:'10px'}}>
                        <h6 style={{fontSize:'18px',color:'black',fontWeight:'900',textAlign:'center'}}>{employee?.length + inactive_employee?.length}</h6>
                        <h6 style={{fontSize:'12px',color:'black',fontWeight:'600',textAlign:'center',opacity:0.5,marginTop:'-5px'}}>Onboard Employee</h6>
                        </div>
                   </div> 
                </div>

                <div style={{width:'125px',height:'155px',borderRadius:'6px',minWidth:'145px',margin:'10px 5px',display:'grid',alignItems:'center',alignContent:'center',justifyItems:'center',justifyContent:'center',boxShadow:'0px 0 3px #e3e3e3'}}>
                    <div style={{margin:'5px 0px',padding:'10px 10px',alignItems:'center',background:'#fff',width:'100%',borderRadius:'6px',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <div style={{backgroundColor:'#fff2db',padding:'10px',width:'60px',height:'60px',borderRadius:'4px',display:'grid',justifyContent:'center',alignItems:'center'}}>
                           <AiOutlineUserAdd size={16} color="#ffc236"/>
                        </div>
                        <div style={{marginTop:'10px'}}>
                        <h6 style={{fontSize:'18px',color:'black',fontWeight:'900',textAlign:'center'}}>{employee?.length}</h6>
                        <h6 style={{fontSize:'12px',color:'black',fontWeight:'600',textAlign:'center',opacity:0.5,marginTop:'-5px'}}>Active Employee</h6>
                        </div>
                   </div> 
                </div>

                <div style={{width:'125px',height:'155px',borderRadius:'6px',minWidth:'145px',margin:'10px 5px',display:'grid',alignItems:'center',alignContent:'center',justifyItems:'center',justifyContent:'center',boxShadow:'0px 0 3px #e3e3e3'}}>
                    <div style={{margin:'5px 0px',padding:'10px 10px',alignItems:'center',background:'#fff',width:'100%',borderRadius:'6px',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                       <div style={{backgroundColor:'#fff5fb',padding:'10px',width:'60px',height:'60px',borderRadius:'4px',display:'grid',justifyContent:'center',alignItems:'center'}}>
                           <AiOutlineUserDelete size={16} color="#f06eff"/>
                        </div>
                        <div style={{marginTop:'10px'}}>
                        <h6 style={{fontSize:'18px',color:'black',fontWeight:'900',textAlign:'center'}}>{inactive_employee?.length}</h6>
                        <h6 style={{fontSize:'12px',color:'black',fontWeight:'600',textAlign:'center',opacity:0.5,marginTop:'-5px'}}>InActive Employee</h6>
                        </div>
                   </div> 
                </div>

                <div style={{width:'125px',height:'155px',borderRadius:'6px',minWidth:'145px',margin:'10px 5px',display:'grid',alignItems:'center',alignContent:'center',justifyItems:'center',justifyContent:'center',boxShadow:'0px 0 3px #e3e3e3'}}>
                    <div style={{margin:'5px 0px',padding:'10px 10px',alignItems:'center',background:'#fff',width:'100%',borderRadius:'6px',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                       <div style={{backgroundColor:'#fff3f0',padding:'10px',width:'60px',height:'60px',borderRadius:'4px',display:'grid',justifyContent:'center',alignItems:'center'}}>
                           <MdRealEstateAgent size={16} color="#ff6338"/>
                        </div>
                        <div style={{marginTop:'10px'}}>
                        <h6 style={{fontSize:'18px',color:'black',fontWeight:'900',textAlign:'center'}}>{departement_arr?.length}</h6>
                        <h6 style={{fontSize:'12px',color:'black',fontWeight:'600',textAlign:'center',opacity:0.5,marginTop:'-5px'}}>Departments</h6>
                        </div>
                   </div> 
                </div>
                
              </div>

            {/* <h3 style={{padding:'15px 0px 15px 10px',fontWeight:'900',fontSize:'16px'}}>Menu Options</h3> */}

            {/* <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',overflow:'scroll',margin:'auto',marginLeft:'3px'}}>
                  <h6 className='active-tab-cont' onClick={()=>setstep(1)}>Employee List</h6>
                  <h6 className='inactive-tab-cont' onClick={()=>setstep(2)}>Designation</h6>
                  <h6 className='inactive-tab-cont' onClick={()=>setstep(3)}>Department</h6>
                  <h6 className='inactive-tab-cont' onClick={()=>setstep(4)}>Roles</h6>
            </div>
            
            {step === 1 && 
            <div style={{width:'100%',margin:'auto',marginBottom:'100px',marginLeft:'20px',marginTop:'20px'}}>
            <div style={{overflowX:'scroll'}} className="hidescroll_bar">
            {employee.length > 0 &&
                    <table >
                    <tr >  
                        <th style={{minWidth:'70px',fontWeight:'800',color:'black'}}><BiHighlight size={18} /> ID</th>
                        <th style={{minWidth:'90px',fontWeight:'800',color:'black'}}><FaIdCardAlt size={18}/> FCPL ID</th>
                        <th style={{minWidth:'120px',fontWeight:'800',color:'black'}}><FiUser size={18} /> Name</th>
                        <th style={{minWidth:'150px',fontWeight:'800',color:'black'}}><IoMailOutline size={18} /> Email</th>
                        <th style={{minWidth:'150px',fontWeight:'800',color:'black'}}><FcDepartment size={18} /> Department</th>
                        <th style={{minWidth:'180px',fontWeight:'800',color:'black'}}><BiHighlight size={18} /> Designation</th>
                        <th style={{minWidth:'180px',fontWeight:'800',color:'black'}}><BiHighlight size={18} /> Roles</th>
                        <th style={{minWidth:'0px',fontWeight:'800',color:'black'}}><VscFolderActive size={18} /> Active</th>
                    </tr>

                    {employee.map((d,i)=>(
                    <tr key={d._id}  style={{borderBottom:'1px solid #eaeded'}}>
                        <td style={{minWidth:'70px',fontWeight:'800',color:'black',paddingLeft:'10px'}}>{i+1}</td>
                        <td style={{minWidth:'90px',fontWeight:'500',wordBreak:'break-word',fontWeight:'bolder'}}  className='active_link'>{d?.employee_id}</td>
                        <td style={{minWidth:'120px',wordBreak:'break-word'}}  className='active_link'>{d?.name}</td>
                        <td style={{minWidth:'150px',wordBreak:'break-word',marginRight:'1%'}}>{d?.official_email}</td>
                        <td style={{minWidth:'150px',wordBreak:'break-word',marginRight:'1%'}}><Tag color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{d?.department_id.map(d=>d.department_name)}</Tag></td>
                        <td style={{minWidth:'180px',wordBreak:'break-word',marginRight:'1%'}}  className='active_link'>{d?.designation_id[0]?.designation_name}</td>
                        <td style={{minWidth:'180px',wordBreak:'break-word',fontWeight:'bolder'}}>{d?.user_roles?.map((r)=>r.roles.map((r)=><Tag style={{marginBottom:'2px'}} color={`${colors[Math.floor(Math.random() * colors.length)]}`}>{r.role_name}  </Tag>))}</td>
                        <td style={{minWidth:'90px',wordBreak:'break-word',paddingLeft:'10px'}}><Checkbox checked={d?.isActive}  /></td>
                    </tr>
                    ))}
                    </table> }
          </div>  
            </div>  
            }

           {step === 2 && 
            <div style={{width:'100%',margin:'auto',marginBottom:'100px',marginLeft:'20px',marginTop:'20px'}}>
            <div style={{overflowX:'scroll'}} className="hidescroll_bar">
            {designation_arr.length > 0 &&
                    <table>
                    <tr>  
                        <th style={{minWidth:'70px',fontWeight:'800',color:'black'}}><BiHighlight size={18} /> ID</th>
                        <th style={{minWidth:'90px',fontWeight:'800',color:'black'}}><FaIdCardAlt size={18}/> Designation Name</th>
                        <th style={{minWidth:'120px',fontWeight:'800',color:'black'}}><FiUser size={18} /> Status</th>
                    </tr>

                    {designation_arr.map((d,i)=>(
                    <tr key={d._id}  style={{borderBottom:'1px solid #eaeded'}}>
                        <td style={{minWidth:'70px',fontWeight:'800',color:'black',paddingLeft:'10px'}}>{i+1}</td>
                        <td style={{minWidth:'90px',fontWeight:'500',wordBreak:'break-word',fontWeight:'bolder'}}  className='active_link'>{d?.designation_name}</td>
                        <td style={{minWidth:'120px',wordBreak:'break-word'}}  className='active_link'> <Checkbox checked={d?.isActive}  /></td>
                    </tr>
                    ))}
                    </table> }
          </div>  
            </div>  
            }

            {step === 3 && 
            <div style={{width:'100%',margin:'auto',marginBottom:'100px',marginLeft:'20px',marginTop:'20px'}}>
            <div style={{overflowX:'scroll'}} className="hidescroll_bar">
            {departement_arr.length > 0 &&
                    <table>
                    <tr>  
                        <th style={{minWidth:'70px',fontWeight:'800',color:'black'}}><BiHighlight size={18} /> ID</th>
                        <th style={{minWidth:'90px',fontWeight:'800',color:'black'}}><FaIdCardAlt size={18}/> Department Name</th>
                        <th style={{minWidth:'120px',fontWeight:'800',color:'black'}}><FiUser size={18} /> Status</th>
                    </tr>

                    {departement_arr.map((d,i)=>(
                    <tr key={d._id}  style={{borderBottom:'1px solid #eaeded'}}>
                        <td style={{minWidth:'70px',fontWeight:'800',color:'black',paddingLeft:'10px'}}>{i+1}</td>
                        <td style={{minWidth:'90px',fontWeight:'500',wordBreak:'break-word',fontWeight:'bolder'}}  className='active_link'>{d?.department_name}</td>
                        <td style={{minWidth:'120px',wordBreak:'break-word'}}  className='active_link'> <Checkbox checked={d?.isActive}  /></td>
                    </tr>
                    ))}
                    </table> }
          </div>  
            </div>  
            }

            {step === 4 && 
            <div style={{width:'100%',margin:'auto',marginBottom:'100px',marginLeft:'20px',marginTop:'20px'}}>
            <div style={{overflowX:'scroll'}} className="hidescroll_bar">
            {roles_arr.length > 0 &&
                    <table>
                    <tr>  
                        <th style={{minWidth:'70px',fontWeight:'800',color:'black'}}><BiHighlight size={18} /> ID</th>
                        <th style={{minWidth:'90px',fontWeight:'800',color:'black'}}><FaIdCardAlt size={18}/> Role Name</th>
                        <th style={{minWidth:'120px',fontWeight:'800',color:'black'}}><FiUser size={18} /> Status</th>
                    </tr>

                    {roles_arr.map((d,i)=>(
                    <tr key={d._id}  style={{borderBottom:'1px solid #eaeded'}}>
                        <td style={{minWidth:'70px',fontWeight:'800',color:'black',paddingLeft:'10px'}}>{i+1}</td>
                        <td style={{minWidth:'90px',fontWeight:'500',wordBreak:'break-word',fontWeight:'bolder'}}  className='active_link'>{d?.role_name}</td>
                        <td style={{minWidth:'120px',wordBreak:'break-word'}}  className='active_link'> <Checkbox checked={d?.isActive}  /></td>
                    </tr>
                    ))}
                    </table> }
          </div>  
            </div>  
            } */}


            
        </div>
    )
}






export default Controller_Dashboard
