import React, { useState, useEffect } from 'react'
import MobileTableHeader from '../../../components/bdcomponenttable/mobileComponents/MobileTableHeader'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import TableView1 from '../../../components/bdcomponenttable/mobileComponents/listview_mobile/TableView1';
import { Drawer, Input, Row, Col, Button, Tag } from 'antd'
import DateField from '../../../components/inputcomponents/DateField';
import { useSelector } from 'react-redux'
import { IoClose, IoFlask } from 'react-icons/io5';
import Loaders from '../../../components/Loaders/Loader'
import { main_bg } from '../../../components/constants/Constants1';
import { data_not_found } from '../../../helpers/Constants'
import toast, { Toaster } from 'react-hot-toast'

function ViewDeal() {


    const [loader, setloader] = useState(false)
    const roles = useSelector(state => state.Auth.roles)


    const department = useSelector(state => state.Auth.department)

    const [deals, setdeals] = useState([])
    const [deal_status_filter, setdeal_status_filter] = useState([])
    const [visible, setVisible] = useState(false)
    const [reminder, setReminders] = useState([])


    const [page, setpage] = useState(1)
    const [pagination, setpagination] = useState({})


    const [search_deal, setsearch_deal] = useState({ search_text: '', deal_stage: '', from_date: '', to_date: '', filter_status: false })
    const [tempsearch_deal, settempsearch_deal] = useState({ search_text: '', deal_stage: '', from_date: '', to_date: '' })

    const navigate = useNavigate()

    const dealStage_proj =
        [
            {
                id: "Execution",
                value: "Execution"
            },
            {
                id: "Handing Over",
                value: "Handing Over"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Invoice raised",
                value: "Invoice raised"
            },
            {
                id: "Closed",
                value: "Closed"
            },
        ]
    const dealStage_trans =
        [
            {
                id: "Payment Recieved",
                value: "Payment Recieved"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Agreement Done",
                value: "Agreement Done"
            },
            {
                id: "Registration Done",
                value: "Registration Done"
            },
            {
                id: "Invoice",
                value: "Invoice"
            },
            {
                id: "Raised Date ",
                value: "Raised Date "
            },
        ]
    const dealStage_hr =
        [
            {
                id: "Payment Received",
                value: "Payment Received"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Deal Closed",
                value: "Deal Closed"
            },
            {
                id: "Service stopped",
                value: "Service stopped"
            },
        ]
    const dealStage_fms =
        [
            {
                id: "Agreement Renewal",
                value: "Agreement Renewal"
            },
            {
                id: "Organic Growth",
                value: "Organic Growth"
            },
            {
                id: "Payment Recieved",
                value: "Payment Recieved"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Invoice Raised",
                value: "Invoice Raised"
            },
        ]
    const dealStage_gallery =
        [
            {
                id: "Payment Received",
                value: "Payment Received"
            },
            {
                id: "Payment Pending",
                value: "Payment Pending"
            },
            {
                id: "Invoise Raised",
                value: "Invoise Raised"
            },
            {
                id: "Work In Progess",
                value: "Work In Proges"
            },
        ]

    useEffect(() => {
        if (department[0].department_name === 'Transaction Team') {
            setdeal_status_filter(dealStage_trans)
        } else if (department[0].department_name === 'Project Team') {
            setdeal_status_filter(dealStage_proj)
        } else if (department[0].department_name === 'Hr Team') {
            setdeal_status_filter(dealStage_hr)
        } else if (department[0].department_name === 'FMS Team') {
            setdeal_status_filter(dealStage_fms)
        } else if (department[0].department_name === 'Gallery Team') {
            setdeal_status_filter(dealStage_gallery)
        }
    }, [])

    function gotodealcreatefun() {
        navigate("/create_deal")
    }

    useEffect(() => {
        getDeals()
    }, [])

    function getDeals() {
        setloader(true)
        axios.get(`deal/get/${page}`, authHeader)
            .then((res) => {
                setloader(false)
                setpagination(res.data.pagination)
                setdeals(res.data.datas)
            })
    }
    function editdeal(id) {
        navigate(`/edit_deal/${id}`)
    }
    function handleChange({ key, value }) {
        settempsearch_deal({ ...tempsearch_deal, [key]: value })
        if (tempsearch_deal.from_date && tempsearch_deal.to_date) {
            setsearch_deal({ ...search_deal, from_date: tempsearch_deal.from_date, to_date: tempsearch_deal.to_date })
        }
    }

    function applyfilter() {
        if (search_deal.search_text || search_deal.deal_stage || search_deal.from_date || search_deal.to_date) {
            setloader(true)
            axios.get(`deal/search?search_text=${search_deal.search_text}&deal_stage=${search_deal.deal_stage}&from_date=${search_deal.from_date}&to_date=${search_deal.to_date}&page=${page}`, authHeader)
                .then((res) => {
                    setloader(false)
                    setsearch_deal({ ...search_deal, filter_status: true })
                    setpagination(res.data.pagination)
                    setdeals(res.data.datas)
                    setVisible(false)
                })
        } else {
            toast.info('select any filter option before applying ')
        }
    }

    function resetfilter() {
        setsearch_deal({ search_text: '', deal_stage: '', from_date: '', to_date: '', filter_status: false })
        settempsearch_deal({ search_text: '', deal_stage: '', from_date: '', to_date: '' })
        getDeals()
        setVisible(false)
    }

    async function getReminder(id) {
        await axios.get(`reminder/get?type=${'Deal'}&type_id=${id}`, authHeader).then((res) => {
            setReminders(res.data.datas.reverse())
        })
    }

    function dealdetailfunc(v) {
        navigate(`/deal_detail/${v.id}`, { state: { datas: v } })
    }





    return (
        <div style={{ background: '#fff', height: '100%' }}>
            {loader && <Loaders />}
            <Toaster />
            <Drawer placement={'bottom'} visible={visible} height={'300px'} width={'85%'} closable={false} maskClosable={false} bodyStyle={{ background: '#fff', margin: '5%', padding: '0px' }} className='scrollbarhider'>
                <div>
                    <div style={{ zIndex: 100, position: 'relative', background: '#fff' }}>
                        <div style={{ alignItems: 'center', margin: '0px 0px', marginBottom: '20px' }}>
                            <h5 style={{ fontWeight: '800', textAlign: 'center' }}>Filter</h5>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', background: '#f0f0f0', borderRadius: 4, padding: 5, position: 'absolute', left: '0px', top: '0px' }} onClick={() => setVisible(false)}>
                            <IoClose size={12} color='black' />
                        </div>

                        <h6 style={{ position: 'absolute', right: '0px', top: '0px', fontWeight: '800', color: '#d9d9d9' }} onClick={resetfilter}>Reset</h6>
                    </div>

                    <div style={{ width: '100%', overflow: 'scroll' }} className='scrollbarhider'>
                        <div style={{ margin: '10px 0px', paddingBottom: '10px', marginTop: '10px' }}>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h6 style={{ fontWeight: '600', fontSize: '14px', opacity: 1 }}>Add Search Query</h6>
                                </div>

                                {search_deal.search_text ?
                                    <div style={{ display: 'flex', alignItems: 'center', background: '#f8f9fa', padding: '12px', borderRadius: '2px', maxWidth: '100%', position: 'relative' }}>
                                        <h6 style={{ marginBottom: '0px', fontSize: '12px' }}>{search_deal.search_text.slice(0, 20)}{search_deal.search_text.length > 20 && '...'}</h6>
                                        <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px' }} onClick={() => setsearch_deal({ ...search_deal, search_text: '' })} />
                                    </div>
                                    :
                                    <div style={{ display: 'flex', width: '100%', border: '1px solid black', height: '40px', borderRadius: '4px', borderColor: '#f5f5f5', overflow: 'hidden', borderRightColor: '#fff', backgroundColor: '#fafafa' }}>
                                        <Input type={"text"} style={{ width: '100%', margin: 'auto', marginLeft: '0%', padding: '5px', border: 'none', background: '#fafafa', boxShadow: 'none' }} value={tempsearch_deal.search_text} onChange={e => settempsearch_deal({ ...tempsearch_deal, search_text: e.target.value })} />
                                        <div style={{ marginTop: '-10px' }}>
                                            <Button style={{ fontWeight: '800', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '28px 12px', background: `${main_bg}`, color: '#fff' }} onClick={() => setsearch_deal({ ...search_deal, search_text: tempsearch_deal.search_text })}>+</Button>
                                            {/* <PrimaryButton btn_name ="+" style={{fontWeight:'800'}} padding='8px 12px' onClick={()=>setsearch_deal({...search_deal,search_text:tempsearch_deal.search_text})} /> */}
                                        </div>
                                    </div>}
                            </div>
                        </div>


                        <div style={{ margin: '10px 0px', paddingBottom: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h6 style={{ fontWeight: '600', fontSize: '14px', opacity: 1 }}>Date Interval</h6>
                                {/* {step === '3' ? <HiChevronUp size={22}  color="#c7c7c7"  onClick={()=>setstep('')} /> : <HiChevronDown size={22} color="#c7c7c7"  onClick={()=>setstep('2')}/>} */}
                            </div>

                            <div>
                                <div>
                                    {tempsearch_deal.from_date && tempsearch_deal.to_date ?
                                        <div style={{ width: '100%', background: '#fafafa', padding: '2px', height: '40px', borderRadius: '5px', position: 'relative' }}>
                                            <p style={{ paddingBottom: '0px', marginTop: '8px', paddingLeft: '10px' }}>{tempsearch_deal.from_date} - {tempsearch_deal.to_date}</p>
                                            <IoClose style={{ marginLeft: '10px', cursor: 'pointer', position: 'absolute', right: '10px', top: '12px' }} onClick={() => settempsearch_deal({ ...tempsearch_deal, from_date: '', to_date: '' })} />

                                        </div>
                                        :

                                        <Row style={{ width: '100%', margin: 'auto', display: 'flex' }}>
                                            <Col span={12} style={{ background: 'white', padding: '0px 5px', width: '100%' }}>
                                                <h6 style={{ fontSize: '12px', color: 'gray' }}>From Date</h6>
                                                {/* {tempsearch_deal.from_date ? <Tag style={{fontWeight:'600',fontSize:'13px',margin:'1.5%',padding:'4px 6px'}}>{tempsearch_deal.from_date}</Tag> : */}
                                                <DateField
                                                    onChangefun={handleChange}
                                                    name={'from_date'}
                                                    type="1"
                                                    size={'large'}
                                                    style={{ width: '100%', margin: 'auto', marginLeft: '0%', padding: '5px', border: 'none', background: '#fafafa', boxShadow: 'none' }}
                                                    date_value={tempsearch_deal.from_date}
                                                />
                                                {/* } */}
                                            </Col>

                                            <Col span={12} style={{ background: 'white', padding: '0px 5px' }}>
                                                <h6 style={{ fontSize: '12px', color: 'gray' }}>To Date</h6>
                                                {/* {tempsearch_deal.to_date ? <Tag style={{fontWeight:'600',fontSize:'13px',margin:'1.5%',padding:'4px 6px'}}>{tempsearch_deal.to_date}</Tag> : */}
                                                <DateField
                                                    name={'to_date'}
                                                    type="1"
                                                    size={'large'}
                                                    onChangefun={handleChange}
                                                    date_value={tempsearch_deal.to_date}
                                                    style={{ width: '100%' }} />
                                                {/* } */}
                                            </Col>

                                        </Row>}
                                </div>
                            </div>
                        </div>
                    </div>


                    {!roles?.includes('admin') && !roles?.includes('db_head') && !roles?.includes('db_user') && !roles?.includes('db_sub_user') &&
                        <div style={{ margin: '10px 0px', paddingBottom: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h6 style={{ fontWeight: '600', fontSize: '14px', opacity: 1 }}>Deal Stage</h6>
                            </div>

                            <div>
                                <Row style={{ width: '100%', margin: 'auto' }}>
                                    {deal_status_filter?.map((s, i) => (
                                        <>
                                            <Tag onClick={() => setsearch_deal({ ...search_deal, deal_stage: s.value })} color={search_deal.deal_stage === s.value ? "#0e68bf" : "default"} style={{ fontWeight: '600', fontSize: '13px', margin: '1.5%', padding: '4px 6px' }}>{s.value}</Tag>
                                        </>
                                    ))}
                                </Row>
                            </div>
                        </div>
                    }


                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                        {/* <div style={{display:'flex',justifyContent:'center',marginTop:'10px',marginRight:'10px'}} onClick={resetfilter}>
                    <Button size='large' style={{background:'#f5f5f7',border:'none',borderRadius:'5px',color:'black',fontWeight:'500',display:'flex',justifyContent:'center',justifyItems:'center'}}>Reset Filter</Button>
                    </div> */}
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', width: '100%' }} onClick={applyfilter}>
                            <Button size='large' style={{ background: `${main_bg}`, border: 'none', borderRadius: '2px', color: 'white', fontWeight: '500', display: 'flex', justifyContent: 'center', justifyItems: 'center', width: '100%' }}>Apply Filter</Button>
                        </div>
                    </div>
                </div>




            </Drawer>

            <div style={{ backgroundColor: 'white' }}>
                <MobileTableHeader btn_val="Add Deal" type="Deal" addfun={() => gotodealcreatefun()} />
            </div>
            <div style={{ height: '100%', width: '100%', backgroundColor: 'white' }}>


                <div style={{ position: 'fixed', right: '30px', width: '40px', bottom: '58px', backgroundColor: '#222f3e', padding: 8, borderRadius: 20 }}>
                    <IoFlask size={24} color="#fff" onClick={() => setVisible(true)} />
                </div>

                {deals?.length > 0 ?
                    <>
                        <div style={{ width: '96%', margin: 'auto', marginTop: '10px' }}>
                            <h6 style={{ fontSize: '13px', color: '#677078', marginTop: '10px' }}> <strong style={{ color: 'black' }}>Total Deals ({deals.length})</strong></h6>
                            <TableView1
                                data={deals}
                                type={'Deal'}
                                editdealfun={(v) => editdeal(v)}
                                reminder={reminder}
                                getreminderfun={(v) => getReminder(v)}
                                detailfun={(v) => dealdetailfunc(v)}
                            />
                        </div>
                    </>
                    :
                    <>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                            <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '130px', objectFit: 'contain' }} />
                            <h4 style={{ fontSize: '18px', fontWeight: '700', marginTop: '10px', textAlign: 'center' }}>{search_deal.filter_status ? 'No data Found' : "You don't have any deal created yet."}</h4>
                            <h6 style={{ color: '#747474', fontSize: '12px', maxWidth: '80%', textAlign: 'center', margin: 'auto' }}>{search_deal.filter_status ? 'No data found with provided search options please check other format filter options' : "Visit a Lead page and press on a convert icon. Then, you can find the deal on this page."}</h6>
                        </div>



                    </>


                }

                {pagination.total > 20 &&
                    <div style={{ width: '100%', display: 'grid', alignItems: 'center', position: 'fixed', bottom: '0px', marginBottom: '15px', paddingTop: '10px', marginLeft: '-14px', backgroundColor: 'white' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '42%', justifyContent: 'space-between', alignSelf: 'center', justifySelf: 'center' }}>
                            {page === 1 ?
                                <h6 style={{ color: '#d6d6d6' }}>{'< Prev'}</h6>
                                :
                                <h6 style={{ color: '#26c5ff' }} onClick={() => setpage(page - 1)}>{'< Prev'}</h6 >
                            }
                            <h6> {page} </h6>
                            {pagination.totalPages === page ?
                                <h6 style={{ color: '#d6d6d6' }}>{'Next >'}</h6>
                                :
                                <h6 style={{ color: '#26c5ff' }} onClick={() => setpage(page + 1)}>{'Next >'}</h6>
                            }
                        </div>
                    </div>}
            </div>



        </div>
    )
}

export default ViewDeal
