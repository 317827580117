import React,{useState,useEffect} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1 } from '../../../helpers/constants/ConstantsText';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import { data_not_found } from '../../../helpers/Constants';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { Pagination, Tabs, Tag } from 'antd'
import Avatar from '@mui/material/Avatar';
import { base_url } from '../../../App';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { MdDeleteOutline,MdOutlineRefresh } from 'react-icons/md'
import toast,{ Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { AiOutlineEdit } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';

function ExitEmployeeList() {

    const { TabPane } = Tabs;


    const navigate = useNavigate()

    const [search,setsearch] = useState('')
    const [filterApplied,setfilterApplied] = useState(false)
    const roles = useSelector(state => state.Auth.roles)
    const fcpl_id = useSelector(state => state.Auth.fcpl_id)

    const [data,setdata] = useState([])
    const [pagination,setpagination] = useState({})
    const [page,setpage] = useState(1)
    const [activetab,setactivetab] = useState('1')

    useEffect(()=>{
        if(filterApplied){
            getfilterdata1()
        }else{
            getdata()
        }
    },[filterApplied,page,activetab])

    function getdata(){
       
      axios.get(`fms/basicemployee/exit_employe?page=${page}&type=${activetab}`,authHeader)
      .then((res)=>{
        setdata(res.data.datas)
        setpagination(res.data.pagination)
      })
    }

    function getfilterdata1(){
            axios.get(`fms/basicemployee/exit_employe?search=${search}&page=${page}&type=${activetab}`,authHeader)
            .then((res)=>{
                console.log("res?.data",res?.data)
              setdata(res.data.datas)
              setpagination(res.data.pagination)
            })
    }

    function setpagefuun(v){
        setpage(v)
    }

    function deleterecord(v){
        axios.delete(`fms/basicemployee/delete/${v}`,authHeader)
        .then((res)=>{
            toast.success(res.data.message);
            getdata('1')
        })
    }

    function applyFilter(){
        setpage(1)
        if(search?.length > 2){
            setfilterApplied(true)
        }
    }

    function resetFilter(){
        console.log("amama")
        setfilterApplied(false)
        setsearch('')
        setpage(1)
        setactivetab(1)
        axios.get(`fms/basicemployee/exit_employe?search=${''}&page=${1}&type=${1}`,authHeader)
        .then((res)=>{
          setdata(res.data.datas)
          setpagination(res.data.pagination)
        })
    }

    


  return (
    <FmsDashboardMainRoot>
        <Toaster />
        <div style={{width:'98%'}}>
        <div style={{borderBottom:'1px solid #eee',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',paddingBottom:'5px'}}>
        <BoldText1  val={`Employee List (${pagination?.total})`} />
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        <input value={search} onChange={(e)=>setsearch(e.target.value)} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} onKeyPress={(e)=>e.key === 'Enter' && getfilterdata1()} /> 
        <BsSearch onClick={()=>{applyFilter();getfilterdata1()}} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
        <MdOutlineRefresh onClick={()=>resetFilter()} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
        </div>
        </div>
        </div>

        <Tabs 
        size="small"
        style={{ width: '98%', margin: '0px 0px 0px 0px', marginBottom: '0px', position: 'sticky', top: '40px' }}
        activetab={activetab}
        onChange={(v) =>{setactivetab(v)}}
        > 
        <TabPane tab="Due to Client" key="1" >
           {data.length > 0 ? 
            <div style={{ border: '0px solid lightgray',marginTop:'0px', overflowY: 'scroll',width:'100%' }}>
                <div className='mateial_table_header'>
                    <p className='checked_here' style={{opacity:0}} ></p> 
                    <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
                    <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                    <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                    <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                    <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                    <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                    <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                    <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Type</p>
                    <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                    <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                    <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                </div>


                {data.map((d, i) => (
                    <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%'}}>
                        <p   className='checked_here' style={{opacity:0}}>
                        </p>
                        <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                        <p style={{fontSize:'12px', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px',textTransform:'uppercase' }}><Avatar  sx={{ width: 24, height: 24,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
                        <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                        <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                        <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                        <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-20px' }}>{d?.designation?.designation_name}</p></Tooltip>
                        <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                        <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'6px' }}>{d.terminated ?  <Tag style={{fontSize:'9px'}} color="red">Terminated</Tag> : <Tag style={{fontSize:'9px'}} color="green">Client Exit</Tag>}</p></Tooltip>
                        <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
                        <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p>

                        <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                            {/* <Tooltip title='Edit'>
                            <span>
                            <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                            </span>
                            </Tooltip>
                            <Tooltip title='Form (F)'>
                            <span>
                            <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                            </span>
                            </Tooltip>
                            <Tooltip title='Statement Form'>
                            <span>
                            <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                            </span>
                            </Tooltip> */}
                            {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                            <Tooltip title='Delete Record'>
                            <span>
                            <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                            </span>
                            </Tooltip>}
                            {(roles.includes('admin') || roles.includes('fms_hr') || fcpl_id == 'fcpl_240') &&
                            <Tooltip title='Update Record'>
                            <span>
                            <AiOutlineEdit onClick={()=>navigate(`/fms/epi_form/edit/${d?._id}` ,{state:d?._id})}  size={15}   style={{marginLeft:'6px'}} />
                            </span>
                            </Tooltip>}
                        </p>
                    </div>
                ))}

            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                {pagination.total > 15 &&
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                }
            </div>

            </div> 
            :
            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
            </div>}
        </TabPane>

        <TabPane tab="Due to FO" key="2" >
        {data.length > 0 ? 
            <div style={{ border: '0px solid lightgray',marginTop:'0px', overflowY: 'scroll',width:'100%' }}>
                <div className='mateial_table_header'>
                    <p className='checked_here' style={{opacity:0}} ></p> 
                    <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
                    <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                    <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                    <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                    <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                    <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                    <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                    <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Type</p>
                    <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                    <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                    <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                </div>


                {data.map((d, i) => (
                    <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',margin:'-4px 0px !important'}}>
                        <p   className='checked_here' style={{opacity:0}}>
                        </p>
                        <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                        <p style={{fontSize:'12px', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px',textTransform:'uppercase' }}><Avatar  sx={{ width: 16, height: 16,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
                        <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                        <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                        <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                        <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-20px' }}>{d?.designation?.designation_name}</p></Tooltip>
                        <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                        <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'6px' }}>{d.terminated ?  <Tag style={{fontSize:'9px'}} color="red">Terminated</Tag> : <Tag style={{fontSize:'9px'}} color="green">Client Exit</Tag>}</p></Tooltip>
                        <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
                        <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p>

                        <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                            {/* <Tooltip title='Edit'>
                            <span>
                            <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                            </span>
                            </Tooltip>
                            <Tooltip title='Form (F)'>
                            <span>
                            <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                            </span>
                            </Tooltip>
                            <Tooltip title='Statement Form'>
                            <span>
                            <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                            </span>
                            </Tooltip> */}
                            {(roles.includes('admin') || roles.includes('fms_hr') || fcpl_id == 'fcpl_240') &&
                            <Tooltip title='Delete Record'>
                            <span>
                            <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                            </span>
                            </Tooltip>}
                            {(roles.includes('admin') || roles.includes('fms_hr')  || fcpl_id == 'fcpl_240') &&
                            <Tooltip title='Update Record'>
                            <span>
                            <AiOutlineEdit onClick={()=>navigate(`/fms/epi_form/edit/${d?._id}` ,{state:d?._id})} size={15}   style={{marginLeft:'6px'}} />
                            </span>
                            </Tooltip>}
                        </p>
                    </div>
                ))}

            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                {pagination.total > 15 &&
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                }
            </div>

            </div> 
            :
            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
            </div>}
        </TabPane>



        </Tabs>

       
        {/* <h6 className='text-[14px] font-[700]'>Employee List ()</h6> */}
    </FmsDashboardMainRoot>
  )
}

export default ExitEmployeeList